import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightFareOptionsModal } from "../../redux/reducers/modalSlice";
import { Close } from "@mui/icons-material";
import FareOptions from "../flight/FareOptions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function FlightFareOptionsModal({ data, handleFareSelect }) {
  const dispatch = useDispatch();
  const { flightFareOptionsModal } = useSelector((state) => state.modal);

  const handleOpen = () => dispatch(setFlightFareOptionsModal(true));
  const handleClose = () => dispatch(setFlightFareOptionsModal(false));

  return (
    <div>
      <Modal
        open={flightFareOptionsModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>Fare options</span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className={Classname.content}>
              <FareOptions data={data} handleReturn={handleFareSelect} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-start lg:items-center px-4 max-h-[80vh] overflow-scroll overflow-x-auto scroll-hide py-3",
  modalTitle: "text-xl lg:text-3xl font-bold text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
