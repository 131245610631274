import React from 'react'
import moment from 'moment';
import getFlightDuration from '../../../features/flight/getFlightDuration';
// import { offerDataTemp } from '../../../data/flight/offerData';
import wifi from '../../../assets/icons/amenities/Wifi.svg'
import cabin from '../../../assets/icons/amenities/ic_round-airline-seat-recline-normal.svg'
import food from '../../../assets/icons/amenities/ion_fast-food.svg'
import stopwatch from '../../../assets/icons/amenities/ion_stopwatch.svg'
import seat2 from '../../../assets/icons/amenities/seat-1.svg'
import seat from '../../../assets/icons/amenities/seat.svg'
import { Tooltip } from '@mui/material';
import Icon from '../../HOC/Icon';
import { formatMoney } from '../../../features/utils/formatMoney';


export const amenities = [
  {icon: seat,description: 'EXTRA LARGE SEAT',value: false, name: 'Extra large seat'},
  {icon: food,description: 'MEALS AND DRINKS',value: false, name: ''},
  {icon: seat2,description: '',value: false, name: ''},
  {icon: cabin,description: '',value: false, name: ''},
  {icon: stopwatch,description: '',value: false, name: ''},
  {icon: wifi,description: '',value: false, name: 'WIFI'},
]

export function FlightAmenities({flight}) {
  return (
    amenities.map((obj,i) => 
      flight?.amenities?.find(amen => (amen.description === obj.description)) || obj.value ?
      <Tooltip title={obj.name || obj.description} key={i}>
        <img alt='' src={obj.icon} />
      </Tooltip>
      :null
    )
  )
}
export default function FlightInfoCard({data,label='Depart',passengers}) {
  // let tdata = offerDataTemp.segments[0];
  // const departureDateTime = moment(`${data?.departureDate} ${data?.departureTime}`, "YYYY-MM-DD HH:mm");
  // const arrivalDateTime = moment(`${data?.arrivalDate} ${data?.arrivalTime}`, "YYYY-MM-DD HH:mm");

  // console.log(data?.flights?.map(flight => flight?.amenities)?.flat())
  console.log(data)
  let refundable = data?.fareRules?.rules?.find(rule => rule.category === 'REFUND')
  let changeable = data?.fareRules?.rules?.find(rule => rule.category === 'EXCHANGE')

  return (
    <div className=' rounded-md w-full border-gray-300 bg-secondary'>
      {/* <div className='flex gap-4 justify-between p-5'>
        <b className='flex gap-2'>
          {label} <span>-</span> {moment(data?.departureDate).format('ddd, DD MMM')}
        </b>
      </div> */}
      {
        data?.flights?.map((flight,i) => {
          refundable = refundable || flight?.amenities?.find(amen => amen.description === 'REFUNDS' || amen.description === "REFUNDABLE TICKET")
          let changable = changeable || flight?.amenities?.find(amen => amen.description === "CHANGEABLE TICKET")
          return (
          <div key={i}>

            <div className=' '>
              <div className='p-2'>
                <div className='flex flex-wrap gap-1 py-2 items-center text-sm'>
                  {/* <img alt='airline' src={flight.carrierIcon} className='w-[50px] max-h-[30px] !object-contain' />
                  {flight.carrierName}
                  <div className='flex flex-wrap gap-4 pl-5 items-center'>
                    <span>
                      {flight.cabin}, <b>{flight.bookingClass}</b> 
                    </span>
                    <span>{flight.aircraftType}</span>
                    <label className='flex gap-1 items-center text-xs'>
                      <Icon icon={'ph:bag-simple-fill'} className='p-1 -scale-x-100' />
                      {flight?.baggage}
                    </label>
                  </div> */}
                </div>
                <div className='flex p-3'>
                  <div className='flex text-[#aaa] gap-2 md:gap-4 w-full '>

                    <small className='font-bold flex gap-2 items-start'>
                      {/* <div className='dot mx-[4.7px] mt-2'></div> */}
                      <div className='flex flex-col flex-wrap gap-2'>
                        <div className='flex gap-1 flex-wrap'>
                          <b className='text-primary min-w-[50px] '>{flight.departureTime}</b>
                          <div>
                            {moment(data?.departureDate).format('ddd, DD MMM')}
                          </div>
                        </div>
                        <div className='text-primary'>{flight.departureAirport} ({flight.departureLocation})</div>
                      </div>
                    </small>
                    
                    {/* <div className='px-2 flex'>
                      <div className='vr'></div>
                    </div> */}
                    
                    <small className='flex flex-col items-center justify-center text-center mx-2'>
                      <p className='max-w-[140px] min-w-[50px]'>{flight.duration}</p>
                      <div className='flex gap-2'>                    
                        <Icon icon='uil:plane-fly' className='rotate-[30deg]' />
                      </div>
                    </small>
                    

                    <small className='font-bold flex gap-2 items-start'>
                      {/* <div className='dot mx-[4.7px] mt-2'></div> */}
                      <div className='flex flex-col flex-wrap gap-2'>
                        <div className='flex items-center gap-1 flex-wrap'>
                          <b className='text-primary min-w-[50px] '>{flight.arrivalTime}</b>
                          <div>
                            {moment(data?.arrivalDate).format('ddd, DD MMM')}
                          </div>
                          {
                            flight.arrivalTime >= flight.departureTime ? 
                            <small className='flex gap-1 px-2 items-center'>
                              <Icon icon='f7:moon-stars-fill' className='text-primary !w-4 !h-4' />
                              Night Trip
                            </small>
                            // <b className='bg-[#f48a3885] rounded-md px-2 p-1 text-[#533218]'>
                            //   Overnight
                            // </b>
                            :
                            <small className='flex gap-1 px-2 items-center'>
                              <Icon icon='material-symbols-light:clear-day' className='text-yellow-400' />
                              Day Trip
                            </small>
                            // <b className='bg-[#C8F0BC] rounded-md px-2 p-1 text-[#378e37]'>
                            //   Daylight
                            // </b>
                          }
                        </div>
                        <div className='text-primary'>{flight.arrivalAirport} ({flight.arrivalLocation})</div>
                      </div>
                    </small>

                  </div>
                </div>
              </div>
              {/* <div className='flex gap-2 p-4 px-6 indent-1 uppercase flex-wrap'>
                <div className='flex gap-4'>
                  <FlightAmenities flight={flight} />
                </div>
              </div> */}
            </div>
            <div className='flex gap-2 p-4 indent-1 uppercase flex-wrap'>
              {changable ? <label className={`${!changable?'error':'triumph'} text-xs bg-opacity-30`}>Changeable Ticket</label> : null}
              <label className={`${!refundable?'error':'triumph'} text-xs bg-opacity-30`}>{!refundable ? 'Non refundable Ticket':'Refundable Ticket'}</label>
            </div>

            {/* {i < data?.flights.length-1 ? (() => {
              const duration = getFlightDuration(moment(`${flight?.arrivalDate} ${flight?.arrivalTime}`),moment(`${data?.flights[i+1]?.departureDate} ${data?.flights[i+1]?.departureTime}`),'short');

              const [hours] = duration.split("H");
              const hour = parseInt(hours);

              let length = hour > 5 ? (hour > 12 ? "very long" : "long") : "short";
              const day = duration.split('day');
              if(day.length > 1)
                length = 'very long'
              // const length = 'short'; // long, very long 
              
              return (
                <div className='border-y p-3 flex items-center gap-1 flex-wrap whitespace-nowrap'>
                  <span className='min-w-[50px] pr-4'>
                    {duration}
                  </span>
                  <span className='pr-4'>
                    Changes plane at {flight.arrivalAirportName} ({flight.arrivalLocation}) 
                  </span>

                  <span className={`${length === 'long' ? 'error bg-opacity-50' : length === 'very long' ? 'error' : 'warn'} uppercase text-xs`}>{length} stopover</span>
                </div>
              )}
            )():null} */}
            {/* <div className='p-3 flex gap-6 items-center justify-end'>
              <small className='flex gap-1 items-center whitespace-nowrap'>
                <FitnessCenter className='text-sm font-bold rotate-[-45deg]' />
                {flight.weightUnit || data?.weightUnit} {flight.weightType || data?.weightType}
              </small>

              <h6 className='px-4 whitespace-nowrap'>{flight.cabin} {flight.bookingClass || data?.bookingClass}</h6>
            </div> */}


          </div>
          )
        })
      }
      {/* <p className='flex flex-col gap-2 p-4'>{Object.entries(passengers || {}).map(([label,obj],ind) => (
        <span key={ind} className='capitalize flex flex-wrap whitespace-nowrap'>
          {obj.total} {label} - {formatMoney(obj.totalAmount)}
          <span className='px-3'>Flight fare: {formatMoney(obj.totalAmountWithoutTax)}</span>
          <span>Tax: {formatMoney(obj.totalAmount - obj.totalAmountWithoutTax)}</span>
        </span>
      ))}</p> */}

    </div>
  )
}
