import { FormControlLabel, Radio, RadioGroup, Slider } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilter,
  setFilter,
  setTourFilterActive,
} from "../../redux/reducers/tour/filterSlice";
import TourFilteringComponent from "./TourFilteringComponent";

function TourFilterMobile() {
  const dispatch = useDispatch();
  const { filterData, tourFilterActive } = useSelector(
    (state) => state.tourFilter
  );

  const handleReset = () => {
    dispatch(clearFilter());
  };

  const handleApply = () => {
    dispatch(setTourFilterActive(false));
  };

  return (
    <div
      className={`${Classname.container} sidebar ${
        tourFilterActive ? "open" : ""
      }`}
    >
      <TourFilteringComponent />
      {tourFilterActive && (
        <div className="w-full h-14 flex border border-gray-200 fixed bottom-0 left-0">
          <div
            className="bg-white flex flex-1 items-center justify-center font-bold"
            onClick={handleReset}
          >
            Reset
          </div>
          <div
            className="bg-primary1 text-white flex flex-1 items-center justify-center font-bold"
            onClick={handleApply}
          >
            Apply
          </div>
        </div>
      )}
    </div>
  );
}

export default TourFilterMobile;

const Classname = {
  container:
    "w-full relative h-screen flex lg:hidden flex-col bg-gray-100 rounded-lg px-4 py-6 border border-gray-200 mb-20 overflow-scroll max-h-screen scroll-hide pb-44",
  title: "flex w-full justify-between items-center font-bold text-sm mt-2",
  closeIconContainer: "flex gap-2 items-center cursor-pointer",
  priceClear: "flex gap-2 items-center cursor-pointer text-primary1",
  filtersContainer: "flex flex-wrap gap-2 py-2",
  filter:
    "flex items-center cursor-pointer bg-primary1 text-white font-bold text-xxs gap-1 px-2 py-1 rounded-md",
  filterPriceBottom: "text-right text-sm mb-2",
  filterPriceAmount: "text-primary1 text-sm mt-2",
  filterTimeOption: "flex text-sm items-center justify-between",

  searchBarContainer:
    "w-full bg-white h-10 border-2 border-gray-200 rounded-lg flex items-center px-2 justify-between gap-2 mt-2 flex-shrink-0",
  searchInput: "text-sm",

  divider: "w-full border border-gray-200 my-1 mb-2",
};
