import { Button, ListItem, Select } from '@mui/material';
import {useEffect, useState} from 'react'
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Icon } from '@iconify/react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CustomDatePicker from '../Utils/Searchbar/DatePicker';
import { useSelector } from 'react-redux';
import CustomDropDown from '../Utils/Searchbar/DropDowns';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs';
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { offerSearchTemp } from '../../../data/flight/offerSearchData';
import { clone } from '../../../features/utils/objClone';
import { encrypt } from '../../../features/utils/crypto';
import DropDownSelect from '../Utils/Searchbar/DropDownSelect';
import SelectPassengers from '../Utils/Searchbar/SelectPassengers';



const SearchBar = ({state, returnData, curSearch}) => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [locations, setLocations] = useState([
    {
      "country": "Nigeria",
      "city": "Lagos",
      "airport":"Lagos, Murtala Muhammed Airport",
      "code":"LOS"
    },
    {
      "country": "United Kingdoms",
      "city": "London",
      "airport":"London, Great Airport",
      "code":"LOS"
    },
  ])
  const [tripType, setTripType] = useState({ label: "Round trip", value: "round_trip" });
  const [selectedTravelers, setSelectedTraveler] = useState({
    adult: 1,
    child: 0,
    infants: 0,
    });
  const updatePassengerCount = (type, action) => {
      if(type == "adult"){
          if(action == "add"){
              setSelectedTraveler({...selectedTravelers, adult: selectedTravelers.adult+1});
          }else{
              if(selectedTravelers.adult > 1){
                  setSelectedTraveler({...selectedTravelers, adult: selectedTravelers.adult-1});
              }
          }
      }
      else if (type=="children"){
        if(action == "add"){
            setSelectedTraveler({...selectedTravelers, child: selectedTravelers.child+1});
        }else{
            if(selectedTravelers.child > 0){
                setSelectedTraveler({...selectedTravelers, child: selectedTravelers.child-1});
            }
        }
      }
      else if (type=="infants"){
        if(action == "add"){
          setSelectedTraveler({...selectedTravelers, infants: selectedTravelers.infants+1});
        }else{
          if(selectedTravelers.infants > 0){
            setSelectedTraveler({...selectedTravelers, infants: selectedTravelers.infants-1});
          }
        }
      }
  }
  const [travelClass, setTravelClass] = useState({ label: "Economy", value: "Economy" });
  const travel = [
    { label: "One way", value: "one_way" },
    { label: "Round trip", value: "round_trip" },
    { label: "Multi-city", value: "multi_city" },
  ];
  const travelClassList = [
    { label: "Economy", value: "Economy" },
    { label: "Premium Economy", value: "PremiumEconomy" },
    { label: "Business", value: "Business" },
    { label: "First Class", value: "FirstClass" },
  ];
  const [travelCitys, setTravelCitys] = useState([
    {
      from: {},
      to: {},
      departure: {date: null},
      return: {date: null},
    },
  ]);
  const {enqueueSnackbar} = useSnackbar();

  const newCity = {
    from: {},
    to: {},
    departure: {date:dayjs()},
    return: {date:dayjs()},
  };
  const [destination,setDestination] = useState(offerSearchTemp.originDestinations);
  useEffect(()=>{
    setTravelCitys([
      {
        from: {},
        to: {},
        departure: {date:null},
        return: {date:null},
      }
    ])
  },[tripType])
  const swapLocations = () => {
    let temp = travelCitys[0].from;
    travelCitys[0].from = travelCitys[0].to;
    travelCitys[0].to = temp;
    setTravelCitys([...travelCitys]);
  }
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  let referralCode = searchParam.get('refCode');
  const validateInputs = () => {
    let stat = true
    if(tripType.value == "one_way"){
      if(isEmpty(travelCitys[0].from) || isEmpty(travelCitys[0].to) || travelCitys[0].departure == ""){
        stat = false
        enqueueSnackbar("Invalid Request! Please select valid destination and date.", { variant: 'error' });
      }
    }
    else if(tripType.value == "round_trip"){
      if(isEmpty(travelCitys[0].from) || isEmpty(travelCitys[0].to) || !travelCitys[0].departure || !travelCitys[0].return){
        stat = false
        enqueueSnackbar("Invalid Request! Please select valid destination and date.", { variant: 'error' });
      }
    }
    else if(tripType.value == "multi_city"){
      let valid = true;
      travelCitys.map((city)=>{
        if(isEmpty(city.from) || isEmpty(city.to) || !city.departure){
          stat = false
          valid = false;
        }
      })
      if(!valid){
        enqueueSnackbar("Invalid Request! Please select valid destination and date.", { variant: 'error' });
      }
    }
    if(selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants == 0){
      stat = false
      enqueueSnackbar("Invalid Request! Please select at least one passenger.", { variant: 'error' });
    }
    if(stat){
      let request = clone(offerSearchTemp)
      request.passengers = {
        adult: selectedTravelers.adult,
        child: selectedTravelers.child,
        infant: selectedTravelers.infants
      }
      request.cabinClass = [travelClass.value];
      if(tripType.value == "one_way"){
        request.originDestinations[0].from = travelCitys[0].from;
        request.originDestinations[0].to = travelCitys[0].to;
        request.originDestinations[0].date = dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ');
        request.originDestinations[0].departure = {date: dayjs(travelCitys[0].departure.date).format('YYYY-DD-mm')};
        request.destinations = []
        request.destinations.push({departureLocation: travelCitys[0].from.iata, arrivalLocation: travelCitys[0].to.iata, date: dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})    
        request.originDestinations.pop()
        request.flightType = "INTERNATIONAL"
      }
      else if(tripType.value == "round_trip"){
        request.originDestinations[0].from = travelCitys[0].from;
        request.originDestinations[0].to = travelCitys[0].to;
        request.originDestinations[0].date = dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ');
        request.originDestinations[0].departure = {date: dayjs(travelCitys[0].departure.date).format('YYYY-DD-mm')};
        request.destinations = []
        request.destinations.push({departureLocation: travelCitys[0].from.iata, arrivalLocation: travelCitys[0].to.iata, date: dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})    

        request.originDestinations[1].to = travelCitys[0].from;
        request.originDestinations[1].from = travelCitys[0].to;
        request.originDestinations[1].date = dayjs(travelCitys[0].return.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ');
        request.originDestinations[1].departure = {date: dayjs(travelCitys[0].return.date).format('YYYY-DD-mm')};
        // request.destinations = []
        request.destinations.push({departureLocation: travelCitys[0].from.iata, arrivalLocation: travelCitys[0].to.iata, date: dayjs(travelCitys[0].return.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})    
        request.flightType = "INTERNATIONAL"
      }
      else if(tripType.value == "multi_city"){
        request.originDestinations = []
        request.destinations = []
        travelCitys.map((city)=>{
          request.originDestinations.push({
            from: city.from,
            to: city.to,
            date: dayjs(city.departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ'),
            departure: {date: dayjs(city.departure.date).format('YYYY-DD-mm')}
          })
          request.destinations.push({departureLocation: city.from.iata, arrivalLocation: city.to.iata, date: dayjs(city.departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})
        })
        request.flightType = "INTERNATIONAL"
      }

      if(returnData)
        returnData(request)

        // return console.log(request)
        let enc = encrypt(JSON.stringify(request));
        navigate(`/order/new/flight/offers?referralCode=${referralCode}&q=${enc}`);
        // navigate(`/order/new/flight/template/1/offers?referralCode=${referralCode}&q=${enc}`);
      }
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  return (
    <div className='font-poppins bg-white p-4'>
      <div className="flex gap-2 sm:flex-wrap overflow-x-auto whitespace-nowrap">
        <DropDownSelect className={'shadow-none'} variant='standard' disableUnderline state={tripType} data={travel} handleDataChange={setTripType}/>
        <DropDownSelect className={'shadow-none'} variant='standard' disableUnderline state={travelClass} data={travelClassList} handleDataChange={setTravelClass}/>
        <div
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            className="rounded-md text-l cursor-pointer border-black/20 flex gap-2 justify-center items-center"
        >
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants}
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants > 1 ? " Passengers" : " Passenger"}
            <ArrowDropDownIcon />
        </div>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <SelectPassengers selectedTravelers={selectedTravelers} updatePassengerCount={updatePassengerCount} />
        </Popover>
        {/* <Dropdown>
          <MenuButton className="rounded-md px-5 py-4 text-l cursor-pointer bg-white border-0 shadow-md flex gap-2 justify-center items-center">
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants }
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants  > 1 ? " Passengers" : " Passenger"} 
            <ArrowDropDownIcon />
          </MenuButton>
          <Menu>
            <SelectPassengers selectedTravelers={selectedTravelers} updatePassengerCount={updatePassengerCount}/>
          </Menu>
        </Dropdown> */}
      </div>
      <div className='flex gap-4 flex-wrap md:flex-nowrap'>
        <div className='flex-1'>
      { travelCitys.map((_, i) => (
        <div className='flex gap-4 items-center'>
          <div className={`w-[300px] sm:w-full !max-w-[70vw] relative rounded-lg grid gap-4  mt-4 grid-cols-1 ${tripType.value != "round_trip" ? "grid-cols-1 md:grid-cols-3" : "lg:grid-cols-2"}`}>
            <div className={`grid grid-cols-1 ${tripType.value != "round_trip" ? "col-span-2" : "col-span-1"} md:grid-cols-2 gap-2 sm:rounded-xl border-2 border-solid border-[#EBEFF9]`}>
                <div className='flex relative'>
                  <div className='col-span-1 flex gap-2 items-center w-full text-sm text-[#343434] p-2 md:p-3 rounded-xl'>
                      <p className='text-sm my-1 flex gap-2 items-center'>
                        <Icon icon="mdi:airplane-takeoff" className='text-2xl'/>
                        {/* From? */}
                        
                        </p>
                      <CustomDropDown label='Leaving from' 
                        labelClass={'font-base font-normal !text-primary/50'} 
                        key={i} index={i} location="from" selectedOption={travelCitys} setSelectedOption={setTravelCitys} state={state}/>
                  </div>
                  {i == 0 ? <div className='absolute top-9 sm:top-0 right-2 sm:right-10 md:right-[-27px] h-full flex justify-end items-center'>
                    <div className="!border-3 flex justify-center items-center rounded-full p-1 !w-10 h-10 bg-white" style={{border: "3px solid #EBEFF9"}}>
                      <Icon icon="eva:swap-fill" className='text-2xl text-black hover:rotate-180 duration-150 cursor-pointer' onClick={swapLocations}/>
                    </div>
                  </div>:""}
                </div>
                <div className='col-span-1 flex gap-2 items-center text-sm text-[#343434] p-2 md:p-4 md:pl-6 rounded-xl'>
                    <p className='text-sm my-1 flex gap-2 items-center'>
                    <Icon icon="mdi:airplane-landing" className='text-2xl'/>
                    {/* To? */}
                    
                    </p>
                    <CustomDropDown label='Going to'
                      labelClass={'font-base font-normal !text-primary/50'} 
                      key={i} index={i} location="to" selectedOption={travelCitys} setSelectedOption={setTravelCitys} state={state}/>
                </div>
            </div>
            <div className={`grid grid-cols-1 col-span-1 items-center gap-2 ${tripType.value != "round_trip" ? "grid-cols-1 justify-center" : "md:grid-cols-2"} rounded-xl border-2 border-solid border-[#EBEFF9]`}>
                <div className='flex relative'>
                  <div className='col-span-1 w-full text-sm text-[#343434] p-2 md:p-4 rounded-xl'>
                      {/* <p className='text-sm my-1 flex gap-2 items-center'>
                        Departure
                        </p> */}
                        <CustomDatePicker hidePastDates className={'font-base !text-primary/50 font-normal'} 
                          label={'Select departure date'}
                          state={state} travelCitys={travelCitys} setTravelCitys={setTravelCitys} index={i} type="departure"/>
                  </div>
                  {
                  tripType.value == "round_trip" && 
                  <div className='absolute top-9 sm:top-0 right-2 sm:right-10 md:right-[-27px] h-full flex justify-end items-center'>
                    <div className="!border-3 flex justify-center items-center rounded-full p-1 !w-10 h-10 bg-white" style={{border: "3px solid #EBEFF9"}}>
                      <Icon icon="ion:calendar" className='text-2xl text-black' />
                    </div>
                  </div>
                  }
                </div>
                {
                  tripType.value == "round_trip" &&
                  <div className='col-span-1 text-sm text-[#343434] p-2 md:p-4 md:pl-6 rounded-xl'>
                    {/* <p className='text-sm my-1 flex gap-2 items-center'>
                    Return
                    </p> */}
                    <CustomDatePicker hidePastDates className={'font-base !text-primary/50 font-normal'} 
                      label={'Select return date'}
                      state={state} travelCitys={travelCitys} setTravelCitys={setTravelCitys} index={i} type="return"/>
                </div>
                }
            </div>
          </div>
        {i==0 ? "" : <Icon icon="carbon:close-filled" className='top-[40%] text-3xl cursor-pointer inline-block' style={{color: "red"}} onClick={()=> setTravelCitys( travelCitys.filter((city, index) => index !== i)) }/>}

        </div>
      ))
      }
        </div>
      <div className='flex'>
        <Button className={`w-full !px-10 !py-3 !capitalize self-end md:w-auto md:place-self-end md:justify-self-end align-self-end`} style={{backgroundColor: state.secondaryColor, color: "white", paddingLeft: "30px", paddingRight: "30px"}} onClick={validateInputs}>
          Search
        </Button>
      </div>
      </div>
      <div className={`flex flex-wrap gap-2 md:gap-0 justify-center md:justify-end mt-4 ${tripType.value == "multi_city" ? "w-full !justify-between" : ""}`}>
        {tripType.value == "multi_city" ?
        <Button className={`w-full md:w-auto md:place-self-end md:justify-self-end align-self-end`} style={{backgroundColor: state.secondaryColor, color: "white", paddingLeft: "30px", paddingRight: "30px"}} onClick={()=> setTravelCitys([...travelCitys, newCity])}>
            Add another flight
        </Button> : ""
        }
      </div>
    </div>
  );
}

export default SearchBar;