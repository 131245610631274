import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";


const Header = () => {
  const nav = useNavigate()
  return (
    <Link to="/website">
      <div className="h-20 mx-0 pl-4 shadow-md flex gap-2 mb-2 items-center">
        <Icon icon="ion:arrow-back" fontSize="medium" className="text-[#6563FF]"/>
        <span>Back to Home</span>
      </div>
    </Link>
  );
};

export default Header;
