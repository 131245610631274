import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  loadingModal: false,
  businessTypeChooseModal: false,
  confirmHotelBookingModal: false,
  confirmHotelBookingModalPaymentOption: "now",
  confirmTourBookingModal: false,
  gettingStartedVideoModal: false,
  gettingStartedLoadingModal: false,
  gettingStartedVideoModal: false,
  editHotelTravellers: false,
  hotelConfirmationPdfModal: false,
  hotelConfirmationPdfModalSendingEmail: false,
  hotelConfirmationPdfModalEmail: "",
  tourConfirmationPdfModal: false,
  tourConfirmationPdfModalSendingEmail: false,
  tourConfirmationPdfModalEmail: "",
  tourCancelModal: false,
  gettingStartedVideModalVideoId: "",
  gettingStartedVideModalName: "",
  ticketManagementLoadingModal: false,
  confirmFlightDateChangeModal: false,
  confirmFlightNameChangeModal: false,
  confirmFlightSubmitRefundRequestModal: false,
  confirmFlightRefundRequestModal: false,
  confirmFlightVoidRequestModal: false,
  confirmFlightAddBagModal: false,
  flightCompareModal: false,
  flightShareModal: false,
  flightFareBreakdownModal: false,
  flightFareOptionsModal: false,
  flightFareRulesModal: false,
  flightSeatMapModal: false,
  flightCheckedBaggageModal: false,
  flightRefundAndReissueModal: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setLoadingModal: (state, action) => {
      state.loadingModal = action.payload;
    },
    setBusinessTypeChooseModal: (state, action) => {
      state.businessTypeChooseModal = action.payload;
    },
    setConfirmHotelBookingModal: (state, action) => {
      state.confirmHotelBookingModal = action.payload;
    },
    setEditHotelTravellersModal: (state, action) => {
      state.editHotelTravellers = action.payload;
    },
    setConfirmHotelBookingModalPaymentOption: (state, action) => {
      state.confirmHotelBookingModalPaymentOption = action.payload;
    },
    setConfirmTourBookingModal: (state, action) => {
      state.confirmTourBookingModal = action.payload;
    },
    setGettingStartedLoadingModal: (state, action) => {
      state.gettingStartedLoadingModal = action.payload;
    },
    setGettingStartedVideoModal: (state, action) => {
      state.gettingStartedVideoModal = action.payload;
    },
    setHotelConfirmationPdfModal: (state, action) => {
      state.hotelConfirmationPdfModal = action.payload;
    },
    setHotelConfirmationPdfModalSendingEmail: (state, action) => {
      state.hotelConfirmationPdfModalSendingEmail = action.payload;
    },
    setHotelConfirmationPdfModalEmail: (state, action) => {
      state.hotelConfirmationPdfModalEmail = action.payload;
    },
    setTourConfirmationPdfModal: (state, action) => {
      state.tourConfirmationPdfModal = action.payload;
    },
    setTourConfirmationPdfModalSendingEmail: (state, action) => {
      state.tourConfirmationPdfModalSendingEmail = action.payload;
    },
    setTourConfirmationPdfModalEmail: (state, action) => {
      state.tourConfirmationPdfModalEmail = action.payload;
    },
    setTourCancelModal: (state, action) => {
      state.tourCancelModal = action.payload;
    },
    setGettingStartedVideoModalVideoId: (state, action) => {
      state.gettingStartedVideModalVideoId = action.payload;
    },
    setGettingStartedVideoModalName: (state, action) => {
      state.gettingStartedVideModalName = action.payload;
    },
    setTicketManagementLoadingModal: (state, action) => {
      state.ticketManagementLoadingModal = action.payload;
    },
    setConfirmFlightDateChangeModal: (state, action) => {
      state.confirmFlightDateChangeModal = action.payload;
    },
    setConfirmFlightNameChangeModal: (state, action) => {
      state.confirmFlightNameChangeModal = action.payload;
    },
    setConfirmFlightSubmitRefundRequestModal: (state, action) => {
      state.confirmFlightSubmitRefundRequestModal = action.payload;
    },
    setConfirmFlightRefundRequestModal: (state, action) => {
      state.confirmFlightRefundRequestModal = action.payload;
    },
    setConfirmFlightVoidRequestModal: (state, action) => {
      state.confirmFlightVoidRequestModal = action.payload;
    },
    setConfirmFlightAddBagModal: (state, action) => {
      state.confirmFlightAddBagModal = action.payload;
    },
    setFlightCompareModal: (state, action) => {
      state.flightCompareModal = action.payload;
    },
    setFlightShareModal: (state, action) => {
      state.flightShareModal = action.payload;
    },
    setFlightFareBreakdownModal: (state, action) => {
      state.flightFareBreakdownModal = action.payload;
    },
    setFlightFareOptionsModal: (state, action) => {
      state.flightFareOptionsModal = action.payload;
    },
    setFlightFareRulesModal: (state, action) => {
      state.flightFareRulesModal = action.payload;
    },
    setFlightSeatMapModal: (state, action) => {
      state.flightSeatMapModal = action.payload;
    },
    setFlightCheckedBaggageModal: (state, action) => {
      state.flightCheckedBaggageModal = action.payload;
    },
    setFlightRefundAndReissueModal: (state, action) => {
      state.flightRefundAndReissueModal = action.payload;
    },
  },
});

export const {
  setLoadingModal,
  setBusinessTypeChooseModal,
  setGettingStartedLoadingModal,
  setGettingStartedVideoModal,
  setGettingStartedVideoModalVideoId,
  setGettingStartedVideoModalName,
  setConfirmHotelBookingModal,
  setConfirmHotelBookingModalPaymentOption,
  setHotelConfirmationPdfModal,
  setHotelConfirmationPdfModalEmail,
  setHotelConfirmationPdfModalSendingEmail,
  setConfirmTourBookingModal,
  setTourConfirmationPdfModal,
  setTourConfirmationPdfModalEmail,
  setTourConfirmationPdfModalSendingEmail,
  setTourCancelModal,
  setConfirmFlightDateChangeModal,
  setTicketManagementLoadingModal,
  setConfirmFlightNameChangeModal,
  setConfirmFlightSubmitRefundRequestModal,
  setConfirmFlightRefundRequestModal,
  setConfirmFlightVoidRequestModal,
  setConfirmFlightAddBagModal,
  setFlightCheckedBaggageModal,
  setFlightCompareModal,
  setFlightFareBreakdownModal,
  setFlightFareOptionsModal,
  setFlightFareRulesModal,
  setFlightRefundAndReissueModal,
  setFlightSeatMapModal,
  setFlightShareModal,
  setEditHotelTravellersModal,
} = modalSlice.actions;

export default modalSlice.reducer;
