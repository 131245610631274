import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const Header5 = ({
  logo,
  primaryColor,
  secondaryColor,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const companyLogo = logo ? (
    <img src={logo} alt="Company Logo" className="w-14" />
  ) : (
    <div
      className={`bg-[#D9D9D9] p-2 py-3 font-extrabold w-36 flex justify-center text-${secondaryColor}`}
    >
      Company Logo
    </div>
  );

  return (
    <div className="bg-white shadow-md origin-top">
        <div className="grid grid-flow-row md:grid-flow-col grid-cols-1 justify-between px-10 font-poppins bg-[#F6F6F6]">
            <div className="flex gap-4 origin-left">
                <span className="flex gap-2 items-center">
                    <EmailOutlinedIcon style={{color: secondaryColor}}/>
                    <p>companyemail@gmail.com</p>
                </span>
                <span className="flex gap-2 items-center">
                    <LocalPhoneOutlinedIcon style={{color: secondaryColor}}/>
                    <p>(+234) 910362720</p>
                </span>
            </div>
            <div className="flex gap-4" >
                <span className="flex gap-2 items-center">
                    <AccessTimeOutlinedIcon style={{color: secondaryColor}}/>
                    <p>8AM util 6PM</p>
                </span>
            </div>
        </div>
        <div className="grid justify-between gap-4 grid-cols-2 md:grid-cols-4 font-poppins px-6 py-6" >
          <div className="col-span-1 md:col-span-2 origin-left">{companyLogo}</div>
          <div className="md:hidden col-span-1 flex items-center justify-end origin-left" >
              <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
          </div>
        <div
            className={`col-span-2 md:col-span-2 flex flex-col md:flex-row items-center gap-10 ${
            menuVisible ? "block" : "hidden"
            } md:flex justify-between`}
        >
            <div
            className={`flex flex-col md:flex-row justify-between items-center gap-10`}
            style={{ color: primaryColor }}
            >
            <div className="flex flex-col md:flex-row gap-10 items-center">
                <a>Home</a>
                <a>About Us</a>
                <a>Contact Us</a>
            </div>
            </div>
            <div
            className={`flex flex-col md:flex-row gap-10 items-center cursor-pointer`}
            style={{ color: secondaryColor }}
            >
            <Button
                style={{ background: secondaryColor }}
                size="large"
                variant="contained"
                className="!rounded-full"
            >
                {" "}
                Signup{" "}
            </Button>
            </div>
        </div>
        </div>
    </div>
  );
};

export default Header5;
