import React, { useState } from 'react'
import Modal1 from '../../../DIsplay/Modal/Modal1';
import Icon from '../../../HOC/Icon';
import ValuesForm from './ValuesForm';

export default function ValuesList({data,handleChange,remove}) {
    const [openForm,setOpenForm] = useState(false);

    function handleSubmit(data) {
        handleChange(data,data?.i)
        setOpenForm(false);
    }

    function handleRemove(ev,i) {
        ev?.stopPropagation();
        remove && remove(i)
    }


    return (
    <div className='w-full'>
        <div className='flex gap-2'>
            <div className='flex flex-wrap gap-4 w-full'>
                {data?.map((obj,i) => (
                    <div className='flex gap-2 min-w-[160px] bg-primary/10 cursor-pointer max-w-[300px]' key={i} onClick={() => setOpenForm({i,...obj})}>
                        <div className='flex-1 py-2 text-left p-4'>
                            {obj?.title}
                            <p>
                                {obj?.description}
                            </p>
                        </div>
                        <div>
                            <div className='self-start p-1 hover:shadow bg-primary/10' onClick={(ev) => handleRemove(ev,i)}>
                                <Icon icon='mdi:delete' className='text-red-600 !w-4 !h-4' />
                            </div>
                        </div>
                    </div>
                ))}
                
            </div>
            <div className='self-start bg-primary/5 cursor-pointer flex gap-1 py-1 px-2 text-sm items-center'
                onClick={() => setOpenForm(true)}
            >
                Add
                <Icon icon='mdi:add' className='!w-4 !h-4' />
            </div>
        </div>
            
        <Modal1 open={openForm} setOpen={setOpenForm}>
            <ValuesForm obj={openForm} handleChange={handleSubmit} />
        </Modal1>
    </div>
  )
}
