import { useDispatch, useSelector } from "react-redux";
import ISO6391 from "iso-639-1";

export function useFilter() {
  const dispatch = useDispatch();
  const { hotels } = useSelector((state) => state.hotelData);
  const { activities } = useSelector((state) => state.tourData);
  const { filterStayData, filterData } = useSelector(
    (state) => state.tourFilter
  );

  const allHotelAmenities = () => {
    let data = [];

    for (const hotel of hotels) {
      if (hotel?.details?.facilities) {
        data = [...data, ...hotel?.details?.facilities];
      }
    }
    let savedCodes = new Set();
    data = data.filter((item) => {
      if (!item?.description && !item?.title) return false;
      if (savedCodes.has(item.code)) {
        return false;
      } else {
        savedCodes.add(item.code);
        return true;
      }
    });

    data = data.map((item) => {
      let name = "";
      if (item?.title) {
        name = item?.title;
      } else {
        name = item?.description;
      }

      name = name.replace(/_/g, " ");
      name = name.replace(",HAC", " ");
      name = name.toLocaleLowerCase();
      name = name.charAt(0).toUpperCase() + name.slice(1);

      let amenity = {
        code: parseInt(item?.code),
        title: name,
      };
      return amenity;
    });

    return data;
  };

  const allTourLanguages = () => {
    let data = [];

    for (const tour of activities) {
      if (tour?.languageGuides) {
        data = [...data, ...tour?.languageGuides];
      }
    }
    let savedCodes = new Set();
    data = data.filter((item) => {
      if (!item?.language) return false;
      if (savedCodes.has(item.language)) {
        return false;
      } else {
        savedCodes.add(item.language);
        return true;
      }
    });

    data = data.map((item) => {
      if (item?.language) {
        return {
          code: item?.language,
          name: ISO6391.getName(item?.language) || "Unkown language",
        };
      }
    });
    return data;
  };

  const allTourTags = () => {
    let data = [];
    let parentTags = [];

    for (const tour of activities) {
      if (tour?.tags) {
        data = [...data, ...tour?.tags];
      }
    }
    let savedCodes = new Set();
    data = data.filter((item) => {
      if (!item?.tagId) return false;
      if (savedCodes.has(item.tagId)) {
        return false;
      } else {
        savedCodes.add(item.tagId);
        return true;
      }
    });
    for (const tag of data) {
      if (tag?.parentTagIds && tag?.parentTagIds?.length > 0) {
        parentTags = [...parentTags, ...tag?.parentTagIds];
      }
    }

    let savedTags = new Set();
    parentTags = parentTags.filter((item) => {
      if (savedTags.has(item)) {
        return false;
      } else {
        savedTags.add(item);
        return true;
      }
    });

    data = data.filter((item) => {
      let isInParentTag = parentTags.find((tag) => item?.tagId === tag);
      if (isInParentTag) return true;
      return false;
    });

    data = data.map((item) => {
      return {
        code: item?.tagId,
        label: item?.allNamesByLocale?.en,
      };
    });

    return data;
  };

  const allTourAttractions = () => {
    let data = [];
    let parentTags = [];

    for (const tour of activities) {
      if (tour?.attractions) {
        data = [...data, ...tour?.attractions];
      }
    }
    let savedCodes = new Set();
    data = data.filter((item) => {
      if (!item?.title) return false;
      if (savedCodes.has(item.title)) {
        return false;
      } else {
        savedCodes.add(item.title);
        return true;
      }
    });

    data = data.map((item) => {
      return {
        code: item?.title,
        label: item?.title,
      };
    });

    return data;
  };

  const filterHotelByAmenities = (hotelData) => {
    let data = [...hotelData];

    if (filterStayData?.amenities) {
      let dat = data.filter((hotel) => {
        let availableAmenities = hotel?.details?.facilities || [];

        let found = filterStayData.amenities.every((amenity) =>
          availableAmenities.some(
            (availableAmenity) => parseInt(availableAmenity?.code) === amenity
          )
        );

        return found;
      });

      data = dat;
    }

    return data;
  };

  const filterHotelByRating = (hotelData) => {
    let data = [...hotelData];
    if (filterStayData?.rating) {
      if (parseInt(filterStayData?.rating) > 0) {
        let dat = data.filter((hotel) => {
          let awards = hotel?.details?.awards;
          if (!awards) return false;
          let star = awards.find((award) => award.RatingSymbol === "Star");
          if (!star) return false;
          if (!parseInt(star?.Rating)) return false;
          if (parseInt(star?.Rating) < parseInt(filterStayData?.rating))
            return false;
          return true;
        });
        data = dat;
      }
    }
    return data;
  };

  const filterHotelsByPrice = (hotelData) => {
    let data = [...hotelData];
    if (filterStayData?.price) {
      let constant = 200000;
      let amount = (constant += filterStayData.price * 10000);
      let dat = data.filter((hotel) => {
        if (!hotel?.price) return false;

        if (parseInt(hotel?.price) < amount) return false;

        return true;
      });
      data = dat;
    }
    return data;
  };

  const filterHotels = () => {
    const filteredByPrice = filterHotelsByPrice(hotels);
    const filteredByRating = filterHotelByRating(filteredByPrice);
    const filteredByAmenity = filterHotelByAmenities(filteredByRating);

    return filteredByAmenity;
  };

  const filterTourByPrice = (tourData) => {
    let data = [...tourData];
    if (filterData?.price) {
      let constant = 200000;
      let amount = (constant += filterData.price * 10000);
      let dat = data.filter((tour) => {
        if (!tour?.farePrice) return false;

        if (parseInt(tour?.farePrice) < amount) return false;

        return true;
      });
      data = dat;
    }
    return data;
  };

  const filterTourByDuration = (tourData) => {
    let data = [...tourData];
    if (parseInt(filterData?.duration)) {
      let dat = data.filter((item) => {
        let duration = item?.itinerary?.duration?.fixedDurationInMinutes;
        if (duration) {
          const selectedDuration = parseInt(filterData?.duration) * 60;
          if (duration < selectedDuration) {
            return true;
          }
        }
        return false;
      });
      data = dat;
    }
    return data;
  };

  const filterTourByLanguages = (tourData) => {
    let data = [...tourData];
    if (filterData?.language && filterData?.language?.length > 0) {
      let dat = data.filter((item) => {
        let languages = item?.languageGuides;
        if (languages) {
          let available = languages?.some((element) =>
            filterData?.language?.includes(element?.language)
          );
          if (available) {
            return true;
          }
        }
        return false;
      });
      data = dat;
    }
    return data;
  };

  const filterTourByServices = (tourData) => {
    let data = [...tourData];
    if (filterData?.service && filterData?.service?.length > 0) {
      let dat = data.filter((item) => {
        let healthMeasures = filterData?.service?.find(
          (item) => item === "healthMeasures"
        );
        let wheelChair = filterData?.service?.find(
          (item) => item === "WHEELCHAIR_ACCESSIBLE"
        );
        let privateTour = filterData?.service?.find(
          (item) => item === "privateTour"
        );
        let pickup = filterData?.service?.find((item) => item === "pickup");
        let skipTheLine = filterData?.service?.find(
          (item) => item === "skipTheLine"
        );
        let smallGroup = filterData?.service?.find(
          (item) => item === "smallGroup"
        );

        if (healthMeasures) {
        }

        if (wheelChair) {
          let additionalInfo = item?.additionalInfo;
          if (!additionalInfo) return false;
          let accessible = additionalInfo?.find(
            (info) => info?.type === "WHEELCHAIR_ACCESSIBLE"
          );
          if (!accessible) return false;
        }

        if (privateTour) {
          let available = item?.itinerary?.privateTour;
          if (!available) return false;
        }

        if (pickup) {
          let available =
            item?.logistics?.travelerPickup?.allowCustomTravelerPickup;
          if (!available) return false;
        }

        if (skipTheLine) {
          let available = item?.itinerary?.skipTheLine;
          if (!available) return false;
        }

        if (smallGroup) {
        }

        return true;
      });

      data = dat;
    }
    return data;
  };

  const filterTourByCategory = (tourData) => {
    let data = [...tourData];
    if (filterData?.category && filterData?.category?.length > 0) {
      let dat = data.filter((item) => {
        let tags = item?.tags;
        if (tags) {
          let available = tags?.some(
            (element) =>
              filterData?.category?.includes(element?.tagId) ||
              element?.parentTagIds?.some((parentId) =>
                filterData?.category?.includes(parentId)
              )
          );

          if (available) {
            return true;
          }
        }
        return false;
      });
      data = dat;
    }
    return data;
  };

  const filterTourByCancellation = (tourData) => {
    let data = [...tourData];
    if (filterData?.cancellation) {
      let dat = data.filter((item) => {
        let cancellation = item?.cancellationPolicy;
        if (cancellation) {
          const selectedPolicy = cancellation?.type;
          if (selectedPolicy === filterData.cancellation) {
            return true;
          }
        }
        return false;
      });
      data = dat;
    }
    return data;
  };

  const filterTourByAttractions = (tourData) => {
    let data = [...tourData];
    if (filterData?.attractions && filterData?.attractions?.length > 0) {
      let dat = data.filter((item) => {
        let attractions = item?.attractions;
        if (attractions) {
          let available = attractions?.some((element) =>
            filterData?.attractions?.includes(element?.title)
          );

          if (available) {
            return true;
          }
        }
        return false;
      });
      data = dat;
    }
    return data;
  };

  const filterTours = () => {
    const filteredByPrice = filterTourByPrice(activities);
    const filteredByDuration = filterTourByDuration(filteredByPrice);
    const filteredByLanguages = filterTourByLanguages(filteredByDuration);
    const filteredByServices = filterTourByServices(filteredByLanguages);
    const filteredByCategory = filterTourByCategory(filteredByServices);
    const filterByCancellation = filterTourByCancellation(filteredByCategory);
    const filterByAttractions = filterTourByAttractions(filterByCancellation);

    return filterByAttractions;
  };

  const tourSortOptions = [
    "Recommended",
    "Price (low to high)",
    "Price (high to low)",
    "Duration (short to long)",
    "Duration (long to short)",
  ];

  const sortPriceLowToHigh = (loadedActivities) => {
    let data = [...loadedActivities];
    data.sort((a, b) => a.FarePrice - b.FarePrice);

    return data;
  };

  const sortPriceHighToLow = (loadedActivities) => {
    let data = [...loadedActivities];
    data.sort((a, b) => b.FarePrice - a.FarePrice);

    return data;
  };

  const sortDurationShortToLong = (loadedActivities) => {
    let data = [...loadedActivities];

    data.sort((a, b) => {
      const durationA =
        a?.itinerary?.duration?.fixedDurationInMinutes ??
        Number.MAX_SAFE_INTEGER;
      const durationB =
        b?.itinerary?.duration?.fixedDurationInMinutes ??
        Number.MAX_SAFE_INTEGER;

      return durationA - durationB;
    });

    return data;
  };

  const sortDurationLongToShort = (loadedActivities) => {
    let data = [...loadedActivities];

    data.sort((a, b) => {
      const durationA = a?.itinerary?.duration?.fixedDurationInMinutes ?? -1;
      const durationB = b?.itinerary?.duration?.fixedDurationInMinutes ?? -1;

      return durationB - durationA;
    });

    return data;
  };

  const sortTours = (sort) => {
    let data = [...activities];
    switch (sort) {
      case tourSortOptions[0]:
        break;
      case tourSortOptions[1]:
        data = sortPriceLowToHigh(data);
        break;
      case tourSortOptions[2]:
        data = sortPriceHighToLow(data);
        break;
      case tourSortOptions[3]:
        data = sortDurationShortToLong(data);
        break;
      case tourSortOptions[4]:
        data = sortDurationLongToShort(data);
        break;
      default:
        break;
    }

    return data;
  };

  return {
    filterHotels,
    allHotelAmenities,
    allTourTags,
    allTourAttractions,
    filterTours,
    sortTours,
    tourSortOptions,
    allTourLanguages,
  };
}
