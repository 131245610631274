import { Button, ListItem, Select } from '@mui/material';
import {useEffect, useState} from 'react'
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Icon } from '@iconify/react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CustomDatePicker from '../Utils/Searchbar/DatePicker2';
import { useSelector } from 'react-redux';
import CustomDropDown from '../Utils/Searchbar/DropDowns2';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs';
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { offerSearchTemp } from '../../../data/flight/offerSearchData';
import { clone } from '../../../features/utils/objClone';
import { decrypt, encrypt } from '../../../features/utils/crypto';
import DropDownSelect, { DropDownSelect2 } from '../Utils/Searchbar/DropDownSelect';
import SelectPassengers from '../Utils/Searchbar/SelectPassengers';



const EditSearchBar = ({returnData}) => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const q = searchParam.get('q')
  let qObj = {}
  if(q) {
    try {
      qObj = JSON.parse(decrypt(q))
    } catch(ex) {/**/}
  }
  console.log((qObj))
  const state = useSelector((state)=> state.template);  
  const curSearch = state.searchbarStyle
  const [selectedTravelers, setSelectedTraveler] = useState({
    adult: qObj?.passengers?.adult || 1,
    child: qObj?.passengers?.child || 0,
    infants: qObj?.passengers?.infant || 0,
    });
  const updatePassengerCount = (type, action) => {
      if(type == "adult"){
          if(action == "add"){
              setSelectedTraveler({...selectedTravelers, adult: selectedTravelers.adult+1});
          }else{
              if(selectedTravelers.adult > 1){
                  setSelectedTraveler({...selectedTravelers, adult: selectedTravelers.adult-1});
              }
          }
      }
      else if (type=="children"){
        if(action == "add"){
            setSelectedTraveler({...selectedTravelers, child: selectedTravelers.child+1});
        }else{
            if(selectedTravelers.child > 0){
                setSelectedTraveler({...selectedTravelers, child: selectedTravelers.child-1});
            }
        }
      }
      else if (type=="infants"){
        if(action == "add"){
          setSelectedTraveler({...selectedTravelers, infants: selectedTravelers.infants+1});
        }else{
          if(selectedTravelers.infants > 0){
            setSelectedTraveler({...selectedTravelers, infants: selectedTravelers.infants-1});
          }
        }
      }
  }
  const travel = [
    { label: "One way", value: "one_way" },
    { label: "Round trip", value: "round_trip" },
    { label: "Multi-city", value: "multi_city" },
  ];
  let type = 'one_way'
  if(qObj.originDestinations?.length) {
    if(qObj.originDestinations?.at(0)?.from?.iata === qObj?.originDestinations?.at(-1)?.to?.iata && qObj?.originDestinations?.length === 2)
      type = 'round_trip'
    else if(qObj.originDestinations.length >= 2)
      type = 'multi_city'
  }

  const [tripType, setTripType] = useState(travel?.find(obj => obj.value === type) || { label: "Round trip", value: "round_trip" });

  const travelClassList = [
    { label: "Economy", value: "Economy" },
    { label: "Premium Economy", value: "PremiumEconomy" },
    { label: "Business", value: "Business" },
    { label: "First Class", value: "FirstClass" },
  ];
  const defClass = travelClassList?.find(obj => obj.value === qObj?.cabinClass?.at(0)) || travelClassList[0]
  const [travelClass, setTravelClass] = useState(defClass || { label: "Economy", value: "Economy" });

  let defLoc = qObj?.originDestinations?.map(obj => ({
    from: obj?.from?.iata ? obj?.from : {iata: obj?.from, country: obj?.from},
    to: obj?.to?.iata ? obj?.to : {iata: obj?.to, country: obj?.to},
    departure: {
      date: dayjs(obj?.date)
    },
    return: {date: dayjs()}
  }))
  if(tripType?.value === 'round_trip') {
    defLoc = defLoc?.slice(0,1)?.map(obj => ({...obj,
      return: {
        date: dayjs(defLoc?.at(1)?.departure?.date || '')
      }
    }))
  }

  const [travelCitys, setTravelCitys] = useState(defLoc || [
    {
      from: {},
      to: {},
      departure: {date: dayjs()},
      return: {date: dayjs()},
    },
  ]);
  const {enqueueSnackbar} = useSnackbar();

  const newCity = {
    from: {},
    to: {},
    departure: {date:dayjs()},
    return: {date:dayjs()},
  };
  const [destination,setDestination] = useState(offerSearchTemp.originDestinations);
  useEffect(()=>{
    const locMod = defLoc;
    let index = travel?.findIndex(obj => obj.value === tripType.value);
    if(index < 0) index = 0;
    console.log(locMod?.slice(0,index+1),index,defLoc,qObj)

    setTravelCitys(locMod?.slice(0,index+1) || [
      {
        from: {},
        to: {},
        departure: {date:dayjs()},
        return: {date:dayjs()},
      }
    ])
  },[tripType])
  const swapLocations = () => {
    let temp = travelCitys[0].from;
    travelCitys[0].from = travelCitys[0].to;
    travelCitys[0].to = temp;
    setTravelCitys([...travelCitys]);
  }
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  let referralCode = searchParam.get('refCode');
  const validateInputs = () => {
    let stat = true
    if(tripType.value == "one_way"){
      if(isEmpty(travelCitys[0].from) || isEmpty(travelCitys[0].to) || travelCitys[0].departure == ""){
        stat = false
        enqueueSnackbar("Invalid Request! Please select valid destination and date.", { variant: 'error' });
      }
    }
    else if(tripType.value == "round_trip"){
      if(isEmpty(travelCitys[0].from) || isEmpty(travelCitys[0].to) || !travelCitys[0].departure || !travelCitys[0].return){
        stat = false
        enqueueSnackbar("Invalid Request! Please select valid destination and date.", { variant: 'error' });
      }
    }
    else if(tripType.value == "multi_city"){
      let valid = true;
      travelCitys.map((city)=>{
        if(isEmpty(city.from) || isEmpty(city.to) || !city.departure){
          stat = false
          valid = false;
        }
      })
      if(!valid){
        enqueueSnackbar("Invalid Request! Please select valid destination and date.", { variant: 'error' });
      }
    }
    if(selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants == 0){
      stat = false
      enqueueSnackbar("Invalid Request! Please select at least one passenger.", { variant: 'error' });
    }
    if(stat){
      let request = clone(offerSearchTemp)
      request.passengers = {
        adult: selectedTravelers.adult,
        child: selectedTravelers.child,
        infant: selectedTravelers.infants
      }
      request.cabinClass = [travelClass.value];
      if(tripType.value == "one_way"){
        request.originDestinations[0].from = travelCitys[0].from;
        request.originDestinations[0].to = travelCitys[0].to;
        request.originDestinations[0].date = dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ');
        request.originDestinations[0].departure = {date: dayjs(travelCitys[0].departure.date).format('YYYY-DD-mm')};
        request.destinations = []
        request.destinations.push({departureLocation: travelCitys[0].from.iata, arrivalLocation: travelCitys[0].to.iata, date: dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})    
        request.originDestinations.pop()
        request.flightType = "INTERNATIONAL"
      }
      else if(tripType.value == "round_trip"){
        request.originDestinations[0].from = travelCitys[0].from;
        request.originDestinations[0].to = travelCitys[0].to;
        request.originDestinations[0].date = dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ');
        request.originDestinations[0].departure = {date: dayjs(travelCitys[0].departure.date).format('YYYY-DD-mm')};
        request.destinations = []
        request.destinations.push({departureLocation: travelCitys[0].from.iata, arrivalLocation: travelCitys[0].to.iata, date: dayjs(travelCitys[0].departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})    

        request.originDestinations[1].to = travelCitys[0].from;
        request.originDestinations[1].from = travelCitys[0].to;
        request.originDestinations[1].date = dayjs(travelCitys[0].return.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ');
        request.originDestinations[1].departure = {date: dayjs(travelCitys[0].return.date).format('YYYY-DD-mm')};
        // request.destinations = []
        request.destinations.push({departureLocation: travelCitys[0].from.iata, arrivalLocation: travelCitys[0].to.iata, date: dayjs(travelCitys[0].return.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})    
        request.flightType = "INTERNATIONAL"
      }
      else if(tripType.value == "multi_city"){
        request.originDestinations = []
        request.destinations = []
        travelCitys.map((city)=>{
          request.originDestinations.push({
            from: city.from,
            to: city.to,
            date: dayjs(city.departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ'),
            departure: {date: dayjs(city.departure.date).format('YYYY-DD-mm')}
          })
          request.destinations.push({departureLocation: city.from.iata, arrivalLocation: city.to.iata, date: dayjs(city.departure.date).format('ddd MMM DD YYYY 00:00:00 [GMT]ZZ')})
        })
        request.flightType = "INTERNATIONAL"
      }

      if(returnData)
        returnData(request)

        // return console.log(request)
        let enc = encrypt(JSON.stringify(request));
        navigate(`/order/new/flight/offers?referralCode=${referralCode}&q=${enc}`);
        // navigate(`/order/new/flight/template/1/offers?referralCode=${referralCode}&q=${enc}`);
      }
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  return (
    <div className='font-poppins w-full'>
      <div className="flex gap-4 flex-wrap pb-2">
        <DropDownSelect2 state={tripType} data={travel} handleDataChange={setTripType}/>
        <DropDownSelect2 state={travelClass} data={travelClassList} handleDataChange={setTravelClass}/>
        <div
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            className='flex gap-2 items-center'
            // className="rounded-md px-5 py-4 text-l cursor-pointer bg-white border border-black/20 shadow-md flex gap-2 justify-center items-center"
        >
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants}
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants > 1 ? " Passengers" : " Passenger"}
            <Icon icon='fe:arrow-down' className='!w-4 !h-4'/>
            {/* <ArrowDropDownIcon /> */}
        </div>

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <SelectPassengers selectedTravelers={selectedTravelers} updatePassengerCount={updatePassengerCount} />
        </Popover>
        {/* <Dropdown>
          <MenuButton className="rounded-md px-5 py-4 text-l cursor-pointer bg-white border-0 shadow-md flex gap-2 justify-center items-center">
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants }
            {selectedTravelers.adult + selectedTravelers.child + selectedTravelers.infants  > 1 ? " Passengers" : " Passenger"} 
            <ArrowDropDownIcon />
          </MenuButton>
          <Menu>
            <SelectPassengers selectedTravelers={selectedTravelers} updatePassengerCount={updatePassengerCount}/>
          </Menu>
        </Dropdown> */}
      </div>
      <div className='flex gap-4 items-center '>
        <div className='flex-1 flex flex-col gap-2'>
          { travelCitys.map((_, i) => (
            <div className='flex gap-4 items-center'>
              <div className={`w-full relative rounded-lg grid gap-4 grid-cols-1 ${tripType.value != "round_trip" ? "grid-cols-1 md:grid-cols-3" : "lg:grid-cols-2"}`}>
                <div className={`bg-white grid grid-cols-1 ${tripType.value != "round_trip" ? "col-span-2" : "col-span-1"} md:grid-cols-2 gap-2 rounded-xl border-2 border-solid border-[#EBEFF9]`}>
                    <div className='flex relative'>
                      <div className='col-span-1 w-full text-sm text-[#343434] p-2 rounded-xl'>
                          <p className='text-sm my-1 flex gap-2 items-center'>
                            <Icon icon="mdi:airplane-takeoff" className='text-xl'/>
                            From?
                            <div className={`!font-bold !text-sm flex items-center pl-1`} style={{ color: state.secondaryColor }}>
                              {travelCitys?.at(i)?.from?.city || travelCitys?.at(i)?.from?.iata || ''}
                            </div>
                            </p>
                          <CustomDropDown key={i} index={i} location="from" selectedOption={travelCitys} setSelectedOption={setTravelCitys} state={state}/>
                      </div>
                      {i == 0 ? <div className='absolute right-10 md:right-[-27px] h-full flex justify-end items-center'>
                        <div className="!border-3 flex justify-center items-center rounded-full p-1 !w-9 h-9 bg-white" style={{border: "3px solid #EBEFF9"}}>
                          <Icon icon="eva:swap-fill" className='text-2xl text-black hover:rotate-180 duration-150 cursor-pointer' onClick={swapLocations}/>
                        </div>
                      </div>:""}
                    </div>
                    <div className='col-span-1 text-sm text-[#343434] p-2 md:pl-6 rounded-xl'>
                        <p className='text-sm my-1 flex gap-2 items-center'>
                        <Icon icon="mdi:airplane-landing" className='text-xl'/>
                        To?
                        <div className={`!font-bold !text-sm flex items-center pl-1`} style={{ color: state.secondaryColor }}>
                          {travelCitys?.at(i)?.to?.city || travelCitys?.at(i)?.to?.iata || ''}
                        </div>
                        </p>
                        <CustomDropDown key={i} index={i} location="to" selectedOption={travelCitys} setSelectedOption={setTravelCitys} state={state}/>
                    </div>
                </div>
                <div className={`bg-white grid grid-cols-1 col-span-1 gap-2 ${tripType.value != "round_trip" ? "grid-cols-1 justify-center" : "md:grid-cols-2"} rounded-xl border-2 border-solid border-[#EBEFF9]`}>
                    <div className='flex relative'>
                      <div className='col-span-1 w-full text-sm text-[#343434] p-2 rounded-xl'>
                          <p className='text-sm my-1 flex gap-2 items-center'>
                            Departure
                            </p>
                            <CustomDatePicker state={state} travelCitys={travelCitys} setTravelCitys={setTravelCitys} index={i} type="departure"/>
                      </div>
                      {
                      tripType.value == "round_trip" && 
                      <div className='absolute right-10 md:right-[-27px] h-full flex justify-end items-center'>
                        <div className="!border-3 flex justify-center items-center rounded-full p-1 !w-10 h-10 bg-white" style={{border: "3px solid #EBEFF9"}}>
                          <Icon icon="ion:calendar" className='text-2xl text-black' />
                        </div>
                      </div>
                      }
                    </div>
                    {
                      tripType.value == "round_trip" &&
                      <div className='col-span-1 text-sm text-[#343434] p-2 md:pl-6 rounded-xl'>
                        <p className='text-sm my-1 flex gap-2 items-center'>
                        Return
                        </p>
                        <CustomDatePicker state={state} travelCitys={travelCitys} setTravelCitys={setTravelCitys} index={i} type="return"/>
                    </div>
                    }
                </div>
              </div>
            {i==0 ? (travelCitys?.length > 1 ? <div className='w-8'></div>:"") : <Icon icon="carbon:close-filled" className='top-[40%] text-3xl cursor-pointer inline-block' style={{color: "red"}} onClick={()=> setTravelCitys( travelCitys.filter((city, index) => index !== i)) }/>}
            </div>
          ))
          }
        </div>
        <Button className={`!p-[15px] !capitalize !min-w-[120px] !mb-2 !rounded-full self-end`} style={{backgroundColor: state.secondaryColor, color: "white", paddingLeft: "30px", paddingRight: "30px"}} onClick={validateInputs}>
            Search
        </Button>
      </div>
      <div className={`flex flex-wrap gap-2 py-2 md:gap-0 justify-center md:justify-end ${tripType.value == "multi_city" ? "w-full !justify-between" : ""}`}>
            {tripType.value == "multi_city" ?
            <Button className={`w-full md:w-auto md:place-self-end md:justify-self-end align-self-end`} style={{backgroundColor: state.secondaryColor, color: "white", paddingLeft: "30px", paddingRight: "30px"}} onClick={()=> setTravelCitys([...travelCitys, newCity])}>
                Add another flight
            </Button> : ""
            }
          </div>
    </div>
  );
}

export default EditSearchBar;