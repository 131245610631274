import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusinessTypeChooseModal,
  setGettingStartedLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import GettingStartedLoadingModal from "./GettingStartedLoadingModal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: window.innerWidth > 600 ? 8 : 3,
};

export default function BusinessTypeChooseModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { businessTypeChooseModal } = useSelector((state) => state.modal);
  const [selected, setSelected] = useState(1);

  const handleClose = () => dispatch(setBusinessTypeChooseModal(false));

  const handleChoose = (chosen) => {
    setSelected(chosen);
  };

  const handleGetStarted = () => {
    dispatch(setGettingStartedLoadingModal(true));
    navigate("/gettingStarted/business/activate");
    handleClose();
  };

  return (
    <div>
      <Modal
        open={businessTypeChooseModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="w-full flex justify-end">
              <div className="p-2 lg:p-4 cursor-pointer" onClick={handleClose}>
                <img
                  src="/IconClose4.svg"
                  alt=""
                  className="h-6 lg:h-12 object-contain"
                />
              </div>
            </div>
            <span className="font-semibold text-lg lg:text-2xl mb-2">
              A few small steps for you. One massive leap for your business.
            </span>
            <span className="text-sm lg:text-base text-[#818083]">
              Tell us about you and we can recommend the next steps.
            </span>
            <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-3 lg:gap-6 my-1 lg:my-6">
              <div
                className={`${Classname.choice} ${
                  selected === 1 && "border border-primary1"
                } `}
                onClick={() => handleChoose(1)}
              >
                <div className={Classname.choiceImageContainer}>
                  <img
                    src="/IndivisualSvg.svg"
                    alt=""
                    className={Classname.choiceImage}
                  />
                </div>
                <span className={Classname.choiceName}>Individual user</span>
                <span className={Classname.choiceDescription}>Side hustle</span>
              </div>
              <div
                className={`${Classname.choice} ${
                  selected === 2 && "border border-primary1"
                } `}
                onClick={() => handleChoose(2)}
              >
                <div className={Classname.choiceImageContainer}>
                  <img
                    src="/AgencySvg.svg"
                    alt=""
                    className={Classname.choiceImage}
                  />
                </div>
                <span className={Classname.choiceName}>Travel Agency</span>
                <span className={Classname.choiceDescription}>
                  IATA and Non-IATA travel agency
                </span>
              </div>
            </div>

            <div className="w-full flex justify-end my-3 lg:my-8">
              <button
                className="text-white font-bold text-sm lg:text-base bg-primary1 rounded-md px-6 py-2"
                onClick={handleGetStarted}
              >
                Get started
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main px-4 lg:px-10 py2 lg:py-4",
  numberContainer: "flex gap-2 text-sm text-gray-500 mb-2",
  number:
    "w-8 h-8 rounded-full items-center justify-center bg-gray-300 text-xs flex flex-shrink-0",
  buttonContainer: "flex justify-end mt-4",
  button: "h-12 px-4 font-bold text-white bg-primary1 rounded-md",
  choice:
    "flex flex-col w-full lg:w-96 lg:min-h-[300px] items-center p-3 rounded-md cursor-pointer",
  choiceImageContainer: "w-full rounded-lg h-[150px] lg:h-[215px]",
  choiceImage: "w-full h-full object-contain",
  choiceName: "mt-1 lg:mt-3 font-bold text-sm text-center lg:text-lg",
  choiceDescription: "text-xs lg:text-base text-[#818083]",
};
