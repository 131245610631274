import { createRef, useEffect, useMemo, useState } from "react";
import FlightDisplay from "./FlightDisplay";
import FlightInfoCard from "./FlightInfoCard";
import { formatMoney, getNumber } from "../../features/utils/formatMoney";
import { def } from "../../config";
import Button1 from "../form/Button1";
import Modal1 from "../DIsplay/Modal/Modal1";
import FareOptions from "./FareOptions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Icon from "../HOC/Icon";
import convertFlightObject from "../../features/utils/flight/flightOfferObj";
import ViewFareRule from "./ViewFareRule";
import MD from "../DIsplay/Screen/MD";
import { getSupplierName } from "../../data/flight/supplier/getSupplierName";
import { Checkbox, Menu, MenuItem } from "@mui/material";
import FlightDisplayV2 from "./FlightDisplay-v2";
import { MoreVert } from "@mui/icons-material";
import {
  setFlightCheckedBaggageModal,
  setFlightCompareModal,
  setFlightFareBreakdownModal,
  setFlightFareOptionsModal,
  setFlightFareRulesModal,
  setFlightRefundAndReissueModal,
  setFlightSeatMapModal,
  setFlightShareModal,
} from "../../redux/reducers/modalSlice";
import {
  setCompareFlights,
  setShareFlights,
} from "../../redux/reducers/flight/flightBookingSlice";
import FlightFareBreakdownModal from "../modal/FlightFareBreakdownModal";
import FlightCheckedBaggageModal from "../modal/FlightCheckedBaggageModal";
import FlightFareRulesModal from "../modal/FlightFareRulesModal";
import FlightRefundAndReissueModal from "../modal/FlightRefundAndReissueModal";
import FlightFareOptionsModal from "../modal/FlightFareOptionsModal";
import FlightViewSeatMapModal from "../modal/FlightViewSeatMapModal";
import FlightMulticityDisplay from "./FlightMulticityDisplay";
// import { offerDataTemp } from '../../data/flight/offerData';

export default function FlightMulticityOfferDisplay({
  data,
  index,
  path,
  showDetail,
  select,
  offer,
}) {
  const dispatch = useDispatch();
  const { compareFlights, shareFlights } = useSelector(
    (state) => state.flightBooking
  );
  const [loading, setLoading] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openFareOptions, setOpenFareOptions] = useState(false);
  const { bookingData } = useSelector((state) => state.flightBooking);

  const detailRef = createRef(null);

  const [searchParam] = useSearchParams();
  let qIndex = useMemo(() => searchParam.get("path"), [searchParam]) || 0;
  qIndex = Number(qIndex);

  const lastPath = qIndex && (offer || bookingData?.offer)?.at(-1);
  // const data = offerDataTemp;
  // data.flightData.booked_flights[1] = (flightDataTemp.flightData.booked_flights[0])
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (detailRef.current && openDetail) {
        detailRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);

    return () => {
      clearTimeout(timeoutId);
    };

    //eslint-disable-next-line
  }, [openDetail]);

  async function loadDetail(ev, data) {
    ev?.stopPropagation();

    select && select(data);
    setOpenDetail(false);
    setOpenFareOptions(false);
    setLoading(true);
    // await showDetail(data)
    setLoading(false);
  }

  function handleOpenDetail(ev) {
    console.log(" ---> ", data);
    ev?.preventDefault();
    ev?.stopPropagation();

    setOpenDetail(!openDetail);
    setOpenFareOptions(false);

    return true;
  }

  async function handleBrandedFare(ev) {
    ev?.stopPropagation();
    setOpenFareOptions(true);
  }

  let initLoc = "";
  try {
    initLoc = data?.segments[0].departureLocation || "";
  } catch (ex) {}

  let totalPrice = data?.farePrice?.fareTotal;
  // let totalPrice = (data?.farePrice && formatMoney(data?.farePrice.fareTotal)) || data?.formatedTotalAmount;
  // bookingData?.offer?.at(path)?.farePrice?.fareTotal

  function handleFareSelect(obj) {
    setOpenDetail(false);
    setOpenFareOptions(false);
    select && select(convertFlightObject(obj), { fareOption: true });
  }

  const getSupplierClass = (sup) => {
    let supplier = sup?.toString()?.toLowerCase();
    let className = "bg-blue-200 text-blue-600";
    if (supplier === "intra1s") {
      className = "bg-red-200 text-red-800";
    } else if (supplier === "verteil") {
      className = "bg-orange-200 text-orange-800";
    } else if (supplier === "Intra1T") className = "bg-gray-200 text-gray-700";
    return className;
  };

  // console.log(data.passengers)
  const totalPassengers =
    Object.values(data?.passengers || {}).reduce(
      (p, c) => c.total + parseInt(p),
      [0]
    ) || 0;
  const pricing = data?.pricingInformation?.price;

  const PriceDisplay = ({ className }) => {
    let priceBreak = [
      {
        label: "Flight Payable",
        value:
          getNumber(pricing?.totalPrice || 0) -
            getNumber(pricing?.agentMarkup || 0) || 0,
      },
      {
        label: "Agent Markup",
        value: getNumber(pricing?.agentMarkup || 0) || 0,
      },
      {
        label: "Ticketing Fee",
        value: getNumber(pricing?.ticketingFee || 0) || 0,
      },
      {
        label: "Agent Payable",
        value:
          getNumber(pricing?.totalPrice || 0) -
            getNumber(pricing?.agentMarkup || 0) || 0,
      },
      {
        label: "Customer Order Total",
        value:
          getNumber(pricing?.totalPrice || 0) +
          (getNumber(pricing?.agentMarkup || 0) || 0),
      },
    ];

    if (["Intra1A", "Intra1T", "Intra1S"]?.includes(data?.supplier))
      priceBreak = [
        { label: "GDS fare", value: pricing?.gdsFare },
        ...priceBreak,
      ];
    // [
    //   {label: 'GDS fare', value: pricing?.priceChange > 0 ? pricing?.totalPrice : (Math.abs(pricing?.priceChange) + getNumber(pricing?.totalPrice))},
    //   {label: 'Intraverse Payable', value: pricing?.totalPrice},
    //   {label: 'My Markup', value: pricing?.agentPriceChange || 0},
    //   {label: 'Fee', value: getNumber(pricing?.ticketingFee) || 0},
    //   {label: 'Customer Total', value: getNumber(pricing?.totalPrice) + (getNumber(pricing?.ticketingFee) || 0)}
    // ]
    const [openFareBreak, setOpenFareBreak] = useState(false);
    return (
      <div
        className={
          "flex flex-row lg:flex-col p-2 px-4 gap-2 justify-end lg:justify-center items-end lg:items-center w-full lg:w-[200px] border-l-0 border-t lg:border-t-0 border-dashed border-primary/10" +
          className
        }
      >
        <div className="flex flex-col w-full items-end lg:items-center">
          <h5 className="flex gap-1 items-center text-xl lg:text-2xl">
            <span className="font-bold">
              {lastPath
                ? totalPrice - (lastPath?.totalAmount || 0) >= 0
                  ? "+"
                  : "-"
                : null}
              {formatMoney(Math.abs(totalPrice - (lastPath?.totalAmount || 0)))}
            </span>
            <button className="hidden sm:block relative hover-parent group text-xs font-normal my-2">
              <Icon icon="ci:info" className="text-blue-500 !w-4 !h-4" />
              <div className="bg-blue-500 rounded-md text-white hidden gap-2 absolute group-hover:flex shadow-md top-full my-2 min-w-[200px] right-0 p-4 z-20">
                {priceBreak?.map((obj, i) => (
                  <span
                    className={`flex flex-col pl-2 items-start border-l-white/40 whitespace-nowrap gap-2 ${
                      i && "border-l"
                    }`}
                    key={i}
                  >
                    {obj?.label}
                    <span>{formatMoney(obj?.value)}</span>
                  </span>
                ))}
              </div>
            </button>
            <button
              className="block sm:hidden relative hover-parent group text-xs font-normal my-2"
              onClick={(ev) => {
                ev?.preventDefault();
                ev?.stopPropagation();
                setOpenFareBreak(true);
              }}
            >
              <Icon icon="ci:info" className="text-blue-500 !w-4 !h-4" />
            </button>
            <Modal1 open={openFareBreak} setOpen={setOpenFareBreak}>
              <div className="card rounded-md gap-2 flex text-primary font-normal flex-col shadow-md my-2 min-w-[200px] p-4">
                {priceBreak?.map((obj, i) => (
                  <span
                    className={`flex justify-between pl-2 items-start border-l-white/40 whitespace-nowrap gap-4 ${
                      i && "border-l"
                    }`}
                    key={i}
                  >
                    {obj?.label}
                    <span>{formatMoney(obj?.value)}</span>
                  </span>
                ))}
              </div>
            </Modal1>
          </h5>
          <button
            className="bg-primary1 font-bold text-sm text-white rounded-md w-full py-3 mx-2"
            onClick={handleOpenDetail}
          >
            Select
          </button>
        </div>
      </div>
    );
  };

  const CompareAndShare = () => {
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const isInCompare = () => {
      let isAvailable = compareFlights?.find(
        (flight, index) => flight?.id === data?.id
      );
      return isAvailable ? true : false;
    };

    const isInShare = () => {
      let isAvailable = shareFlights?.find(
        (flight, index) => flight?.id === data?.id
      );
      return isAvailable ? true : false;
    };

    const handleCompare = () => {
      let items = compareFlights ? [...compareFlights] : [];
      let isAvailable = compareFlights?.find(
        (flight, index) => flight?.id === data?.id
      );
      if (isAvailable) {
        items = items?.filter((item) => item.id !== data?.id);
        dispatch(setCompareFlights(items));
      } else {
        dispatch(setCompareFlights([...items, data]));
      }
    };

    const handleShare = () => {
      let items = shareFlights ? [...shareFlights] : [];
      let isAvailable = shareFlights?.find(
        (flight, index) => flight?.id === data?.id
      );
      if (isAvailable) {
        items = items?.filter((item) => item.id !== data?.id);
        dispatch(setShareFlights(items));
      } else {
        dispatch(setShareFlights([...items, data]));
      }
    };
    return (
      <div className="flex items-center justify-between lg:gap-4">
        <div
          className="cursor-pointer flex items-center"
          onClick={handleCompare}
        >
          <Checkbox {...label} checked={isInCompare()} />
          <span className="font-bold text-sm">Compare</span>
        </div>
        <div className="cursor-pointer flex items-center" onClick={handleShare}>
          <Checkbox {...label} checked={isInShare()} />
          <span className="font-bold text-sm">Share</span>
        </div>
      </div>
    );
  };

  if (openDetail)
    return <FlightOfferDetail OnClickHeader={handleOpenDetail} data={data} />;

  return (
    <div className="bg-[#E7F6FF] rounded-2xl ">
      <div className="py-2 px-3 lg:px-6 flex items-center justify-between">
        <span className="text-sm">Cheapest Direct flight</span>
        <CompareAndShare />
      </div>
      <div
        className="bg-secondary rounded-2xl overflow-clip border border-primary/10 transition-all pb-4"
        data-container={true}
      >
        <div className={`flex flex-col`}>
          <div className="flex flex-col justify-stretch grow ">
            {(data?.segments || []).slice(qIndex, qIndex + 1).map((obj, i) => {
              // let flight = obj.flights[0];
              return (
                <div
                  key={i}
                  className={`flex flex-col justify-stretch grow border-[#e7e7e7] ${
                    i === 0 ? "border-t-0" : ""
                  } `}
                >
                  <FlightMulticityDisplay
                    key={i}
                    flight={obj}
                    segments={data?.segments}
                    body={<PriceDisplay className={"w-full border-l-0"} />}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex flex-col items-end">
            <PriceDisplay className={"flex"} />
          </div>
        </div>
        <div
          ref={detailRef}
          className={` border-[#e7e7e7] ${openDetail ? "block" : "hidden"}`}
          onClick={(ev) => ev.stopPropagation()}
        >
          {/* <Collapse className='test'> */}
          {(data?.segments || [])
            .slice(qIndex, qIndex + 1)
            .map((flights, i) => (
              <FlightInfoCard
                key={i}
                data={flights}
                segments={data?.segments}
                label={
                  initLoc === flights.arrivalLocation ? "Return" : "Depart"
                }
              />
            ))}
          {/* <ViewFareRule data={data} /> */}

          {/* </Collapse> */}
          {/* {data?.supplier} */}
          {data?.supplier === "Intra1A" ? (
            <div className="border-t p-4 flex justify-between items-center gap-4">
              <b className="min-w-[50px]">Travel Smart</b>
              <MD className="flex-1">
                This airline is offering additional flexibility & other fare
                options
              </MD>
              <Button1
                className="!w-auto !bg-primary !text-secondary"
                onClick={handleBrandedFare}
              >
                View Fare Options
              </Button1>
            </div>
          ) : null}

          <div className="p-4 flex flex-wrap border-t justify-between items-center gap-4">
            <p className="self-start">From</p>
            <div className="flex-1 flex flex-col mb-4">
              <h5>{formatMoney(totalPrice)}</h5>
              <p className="flex flex-col gap-2">
                {Object.entries(data?.passengers || {}).map(
                  ([label, obj], ind) => (
                    <span
                      key={ind}
                      className="capitalize flex flex-wrap whitespace-nowrap"
                    >
                      {obj.total} {label} - {formatMoney(obj.totalAmount)}
                      <span className="px-3">
                        Flight fare: {formatMoney(obj.totalAmountWithoutTax)}
                      </span>
                      <span>
                        Tax:{" "}
                        {formatMoney(
                          obj.totalAmount - obj.totalAmountWithoutTax
                        )}
                      </span>
                    </span>
                  )
                )}
              </p>
              <ViewFareRule
                button={
                  <small>View fare rule</small>
                  // <Icon icon='flat-color-icons:rules' className='my-2 cursor-pointer' />
                }
                data={data}
              />
              {/* <p>1 Adult - {def.currency}50,000, 1 Child - {def.currency}10,000, 1 Infant - {def.currency}5,000 </p> */}
            </div>
            <Button1
              loading={loading}
              className="btn1 !w-auto flex items-center gap-2 sm:!min-w-[140px]"
              onClick={(ev) => loadDetail(ev, data)}
            >
              Select
            </Button1>
          </div>
        </div>

        <Modal1 open={openFareOptions} setOpen={setOpenFareOptions}>
          <FareOptions
            data={openFareOptions && data}
            handleReturn={handleFareSelect}
          />
        </Modal1>
      </div>
    </div>
  );
}

function FlightOfferDetail({ OnClickHeader, data }) {
  const dispatch = useDispatch();
  const handleAdd = () => {};
  return (
    <div className="w-full bg-[#FAFAFA] rounded-2xl pb-2 ">
      <FlightFareOptionsModal data handleFareSelect={() => {}} />
      <FlightViewSeatMapModal />
      {Array(4)
        .fill("")
        .map((_, index) => (
          <div className="flex flex-col w-full" key={index}>
            <div
              className="bg-[#E7F6FF] rounded-xl cursor-pointer px-4 py-4 flex items-center justify-between"
              onClick={OnClickHeader}
            >
              <span className="text-sm lg:text-base flex items-center">
                Lagos (LOS) - London (LHR){" "}
                <div className="text-[10px] text-primary1 bg-primary1/10 px-2 py-1 rounded-md font-bold ml-3">
                  AMADEUS
                </div>
              </span>
              <span className="font-bold text-sm lg:text-base">₦534,000</span>
            </div>
            <div className="px-2 py-3">
              <div className="p-3 rounded-md border flex flex-col bg-white">
                <FlightDetailItem />
                <FlightDetailItem />
                <div className="flex w-full items-center">
                  <div className="border-t flex-1"></div>
                  <div className="text-sm rounded-full bg-[#F0F0F0] px-6 py-1">
                    Return
                  </div>
                  <div className="border-t flex-1"></div>
                </div>
                <FlightDetailItem />
                <FlightDetailItem />
              </div>
              <div className=" rounded-md mt-2 flex justify-between items-center w-full bg-white px-2 lg:px-4 py-2">
                <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2 lg:gap-4">
                  <div className="flex items-center gap-2">
                    <div
                      className="flex items-center gap-1 text-sm cursor-pointer "
                      onClick={() => dispatch(setFlightSeatMapModal(true))}
                    >
                      <img
                        src="/IconChair.svg"
                        alt=""
                        className="h-4 object-contain"
                      />
                      <span>
                        Economy |<span className="ml-1 font-bold">Y</span>
                      </span>
                    </div>
                    <div className="flex items-center gap-1 text-sm ">
                      <img
                        src="/IconBag4.svg"
                        alt=""
                        className="h-4 object-contain"
                      />
                      <span>12KG</span>
                    </div>
                    <div className="flex items-center gap-1 text-sm ">
                      <img
                        src="/IconBag3.svg"
                        alt=""
                        className="h-4 object-contain"
                      />
                      <span>23KG</span>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 items-center">
                    <span className="rounded-md py-[1px] px-3 text-xs text-primary1 bg-[#CEECFF] tracking-widest font-semibold">
                      CHANGEABLE
                    </span>
                    <span className="rounded-md py-[1px] px-3 text-xs text-primary1 bg-[#CEECFF] tracking-widest font-semibold">
                      REFUNDABLE
                    </span>
                  </div>
                </div>
                <div
                  onClick={handleAdd}
                  className="cursor-pointer flex items-center justify-center w-12 h-10 rounded-md border"
                >
                  <img
                    src="/IconPlus.svg"
                    alt=""
                    className="h-5 object-contain"
                  />
                </div>
              </div>
              <FlightDetailCTA showBook={index === 3} />
            </div>
          </div>
        ))}
    </div>
  );
}

function FlightDetailItem({}) {
  return (
    <div className="flex flex-col w-full justify-between gap-4 items-start mb-4">
      <div className="flex lg:hidden w-full justify-between mb-2">
        <div className="flex gap-2">
          <img src="/IconTurkish.svg" alt="" className="h-10 object-contain" />
          <div className="flex flex-col items-end mt-2">
            <span className="text-sm">Turkish airline</span>
            <span className="text-xs text-gray-500">TK78948</span>
          </div>
        </div>
        <div className="flex flex-col items-end w-fit gap-2">
          <div className="flex items-center gap-2 px-2 py-1 rounded-full bg-[#F0F0F0] text-primary1 text-xs ">
            <img src="/IconClock1.svg" alt="" className="w-3 object-contain " />
            <span>Layover: 3h</span>
          </div>
          <div className="flex items-center gap-2 px-2 py-1 rounded-full text-red-600 bg-red-600/10 text-xs ">
            <span>Long stopover</span>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between gap-4 items-center">
        <div className="hidden lg:flex gap-2">
          <img src="/IconTurkish.svg" alt="" className="h-14 object-contain" />
          <div className="flex flex-col items-end mt-2">
            <span className="text-sm">Turkish airline</span>
            <span className="text-xs text-gray-500">TK78948</span>
          </div>
        </div>
        <div className="flex flex-1 justify-between pt-2">
          <div className="flex flex-col items-end">
            <span className="font-bold text-sm text-end">
              Mon, 30 SEP 18:30 - LOS
            </span>
            <span className="text-xs text-gray-600 text-end">
              Murtala Muhammed Intl, Lagos
            </span>
          </div>
          <div className="flex flex-1 items-center flex-col mx-2 h-full pt-2 min-w-[20%]">
            <div className="flex flex-1 w-full items-center ">
              <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
              <div className="border-b flex-1 border-[#A4B1C1]"></div>
              <img
                src="/IconPlane.svg"
                alt=""
                className="w-3 object-contain gap-2"
              />
              <div className="border-b flex-1 border-[#A4B1C1]"></div>
              <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
            </div>
            <small className="whitespace-nowrap py-1 flex items-center gap-1">
              <img
                src="/IconClock1.svg"
                alt=""
                className="w-3 object-contain "
              />
              12h 14m
            </small>
          </div>
          <div className="flex flex-col items-start relative">
            <span className="font-bold text-sm">Mon, 30 SEP 18:30 - LOS</span>
            <span className="text-xs text-gray-600">
              Murtala Muhammed Intl, Lagos
            </span>
            <span className="text-primary1 text-xs -top-4 -right-1 lg:-right-2 absolute whitespace-nowrap">
              +1 day
            </span>
          </div>
        </div>
        <div className="min-w-[200px] hidden lg:flex justify-between gap-2">
          <div className="flex items-center gap-2 px-2 py-1 rounded-full bg-[#F0F0F0] text-primary1 text-xs ">
            <img src="/IconClock1.svg" alt="" className="w-3 object-contain " />
            <span>Layover: 3h</span>
          </div>
          <div className="flex items-center gap-2 px-2 py-1 rounded-full text-red-600 bg-red-600/10 text-xs ">
            <span>Long stopover</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function FlightDetailCTA({ showBook }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let ctas = [
    {
      name: "Create quote",
      onClick: () => {},
    },
    {
      name: "Breakdown",
      onClick: () => {
        dispatch(setFlightFareBreakdownModal(true));
      },
    },
    {
      name: "Upsell",
      onClick: () => {},
    },
    {
      name: "Baggage",
      onClick: () => {
        dispatch(setFlightCheckedBaggageModal(true));
      },
    },
    {
      name: "Fare rules",
      onClick: () => {
        // dispatch(setFlightFareRulesModal(true));
        dispatch(setFlightFareOptionsModal(true));
      },
    },
    {
      name: "Refund & Re-issue",
      onClick: () => {
        dispatch(setFlightRefundAndReissueModal(true));
      },
    },
  ];
  if (showBook) {
    ctas.push({
      name: "Book now",
      onClick: () => {},
      main: true,
    });
  }
  return (
    <div className=" rounded-md mt-2 flex justify-between items-center w-full bg-[#EDEDED] px-2 lg:px-4 py-2">
      <FlightFareBreakdownModal />
      <FlightCheckedBaggageModal />
      <FlightFareRulesModal />
      <FlightRefundAndReissueModal />
      <div className="hidden lg:flex items-start lg:items-center gap-2 lg:gap-4 w-full">
        {ctas?.map(({ name, onClick, main }, index) => (
          <button
            key={index}
            onClick={() => {
              onClick();
            }}
            className={`flex flex-1 items-center justify-center h-12 rounded-md font-bold text-sm px-4 whitespace-nowrap ${
              main ? "bg-primary1 text-white" : "bg-white text-black"
            }`}
          >
            {name}
          </button>
        ))}
      </div>
      <div className="flex lg:hidden items-start lg:items-center gap-2 lg:gap-4 w-full">
        {ctas
          ?.filter((cta, index) => {
            if (showBook) {
              return cta?.main;
            } else {
              return index === 0;
            }
          })
          ?.map(({ onClick, name, main }, index) => (
            <button
              key={index}
              onClick={() => {
                onClick();
              }}
              className={`cursor-pointer flex flex-1 items-center justify-center h-9 rounded-md font-bold text-sm px-4 whitespace-nowrap ${
                main ? "bg-primary1 text-white" : "bg-white text-black"
              }`}
            >
              {name}
            </button>
          ))}
        <div
          className="w-9 h-9 bg-white flex items-center justify-center rounded-md"
          id="basic-button"
          onClick={handleClick}
        >
          <MoreVert />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {showBook ? (
            <>
              {ctas
                ?.filter((cta) => !cta?.main)
                .map(({ name, onClick }, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleClose();
                      onClick();
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
            </>
          ) : (
            <>
              {ctas
                ?.filter((cta, index) => index !== 0)
                .map(({ name, onClick }, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleClose();
                      onClick();
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
            </>
          )}
        </Menu>
      </div>
    </div>
  );
}
