import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightFareRulesModal } from "../../redux/reducers/modalSlice";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: "800px",
};

const rules = [
  {
    title: "Mini Fare Rules",
    rules: [
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
    ],
    opened: false,
    cover: true,
  },
  {
    title: "Mini Fare Rules",
    rules: [
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
    ],
    opened: false,
    cover: true,
  },
  {
    title: "Mini Fare Rules",
    rules: [
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
    ],
    opened: false,
    cover: true,
  },
  {
    title: "Mini Fare Rules",
    rules: [
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
    ],
    opened: false,
  },
  {
    title: "Mini Fare Rules",
    rules: [
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
    ],
    opened: false,
  },
  {
    title: "Mini Fare Rules",
    rules: [
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
      "Once you have purchased Compose + Launch, they can be installed on all of your spaces, or only the ones you want to have them on. There is no extra cost to install Compose + Launch on multiple spaces.",
    ],
    opened: false,
  },
];

export default function FlightFareRulesModal() {
  const dispatch = useDispatch();
  const { flightFareRulesModal } = useSelector((state) => state.modal);
  const [viewAll, setViewAll] = useState(false);
  const [fareRules, setFareRules] = useState(rules);

  const handleOpen = () => dispatch(setFlightFareRulesModal(true));
  const handleClose = () => dispatch(setFlightFareRulesModal(false));

  return (
    <div>
      <Modal
        open={flightFareRulesModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>Fare rules</span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className={Classname.content}>
              {fareRules
                ?.filter((rule) => {
                  if (viewAll) return true;
                  return rule.cover || false;
                })
                .map((rule, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-col gap-3 py-3 my-2 border-y"
                  >
                    <div
                      className="flex justify-between w-full cursor-pointer"
                      onClick={() => {
                        setFareRules(
                          fareRules?.map((rule, i) =>
                            i === index
                              ? { ...rule, opened: !rule.opened }
                              : rule
                          )
                        );
                      }}
                    >
                      <span className="text-sm lg:text-base font-semibold">
                        {rule?.title}
                      </span>
                      {rule?.opened ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </div>
                    {rule?.opened && (
                      <div className="flex flex-col gap-2">
                        {rule?.rules?.map((rule, ruleIndex) => (
                          <div
                            key={ruleIndex}
                            className="text-sm text-gray-600"
                          >
                            {rule}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              {!viewAll && (
                <button
                  className="w-full lg:w-56 rounded-md py-3 bg-[#E5E7EB] font-bold text-sm lg:text-base  my-2"
                  onClick={() => setViewAll(true)}
                >
                  View full fare rules
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-start lg:items-center px-4 max-h-[80vh] overflow-scroll overflow-x-auto scroll-hide py-3",
  modalTitle: "text-xl lg:text-3xl font-bold text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
