import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const SelectPassengers = ({selectedTravelers, updatePassengerCount}) => {
    return(
        <div className="w-72 p-2 px-3 z-10 shadow-lg bg-white rounded-lg  font-poppins py-2" style={{border: "1px solid #cecece"}}>
                <div className='mt-4 h-auto'>
                    <span className='text-l text-[#1E61DC]'>Passengers</span>
                    <div className='gird grid-cols-1 mt-2'>
                        <div className='grid grid-cols-2'>
                            <div className='grid'>
                                <span className='font-bold'>Adult</span>
                                <span>&gt; 12 years</span>
                            </div>
                            <div className='grid grid-cols-3'>
                                <span className='p-1 flex justify-center items-center rounded-lg bg-[#EBEFF9] cursor-pointer' onClick={()=>updatePassengerCount("adult", "dec")}><RemoveIcon className='text-sm'/></span>
                                <span className='flex justify-center items-center font-bold'>{selectedTravelers.adult}</span>
                                <span className='p-1 flex justify-center items-center rounded-lg bg-[#EBEFF9] cursor-pointer' onClick={()=>updatePassengerCount("adult", "add")}><AddIcon className='text-sm'/></span>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 mt-6'>
                            <div className='grid'>
                                <span className='font-bold'>Children</span>
                                <span> 2-12 years</span>
                            </div>
                            <div className='grid grid-cols-3'>
                                <span className='p-1 flex justify-center items-center rounded-lg bg-[#EBEFF9] cursor-pointer' onClick={()=>updatePassengerCount("children", "dec")}><RemoveIcon className='text-sm'/></span>
                                <span className='flex justify-center items-center font-bold'>{selectedTravelers.child}</span>
                                <span className='p-1 flex justify-center items-center rounded-lg bg-[#EBEFF9] cursor-pointer' onClick={()=>updatePassengerCount("children", "add")}><AddIcon className='text-sm'/></span>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 mt-6'>
                            <div className='grid'>
                                <span className='font-bold'>infants</span>
                                <span> Under 2</span>
                            </div>
                            <div className='grid grid-cols-3'>
                                <span className='p-1 flex justify-center items-center rounded-lg bg-[#EBEFF9] cursor-pointer' onClick={()=>updatePassengerCount("infants", "dec")}><RemoveIcon className='text-sm'/></span>
                                <span className='flex justify-center items-center font-bold'>{selectedTravelers.infants}</span>
                                <span className='p-1 flex justify-center items-center rounded-lg bg-[#EBEFF9] cursor-pointer' onClick={()=>updatePassengerCount("infants", "add")}><AddIcon className='text-sm'/></span>
                            </div>
                        </div>
                        {/* <div className='flex w-full justify-center items-center mt-4'>
                            <Button className='font-bold flex justify-center items-center w-full' 
                                sx={{
                                    backgroundColor: "#D9A513",
                                    color: "white",
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: "#ba8d0f", // Change to the desired hover color
                                      },
                                    }}
                                    >
                                Done
                            </Button>
                        </div> */}
                    </div>
                </div>

            </div>
    )


}

export default SelectPassengers