import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightFareBreakdownModal } from "../../redux/reducers/modalSlice";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function FlightFareBreakdownModal() {
  const dispatch = useDispatch();
  const { flightFareBreakdownModal } = useSelector((state) => state.modal);

  const handleOpen = () => dispatch(setFlightFareBreakdownModal(true));
  const handleClose = () => dispatch(setFlightFareBreakdownModal(false));

  const cellStyle = { borderLeftWidth: 0, borderTop: 0 };

  const breakdown = {
    total: "₦850,000",
    detail: [
      {
        pax: "ADT",
        price: "₦850,000",
        tax: "₦850,000",
        ticketfee: "₦850,000",
        markup: "₦850,000",
        agentTotal: "₦850,000",
        customerTotal: "₦850,000",
      },
      {
        pax: "ADT",
        price: "₦850,000",
        tax: "₦850,000",
        ticketfee: "₦850,000",
        markup: "₦850,000",
        agentTotal: "₦850,000",
        customerTotal: "₦850,000",
      },
      {
        pax: "ADT",
        price: "₦850,000",
        tax: "₦850,000",
        ticketfee: "₦850,000",
        markup: "₦850,000",
        agentTotal: "₦850,000",
        customerTotal: "₦850,000",
      },
    ],
  };

  return (
    <div>
      <Modal
        open={flightFareBreakdownModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>Fare breakdown</span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className={Classname.content}>
              <table className="min-w-[1024px] table-fixed">
                <thead className="bg-[#F5F5F5] !border-none rounded-md">
                  <tr>
                    <td className="" style={cellStyle}>
                      No
                    </td>
                    <td ClassName="" style={cellStyle}>
                      Pax
                    </td>
                    <td ClassName="" style={cellStyle}>
                      Tax
                    </td>
                    <td ClassName="" style={cellStyle}>
                      Ticket fee
                    </td>
                    <td ClassName="" style={cellStyle}>
                      Markup
                    </td>
                    <td ClassName="" style={cellStyle}>
                      Agent Total
                    </td>
                    <td ClassName="" style={cellStyle}>
                      Customer Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {breakdown?.detail?.map((fee, index) => (
                    <tr key={index}>
                      <td className="" style={cellStyle}>
                        {index + 1}
                      </td>
                      <td lassName="" style={cellStyle}>
                        {fee?.pax}
                      </td>
                      <td lassName="" style={cellStyle}>
                        {fee?.tax}
                      </td>
                      <td lassName="" style={cellStyle}>
                        {fee?.ticketfee}
                      </td>
                      <td lassName="" style={cellStyle}>
                        {fee?.markup}
                      </td>
                      <td lassName="" style={cellStyle}>
                        {fee?.agentTotal}
                      </td>
                      <td lassName="" style={cellStyle}>
                        {fee?.customerTotal}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex w-full items-center justify-end my-4 pr-4">
              <div className="px-4 text-sm bg-[#F5F5F5] py-1 rounded-md">
                TOTAL : <span className="font-bold">{breakdown?.total}</span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-start lg:items-center px-4 max-h-[80vh] overflow-scroll overflow-x-auto scroll-hide py-3",
  modalTitle: "text-xl lg:text-3xl font-bold text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
