import { useState } from "react";

const TicketManagementAddRemark = () => {
  const [remark, setRemark] = useState("");

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Remarks</span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          Include any relevant details or information here
        </span>
      </div>
      <span className="text-sm mb-2">Remarks (optional)</span>
      <textarea
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
        className="w-full h-36 border resize-y border-gray-300 rounded-md px-4 py-2"
        placeholder="Enter your remarks here"
      />
      <div className={Classname.detailInfoContainer}>
        {/* <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} /> */}
        <span className="font-normal flex flex-col">
          <span>The airline requires 1-3 business days for review.</span>
          <span>
            Service hours for name/title correction：Weekdays: 00:80--22:00 SAT
            : 10:00--04:30 SUN : Not for service.
          </span>
          <span>
            This time is for reference only. The actual time is subject to the
            working hours of airline's local branch offices and Intraverse.
          </span>
        </span>
      </div>
    </div>
  );
};

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
};

export default TicketManagementAddRemark;
