import { useSelector } from "react-redux"
import SearchBar1 from "./SearchBar1";
import SearchBar2 from "./SearchBar2";
import SearchBar from "./SearchBar";
import SearchBar3 from "./SearchBar3";
import SearchBar4 from "./SearchBar4";
import SearchBar5 from "./SearchBar5";

const SearchBarList = ({id,returnData,...props}) => {
    const state = useSelector((state)=> state.template);  
    switch (id || state.searchbarStyle){
        case "1":
            return <SearchBar {...props} returnData={returnData} state={state} curSearch={state.searchbarStyle}/>
        case "2":
            return <SearchBar2 {...props} returnData={returnData} state={state} curSearch={state.searchbarStyle}/>
        case "3":
            return <SearchBar3 {...props} returnData={returnData} state={state} curSearch={state.searchbarStyle}/>
        case "4":
            return <SearchBar4 {...props} returnData={returnData} state={state} curSearch={state.searchbarStyle}/>
        case "5":
            return <SearchBar5 {...props} returnData={returnData} state={state} curSearch={state.searchbarStyle}/>
        default:
            return <SearchBar {...props} returnData={returnData} state={state} curSearch={state.searchbarStyle}/>
    }
}

export default SearchBarList