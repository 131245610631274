import { useEffect } from "react";

const useLoadLocalStorage = (key, setData) => {
  useEffect(() => {
    const loadData = () => {
      let newData = localStorage.getItem(key);
      setData(newData ? JSON.parse(newData) : null);
    };

    loadData();

    const handleStorageChange = (event) => {
      if (event.key === key) {
        loadData();
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, setData]);

  useEffect(() => {
    const syncData = () => {
      const newData = localStorage.getItem(key);
      setData(newData ? JSON.parse(newData) : null);
    };

    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (newKey, newValue) {
      originalSetItem.apply(this, arguments);
      if (newKey === key) {
        syncData();
      }
    };

    return () => {
      localStorage.setItem = originalSetItem;
    };
  }, [key, setData]);
};

export default useLoadLocalStorage;
