import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddColors from '../../../../components/templates/AddColors'
import ChoseFont from '../../../../components/templates/FontStyleChose'
import { Divider } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";

import { setLogoImage } from "../../../../redux/reducers/template/templateSlice";
import Button1 from "../../../../components/form/Button1";
import updateProfile from "../../../../controllers/user/updateProfile";
import { setUser } from "../../../../redux/reducers/userSlice";
import { enqueueSnackbar } from "notistack";


const GenerateLinkForm = () => {
  const state = useSelector((state) => state.template);
  const dispatch = useDispatch();
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [agencyLogo,setAgencyLogo] = useState();
  const [loading,setLoading] = useState(false);
  const setLogo = (file) => {
    setAgencyLogo(file);
    const localImageUrl =  window.URL.createObjectURL(file);
    dispatch(setLogoImage(localImageUrl));
  };

  async function handleLogo(ev) {
    ev.preventDefault();
    ev?.stopPropagation();

    const formData = new FormData();
    // Object.entries(data).map(([key, val]) => val && 
    // );
    formData.append('agencyLogo', agencyLogo)

    setLoading(true);
    const res = await updateProfile(formData);
    setLoading(false);
    if (res.return) {
      enqueueSnackbar("Logo Updated", { variant: "success" });
      // console.log(res.data);
      if (res?.data) dispatch(setUser(res.data));
    } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
  }

  
  console.log(agencyLogo)
  return (
    <div className="mt-10 ">
      <div>
        <span className="font-poppins font-bold text-[#818083]">
          {" "}
          Add your logo
        </span>
        <div className="flex gap-4">
          <FileUploader
            handleChange={setLogo}
            name="file"
            types={fileTypes}
            children={
              <div className="flex h-20 mt-2">
                <div className="flex justify-center items-center bg-silverColor h-full w-20 rounded-md">
                  {state.logoImage ? (
                    <img
                      src={state.logoImage}
                      className="h-20 w-20 rounded-md object-scale-down"
                    />
                  ) : (
                    <AddIcon fontSize="large" />
                  )}
                </div>
                <div className="flex flex-col justify-center ml-2">
                  <div>
                    <h1 className="text-xl font-BrFirmaBold font-bold my-0">
                      Logo
                    </h1>
                    <p className="font-BrFirmaRegular text-[#818083] my-0 mt-2">
                      Pick a logo for your agency. The upload file size limit is
                      1MB.
                    </p>
                  </div>
                </div>
              </div>
            }
            />
            {state?.logoImage?
              <div className="flex items-center px-4">
                <Button1 loading={loading} onClick={handleLogo}>Save</Button1>
              </div>
            :null}
          </div>
      </div>
      <Divider className="!mt-10" />
      <AddColors />
      <ChoseFont />
    </div>
  );
};

export default GenerateLinkForm;
