import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import { tourPackageOptions } from "../../../data/tour/packageData";
import TourManagementChoice from "../../../components/tour/TourManagementChoice";
import TourManagementWelcomeModal from "../../../components/modal/TourManagementWelcomeModal";

function TourManagementCreatePage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [modalOpen, setModalOpen] = useState(true);

  const onSelect = (option) => {
    setSelected(option);
  };

  const onContinue = () => {
    navigate("/tourManagement/create/name");
  };

  const OnContinue = () => {
    setModalOpen(false);
  };

  return (
    <div className={Classname.container}>
      <TourManagementWelcomeModal
        open={modalOpen}
        setOpen={setModalOpen}
        onContinue={OnContinue}
      />
      <TourManagementHeader />
      <div className={Classname.content}>
        <span className="font-bold mb-2">Create a new package</span>
        <span className="font-semibold mb-2">
          Which best describes your activity?
        </span>
        <p>
          This helps us categorize your product so customers can find it. Choose
          carefully as this is the only section that can't be changed later.
        </p>
        <TourManagementChoice
          selected={selected}
          onSelect={onSelect}
          options={tourPackageOptions}
          className="mb-10"
        />
        <div className={Classname.buttonContainer}>
          <button className={Classname.continueButton} onClick={onContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default TourManagementCreatePage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 pt-28",
  buttonContainer: "flex items-center justify-end",
  continueButton:
    "font-bold px-6 h-11 rounded-md flex items-center justify-center text-white bg-primary1 mb-20",
};
