import {
  Dashboard,
  HomeOutlined,
  Person,
  ShoppingCartOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "./HOC/Icon";
import { getsubDomain } from "../utils/getsubDomain";
import { getTestLevel } from "../utils/testLevel";
import BetaTesting from "./mini/BetaTesting";

// const returnPage = (link) => link === 0 ? ''
// :
// link ===  1 ? 'order'
// :
// link ===  2 ? 'users'
// :
// link ===  3 ? 'support'
// :
// 0

export default function NavLinks({ profileCompleted }) {
  const { user, subscriptionPlan } = useSelector(
    (state) => state.user.userData
  );
  const location = useLocation();
  const locations = location.pathname?.split("/");
  const page = locations[1] || "";
  const navigate = useNavigate();
  const [link, setLink] = useState(0);
  const agency = getsubDomain();
  const profileComplete = agency || user?.detail?.isVerified;
  const subscribed = subscriptionPlan?.plan?._id && (subscriptionPlan?.plan?.mode !== 'FREE');

  // const [initialPage,setInitialPage] = useState(link);

  useEffect(() => {
    let pag = (page === '' ? 0 
    // :
    // page === 'getting-started' && !profileComplete ? -1
    :
    page === 'dashboard' ? 1
    :
    page === 'order' ? 2
    :
    page === 'accommodation' ? 2
    :
    page === 'tour' ? 2
    :
    page === 'users' ? 3
    :
    page === 'website' ? 4
    :
    page === 'plans' ? 5
    :
    page === 'support' ? 5
    :
    0)
    setLink(pag)
    // setLink((agency && page !== 'agency-welcome') || !profileComplete?pag+1:pag)
    //eslint-disable-next-line
  }, [page]);

  // useEffect(() => {
  //   // let link = ev.target.getAttribute('link');
  //   if(initialPage !== link) {

  //     navigate(returnPage(link));
  //     setInitialPage(link)
  //   }
  // },[link,navigate,initialPage])

  function handleLink(ev, val) {
    // navigate(ev.target.dataset.link)
    setLink(val);
    return true;
  }

  function handleNav(link) {
    if (link === window.location.pathname) window.location.reload();

    navigate(link);
  }

  return profileCompleted ? (
    <div className="">
      <Tabs
        variant="scrollable"
        value={link}
        onChange={handleLink}
        className="font-bold"
        TabIndicatorProps={{ sx: { height: "4px" } }}
      >
        {/* {!profileComplete?
          <Tab label='Getting Started' onClick={() => handleNav("/getting-started")} data-link="/getting-started" icon={<Icon icon='heroicons:rocket-launch' className=' -rotate-[43deg]' />} iconPosition="start" className='!capitalize !min-w-[150px] md:flex-1 !whitespace-nowrap' />
        :null}
        {agency ?
          <Tab label='Getting Started' onClick={() => handleNav("/agency-welcome")} data-link="/agency-welcome" icon={<Icon icon='heroicons:rocket-launch' className=' -rotate-[43deg]' />} iconPosition="start" className='!capitalize !min-w-[150px] md:flex-1 !whitespace-nowrap' />
        :null} */}
        <Tab
          label="Home"
          onClick={() => handleNav("/")}
          data-link="/"
          icon={<HomeOutlined />}
          iconPosition="start"
          className="!capitalize md:flex-1 !whitespace-nowrap"
        />
        <Tab
          label="Dashboard"
          onClick={() => handleNav("/dashboard")}
          data-link="/dashboard"
          icon={<Dashboard />}
          iconPosition="start"
          className="!capitalize md:flex-1 !whitespace-nowrap"
        />
        <Tab
          label="Orders"
          onClick={() => handleNav("/order")}
          data-link="/order"
          icon={<ShoppingCartOutlined />}
          iconPosition="start"
          className="!capitalize md:flex-1 !whitespace-nowrap"
        />
        <Tab
          label="Customers"
          onClick={() => handleNav("/users")}
          data-link="/users"
          icon={<Person />}
          iconPosition="start"
          className="!capitalize md:flex-1 !whitespace-nowrap"
        />
        <Tab
          label={<span>My Website <BetaTesting /></span>}
          onClick={() => handleNav("/website")}
          data-link="/website"
          icon={<Icon icon='ph:link-bold' className='text-[#4A5C6B] !w-5 !h-5' />}
          iconPosition="start"
          className="!capitalize md:flex-1 !whitespace-nowrap"
          />
        {user?.detail?.isVerified && (subscriptionPlan && !subscribed) ? (
          <Tab
            label="Subscription"
            onClick={() => handleNav("/plans")}
            data-link="/plans"
            icon={<Icon icon="eos-icons:subscriptions-created" />}
            iconPosition="start"
            className="!capitalize md:flex-1 !whitespace-nowrap"
          />
        ) : null}
        {/* <Tab label='Support' data-link="/support" icon={<SupportAgentOutlined />} iconPosition="start" className='!capitalize md:flex-1 !whitespace-nowrap' /> */}
      </Tabs>
    </div>
  ) : (
    <div>
      {/* <Tabs variant="scrollable" value={link} onChange={handleLink} className="font-bold" 
        TabIndicatorProps={{sx: {height: '4px'}}}>
          {page === 'welcome' ? 
            <Tab label='Support' data-link="/" icon={<Icon icon='heroicons:rocket-launch' className='text-theme1 -rotate-[43deg]' />} iconPosition="start" className='!capitalize md:flex-1 !whitespace-nowrap' />
          :
            <Tab label='Getting Started' data-link="/" icon={<Icon icon='heroicons:rocket-launch' className='text-theme1 -rotate-[43deg]' />} iconPosition="start" className='!capitalize md:flex-1 !whitespace-nowrap' />
          }
      </Tabs> */}
    </div>
  );
}
