import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react'
import Slider from "react-slick";
import home1 from '../../../assets/images/home/bg1.jpg';
import home2 from '../../../assets/images/home/bg2.jpg';
import home3 from '../../../assets/images/home/bg3.jpg';


export default function HomeCarousel({data,...props}) {
  const list = [home1,home2,home3];

  return (
    <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <Slider className="h-full"
            sx={{
              
            }}
            {...{
            // dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 10000,
            ...(props || {})
            }}              // infiniteLoop
            // autoPlay
            // interval={10000}
        >
          {(data || list).map((val,i) => 
            <img src={val} key={i} className="w-full h-full object-cover" />
          )}
        </Slider>
    </div>
  )
}
