import React from "react";



function TicketManagementFlightOrderDetailStatusContainer({order}) {
  return (
    <div className="flex flex-col w-full lg:w-[485px] border border-[#DBDBDB] bg-white rounded-md p-4 shadow-sm h-fit">
      <span className="text-sm lg:text-base mb-2 text-[#818083] ">Status</span>
      <Status value={order?.status} />
      <div className="flex flex-row justify-between mt-4">
        <div className="flex flex-col">
          <span className="text-sm lg:text-base text-[#818083] ">Airline</span>
          <div className="flex items-center gap-2">
            <img
              src="/IconTurkish.svg"
              alt=""
              className="w-6 h-6 object-contain"
            />
            <span className="text-sm lg:text-base font-bold">
              Turkish Airline
            </span>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-sm lg:text-base text-[#818083] ">Date</span>
          <span className="text-sm lg:text-base font-bold">09/04/2023</span>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <span className="text-sm lg:text-base text-[#818083] ">Order ID</span>
        <span className="text-sm lg:text-base font-bold">M494930</span>
      </div>
      <PriceBreakdown />
      <span className="text-sm mt-4 mb-3 font-bold">Important info</span>
      <p className="mt-3 text-xs text-[#6D7C94] ">
        On rare occasions, ticketing of paid order may not be successful for
        many expected reasons such as the variety of booking class, INTRAVERSE
        may offer full refund of payments for the failure of issuance within 3
        hours of payment received.
      </p>
      <p className="mt-3 text-xs text-[#6D7C94] ">
        Please make sure the passport number is correct and the validity should
        be at least six month from date of departure of the last journey.
      </p>
      <p className="mt-3 text-xs text-[#6D7C94] ">
        INTRAVERSE strongly recommends that duplicate reservations are avoided.
        Merging and cancellation may occur within the airline system without
        prior notification. INTRAVERSE is not liable for any loss or damage.
      </p>
    </div>
  );
}

const Status = () => {
  let stat = "success";
  const getColors = () => {
    switch (stat) {
      case "success":
        return "text-[#0B662C] border-[#1EA994] bg-[#C4E9E3]";
      case "pending":
        return "text-[#D9A513] border-[#D9A513] bg-[#D9A513]/20";
      case "rejected":
        return "text-[#B52026] border-[#B52026] bg-[#B52026]/20";
      default:
        return "text-[#0B662C] border-[#1EA994] bg-[#C4E9E3]";
    }
  };
  return (
    <div
      className={`flex flex-col w-fit border-2 ${getColors()} px-4 rounded-md py-1`}
    >
      Ticket issued
    </div>
  );
};

const PriceBreakdown = () => {
  const breakdown = {
    total: "606.17 USD",
    detail: [
      {
        quantity: 1,
        feeName: "Adult",
        total: "606.17 USD",
        items: [
          { name: "Fare per adult", price: "534.1 USD" },
          { name: "Taxes per adult", price: "71.07 USD" },
        ],
      },
      { quantity: 1, feeName: "Ticketing fee", total: "1 USD" },
    ],
  };
  return (
    <div className="w-full bg-[#F1F1F1] flex flex-col p-4 mt-4">
      <div className="flex justify-end text-primary1 text-end mb-2 text-sm lg:text-base">
        Price breakdown
      </div>
      {breakdown.detail.map((item, index) => (
        <div key={index} className="flex flex-col mb-2">
          <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span className="">
              {item.quantity} x {item.feeName}
            </span>
            <span className="">{item.total}</span>
          </div>
          {item.items &&
            item.items?.map((subItem, subIndex) => (
              <div
                key={subIndex}
                className="flex items-center gap-2 justify-between text-gray-600 ml-2"
              >
                <span className="">{subItem.name}</span>
                <span className="">{subItem.price}</span>
              </div>
            ))}
        </div>
      ))}
      <div className="w-full border-b border-gray-300 mb-2"></div>
      <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
        <span className="">Total</span>
        <span className="">606.17 USD</span>
      </div>
      <div className="flex font-bold w-full justify-end text-end text-primary1 text-sm lg:text-base mt-2">
        606.17 USD
      </div>
      <div className="flex font-bold w-full justify-end text-end text-[#6D7C94] text-sm lg:text-base mt-2">
        NOT including payment fee
      </div>
    </div>
  );
};

export default TicketManagementFlightOrderDetailStatusContainer;
