import React, { useState } from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TourManagementPackagePhotoUploadPage() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [images, setImages] = useState([]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleImageUpload(files);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    handleImageUpload(files);
  };

  const handleImageUpload = (files) => {
    const imageFiles = Array.from(files).filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif"
    );

    const newImages = imageFiles.map((file) => URL.createObjectURL(file));
    setImages([...images, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    const images1 = images.filter((image, idx) => idx !== index);
    setImages(images1);
  };

  const handleContinue = () => {
    navigate("/tourManagement/create/options");
  };

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementSidebar selected={11} />
        <div className={Classname.detail}>
          <div className={Classname.title}>
            Drag your photos into the area below or select "Upload". To change
            the order, click and drag.
          </div>
          <div className="flex items-center my-2">
            <FormGroup onChange={handleCheckboxChange}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <label className="text-sm text-gray-400">
                    I own the copyright for these pictures, and have model
                    release forms for any recognizable faces. I understand I’m
                    liable for any copyright infringement
                  </label>
                }
              />
            </FormGroup>
          </div>
          <div>
            Your photos should have no more than 7MB each and be at least 1280px
            wide. Also, please use only JPG, JPEG, PNG, or GIF images
          </div>
          <div className="mt-4">
            <label htmlFor="uploadInput" className={Classname.continueButton}>
              Upload
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                className="hidden"
                id="uploadInput"
                multiple
                disabled={!isChecked}
              />
            </label>
          </div>
          <div
            className="flex flex-wrap gap-4 justify-center mt-6 mb-4 border-2 py-8 "
            onDragOver={(event) => handleDragOver(event)}
            onDrop={(event) => handleDrop(event)}
          >
            {images.length > 0 ? (
              images.map((image, index) => (
                <div
                  key={index}
                  className="flex relative group"
                  onClick={() => handleRemoveImage(index)}
                >
                  <div className={Classname.imageDeleteButton}>
                    <img
                      src="/IconClose1.svg"
                      alt=""
                      className={Classname.closeIcon}
                    />
                  </div>
                  <img
                    src={image}
                    className="w-44 h-44 rounded-md object-cover"
                    alt={`Image ${index + 1}`}
                  />
                </div>
              ))
            ) : (
              <div className="py-20">Drop your photos here</div>
            )}
          </div>

          <div className="flex gap-4 justify-end mt-4">
            <button className={Classname.saveButton}>Save and exit</button>
            <button
              className={Classname.continueButton}
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackagePhotoUploadPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  titleOptional: "text-sm text-gray-500 font-normal",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 w-fit px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md cursor-pointer",
  closeIcon: "w-4 object-contain",
  imageDeleteButton:
    "bg-white w-8 h-8 rounded-full hidden group-hover:flex items-center justify-center absolute right-2 top-2 shadow-md cursor-pointer",
};
