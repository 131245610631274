import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// import ProfileSurvey from './ProfileSurvey/ProfileSurvey';
import Header from "./Header";
import NavLinks from "./NavLinks";
import { useSelector } from "react-redux";
import checkProfileComplete from "../features/profile/checkProfileComplete";
import WelcomeNavbar from "./WelcomeNavbar";
import GettingStarted from "../pages/Welcome/GettingStarted";
import { getsubDomain, isCustomerSite } from "../utils/getsubDomain";
import HeadersList from "./templates/headers/headersList";
import FooterList from "./templates/footers/footerList";
import Footer from "./templates/footers/Footer";

function Navbar() {
  const { user } = useSelector((state) => state.user.userData);
  const agency = getsubDomain();
  const profileCompleteCheck = checkProfileComplete(user);
  const verified = user?.detail?.isVerified;
  const completed =
    (profileCompleteCheck?.every((obj) => obj.complete) &&
      user?.detail?.requestedVerification) ||
    verified;
  const [secondaryNavBar, setSecondaryNavBar] = useState(true);
  const location = useLocation();
  const pathname = location.pathname.replace(/\/$/, "");

  const state = useSelector((state) => state.template);

  useEffect(() => {
    if (window?.Intercom) window?.Intercom("update");
  }, []);

  useEffect(() => {
    showSecondaryNavbar();
  }, [pathname]);

  // enqueueSnackbar('your welcom',{variant: 'success'})
  const showSecondaryNavbar = () => {
    const pathsToHideNavbar = [
      "/gettingStarted/business/activate",
      "/gettingStarted/logo",
      "/gettingStarted/link",
      "/gettingStarted/markups",
      "/gettingStarted/markups/create",
      "/gettingStarted/team",
      "/gettingStarted/team/invite",
      "/gettingStarted/subscription",
      "/gettingStarted/pricing",
      "/gettingStarted/congratulations",
      "/welcome/pricing",
      "/whitelabel",
    ];

    if (pathsToHideNavbar.includes(pathname)) {
      setSecondaryNavBar(false);
    } else {
      if (!secondaryNavBar) {
        setSecondaryNavBar(true);
      }
    }
  };

  const customer = isCustomerSite();

  return (
    <div className="flex flex-col min-h-screen">
      {customer ? (
        <div className="border-b border-primary/5">
          <HeadersList />
        </div>
      ) : (
        <Header />
      )}
      {secondaryNavBar && !customer && (
        <div className="w-full hidden md:block px-md shadow-sm bg-secondary border-b">
          <NavLinks profileCompleted={true} />
        </div>
      )}
      {/* <ProfileSurvey /> */}
      <div
        className={`bg-secondary flex flex-col h-full flex-1 ${
          customer ? "min-h-screen" : ""
        }`}
      >
        <Outlet />
        {/* <ProfileStatusCheck data={profileCompleteCheck} /> */}
        {/* {agency || completed ? (
        ) : (
          <WelcomeNavbar>
            <GettingStarted />
          </WelcomeNavbar>
        )} */}
      </div>
    </div>
  );
}

export default React.memo(Navbar);
