import fetchServer from "../fetchServer";


export default async function deleteOrderPriceAdjustments(id) {
  let result = { return: 0, msg: "Something went wrong removing Price Adjustment!" };

  await fetchServer({
    method: "DELETE",
    url: "/product/v1/otherPriceAdjustment/"+id,
  })
    .then((res) => {
      if (res?.data && !res?.data?.error) {
        result = { return: 1, msg: "Successful", data: res?.data };
      } else result["msg"] = res?.data?.error || result["msg"];
    })
    .catch((err) => {
      console.log("Network Error!");
    });

  return result;
}
