import React from "react";

function WhiteLabelNeedSection() {
  const items = [
    {
      title: "Search",
      image: "/WhiteLabelNeedSearch.svg",
      description:
        "Make searching flights an intuitive experience for your customers.",
    },
    {
      title: "Book",
      image: "/WhiteLabelNeedBook.svg",
      description:
        "Capture all relevant details from your customer in a one-step checkout",
    },
    {
      title: "Ancillaries",
      image: "/WhiteLabelNeedAncillaries.svg",
      description:
        "Let your customers book premium sears and pay for additional baggage.",
    },
    {
      title: "Payments",
      image: "/WhiteLabelNeedPayments.svg",
      description:
        "Take payments securely from your customers using our payments.",
    },
    {
      title: "Order Management",
      image: "/WhiteLabelNeedManagement.svg",
      description: "Register interest today...",
      link: true,
    },
  ];
  return (
    <div className="flex flex-col w-full px-2 py-10 items-center self-center max-w-7xl">
      <span className="text-xl lg:text-3xl font-bold text-center mt-10 mb-10">
        Everything you need, in one link.
      </span>
      <div className="flex flex-wrap justify-between w-full gap-3">
        {items?.map((item, index) => (
          <div
            className="flex flex-col gap-3 items-start w-full lg:flex-1 lg:min-w-[380px] py-2 rounded-md bg-[#F0F6FC]"
            key={index}
          >
            <div className="w-full justify-end flex">
              <img src={item?.image} alt="" className="h-28 object-str " />
            </div>
            <div className="flex flex-col p-4">
              <span className="font-bold text-base lg:text-lg mb-2">
                {item?.title}
              </span>
              {item?.link ? (
                <a className="text-sm lgg:text-base text-primary1 underline">
                  {item?.description}
                </a>
              ) : (
                <span className="text-sm lgg:text-base">
                  {item?.description}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhiteLabelNeedSection;
