import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

function SortDropdown({ classname, value, onChange, items }) {
  return (
    <div className={`${Classname.container} ${classname}`}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Sort</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Sort"
          onChange={onChange}
          className={Classname.select}
        >
          {items?.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SortDropdown;

const Classname = {
  container: "w-52 hidden lg:flex",
  select: "bg-gray-100 text-xs",
};
