import React from "react";
import { useNavigate } from "react-router-dom";
import RatingStars from "../common/RatingStars";
import { URLdecode, URLencode } from "../../utils/url";
import { useHotels } from "../../hooks/useHotels";
import { formatMoney } from "../../features/utils/formatMoney";

function HotelCard({ hotel }) {
  const navigate = useNavigate();
  const { travellersString } = useHotels();
  const params = URLdecode();
  const adult = params?.adult;
  const children = params?.children;

  const rating = () => {
    let stars = null;

    const awards = hotel?.details?.awards;

    if (awards) {
      let star = awards.find((award) => award.RatingSymbol === "Star");
      if (star) {
        if (parseInt(star?.Rating)) {
          return parseInt(star?.Rating);
        }
      }
    }

    return stars;
  };

  const handleClick = () => {
    const parameters = {
      ...params,
      lat: params?.lat,
      lng: params?.lng,
      location: params?.location,
      start_date: params?.start_date,
      end_date: params?.end_date,
      code: hotel?.code,
      codeContext: hotel?.codeContext,
      name: hotel?.name,
      cityCode: hotel?.cityCode,
      chainCode: hotel?.chainCode,
      chainName: hotel?.chainName,
      areaId: hotel?.areaId || "",
      categoryCode: hotel?.categoryCode,
      address: hotel?.address,
      city: hotel?.city,
      postalCode: hotel?.postalCode,
      countryCode: hotel?.countryCode,
      units: hotel?.units,
      nights: hotel?.nights,
      supplier: hotel?.supplier,
      // availabilities: JSON.stringify(hotel?.availabilities),
    };

    localStorage.setItem(`hotel ${hotel.name}`, JSON.stringify(hotel));

    let url = `/accommodation/hotel?${URLencode(parameters)}`;

    window.open(url, "_blank");
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.imageContainer}>
        {hotel?.details?.image && hotel?.details?.image[0] && (
          <img src={hotel?.details?.image[0]} className={Classname.image} />
        )}
      </div>

      <div className={Classname.content}>
        <div className={Classname.nameContainer}>
          <span className={Classname.name}>{hotel?.name}</span>
        </div>

        {rating() && (
          <div className={Classname.ratingContainer}>
            <RatingStars rating={rating()} />
            <span>{rating()}/5</span>
          </div>
        )}

        <div className={Classname.locationContainer}>
          <img
            src="/IconLocationGreen.svg"
            alt=""
            className={Classname.locationIcon}
          />
          <span>{hotel?.address}</span>
        </div>

        <div className={Classname.detailRow}>
          <span className="font-bold"></span>
          <span>
            {hotel?.nights}{" "}
            {hotel?.nights && hotel?.nights === 1 ? "night" : "nights"}
            {" ,"}
            {travellersString()}
          </span>
        </div>
        <div className={Classname.featruesAndButton}>
          <div className={Classname.ctaContainer}>
            <span className="font-bold text-3xl ">
              {formatMoney(hotel?.price, hotel?.currency === "NGN" ? "₦" : "$")}
            </span>

            <span className="text-gray-600 text-sm mb-2">
              includes taxes and charges
            </span>
            <button
              onClick={() => handleClick()}
              className={Classname.selectButton}
            >
              Check availability
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelCard;

const Classname = {
  container:
    "w-full min-h-280px h-fit rounded-lg shadow-md bg-gray-100  border-gray-200 flex flex-wrap",
  imageContainer: "lg:w-72 max-h-72  flex items-center justify-center relative",
  image: " w-full h-full object-cover rounded-md rounded-r-none",
  content: "flex flex-1 flex-col min-w-96 px-6 py-4 bg-gray-100",
  nameContainer: "flex gap-2",
  name: "flex flex-1 mb-2 font-bold text-xl",
  priceContainer: "flex flex-col text-gray-600 font-bold",
  price: "font-bold text-lg text-black",
  description: "flex text-sm text-gray-500 mt-2 mb-4",
  detailRow: "flex w-full justify-between items-center text-sm text-gray-500",
  ratingLink: "text-primary1",
  featruesAndButton: "flex w-full justify-between items-center mt-4",
  ctaContainer: "flex flex-1 flex-col items-end text-sm",
  locationContainer: "flex items-center gap-2 mb-3",
  locationIcon: "",

  ratingContainer: "flex flex-col sm:flex-row sm:items-center gap-3 mb-2",

  selectButton:
    "h-10 rounded-md px-6 bg-primary1 flex items-center justify-center font-bold text-sm text-white",
  cardTagsContainer: "flex flex-wrap gap-2 mt-2",
  likelyTag:
    "text-xxxs font-semibold text-red-700 bg-red-600/20 px-2 py-1 rounded-md",
  topTag:
    "text-xxxs font-semibold text-yellow-800 bg-yellow-600/20 px-2 py-1 rounded-md",
};
