/**@type {string[]} */
export const RolesData = [
  {
    "value": "FLIGHT_SEARCH",
    "category": "Booking",
    "product": "Flight"
  },
  {
    "value": "BOOK_FLIGHT",
    "category": "Booking",
    "product": "Flight"
  },
  {
    "value": "GET_FLIGHT_BOOKING",
    "category": "Booking",
    "product": "Flight"
  },
  {
    "value": "CANCEL_FLIGHT_BOOKING",
    "category": "Booking",
    "product": "Flight"
  },
  {
    "value": "HOLD_FLIGHT_BOOKING",
    "category": "Booking",
    "product": "Flight"
  },
  {
    "value": "SELF_TICKET",
    "category": "Transactions",
    "product": "Flight"
  },
  {
    "value": "ISSUE_TICKET",
    "category": "Transactions",
    "product": "Flight"
  },
  {
    "value": "PAY_FOR_TICKET",
    "category": "Transactions",
    "product": "Flight"
  },
  {
    "value": "CREATE_FLIGHT_PRICE_ADJUSTMENT",
    "category": "Transactions",
    "product": "Flight"
  },
  {
    "value": "UPDATE_FLIGHT_PRICE_ADJUSTMENT",
    "category": "Transactions",
    "product": "Flight"
  },
  {
    "value": "GET_FLIGHT_PRICE_ADJUSTMENT",
    "category": "Finance",
    'product': 'Flight'
  },
  {
    "value": "ADD_PAYSTACK_KEYS",
    "category": "Confidential Info",
    "product": "All"
  },
  {
    "value": "GET_PAYSTACK_KEYS",
    "category": "Confidential Info",
    "product": "All"
  },
  {
    "value": "GET_TRANSACTIONS",
    "category": "Transactions",
    "product": "All"
  },
  {
    "value": "ADD_WEBHOOK",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "UPDATE_WEBHOOK",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "GET_WEBHOOK",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "DELETE_WEBHOOK",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "GET_WALLET_BALANCE",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "CREDIT_WALLET",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "SETUP_AUTOMATIC_PAYMENT",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "ADD_CONTACT_MAIL",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "UPDATE_CONTACT_MAIL",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "GET_CONTACT_MAIL",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "DELETE_CONTACT_MAIL",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "ADD_BANK_ACCOUNT",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "ADD_BANK_ACCOUNT",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "UPDATE_BANK_ACCOUNT",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "GET_BANK_ACCOUNT",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "REMOVE_BANK_ACCOUNT",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "ADD_CARD",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "UPDATE_CARD",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "GET_CARD",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "REMOVE_CARD",
    "category": "Finance",
    "product": "All"
  },
  {
    "value": "ADD_DEAL_CODE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "UPDATE_DEAL_CODE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "GET_DEAL_CODE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "REMOVE_DEAL_CODE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "ADD_EMAIL_TEMPLATE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "UPDATE_EMAIL_TEMPLATE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "GET_EMAIL_TEMPLATE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "REMOVE_EMAIL_TEMPLATE",
    "category": "Configuration",
    "product": "All"
  },
  {
    "value": "ADD_CUSTOM_SUPPLIER_KEYS",
    "category": "Confidential Info",
    "product": "All"
  },
  {
    "value": "ADD_CUSTOM_SUPPLIER_KEYS",
    "category": "Confidential Info",
    "product": "All"
  },
  {
    "value": "UPDATE_CUSTOM_SUPPLIER_KEYS",
    "category": "Confidential Info",
    "product": "All"
  },
  {
    "value": "VIEW_CUSTOM_SUPPLIER_KEYS",
    "category": "Confidential Info",
    "product": "All"
  },
  {
    "value": "INVITE_STAFF",
    "category": "User Management",
    "product": "All"
  }
]


export const RolesObj = {
  "ALL":"ALL",
  "AMADEUS_SEARCH":"AMADEUS_SEARCH",
  "AMADEUS_BOOK":"AMADEUS_BOOK",
  "AMADEUS_TICKET":"AMADEUS_TICKET",
  "AMADEUS_CANCEL":"AMADEUS_CANCEL",
  "AMADEUS_IMPORT":"AMADEUS_IMPORT",
  "AMADEUS_REFUND":"AMADEUS_REFUND",
  "AMADEUS_REISSUE":"AMADEUS_REISSUE",
  "AMADUES_HOTEL_SEARCH":"AMADUES_HOTEL_SEARCH",
  "AMADUES_HOTEL_BOOK":"AMADUES_HOTEL_BOOK",
  "AMADUES_HOTEL_CANCEL":"AMADUES_HOTEL_CANCEL",
  "HOTELBEDS_SEARCH":"HOTELBEDS_SEARCH",
  "HOTELBEDS_BOOK":"HOTELBEDS_BOOK",
  "HOTELBEDS_CANCEL":"HOTELBEDS_CANCEL",
  "TOURPRO_SEARCH":"TOURPRO_SEARCH",
  "TOURPRO_BOOK":"TOURPRO_BOOK",
  "TOURPRO_CANCEL":"TOURPRO_CANCEL",
}