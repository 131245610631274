import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  recentActivities: [],
  teamActivities: [],
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setRecentActivities: (state, action) => {
      state.recentActivities = action.payload;
    },
    setTeamActivities: (state, action) => {
      state.teamActivities = action.payload;
    },
  },
});

export const { setRecentActivities, setTeamActivities } = overviewSlice.actions;

export default overviewSlice.reducer;
