import { useRef, useState } from "react"
import { Icon } from "@iconify/react"
import TextField from '@mui/material/TextField';
import { MuiTelInput } from 'mui-tel-input'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import countryList from '../../../assets/json/countries.json'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button1 from "../../form/Button1";
import { enqueueSnackbar } from "notistack";
import customerPasswordUpdate from "../../../controllers/customer/updatePassword";
import { useDispatch, useSelector } from "react-redux";
import updateCustomerProfile from "../../../controllers/customer/updateProfile";
import { setUser } from "../../../redux/reducers/userSlice";
const BasicInfo = ()=>{
    const [expanded, setExpanded] = useState(true)
    const [phone, setPhone] = useState('')
    const [loading,setLoading] = useState(false);
    const [profileImage,setProfileImage] = useState();
    const [data,setData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: '',
    })
    const dispatch = useDispatch();

    console.log(profileImage)

    const profImg = useRef();

    function openProileImage() {
        profImg?.current?.click();
    }

    async function handleSubmit() {
        const formData = new FormData();
        formData.append('firstName',data?.firstName)
        formData.append('middleName',data?.middleName)
        formData.append('lastName',data?.lastName)
        // formData.append('email',data?.email)
        formData.append('phone',data?.phone)
        if(profileImage)
            formData.append('profileImage',profileImage[0])
        setLoading(true);
        const res = await updateCustomerProfile(formData);
        setLoading(false);
        if(res.return) {
            enqueueSnackbar('Profile Updated',{variant: 'success'})
            dispatch(setUser(res.data));
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }

    return(
        <div className="bg-white p-2 mt-10">
            <div className="flex justify-between">
                <div className="text-md font-bold">Basic Information</div>
                {expanded ? <Icon icon="icon-park-outline:up" onClick={()=>setExpanded(!expanded)} className="h-5 w-5"/> : <Icon icon="icon-park-outline:down" onClick={()=>setExpanded(!expanded)} className="h-5 w-5"/>}
            </div>
            {expanded ? <div className="grid gap-2 !grid-cols-1  mt-2 gap-y-4">
                <TextField id="outlined-basic" label="Firstname" variant="outlined" className="col-span-1"
                    value={data?.firstName}
                    onChange={(ev) => setData({...data,firstName: ev?.target?.value})}
                />
                <TextField id="outlined-basic" label="Middlename" variant="outlined" className="col-span-1"
                    value={data?.middleName}
                    onChange={(ev) => setData({...data,middleName: ev?.target?.value})}
                />
                <TextField id="outlined-basic" label="Lastname" variant="outlined" className="col-span-1"
                    value={data?.lastName}
                    onChange={(ev) => setData({...data,lastName: ev?.target?.value})}
                />
                {/* <TextField id="outlined-basic" label="Email" type="email" variant="outlined" className="col-span-1"
                    value={data?.email}
                    onChange={(ev) => setData({...data,email: ev?.target?.value})}
                /> */}
                <MuiTelInput value={data?.phone} onChange={(val)=>setData({...data,phone: val})} label="phone" defaultCountry="NG" className="col-span-1"/>
                {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Country"
                    onChange={(e)=> selectCountry(e.target.value)}
                    >
                    {countryList.map((country, index)=>{
                        return(
                            <MenuItem key={index} value={country.code}>{country.name}</MenuItem>
                        )
                        })
                    }
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        label="Gender"
                        onChange={(e)=> setGender(e.target.value)}
                        >
                            <MenuItem key={1} value="male">Male</MenuItem>
                            <MenuItem key={1} value="female">Female</MenuItem>
                    </Select>
                </FormControl> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker']} className="pt-0">
                        <DatePicker
                        label="Date of birth"
                        value={data?.birthDate}
                        sx={{paddingTop: 0}}
                        onChange={(newValue) => setData({...data,birthDate: newValue})}
                        />
                    </DemoContainer>
                </LocalizationProvider> */}
                <input type='file' className="absolute w-0 h-0" ref={profImg} 
                    onChange={(ev) => setProfileImage(ev?.target?.files)}
                />
                <div className="bg-[#E9EDF1] rounded-sm flex justify-center items-center cursor-pointer px-2"
                    onClick={openProileImage}
                >
                    <div className="flex gap-2 items-center ">
                        <Icon icon="icon-park-outline:camera" className="h-5 w-5"/>
                        <div className="font-bold">Add a profile picture</div>
                        {profileImage ? 
                            <Icon icon='lets-icons:check-fill' className="w-5 h-5 text-green-500" />
                        :null}
                    </div>
                </div>
                <Button1 loading={loading} className="!bg-[#E9EDF1] !text-black rounded-sm flex justify-center items-center cursor-pointer col-end-4 !py-4 !px-2"
                  onClick={handleSubmit}
                >
                    <div className="font-bold">Save changes</div>
                </Button1>
            </div> : null}
        </div>
    )
}

const PasswordSettings = ()=>{
    const [expanded, setExpanded] = useState(true)
    const [currentPass, setCurrentPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [newPasswordVisible, setNewPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [loading,setLoading] = useState(false)

    async function handleSubmit() {
        
        if(newPass !== confirmPass)
        return enqueueSnackbar('Password doesn\'t match', {variant: 'error'})
    
        setLoading(true);
        const res = await customerPasswordUpdate({
            oldPassword: currentPass,
            newPassword: newPass,
        })
        setLoading(false);
        if(res.return) {
            enqueueSnackbar('Password updated',{variant: 'success'})
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
    
    return(
        <div className="bg-white p-2 mt-10">
            <div className="flex justify-between">
                <div className="text-md font-bold">Password Settings</div>
                {expanded ? <Icon icon="icon-park-outline:up" onClick={()=>setExpanded(!expanded)} className="h-5 w-5"/> : <Icon icon="icon-park-outline:down" onClick={()=>setExpanded(!expanded)} className="h-5 w-5"/>}
            </div>
            {expanded ? <div className="grid gap-2 grid-cols-1 lg:grid-cols-3 mt-4 gap-y-4">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={passwordVisible ? 'text' : 'password'}
                        onChange={(ev) => setCurrentPass(ev.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>setPasswordVisible(!passwordVisible)}
                            edge="end"
                            >
                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={newPasswordVisible ? 'text' : 'password'}
                        onChange={(ev) => setNewPass(ev.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>setNewPasswordVisible(!newPasswordVisible)}
                            edge="end"
                            >
                            {newPasswordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="New Password"
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        onChange={(ev) => setConfirmPass(ev.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>setConfirmPasswordVisible(!confirmPasswordVisible)}
                            edge="end"
                            >
                            {confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="New Password"
                    />
                </FormControl>
                <Button1 loading={loading} onClick={handleSubmit} className="!bg-[#E9EDF1] !text-black rounded-sm flex justify-center items-center cursor-pointer col-end-4 !py-4 !px-2">
                    <div className="font-bold">Save changes</div>
                </Button1>
            </div> : null}
        </div>
    )
}

const DeleteAccount = ()=>{
    const [expanded, setExpanded] = useState(true)
    const [password, setPassword] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)

    return(
        <div className="bg-white p-2 mt-10">
            <div className="flex justify-between">
                <div className="text-md font-bold">Delete Account</div>
                {expanded ? <Icon icon="icon-park-outline:up" onClick={()=>setExpanded(!expanded)} className="h-5 w-5"/> : <Icon icon="icon-park-outline:down" onClick={()=>setExpanded(!expanded)} className="h-5 w-5"/>}
            </div>
            {expanded ? <div className="grid gap-2 grid-cols-1 lg:grid-cols-3 gap-y-4 mt-4">
                <FormControl variant="outlined" className="col-span-1">
                    <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={passwordVisible ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>setPasswordVisible(!passwordVisible)}
                            edge="end"
                            >
                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <div className="bg-[#E9EDF1] rounded-sm flex justify-center items-center cursor-pointer col-end-4 py-4 col-span-1 px-2">
                    <div className="font-bold">Delete Account</div>
                </div>
            </div> : null}
        </div>
    )
}

const ProfileDetails = ()=>{
    const {userData: {user}} = useSelector(state => state.user);
    return(
        <div className="bg-white mt-10 px-4 py-2 shadow-lg">
            <div className="font-bold text-xl py-2">
                Saved detail
            </div>
            <hr/>
            <div className="flex w-full justify-center py-2">
                <div className="bg-[#D9D9D9] rounded-full h-32 w-32 overflow-hidden">
                    {user?.profileImage ? 
                        <img src={user?.profileImage} alt='' className="w-full h-full object-cover" />
                    :null}
                </div>
            </div>
            <hr/>
            <div className="grid gap-4 py-2">
                <div className="flex justify-between">
                    <div className="font-bold">Full Name</div>
                    <div className="text-[#6D7C94]">{user?.firstName} {user?.middleName} {user?.lastName}</div>
                </div>
                <div className="flex justify-between">
                    <div className="font-bold">Email</div>
                    <div className="text-[#6D7C94]">{user?.email}</div>
                </div>
                <div className="flex justify-between">
                    <div className="font-bold">phone number</div>
                    <div className="text-[#6D7C94]">{user?.phone}</div>
                </div>
                {/* <div className="flex justify-between">
                    <div className="font-bold">Gender</div>
                    <div className="text-[#6D7C94]">Male</div>
                </div>
                <div className="flex justify-between">
                    <div className="font-bold">DOB</div>
                    <div className="text-[#6D7C94]">23 Feb 1999</div>
                </div>
                <div className="flex justify-between">
                    <div className="font-bold">Nationality</div>
                    <div className="text-[#6D7C94]">Nigerian</div>
                </div> */}
            </div>

        </div>
    )
}
const Profile = () => {
    return (
        <div className="grid lg:grid-cols-3 pb-10 gap-6">
            <div className="lg:col-span-2">
                <BasicInfo/>
                <PasswordSettings/>
                {/* <DeleteAccount /> */}
            </div>
            <div className="col-span-1">
                <ProfileDetails />
            </div>
        </div>
    );
}

export default Profile;