import React, { useEffect, useRef, useState } from "react";
import TourFilter from "../../../components/filter/TourFilter";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TourCard from "../../../components/tour/TourCard";
import { Pagination } from "@mui/material";
import TourCard1 from "../../../components/tour/TourCard1";
import SortDropdown from "../../../components/filter/SortDropdown";
import ViewDropdown from "../../../components/filter/ViewDropdown";
import TourFilterMobile from "../../../components/filter/TourFilterMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilter,
  setTourFilterActive,
} from "../../../redux/reducers/tour/filterSlice";
import { useTours } from "../../../hooks/useTours";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/loader";
import { URLdecode, URLencode } from "../../../utils/url";
import { useSnackbar } from "notistack";
import useDateFormat from "../../../hooks/useDateFormat";
import { useFilter } from "../../../hooks/useFilter";
import TourDestinationSearch from "../../../components/tour/TourDestinationSearch";
import axios from "axios";

function TourOrderResultPage() {
  const { getTourPackages } = useTours();
  const { enqueueSnackbar } = useSnackbar();
  const { isPastDate, formatDate } = useDateFormat();
  const { filterTours, sortTours, tourSortOptions } = useFilter();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tourFilterActive, filterData } = useSelector(
    (state) => state.tourFilter
  );
  const { activities: loadedActivities } = useSelector(
    (state) => state.tourData
  );

  const params = URLdecode();

  const [date, setDate] = useState(params?.date);
  const [location, setLocation] = useState(params?.location);
  const [destination, setDestination] = useState(params?.destination);
  const [tag, setTag] = useState(params?.tag);
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const [sort, setSort] = useState("Price (low to high)");
  const [view, setView] = useState("List");
  const [error, setError] = useState("");
  const [activities, setActivities] = useState(loadedActivities);
  const calendarRef = useRef([...Array(10)]);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const cancelTokenLimited = useRef(null);
  const cancelTokenUnlimited = useRef(null);

  const packagePerPage = 20;
  const pages =
    parseInt(activities?.length / packagePerPage) +
    (activities?.length % 20 > 0 ? 1 : 0);

  const loadData = async () => {
    let cancellationMessage = "Operation canceled due to new request.";
    if (cancelTokenLimited.current) {
      cancelTokenLimited.current?.cancel(cancellationMessage);
    }
    if (cancelTokenUnlimited.current) {
      cancelTokenUnlimited.current?.cancel(cancellationMessage);
    }

    // Create new cancel tokens for the new requests
    cancelTokenLimited.current = axios.CancelToken?.source();
    cancelTokenUnlimited.current = axios.CancelToken?.source();
    const promises = [
      getTourPackages(setLoading, 10, cancelTokenLimited.current),
      getTourPackages(setLoadingMore, null, cancelTokenUnlimited.current),
    ];
    await Promise.all(promises);
  };

  useEffect(() => {
    loadData();
  }, [params?.date, params?.location, params?.destinationId]);

  useEffect(() => {
    if (loadedActivities) {
      setActivities(loadedActivities);
    }
  }, [loadedActivities]);

  useEffect(() => {
    let data = filterTours();
    if (data) {
      setActivities(data);
    }
  }, [filterData, loadedActivities]);

  useEffect(() => {
    let data = sortTours(sort);
    if (data) {
      setActivities(data);
    }
  }, [sort, loadedActivities]);

  useEffect(() => {
    setError("");
  }, [date, location]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [activities]);

  const OnDateChange = (text) => {
    setDate(text);
    if (calendarRef.current?.at(1)) {
      calendarRef.current[1]?.toggle(calendarRef.current[1]?.ref?.current);
    }
    calendarRef.current[0]?.toggle();
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const handleViewChange = (e) => {
    setView(e.target.value);
  };

  const handleSearch = async () => {
    if (!destination) return setError("Location is required");
    if (!date) return setError("Date is required");
    if (isPastDate(date)) {
      enqueueSnackbar("Date can't be a past date", {
        variant: "error",
      });
      return;
    }

    const data = { ...filterData };
    data.date = formatDate(date);
    data.country = location;
    dispatch(setFilter(data));
    const parameters = {
      location: location,
      destinationId: destination,
      date: formatDate(date),
      tag: parseInt(tag),
    };
    navigate(`/tour/result?${URLencode(parameters)}`);
    await loadData();
  };

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  const onFilterClick = () => {
    dispatch(setTourFilterActive(true));
  };

  return (
    <div>
      {/* <TourOrderResultPageMobile /> */}

      <div className={Classname.container}>
        <div className={Classname.content}>
          {/* Page title */}
          <div className={Classname.titleContainer}>
            {`Tour > Orders >`}{" "}
            <span className={Classname.titleMain}>New Order</span>
          </div>

          <div className={Classname.cityContainer}>
            <span>{params?.location}</span>
            {!edit && (
              <button className={Classname.editButton} onClick={handleEdit}>
                Edit
              </button>
            )}
          </div>
          {edit && (
            <div className={Classname.cityEditContainer}>
              <TourDestinationSearch
                selected={location}
                noLabel
                onChange={(id, name, tagId) => {
                  setDestination(id);
                  setLocation(name);
                  setTag(tagId);
                }}
              />
              <div className={Classname.cityEditCountryInput}>
                <CalendarInput1
                  ref={(el) => (calendarRef.current[0] = el)}
                  className={Classname.cityEditDateInput}
                  value={date || ""}
                  onChange={(value) => OnDateChange(value?.start || value)}
                  config={{ validDates: [new Date()] }}
                />
              </div>
              <button className={Classname.searchButton} onClick={handleSearch}>
                Search
              </button>
            </div>
          )}

          {error && <span className="text-red-600 text-sm mt-4">{error}</span>}

          <div className={Classname.dropdownsContainer}>
            <ViewDropdown value={view} onChange={handleViewChange} />
            <SortDropdown
              value={sort}
              onChange={handleSortChange}
              items={tourSortOptions}
            />
          </div>

          {/* Content */}
          <div className={Classname.resultContent}>
            {/* Filter content */}
            <div className={Classname.resultFilterContainer}>
              {/* <div className={Classname.resultDateContainer}>
                <span className={Classname.resultDateTitle}>
                  When are you travelling?
                </span>
                <CalendarInput1
                  className={Classname.resultDateInput}
                  value={date || ""}
                  onChange={(value) => OnDateChange(value?.start || value)}
                />
              </div> */}
              <TourFilter />
            </div>
            <TourFilterMobile />
            {/* Tours content */}
            <div className={Classname.resultToursContainer}>
              {/* <span className={Classname.resutlToursTitle}>Top activities</span>
              <div className={Classname.resultTopActivities}>
                {tourOffers.slice(0, 5).map((tour, index) => (
                  <TourCard tour={tour} key={index} />
                ))}
              </div>
              <span className={Classname.resutlToursTitle}>
                Top sights in London
              </span>
              <div className={Classname.resultTopSights}>
                {tourOffers.slice(0, 5).map((tour, index) => (
                  <SightCard tour={tour} key={index} />
                ))}
              </div> */}
              <Loader loading={loading} />
              {!loading && (
                <>
                  <span className={Classname.resutlToursTitle}>
                    Available Activities (
                    {loadingMore ? "..." : activities.length})
                  </span>
                  <div className={Classname.resultActivities}>
                    {activities
                      ?.slice(
                        (page - 1) * packagePerPage,
                        page * packagePerPage
                      )
                      .map((tour, index) =>
                        view === "Grid" ? (
                          <TourCard tour={tour} key={index} />
                        ) : (
                          <TourCard1 tour={tour} key={index} />
                        )
                      )}
                  </div>
                  <Loader loading={loadingMore} />
                </>
              )}
              {activities?.length > packagePerPage &&
                !loading &&
                !loadingMore && (
                  <div className={Classname.paginationContainer}>
                    <Pagination
                      count={pages}
                      variant="outlined"
                      shape="rounded"
                      size="medium"
                      page={page}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        {!tourFilterActive && (
          <div className="flex lg:hidden w-full px-5 justify-around items-center fixed bg-gray-200  bottom-0 rounded-lg shadow-md">
            <div className="flex  space-x-2">
              <img src="/IconSort.svg" alt="" />
              <div className="text-sm font-medium">Sort</div>
            </div>

            <div className="bg-white px-8 py-2 justify-center items-center">
              <div className="">Logos, Nigeria</div>

              <button className="text-sm ml-7 font-medium text-blue-500 hover:text-gray-700">
                Change
              </button>
            </div>

            <div className="flex space-x-2" onClick={onFilterClick}>
              <img src="/IconFilter.svg" alt="" />
              <button className="text-sm font-medium hover:text-gray-700">
                Filter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TourOrderResultPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full flex-col flex-1",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10 mb-4",
  titleMain: "text-black",

  cityContainer: "flex items-center gap-2 text-5xl font-black",
  editButton: "bg-primary1 text-sm text-white font-bold py-1 px-3 rounded-md",
  cityEditContainer:
    "flex flex-col lg:flex-row items-center w-full justify-between gap-10 mt-6",
  searchButton: "bg-primary1 text-sm text-white font-bold py-1 px-3 rounded-md",
  cityEditCountryInput: "flex-1 w-full",
  cityEditDateInput:
    "border border-primary/20 rounded-md p-2 flex-1 w-full h-12",

  optionsContainer:
    "w-full flex flex-wrap bg-gray-200 overflow-hidden border border-gray-300",
  option:
    "flex flex-1 px-2 sm:px-10 text-xxxs sm:text-base h-16 items-center justify-center sm:justify-start text-center sm:text-start font-bold border border-gray-300 cursor-pointer min-w-100px sm:min-w-200px ",
  optionLast: "",
  optionActive: "bg-primary1 text-white",

  dropdownsContainer: "flex justify-end items-center gap-4 mt-6",

  resultContent: "flex w-full gap-10 mt-10",
  resultFilterContainer: "hidden lg:flex flex-col w-72 ",
  resultDateContainer:
    "w-full hidden lg:flex flex-col bg-primary1 pt-6 rounded-t-xl p-4 mb-2",
  resultDateTitle: "text-white font-semibold text-sm",
  resultDateInput: "w-full bg-white border border-white rounded-md p-2 mt-2",

  resultToursContainer: "flex flex-1 flex-col",
  resutlToursTitle: "text-lg font-bold mb-4",
  resultTopActivities:
    "flex flex-wrap lg:justify-between justify-center mb-10 gap-10",
  resultTopSights:
    "flex flex-wrap lg:justify-between justify-center mb-10 gap-10",
  resultActivities:
    "flex flex-wrap lg:justify-between justify-center gap-4 mb-10",

  paginationContainer: "flex w-full items-center justify-center mb-32 lg:mb-10",
};
