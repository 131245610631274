import { FormControlLabel, Radio, RadioGroup, Slider } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  tourFilterCancellation,
  tourFilterDuration,
  tourFilterServices,
} from "../../data/tour/tourFilter";
import { clearFilter, setFilter } from "../../redux/reducers/tour/filterSlice";
import { useFilter } from "../../hooks/useFilter";

function TourFilteringComponent() {
  const dispatch = useDispatch();
  const { filterData } = useSelector((state) => state.tourFilter);
  const { allTourLanguages, allTourTags, allTourAttractions } = useFilter();
  const [searchInterest, setSearchInterest] = useState("");
  const [moreAttractios, setMoreAttractions] = useState(false);

  const activeFilterKeys = () => {
    const { price, ...data } = filterData;
    const filterArray = Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }));
    const values = filterArray.filter((val) => val.value !== null);
    return values;
  };

  const activeFilter = () => {
    let active = false;
    const {
      price,
      duration,
      language,
      service,
      cancellation,
      category,
      attractions,
      ...dat
    } = filterData;
    const data = {
      price,
      duration,
      cancellation,
      language: language && language.length > 0 ? language : null,
      service: service && service.length > 0 ? service : null,
      category: category && category.length > 0 ? category : null,
      attractions: attractions && attractions.length > 0 ? attractions : null,
    };

    let findActive = Object.values(data).find(
      (val) => val !== null && val !== 0
    );
    if (findActive) {
      active = true;
    }

    return active;
  };

  const activeFilterValues = () => {
    const {
      price,
      duration,
      language,
      service,
      category,
      cancellation,
      attractions,
      ...dat
    } = filterData;
    const data = {
      price,
      duration,
      cancellation,
      language: language && language.length > 0 ? language : null,
      service: service && service.length > 0 ? service : null,
      category: category && category.length > 0 ? category : null,
      attractions: attractions && attractions.length > 0 ? attractions : null,
    };
    let activeValues = Object.values(data).filter(
      (val) => val !== null && val !== 0
    );
    return activeValues.length;
  };

  const handleClearFilter = () => {
    dispatch(clearFilter());
  };

  const handleRemoveFilter = (category) => {
    let data = { ...filterData };
    data[category] = null;
    dispatch(setFilter(data));
  };

  const handlePriceChange = (e) => {
    let data = { ...filterData };
    data.price = e.target.value;
    dispatch(setFilter(data));
  };

  const handleClearPrice = () => {
    let data = { ...filterData };
    data.price = null;
    dispatch(setFilter(data));
  };

  const calculatePrice = () => {
    let amount = 200000;
    if (filterData.price) {
      amount += filterData.price * 10000;
    }
    return amount.toLocaleString();
  };

  const handleTimeChange = (e) => {
    let data = { ...filterData };
    data.time = e.target.value;
    dispatch(setFilter(data));
  };

  const handleDurationChange = (e) => {
    let data = { ...filterData };
    data.duration = e.target.value;
    dispatch(setFilter(data));
  };

  const handleDestinationChange = (e) => {
    let data = { ...filterData };
    data.destination = e.target.value;
    dispatch(setFilter(data));
  };

  const handleLanguageChange = (e) => {
    let data = { ...filterData };
    let language = data?.language ? [...data?.language] : [];
    if (language.includes(e?.code)) {
      language = language.filter((code) => code !== e?.code);
    } else {
      language = [...language, e?.code];
    }
    data.language = language;
    dispatch(setFilter(data));
  };

  const handleServiceChange = (e) => {
    let data = { ...filterData };
    let service = data?.service ? [...data?.service] : [];
    if (service.includes(e?.code)) {
      service = service.filter((code) => code !== e?.code);
    } else {
      service = [...service, e?.code];
    }
    data.service = service;
    dispatch(setFilter(data));
  };

  const handleCategoryChange = (e) => {
    let data = { ...filterData };
    let category = data?.category ? [...data?.category] : [];
    if (category.includes(e?.code)) {
      category = category.filter((code) => code !== e?.code);
    } else {
      category = [...category, e?.code];
    }
    data.category = category;
    dispatch(setFilter(data));
  };

  const handleAttractionsChange = (e) => {
    let data = { ...filterData };
    let attractions = data?.attractions ? [...data?.attractions] : [];
    if (attractions.includes(e?.code)) {
      attractions = attractions.filter((code) => code !== e?.code);
    } else {
      attractions = [...attractions, e?.code];
    }
    data.attractions = attractions;
    dispatch(setFilter(data));
  };

  const handleCancellationChange = (e) => {
    let data = { ...filterData };
    data.cancellation = e.target.value;
    dispatch(setFilter(data));
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className={Classname.divider}></div>
      {/* Active filters */}
      {activeFilter() && (
        <>
          <div className={Classname.title}>
            <span>{activeFilterValues()} filter active</span>
            <div
              className={Classname.closeIconContainer}
              onClick={handleClearFilter}
            >
              <img src="/IconClose.svg" alt="" />
              <span>Clear filters</span>
            </div>
          </div>
          <div className={Classname.filtersContainer}>
            {/* {activeFilterKeys().map((filter, index) => (
              <div
                className={Classname.filter}
                key={index}
                onClick={() => handleRemoveFilter(filter.key)}
              >
                <span>{filter.value}</span>
                <img src="/IconCloseWhite.svg" alt="" />
              </div>
            ))} */}
          </div>
          <div className={Classname.divider}></div>
        </>
      )}

      {/* Price filter */}
      <>
        <div className={Classname.title}>
          <span>Price</span>
          <div className={Classname.priceClear} onClick={handleClearPrice}>
            {filterData.price ? <span>Clear</span> : <></>}
            <img src="/IconArrowHeadUp.svg" alt="" />
          </div>
        </div>

        <span className={Classname.filterPriceAmount}>
          {calculatePrice()} - Any price
        </span>

        <Slider
          value={filterData.price || 0}
          aria-label="Default"
          onChange={handlePriceChange}
        />
        <span className={Classname.filterPriceBottom}>Any</span>
        <div className={Classname.divider}></div>
      </>

      {/* Duration filter */}
      <>
        <div className={Classname.title}>
          <span>Duration</span>
          <div className={Classname.priceClear}>
            <img src="/IconArrowHeadUp.svg" alt="" />
          </div>
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          className="mt-2"
          value={filterData.duration}
          onChange={handleDurationChange}
        >
          {tourFilterDuration.map((duration, index) => (
            <FormControlLabel
              key={index}
              value={duration.value}
              control={<Radio size="small" />}
              label={duration.label}
            />
          ))}
        </RadioGroup>
        <div className={Classname.divider}></div>
      </>

      {/* Language filter */}
      <>
        <div className={Classname.title}>
          <span>Languages</span>
          <div className={Classname.priceClear}>
            <img src="/IconArrowHeadUp.svg" alt="" />
          </div>
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          className="mt-2"
        >
          {allTourLanguages().map((language, index) => (
            <FormControlLabel
              key={index}
              value={language?.code}
              onClick={() => handleLanguageChange(language)}
              control={
                <Radio
                  size="small"
                  checked={
                    filterData?.language?.includes(language.code) ? true : false
                  }
                />
              }
              label={language.name}
            />
          ))}
        </RadioGroup>
        <div className={Classname.divider}></div>
      </>

      {/* Service filter */}
      <>
        <div className={Classname.title}>
          <span>Service</span>
          <div className={Classname.priceClear}>
            <img src="/IconArrowHeadUp.svg" alt="" />
          </div>
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          className="mt-2"
        >
          {tourFilterServices.map((service, index) => (
            <FormControlLabel
              key={index}
              value={service?.code}
              onClick={() => handleServiceChange(service)}
              control={
                <Radio
                  size="small"
                  checked={
                    filterData?.service?.includes(service.code) ? true : false
                  }
                />
              }
              label={service.title}
            />
          ))}
        </RadioGroup>
      </>

      {/* Category filter */}
      {allTourTags() && allTourTags()?.length > 0 && (
        <>
          <div className={Classname.title}>
            <span>Category</span>
            <div className={Classname.priceClear}>
              <img src="/IconArrowHeadUp.svg" alt="" />
            </div>
          </div>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            className="mt-2"
          >
            {allTourTags().map((category, index) => (
              <FormControlLabel
                key={index}
                value={category?.code}
                onClick={() => handleCategoryChange(category)}
                control={
                  <Radio
                    size="small"
                    checked={
                      filterData?.category?.includes(category.code)
                        ? true
                        : false
                    }
                  />
                }
                label={category.label}
              />
            ))}
          </RadioGroup>
        </>
      )}

      {/* Attractions filter */}
      {allTourAttractions() && allTourAttractions()?.length > 0 && (
        <>
          <div className={Classname.title}>
            <span>Attractions</span>
            <div className={Classname.priceClear}>
              <img src="/IconArrowHeadUp.svg" alt="" />
            </div>
          </div>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            className="mt-2"
          >
            {allTourAttractions()
              ?.slice(0, moreAttractios ? 200 : 5)
              .map((attraction, index) => (
                <FormControlLabel
                  key={index}
                  value={attraction?.code}
                  onClick={() => handleAttractionsChange(attraction)}
                  control={
                    <Radio
                      size="small"
                      checked={
                        filterData?.attractions?.includes(attraction.code)
                          ? true
                          : false
                      }
                    />
                  }
                  label={attraction.label}
                />
              ))}
            {!moreAttractios && (
              <div
                className="ml-4 py-2 cursor-pointer "
                onClick={() => {
                  setMoreAttractions(true);
                }}
              >
                More ...
              </div>
            )}
          </RadioGroup>
        </>
      )}

      {/* Cancellation filter */}
      <>
        <div className={Classname.title}>
          <span>Cancellation policy</span>
          <div className={Classname.priceClear}>
            <img src="/IconArrowHeadUp.svg" alt="" />
          </div>
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          className="mt-2"
          value={filterData.cancellation}
          onChange={handleCancellationChange}
        >
          {tourFilterCancellation.map((cancellation, index) => (
            <FormControlLabel
              key={index}
              value={cancellation.code}
              control={<Radio size="small" />}
              label={cancellation.title}
            />
          ))}
        </RadioGroup>
        <div className={Classname.divider}></div>
      </>
    </div>
  );
}

export default TourFilteringComponent;

const Classname = {
  container:
    "w-full relative h-screen flex lg:hidden flex-col bg-gray-100 rounded-lg px-4 py-6 border border-gray-200 mb-20 overflow-scroll max-h-screen scroll-hide pb-44",
  title: "flex w-full justify-between items-center font-bold text-sm mt-2",
  closeIconContainer: "flex gap-2 items-center cursor-pointer",
  priceClear: "flex gap-2 items-center cursor-pointer text-primary1",
  filtersContainer: "flex flex-wrap gap-2 py-2",
  filter:
    "flex items-center cursor-pointer bg-primary1 text-white font-bold text-xxs gap-1 px-2 py-1 rounded-md",
  filterPriceBottom: "text-right text-sm mb-2",
  filterPriceAmount: "text-primary1 text-sm mt-2",
  filterTimeOption: "flex text-sm items-center justify-between",

  searchBarContainer:
    "w-full bg-white h-10 border-2 border-gray-200 rounded-lg flex items-center px-2 justify-between gap-2 mt-2 flex-shrink-0",
  searchInput: "text-sm",

  divider: "w-full border border-gray-200 my-1 mb-2",
};
