import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TourFilteringComponent from "./TourFilteringComponent";

function TourFilter() {
  const dispatch = useDispatch();
  const { filterData } = useSelector((state) => state.tourFilter);

  return (
    <div className={Classname.container}>
      <TourFilteringComponent />
    </div>
  );
}

export default TourFilter;

const Classname = {
  container:
    "w-full hidden lg:flex flex-col rounded-lg px-4 py-6 border border-gray-200 mb-20 overflow-scroll max-h-screen scroll-hide",
  title: "flex w-full justify-between items-center font-bold text-sm mt-2",
  closeIconContainer: "flex gap-2 items-center cursor-pointer",
  priceClear: "flex gap-2 items-center cursor-pointer text-primary1",
  filtersContainer: "flex flex-wrap gap-2 py-2",
  filter:
    "flex items-center cursor-pointer bg-primary1 text-white font-bold text-xxs gap-1 px-2 py-1 rounded-md",
  filterPriceBottom: "text-right text-sm mb-2",
  filterPriceAmount: "text-primary1 text-sm mt-2",
  filterTimeOption: "flex text-sm items-center justify-between",

  searchBarContainer:
    "w-full bg-white h-10 border-2 border-gray-200 rounded-lg flex items-center px-2 justify-between gap-2 mt-2 flex-shrink-0",
  searchInput: "text-sm",

  divider: "w-full border border-gray-200 my-1 mb-2",
};
