import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  setAdultsNo,
  setChildrenNo,
  setRooms,
} from "../../redux/reducers/tour/dataSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { URLdecode } from "../../utils/url";
import { setEditHotelTravellersModal } from "../../redux/reducers/modalSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function EditTravelersModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { editHotelTravellers } = useSelector((state) => state.modal);
  const { adultNo, childNo, rooms } = useSelector((state) => state.data);

  const params = URLdecode();

  const [adult, setAdult] = useState(childNo);
  const [children, setChildren] = useState(adultNo);
  const [adultError, setAdultError] = useState("");
  const [loadedRooms, setLoadedRooms] = useState(rooms);

  const adjustData = () => {
    let data = params?.rooms ? JSON.parse(params?.rooms) : null;
    if (data) {
      if (data !== rooms) {
        setLoadedRooms(data);
        dispatch(setRooms(data));
      }
    }
  };

  useEffect(() => {
    adjustData();
  }, [params?.rooms]);

  useEffect(() => {
    setAdultError("");
  }, [adult]);

  const handleOpen = () => dispatch(setEditHotelTravellersModal(true));
  const handleClose = () => dispatch(setEditHotelTravellersModal(false));

  const handleCancel = () => {
    handleClose();
  };

  const isDataInvalid = () => {
    let invalid = false;
    let tempRooms = loadedRooms ? [...loadedRooms] : [];
    tempRooms.map((room, index) => {
      let children = room.children;
      if (children) {
        children.map((child, idx) => {
          if (!child) {
            invalid = true;
            enqueueSnackbar(`Child age is required in room ${index + 1}`, {
              variant: "warning",
            });
          }
        });
      }
    });

    return invalid;
  };

  const handleConfirm = () => {
    if (isDataInvalid()) return;

    dispatch(setRooms(loadedRooms));

    handleClose();
  };

  const ages = () => {
    const data = [];
    for (let i = 0; i <= 17; i++) {
      data.push({ value: i, label: `${i} years old` });
    }
    return data;
  };

  const handleAddRoom = () => {
    let data = {
      adults: 0,
      children: [],
      infants: 0,
    };
    let tempRooms = loadedRooms ? [...loadedRooms, data] : [data];
    setLoadedRooms(tempRooms);
  };

  const handleRemoveRoom = (index) => {
    let tempRooms = loadedRooms ? [...loadedRooms] : [];
    tempRooms = tempRooms.filter((_, idx) => idx !== index);
    setLoadedRooms(tempRooms);
  };

  const handleAddTraveller = (field, index) => {
    let tempRooms = loadedRooms ? [...loadedRooms] : [];
    if (field === "children") {
      let children = [...tempRooms[index].children, null];
      let tempRoom = { ...tempRooms[index], children };
      tempRooms[index] = tempRoom;
    } else {
      tempRooms[index] = {
        ...tempRooms[index],
        [field]: tempRooms[index][field] + 1,
      };
    }
    setLoadedRooms(tempRooms);
  };

  const handleSubstractTraveller = (field, index) => {
    let tempRooms = loadedRooms ? [...loadedRooms] : [];

    if (field === "children") {
      if (tempRooms[index].children && tempRooms[index].children.length > 0) {
        let children = tempRooms[index].children.slice(0, -1);
        let tempRoom = { ...tempRooms[index], children };
        tempRooms[index] = tempRoom;
      }
    } else {
      let currentValue = tempRooms[index][field];
      let newValue = currentValue - 1;

      if (index === 0 && field === "adults") {
        newValue = Math.max(newValue, 1);
      } else {
        newValue = Math.max(newValue, 0);
      }
      tempRooms[index] = {
        ...tempRooms[index],
        [field]: newValue,
      };
    }

    setLoadedRooms(tempRooms);
  };

  const handleSelectChildAge = (room, child, age) => {
    let tempRooms = loadedRooms ? [...loadedRooms] : [];
    let tempRoom = tempRooms[room];
    let tempChild = tempRoom?.children;
    tempChild[child] = age;
    tempRooms[room] = { ...tempRooms[room], children: tempChild };
    setLoadedRooms(tempRooms);
  };

  return (
    <div>
      <Modal
        open={editHotelTravellers}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>Edit Travelers</span>
              {loadedRooms?.map((room, index) => (
                <div key={index} className="w-full flex flex-col mb-4">
                  <div className="flex w-full justify-between font-bold mb-2">
                    <span>Room {index + 1}</span>
                    {index ? (
                      <img
                        src="/IconClose.svg"
                        alt=""
                        className="p-3 cursor-pointer"
                        onClick={() => handleRemoveRoom(index)}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-col w-full px-6">
                    <div className={Classname.travellerItem}>
                      <div className={Classname.travellerTypeContainer}>
                        <span className={Classname.travellerType}>Adult</span>
                      </div>
                      <div className={Classname.travellerChoiceContainer}>
                        <div
                          className={Classname.travellerButton}
                          onClick={() =>
                            handleSubstractTraveller("adults", index)
                          }
                        >
                          -
                        </div>
                        <div className={Classname.travellerAmount}>
                          {room?.adults}
                        </div>
                        <div
                          className={Classname.travellerButton}
                          onClick={() => handleAddTraveller("adults", index)}
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div className={Classname.travellerItem}>
                      <div className={Classname.travellerTypeContainer}>
                        <span className={Classname.travellerType}>
                          Children
                        </span>
                      </div>
                      <div className={Classname.travellerChoiceContainer}>
                        <div
                          className={Classname.travellerButton}
                          onClick={() =>
                            handleSubstractTraveller("children", index)
                          }
                        >
                          -
                        </div>
                        <div className={Classname.travellerAmount}>
                          {room?.children?.length}
                        </div>
                        <div
                          className={Classname.travellerButton}
                          onClick={() => handleAddTraveller("children", index)}
                        >
                          +
                        </div>
                      </div>
                    </div>
                    {room?.children && room?.children?.length > 0 ? (
                      <div className="w-full gap-2 flex flex-wrap">
                        {room?.children?.map((child, idx) => (
                          <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              className="pb-20"
                            >
                              Child age
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={child}
                              label="Child age"
                              className="h-10 w-32"
                              onChange={(e) =>
                                handleSelectChildAge(index, idx, e.target.value)
                              }
                            >
                              {ages().map((age, i) => (
                                <MenuItem value={age.value} key={i}>
                                  {age.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
              <div className="flex w-full justify-end my-2">
                <button
                  className="text-xs bg-primary1 items-center justify-center flex px-4 py-2 font-bold text-white flex-shrink-0 rounded-md"
                  onClick={handleAddRoom}
                >
                  + Add room
                </button>
              </div>
            </div>
            <div className={Classname.bottomContent}>
              <button
                className={`${Classname.button} ${Classname.backButton}`}
                onClick={handleCancel}
              >
                cancel
              </button>
              <button
                className={`${Classname.button} ${Classname.confirmButton}`}
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main max-h-[80vh] overflow-scroll scroll-hide",
  content: "flex flex-col items-center px-4",
  modalTitle: "text-xl lg:text-3xl font-bold mb-6 mt-4 text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-2 lg:py-10 bg-gray-100 h-14 px-4",
  button: "h-10 rounded-md flex items-center justify-center w-32 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",

  travellerItem:
    "flex justify-between items-center bg-gray-50 rounded-md px-2 py-2 mb-2 border border-gray-100",
  travellerTypeContainer: "flex flex-col items-center",
  travellerType: "font-bold",
  travellerAge: "text-sm text-gray-500",
  travellerChoiceContainer: "flex items-center ",
  travellerButton:
    "cursor-pointer w-5 h-5 rounded-full flex items-center justify-center text-white bg-primary1",
  travellerAmount: "w-10 flex items-center justify-center font-bold",
};
