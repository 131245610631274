import { useSelector } from "react-redux";
import { Layout1 } from "./Layout1";
import { Layout2 } from "./Layout2";
import { Layout3 } from "./Layout3";

export function CustomerHome() {
    const state = useSelector((state)=> state.template);
  
    console.log('layout: ',state.layoutType);
    if(Number(state.layoutType) === 2)
      return <Layout2 />
    else if(Number(state.layoutType) === 3)
      return <Layout3 />
    else 
      return <Layout1 />
}
