import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Header9 = ({
  logo,
  primaryColor,
  secondaryColor,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const companyLogo = logo ? (
    <img src={logo} alt="Company Logo" className="w-14" />
  ) : (
    <div
      className={`bg-[#D9D9D9] p-2 py-3 font-extrabold w-36 flex justify-center text-${secondaryColor}`}
    >
      Company Logo
    </div>
  );

  return (
    <div className="relative overflow-x-auto bg-white shadow-md grid justify-between gap-4 grid-cols-2 md:grid-cols-4 font-poppins px-10 py-6 origin-top">
      <div className="col-span-1 order-1 origin-left" >{companyLogo}</div>
      <div className="hidden md:flex items-center justify-between gap-2 order-3 col-span-2 md:col-span-2 md:order-2 w-full origin-center">
        <p>CALL US: (+234) 9100phone num</p>
        <div className="flex gap-6 cursor-pointer">
            <InstagramIcon style={{color: secondaryColor}}/>
            <TwitterIcon style={{color: secondaryColor}}/>
        </div>
      </div>
      <div className="order-2 md:order-3 col-span-1 flex items-center justify-end" >
        <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
      </div>
      <div
        className={`order-4 col-span-2 md:col-span-4 flex flex-row items-center gap-10 justify-self-end ${
          menuVisible ? "flex" : "hidden"
        }`}
      >
        <div
          className={`flex flex-col bg-slate-100 px-6 rounded-md py-4 gap-10 items-center cursor-pointer`}
          style={{ color: secondaryColor }}
        >
          <div
            className={`flex flex-col justify-between items-center gap-10`}
            style={{ color: primaryColor }}
          >
            <div className="flex flex-col gap-10 items-center">
              <a>Home</a>
              <a>About Us</a>
              <a>Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header9;
