import Radio from "@mui/material/Radio";
import { useDispatch, useSelector } from "react-redux";
import { setFontFamily } from "../../redux/reducers/template/templateSlice";



const ChoseFont = () => {
  // const { state, setFontFamily } = useAppContext();
  const state = useSelector((state) => state.template)
  const dispatch = useDispatch();
  const fontList = [
    {
      id: "1",
      fontName: "BR Firma",
      cssFont: "BrFirmaRegular",
      description:
        "This is the font that will be used for your custom size this.",
    },
    {
      id: "2",
      fontName: "Merriweather",
      cssFont: "Merriweather",
      description:
        "This is the font that will be used for your custom size this.",
    },
    {
      id: "3",
      fontName: "Nova Flat",
      cssFont: "Nova Flat",
      description:
        "This is the font that will be used for your custom size this.",
    },
    {
      id: "4",
      fontName: "Poppins",
      cssFont: "Poppins",
      description:
        "This is the font that will be used for your custom size this.",
    },
    {
      id: "5",
      fontName: "Sonoma",
      cssFont: "BRSonoma",
      description:
        "This is the font that will be used for your custom size this.",
    },
    {
      id: "6",
      fontName: "Inter",
      cssFont: "Inter",
      description:
        "This is the font that will be used for your custom size this.",
    },
  ];
  const updateFont = (id) => {
    // setFontFamily(id);
    switch(id){
      case "1":
        dispatch(setFontFamily("BrFirmaRegular"))
        break;
      case "2":
        dispatch(setFontFamily("Merriweather"))
        break;
      case "3":
        dispatch(setFontFamily("Nova Flat"))
        break;
      case "4":
        dispatch(setFontFamily("Poppins"))
        break;
      case "5":
        dispatch(setFontFamily("BRSonoma"))
        break;
      case "6":
        dispatch(setFontFamily("Inter"))
        break;
    }
    dispatch(setFontFamily(id))
  };
  return (
    <div className="mt-10">
      <span className="font-poppins font-bold text-[#818083]">
        {" "}
        Choose a font style
      </span>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 gap-y-3 mt-4">
        {fontList.map((current) => (
          <div
            className={`flex flex-col gap-2 border-solid border-2 ${
              state.fontFamily == current.id
                ? "border-[#1EA994] "
                : "border-[#F8F8F8]"
            } bg-[#F8F8F8] px-4 py-4 cursor-pointer`}
            style={{fontFamily: current.cssFont}}
            id={current.id}
            onClick={() => updateFont(current.id)}
          >
            <div className="flex justify-between">
              <span className="text-2xl font-bold">{current.fontName}</span>
              <Radio
                checked={state.fontFamily == current.id ? true : false}
                color="success"
              />
            </div>
            <div>
              <p className="text-[#818083]">
                {current.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ChoseFont;
