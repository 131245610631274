import React, { useEffect, useState } from "react";
import useOrders from "../../hooks/useOrders";
import { useDispatch, useSelector } from "react-redux";
import { setTicketSearchValue } from "../../redux/reducers/orderSlice";
import { FormControl, MenuItem, Select } from "@mui/material";

function OrderFilterTypeSelection() {
  const dispatch = useDispatch();
  const { ticketFilterType, ticketSearchValue } = useSelector(
    (state) => state.order
  );
  const { getOrdersCount, selectOrderType, getTicketFilterTypes } = useOrders();
  const [dropdown, setDropdown] = useState(false);

  const getPlaceholder = () => {
    let placeholder = "Search by PNR, Ticket No, Date, Airline";

    return placeholder;
  };

  const handleSearchValueChange = (e) => {
    dispatch(setTicketSearchValue(e.target.value));
  };

  return (
    <div className="w-full flex flex-col pt-10">
      <div className="flex flex-col md:flex-row items-center gap-2 md:gap-4 justify-between w-full md:overflow-x-scroll scroll-hide">
        <OrderFilterTypeDropdown />
        <div className="hidden md:flex items-center gap-4">
          {getTicketFilterTypes().map(({ name, onClick }) => (
            <button
              key={name}
              onClick={onClick}
              className={`h-12 flex items-center justify-between gap-2 px-4 text-sm font-bold rounded-lg ${
                ticketFilterType === name
                  ? "text-white bg-primary1"
                  : "text-[#818083] bg-[#F8F8F8]"
              }`}
            >
              <span>{name}</span>
            </button>
          ))}
        </div>
        <div className="flex flex-1 w-full flex-shrink-0 h-12 py-3 md:py-0 rounded-lg items-center border-2 border-[#DBDBDB] px-4 gap-3">
          <input
            type="text"
            className="flex flex-1 text-sm font-main"
            onChange={handleSearchValueChange}
            placeholder={getPlaceholder()}
          />
          <img src="/IconSearch1.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

const OrderFilterTypeDropdown = () => {
  const dispatch = useDispatch();
  const { ticketFilterType, ticketSearchValue } = useSelector(
    (state) => state.order
  );
  const { getOrdersCount, selectOrderType, getTicketFilterTypes } = useOrders();
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdown) {
        setTimeout(() => {
          setDropdown(false);
        }, 100);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, setDropdown]);

  return (
    <div
      className="w-full flex  md:hidden relative"
      onClick={() => setDropdown(!dropdown)}
    >
      <div className="h-10 w-full flex flex-row rounded-md bg-primary1 items-center justify-between px-3 cursor-pointer">
        <span className="text-sm font-bold text-white">{ticketFilterType}</span>
        <img src="/IconChevronWhiteDown.svg" alt="" />
      </div>
      {dropdown && (
        <div className="absolute w-full z-10 top-10 bg-[#F8F8F8] rounded-md shadow-md py-2 gap-2">
          {getTicketFilterTypes().map(({ name, onClick }) => (
            <div
              key={name}
              onClick={onClick}
              className={`text-sm font-bold px-2 py-2 ${
                ticketFilterType === name
                  ? "text-white bg-primary1"
                  : "text-[#818083] bg-[#F8F8F8]"
              }`}
            >
              {name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderFilterTypeSelection;
