import React from 'react'
import AccountSettings from '../Settings/Agency/Account/Account';

export default function ProfileManagement() {
  return (
    <div className='flex items-center pd-md'>
        <AccountSettings />
    </div>
  )
}
