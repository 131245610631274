import React from 'react'
import Button1 from '../../form/Button1';
import { path } from '../../../config';

export default function BlogLink({data}) {
    
    return !data?.text && !data?.link ? null : (
        <div className="flex items-center justify-center pd-md text-2xl bg-primary/5 gap-5 mt-10">
            {data?.text}
            {/* {agency} has been in the news, see what we have been up to! */}
            <div>
                <Button1 size='small' variant='outlined' to={path.site+'/'+(data?.link||'blog')}>See Press</Button1>
            </div>
        </div>
    )
}
