import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TourManagementPackageExtraInfoPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [activity, setActivity] = useState("");
  const [detail, setDetail] = useState("");
  const [allowed, setAllowed] = useState("");
  const [mandatory, setMandatory] = useState("");
  const [emergencyType, setEmergencyType] = useState("");
  const [emergencyValue, setEmergencyValue] = useState("");
  const [packageDetail, setPackageDetail] = useState("");

  const onContinue = () => {
    navigate("/tourManagement/create/photoUpload");
  };

  const onSave = () => {};

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementSidebar selected={10} />
        <div className={Classname.detail}>
          <span className={Classname.title}>
            Before booking with Intraverse
          </span>
          <span className={Classname.title}>
            Who is this activity not suitable for?{" "}
            <span className={Classname.titleOptional}>(optional)</span>
          </span>
          <p className={Classname.description}>
            Add the types of travelers who should not join this activity, like
            under 18s or pregnant women. This information appears on the
            activity details page.
          </p>
          <input
            type="text"
            placeholder="Enter your details"
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
            className={Classname.input}
          />
          <span className={Classname.title}>
            What’s not allowed?{" "}
            <span className={Classname.titleOptional}>(optional)</span>
          </span>
          <p className={Classname.description}>
            List any object, clothing, or action that’s not allowed on your
            activity, such as sleeveless shirts. This information appears on the
            activity details page & voucher.
          </p>
          <input
            type="text"
            placeholder="Enter your details"
            value={allowed}
            onChange={(e) => setAllowed(e.target.value)}
            className={Classname.input}
          />
          <span className={Classname.title}>
            What mandatory items must the customer bring with them?{" "}
            <span className={Classname.titleOptional}>(optional)</span>
          </span>
          <p className={Classname.description}>
            Such as a towel for a boat tour, or comfortable shoes for a hike.
            This information appears on the activity details page & voucher.
          </p>
          <input
            type="text"
            placeholder="Enter your details"
            value={mandatory}
            onChange={(e) => setMandatory(e.target.value)}
            className={Classname.input}
          />

          <span className={Classname.title}>
            Add custom information before booking{" "}
            <span className={Classname.titleOptional}>(optional)</span>
          </span>
          <p className={Classname.description}>
            Add any remaining information that customers must know before they
            book. This information appears on the activity details page.
          </p>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className={Classname.textarea}
            placeholder="Enter your detail"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />
          <span className={Classname.title}>Before the activity</span>
          <span className={Classname.title}>
            How can customers contact you in case of an emergency?{" "}
            <span className={Classname.titleOptional}>(optional)</span>
          </span>
          <p className={Classname.description}>
            This information appears on the voucher.
          </p>
          <div className="flex gap-3 items-center">
            <div className="mt-2 w-72">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Choose one
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={emergencyType}
                  label="Choose one"
                  onChange={(e) => setEmergencyValue(e.target.value)}
                >
                  <MenuItem value="facebook">Facebook</MenuItem>
                  <MenuItem value="phone">Phone number</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                </Select>
              </FormControl>
            </div>
            <input
              type="text"
              placeholder="Enter your details"
              value={emergencyValue}
              onChange={(e) => setEmergencyValue(e.target.value)}
              className={Classname.input}
            />
          </div>

          <span className={Classname.title}>
            What else do customers need to know before your activity?{" "}
            <span className={Classname.titleOptional}>(optional)</span>
          </span>
          <p className={Classname.description}>
            Add any remaining information that customers must know before they
            book. This infoProvide any other logistical information that hasn't
            been covered elsewhere. This appears on the voucher.rmation appears
            on the activity details page.
          </p>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className={Classname.textarea}
            placeholder="Leave it blank if it does not apply to your package"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />

          <InfoCardLightening description="Make sure the customer has any remaining information they need to enjoy the activity fully, or the next steps. Use bullet points." />

          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageExtraInfoPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  titleOptional: "text-sm text-gray-500 font-normal",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
