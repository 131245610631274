import { Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmMilesPaymentModal from "../../../components/modal/ConfirmMilesPaymentModal";
import { useNavigate } from "react-router-dom";
import { URLdecode } from "../../../utils/url";
import { formatMoney } from "../../../features/utils/formatMoney";
import Loader from "../../../utils/loader";
import { useHotels } from "../../../hooks/useHotels";

function AccommodationPaymentPage() {
  const navigate = useNavigate();
  const params = URLdecode();
  const { initializePayment } = useHotels();

  const [payment, setPayment] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [images, setImages] = useState(
    params?.images ? JSON.parse(params?.images) : []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let bookingId = localStorage.getItem("hotelBookingId");
    if (bookingId === params?.bookingId) {
      navigate("/order");
    }
  }, []);

  const handleChoosePay = (type) => {
    setPayment(type);
  };

  const handleCancelModal = () => {
    setModalOpen(false);
  };

  const handleConfirmModal = () => {
    navigate("/accommodation/confimation");
  };

  const handlePay = async () => {
    if (payment === "miles") {
    } else {
      await initializePayment(setLoading);
    }
  };

  const rooms = params.reservedRooms ? JSON.parse(params.reservedRooms) : [];

  const totalAmount = () => {
    let total = 0;
    if (params?.price) {
      total += parseInt(params?.price);
    }
    // if (params?.reservedRooms) {
    //   let rRooms = JSON.parse(params?.reservedRooms);
    //   rRooms?.map((room) => {
    //     total += room?.amount * room?.price;
    //   });
    // }

    return formatMoney(total);
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        <ConfirmMilesPaymentModal
          open={modalOpen}
          setOpen={setModalOpen}
          onConfirm={handleConfirmModal}
          onBack={handleCancelModal}
        />
        {/* Page title */}
        <div className={Classname.titleContainer}>
          {`Stays > Orders > London > New Order > Single Tour > Tourist Details > `}{" "}
          <span className={Classname.titleMain}>Payment</span>
        </div>
        <div className={Classname.detail}>
          <div className={Classname.boxTitleContainer}>
            <div className={Classname.boxTitleContent}>
              <span className={Classname.boxTitleContentTitle}>
                {params?.name}
              </span>
              {images.length > 0 && (
                <img
                  src={images[0]}
                  alt=""
                  className={Classname.boxTitleContentImage}
                />
              )}
              <div
                className={`${Classname.boxTitleTitleRow} ${Classname.boxTitleTitleRow1}`}
              >
                <span>Price Summary</span>
                <span className={Classname.boxTitleAmount}>Amount</span>
              </div>
              <div className={`${Classname.boxTitleTitleRow} `}>
                <span>Payable to Intraverse: </span>
                <span>{totalAmount()}</span>
              </div>
              {/* <div className={`${Classname.boxTitleTicketContainer} `}>
                <span>Ticketing fee:</span>
                <span>+₦3,000</span>
              </div> */}
              <div
                className={`${Classname.boxTitleTitleRow} ${Classname.boxTitleTitleRow2}`}
              >
                <span>Total due:</span>
                <span>{totalAmount()}</span>
              </div>
            </div>
          </div>

          {rooms?.map((room, index) => (
            <div className="flex flex-1 flex-col" key={index}>
              {room?.rateComments && (
                <div className={Classname.detailInfoContainer}>
                  <img
                    src="/IconInfo.svg"
                    alt=""
                    className={Classname.detailInfoIcon}
                  />
                  <span>{room?.rateComments}</span>
                </div>
              )}
            </div>
          ))}
          <div className={Classname.methodsContainer}>
            <div className={Classname.methodTitleContainer}>
              Choose payment method
            </div>
            <div
              className={Classname.method}
              onClick={() => handleChoosePay("new")}
            >
              <img
                src="/IconNewCard.svg"
                alt=""
                className={Classname.methodIcon}
              />
              <div className={Classname.methodDetail}>
                <div className={Classname.methodContent}>
                  <span className={Classname.methodContentTitle}>
                    Pay with a new card
                  </span>
                  <span className={Classname.methodContentDescription}>
                    Pay with a debit or credit card
                  </span>
                </div>
                <Radio
                  checked={payment === "new"}
                  onChange={() => handleChoosePay("new")}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </div>
            <div
              className={Classname.method}
              onClick={() => handleChoosePay("stored")}
            >
              <img
                src="/IconStoredCard.svg"
                alt=""
                className={Classname.methodIcon}
              />
              <div className={Classname.methodDetail}>
                <div className={Classname.methodContent}>
                  <span className={Classname.methodContentTitle}>
                    Pay with stored card
                  </span>
                  <span className={Classname.methodContentDescription}>
                    Pay with stored card
                  </span>
                </div>
                <Radio
                  checked={payment === "stored"}
                  onChange={() => handleChoosePay("stored")}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </div>

            <div
              className={Classname.method}
              onClick={() => handleChoosePay("miles")}
            >
              <img
                src="/IconMiles.svg"
                alt=""
                className={Classname.methodIcon}
              />
              <div className={Classname.methodDetail}>
                <div className={Classname.methodContent}>
                  <span className={Classname.methodContentTitle}>
                    Pay with miles balance
                  </span>
                  <span className={Classname.methodContentDescription}>
                    Pay from your miles wallet balance
                  </span>
                </div>
                <Radio
                  checked={payment === "miles"}
                  onChange={() => handleChoosePay("miles")}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </div>
            {loading ? (
              <Loader loading={true} />
            ) : (
              <button
                className={`${Classname.payButton} ${
                  !payment && "opacity-20"
                } `}
                onClick={handlePay}
              >
                Pay Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationPaymentPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1",
  titleContainer: "w-full h5 my-10",
  titleMain: "text-black",
  detail: "flex flex-1 flex-col items-center",
  boxTitleContainer:
    "w-full max-w-lg rounded-lg p-4 border border-gray-400 mt-20 flex flex-col mb-4",
  boxTitleContent: "w-full rounded-md p-4 border border-gray-400",
  boxTitleContentTitle: "font-bold text-lg mb-2",
  boxTitleContentImage: "w-full rounded-lg h-64 object-cover mt-4 mb-2",
  boxTitleTitleRow:
    "flex w-full justify-between font-bold text-lg items-center ",
  boxTitleTitleRow1: "border-b border-gray-300 pb-2 mb-4",
  boxTitleTitleRow2: "border-t border-gray-300 pt-2 mt-4",
  boxTitleAmount: "text-sm font-normal",
  boxTitleTicketContainer: "mb-6 flex justify-between w-full items-center",
  methodsContainer:
    "w-full max-w-lg rounded-lg p-4 border border-gray-400 mt-4 flex flex-col mb-10",
  methodTitleContainer: "font-bold text-lg border-b border-gray-300 pb-2",
  method:
    "flex w-full gap-3 items-center pt-3 cursor-pointer pb-4 border-b border-gray-300",
  methodIcon: "",
  methodDetail: "flex flex-1 items-center gap-2",
  methodContent: "flex flex-col flex-1",
  methodContentTitle: "text-lg font-semibold",
  methodContentDescription: "text-sm",
  payButton:
    "w-full flex items-center justify-center font-bold text-white bg-primary1 rounded-md mt-4 mb-2 h-14",
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-center gap-2 mb-4 px-6 py-2 rounded-md text-sm mt-6 max-w-lg",
  detailInfoIcon: "",
};
