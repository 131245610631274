import React, { useState } from "react";
import CustomRadio from "../../../components/common/CustomRadio";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";

const dietary1 = [
  "Diabetic",
  "Gluten-free",
  "Lactose-free",
  "Nut-free",
  "Seafood/fish-free",
];
const dietary2 = ["Vegitarian", "Low-carb", "Pescatarian", "Vegan", "Halal"];
const dietary3 = ["Kosher", "Egg-free", "Keto"];

function TourManagementPackageFoodPage() {
  const navigate = useNavigate();
  const [selectedOptiong1, setSelectedOptiong1] = useState(null);
  const [foodIncluded, setFoodIncluded] = useState("");
  const [showRestriction, setShowRestriction] = useState(false);
  const [meal, setMeal] = useState([
    {
      mealType: "",
      mealFormat: "",
    },
  ]);
  const [mealType, setMealtype] = useState("");
  const [mealFormat, setMealFormat] = useState("");

  const handleSelectg1 = (option) => {
    setSelectedOptiong1(option);
  };

  const handleShowRestriction = () => {
    if (showRestriction) {
      setShowRestriction(false);
    } else {
      setShowRestriction(true);
    }
  };

  const onContinue = () => {
    navigate("/tourManagement/create/transportation");
  };

  const handleMeal = () => {};

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <div className="hidden lg:flex">
          <TourManagementSidebar selected={8} />
        </div>
        <div className={Classname.detail}>
          <div className={Classname.title}>
            Is food included in your activity?
          </div>

          <CustomRadio
            label="No"
            selected={foodIncluded}
            value="no"
            onSelect={(value) => setFoodIncluded(value)}
          />
          <CustomRadio
            label="Yes"
            selected={foodIncluded}
            value="yes"
            onSelect={(value) => setFoodIncluded(value)}
          />

          <div className="flex flex-col lg:flex-row gap-5 font-semibold mt-5 mb-6">
            <div className={Classname.dropdownContainer}>
              <span className={Classname.dropdownTitle}>Type of meal</span>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Meal type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mealType}
                  label="Meal type"
                  onChange={(e) => setMealtype(e.target.value)}
                >
                  <MenuItem value="breakfast">Breakfast</MenuItem>
                  <MenuItem value="lunch">Lunch</MenuItem>
                  <MenuItem value="dinner">Dinner</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={Classname.dropdownContainer}>
              <span className={Classname.dropdownTitle}>Format</span>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Meal format
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mealFormat}
                  label="Meal format"
                  onChange={(e) => setMealFormat(e.target.value)}
                >
                  <MenuItem value="breakfast">Breakfast</MenuItem>
                  <MenuItem value="lunch">Lunch</MenuItem>
                  <MenuItem value="dinner">Dinner</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className={Classname.highlightButton} onClick={handleMeal}>
              + Add meal
            </div>
          </div>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Drinks are included"
            />
          </FormGroup>

          <CustomRadio
            label="Show dietary restrictions"
            selected={showRestriction}
            standalone
            onSelect={handleShowRestriction}
            className="mt-4 mb-5"
          />
          <span className="mb-2 text-lg">
            Which dietary restrictions can you accommodate?
          </span>
          <p>Please select all that are relevant</p>

          <div className="lg:gap-6 flex flex-col lg:flex-row flex-wrap mt-4">
            <div className="flex flex-col flex-1">
              <FormGroup>
                {dietary1.map((diet, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox />}
                    label={diet}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="flex flex-col flex-1">
              <FormGroup>
                {dietary2.map((diet, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox />}
                    label={diet}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="flex flex-col flex-1">
              <FormGroup>
                {dietary3.map((diet, index) => (
                  <FormControlLabel
                    key={index}
                    control={<Checkbox />}
                    label={diet}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageFoodPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  dropdownContainer: "flex flex-col flex-1",
  dropdownTitle: "mb-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
