import React, { useState } from "react";
import GettingStartedStepTitle from "../../components/gettingStarted/GettingStartedStepTitle";
import GettingStartedVerticalLine from "../../components/gettingStarted/GettingStartedVerticalLine";
import GettingStartedCheckbox from "../../components/gettingStarted/GettingStartedCheckbox";
import GettingStartedVideo from "../../components/gettingStarted/GettingStartedVideo";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { setGettingStartedLoadingModal } from "../../redux/reducers/modalSlice";
import { setUser } from "../../redux/reducers/userSlice";
import updateProfile from "../../controllers/user/updateProfile";
import { gettingStartedVideos } from "../../data/tour/gettingStartedData";

function GettingStartedMarkups() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [videoWatched, setVideoWatched] = useState(false);
  const [later, setLater] = useState(false);

  const handleCreateMarkup = async () => {
    dispatch(setGettingStartedLoadingModal(true));
    const formData = new FormData();
    formData.append("guideStep", "markup");

    const res = await updateProfile(formData);
    dispatch(setGettingStartedLoadingModal(false));
    if (res.return) {
      navigate("/gettingStarted/markups/create");
      if (res?.data) dispatch(setUser(res.data));
    } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
  };

  const handleContinue = async () => {
    dispatch(setGettingStartedLoadingModal(true));
    const formData = new FormData();
    formData.append("guideStep", "markup");

    const res = await updateProfile(formData);
    dispatch(setGettingStartedLoadingModal(false));
    if (res.return) {
      navigate("/gettingStarted/team");
      if (res?.data) dispatch(setUser(res.data));
    } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center font-main">
      <GettingStartedLoadingModal />
      <div className="flex w-full flex-col py-10 px-4 lg:px-10 flex-1 max-w-7xl">
        <span className="text-[#818083] text-sm lg:text-base">Step 5</span>
        <span className="text-lg lg:text-3xl my-2">Order management</span>
        <span className="text-[#818083] text-sm lg:text-base">
          Setup order requirements based on business needs
        </span>
        <GettingStartedStepTitle
          visible
          active={videoWatched}
          title={"How to add markups"}
        />
        <div className="flex gap-6">
          <GettingStartedVerticalLine
            height="h-[320px]"
            active={videoWatched}
          />
          <div className="flex flex-1 flex-col">
            <GettingStartedVideo video={gettingStartedVideos.markup} />
            <GettingStartedCheckbox
              text="Confirm if you have watched the training to continue."
              active={videoWatched}
              setActive={setVideoWatched}
            />
          </div>
        </div>
        <GettingStartedStepTitle
          visible={videoWatched}
          active={later}
          title={"Create your first markup"}
        />
        {videoWatched && (
          <div className="flex gap-6 uncollapse">
            <GettingStartedVerticalLine height="h-10" active={false} />
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col lg:flex-row items-center gay-2 lg:gap-4">
                <button
                  className="bg-primary1 rounded-md text-white text-sm lg:text-base font-bold px-6 lg:px-10 w-fit py-2 lg:py-3"
                  onClick={handleCreateMarkup}
                >
                  Add a markup
                </button>
                <GettingStartedCheckbox
                  text="I will add markups later."
                  active={later}
                  setActive={setLater}
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueActive={videoWatched && later}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedMarkups;
