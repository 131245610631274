import React from 'react';
import SubscriptionImage from '../../../assets/images/Subscription.png';


export default function LearnAboutSubscription({callback,...props}) {
    return (
      <div className='flex flex-col gap-5'>
        <h5 className=''>Subscription</h5>
        <div className='relative h-[160px] '>
          <img alt='' src={SubscriptionImage} className='w-full h-full object-cover' />
        </div>
        <div className='flex flex-col gap-4'>
          <b>Introducing Intraverse Subscriptions: Empowering Your Business with Seamless Travel Solutions</b>
          <div>
          At Intraverse, we understand the dynamic needs of businesses in the travel industry. 
          Our subscription plans are designed to provide you with the flexibility, tools, and support to offer unparalleled travel services to your clients. 
          Here's how our subscription system works:
          </div>
          <b>
            Subscription Tiers:
          </b>
          <ol className='flex flex-col gap-2 list-decimal ml-4' type='1'>
            <li> Starter Plan: 
              <ul type='dot' className='!list-item !list-inside ml-2'>
                <li>Who It’s For: Ideal for small businesses starting out in the travel sector.</li>
                <li>Features
                  <ul type='dot' className='!list-item !list-inside ml-2'>
                    <li>Access to the Intraverse platform.</li>
                    <li>Basic search functionalities to find travel options effortlessly.</li>
                    <li>Standard customer support to assist with any queries.</li>
                  </ul>
                </li>
                <li>Pricing:  $5 per month.</li>
              </ul>
            </li>
            <li> Premium Plan: 
              <ul type='dot' className='!list-item !list-inside ml-2'>
                <li>Who It’s For: Perfect for growing businesses looking to expand their service offerings.</li>
                <li>Features
                  <ul type='dot' className='!list-item !list-inside ml-2'>
                    <li>AllStarter features.</li>
                    <li>Advanced search options to provide more tailored travel solutions.</li>
                    <li>Integration with third-party tools to streamline your operations.</li>
                    <li>Priority customer support to ensure your business runs smoothly</li>
                  </ul>
                </li>
                <li>Pricing:  $20 per month.</li>
              </ul>
            </li>
            <li> Ultimate Plan: 
              <ul type='dot' className='!list-item !list-inside ml-2'>
                <li>Who It’s For: Designed for established businesses needing comprehensive solutions and customization.</li>
                <li>Features
                  <ul type='dot' className='!list-item !list-inside ml-2'>
                    <li>All Premium plan features.</li>
                    <li>Custom branding to align with your business identity.</li>
                    <li>Dedicated account manager for personalized assistance and support.</li>
                    <li>API access for seamless integration with your existing systems.</li>
                    <li>Advanced analytics and reporting to gain insights and drive business growth</li>
                  </ul>
                </li>
                <li>Pricing:  $50 per month.</li>
              </ul>
            </li>
          </ol>
        </div>
        {callback && callback(props)}
      </div>
    )
  }
  