import React from "react";
import { pricingFeatures } from "../../data/tour/pricingData";

function PricingFeaturesSection() {
  const handleSignup = (selected) => {
    switch (selected) {
      case "Starter":
        return;
      case "Premium":
        return;
      case "Ultimate":
        return;
      default:
        return;
    }
  };

  return (
    <div className={ClassName.container}>
      <div className={ClassName.content}>
        <div className={ClassName.features}>
          <div className={ClassName.cover}></div>
          <table className={ClassName.featuresContainer}>
            <tbody>
              {/* Header */}
              <tr>
                <td className={ClassName.titleHeader}>Core features</td>
                <td className={ClassName.starterHeader}>
                  <div className={ClassName.headerCellContent}>
                    <span>Starter</span>
                    <button
                      onClick={() => handleSignup("Starter")}
                      className={ClassName.headerButton}
                    >
                      Sign up
                    </button>
                  </div>
                </td>
                <td className={ClassName.premiumHeader}>
                  <div className={ClassName.headerCellContent}>
                    <span>Premium</span>
                    <button
                      onClick={() => handleSignup("Premium")}
                      className={ClassName.headerButton}
                    >
                      Sign up
                    </button>
                  </div>
                </td>
                <td className={ClassName.ultimateHeader}>
                  <div className={ClassName.headerCellContent}>
                    <span>Ultimate</span>
                    <button
                      onClick={() => handleSignup("Ultimate")}
                      className={ClassName.headerButton}
                    >
                      Sign up
                    </button>
                  </div>
                </td>
              </tr>
              {/* Rows */}
              {pricingFeatures.map((feature, index) => (
                <tr key={index}>
                  <td>
                    <div className={ClassName.cellHeaderContent}>
                      {feature.title}
                    </div>
                  </td>
                  <td>
                    <div className={ClassName.cellContent}>
                      {feature.starter === "+" ? (
                        <img
                          src="/IconCircleCheck.svg"
                          alt=""
                          className={ClassName.icon}
                        />
                      ) : feature.starter === "-" ? (
                        <img
                          src="/IconCircleMinus.svg"
                          alt=""
                          className={ClassName.icon}
                        />
                      ) : (
                        <span>{feature.starter}</span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className={ClassName.cellContent}>
                      {feature.premium === "+" ? (
                        <img
                          src="/IconCircleCheck.svg"
                          alt=""
                          className={ClassName.icon}
                        />
                      ) : feature.premium === "-" ? (
                        <img
                          src="/IconCircleMinus.svg"
                          alt=""
                          className={ClassName.icon}
                        />
                      ) : (
                        <span>{feature.premium}</span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className={ClassName.cellContent}>
                      {feature.ultimate === "+" ? (
                        <img
                          src="/IconCircleCheck.svg"
                          alt=""
                          className={ClassName.icon}
                        />
                      ) : feature.ultimate === "-" ? (
                        <img
                          src="/IconCircleMinus.svg"
                          alt=""
                          className={ClassName.icon}
                        />
                      ) : (
                        <span>{feature.ultimate}</span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PricingFeaturesSection;

const ClassName = {
  container: "flex items-center w-full justify-center px-basicx xl:px-0",
  content:
    "w-full max-w-7xl bg-[#F5F7F9] rounded-xl p-3 md:p-6 overflow-x-scroll scroll-hide",
  features: "w-1280px xl:w-full bg-white rounded-xl overflow-hidden relative",
  cover: "absolute h-2 top-0 left-0 w-full bg-white",
  featuresContainer: "w-full bg-white rounded-xl",
  cellContent:
    "h-full flex flex-col items-center justify-center text-black/60 text-sm text-center px-12",
  cellHeaderContent: "h-full flex items-center py-4 px-3 text-sm",
  titleHeader: "w-1/4 h-32 text-center font-semibold text-2xl",
  starterHeader: "w-1/4 h-32 text-center",
  premiumHeader: "w-1/4 h-32 text-center",
  ultimateHeader: "w-1/4 h-32 text-center",
  headerCellContent:
    "h-full flex flex-col items-center justify-center font-bold text-xl gap-2",
  headerButton: "bg-[#F5F7F9] text-sm font-normal rounded-xl py-4 w-4/5",
  icon: "w-5 h-5 object-contain",
};
