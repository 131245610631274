import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import CustomRadio from "../../../components/common/CustomRadio";
import CustomDropdown from "../../../components/common/CustomDropdown";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import TourManagementAvailabilityHeader from "../../../components/header/TourManagementAvailabilityHeader";

const options = [
  "Infant",
  "Child",
  "Youth",
  "Senior",
  "Student(with ID)",
  "Military(with ID)",
  "Participant",
];

function TourManagementPackageAvailabilityPriceCategoryPage() {
  const navigate = useNavigate();

  const [selectedOptionPrice, setSelectedOptionPrice] = useState(null);
  const [selectedOptionFree, setSelectedOptionFree] = useState(null);
  const [selectedOptionPermitted, setSelectedOptionPermitted] = useState(null);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [ageRange, setAgeRange] = useState(1);
  const [switchActive, setSwitchActive] = useState(false);

  const handleRadioChangePermitted = (option) => {
    setSelectedOptionPermitted(option);
  };
  const handleRadioChangeFree = (option) => {
    setSelectedOptionFree(option);
  };

  const handleRadioChange = (option) => {
    setSelectedOptionPrice(option);
    setShowAdvancedSettings(
      option === "Price depends on age, eg: adults, child, senior, etc"
    );
  };

  const handleAgeRangeChange = (event) => {
    setAgeRange(event.target.value);
  };

  const handleSwitchChange = () => {
    setSwitchActive(!switchActive);
  };

  const buttonElement = (
    <button className={Classname.buttonElement}>Add age group</button>
  );

  const onContinue = () => {
    navigate("/tourManagement/create/availability/capacity");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={4} />
        <div className={Classname.detail}>
          <InfoCardLightening description="We have updated our experience. See what has changed here" />
          <TourManagementAvailabilityHeader active={1} />
          <div className={Classname.title}>Tell us more about your prices:</div>
          <CustomRadio
            value=""
            label="The price is the same for everyone, eg: per participant"
            selected={selectedOptionPrice}
            onSelect={handleRadioChange}
          />
          <CustomRadio
            value="Price depends on age, eg: adults, child, senior, etc"
            label="Price depends on age, eg: adults, child, senior, etc"
            selected={selectedOptionPrice}
            onSelect={handleRadioChange}
          />
          {showAdvancedSettings && (
            <div>
              <div className="flex flex-wrap justify-between items-center">
                <div className={Classname.title}>Price categories:</div>
                <div className="flex gap-2 items-center">
                  <div className={Classname.title}>Show advanced settings</div>
                  <Switch
                    color="primary"
                    className="mt-4"
                    checked={switchActive}
                    onChange={handleSwitchChange}
                  />
                </div>
              </div>

              <div className="flex w-full p-3 h-fit border text-start flex-col-reverse md:flex-row justify-between border-gray-300 rounded-md px-4 mt-2">
                <div className="flex flex-wrap gap-10 justify-center">
                  <div className="flex gap-24">
                    <div className={Classname.title}>Adult</div>
                    <div>
                      <div className="mt-6">Set an age range:</div>
                      <div className="flex mt-2 gap-4 items-center">
                        <div>0 to</div>
                        <Select
                          value={ageRange}
                          onChange={handleAgeRangeChange}
                          className="h-8"
                        >
                          {[...Array(99)].map((_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  {switchActive && (
                    <div>
                      <div className="mt-6 font-semibold">
                        Advanced Settings
                      </div>
                      <div>Is this category permitted?</div>
                      <div className="flex gap-10">
                        <CustomRadio
                          value=""
                          label="Yes"
                          selected={selectedOptionPermitted}
                          onSelect={handleRadioChangePermitted}
                        />
                        <CustomRadio
                          label="No"
                          selected={selectedOptionPermitted}
                          onSelect={handleRadioChangePermitted}
                        />
                      </div>
                      <div>Is this category free of charge?</div>
                      <div className="flex gap-10">
                        <CustomRadio
                          value=""
                          label="Yes"
                          selected={selectedOptionFree}
                          onSelect={handleRadioChangeFree}
                        />
                        <CustomRadio
                          label="No"
                          selected={selectedOptionFree}
                          onSelect={handleRadioChangeFree}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-6 text-red-600 font-bold">Remove</div>
              </div>
              <CustomDropdown button={buttonElement} options={options} />
            </div>
          )}
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton} onClick={handleBack}>
              Back
            </button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Save and continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageAvailabilityPriceCategoryPage;

const Classname = {
  buttonElement:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3",
  gridContainer: "grid grid-cols-4 gap-4",
  gridItem: "flex text-sm items-center h-8 gap-2 p-4",
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full pb-80",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-24 h-8 border border-gray-300 rounded-md px-4 mt-2",
  inputSmall: "flex w-10 h-8 border bg-gray-200 rounded-md px-2 mt-2",
  inputPrice: "flex w-24 h-8 border bg-gray-200 rounded-md px-2 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 mt-10 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
