import React from 'react'
import { isCustomerSite } from '../../../../utils/getsubDomain'
import FlightBook from './FlightBook';
import FlightBookTemplate1 from '../../../../components/templates/flight/FlightBook';

export default function FlightBookContainer() {
    const customer = isCustomerSite();
  return !customer ? (
    <FlightBook />
  ) : <FlightBookTemplate1 />
}
