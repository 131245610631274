import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { URLdecode } from "../../utils/url";
import { setTourCancelModal } from "../../redux/reducers/modalSlice";
import { useTours } from "../../hooks/useTours";
import { useState } from "react";
import { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { formatMoney } from "../../features/utils/formatMoney";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function TourCancelModal() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { tourCancelModal } = useSelector((state) => state.modal);
  const { cancelQuote } = useSelector((state) => state.tourData);
  const { cancelAndRefund } = useTours();
  const params = URLdecode();

  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState();
  const [refund, setRefund] = useState();

  const parseResons = () => {
    let tempReasons = cancelQuote?.cancelReasons || [];
    if (tempReasons && tempReasons?.length > 0) {
      setReason(tempReasons[0]?.cancellationReasonCode);
    }
    setReasons(tempReasons);
  };

  const parseRefund = () => {
    let amount = "";
    let refundDetails = cancelQuote?.cancelQuote?.refundDetails;
    if (refundDetails) {
      let refundAmount = refundDetails?.refundAmount;
      let refundCurrency = refundDetails?.currencyCode;
      if (refundAmount) {
        // let currency =
        //   refundCurrency === "NGN"
        //     ? "₦"
        //     : refundCurrency === "USD"
        //     ? "USD"
        //     : refundCurrency || null;
        amount = formatMoney(refundAmount, " ") + " " + refundCurrency;
      }
    }
    setRefund(amount);
  };

  useEffect(() => {
    parseResons();
    parseRefund();
  }, [cancelQuote]);

  const handleOpen = () => dispatch(setTourCancelModal(true));
  const handleClose = () => dispatch(setTourCancelModal(false));

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = async () => {
    handleClose();
    await cancelAndRefund(reason || "");
  };

  return (
    <div>
      <Modal
        open={tourCancelModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>
                Are you sure you want to cancel?
              </span>
              <p className={Classname.modalDescription}>
                By clicking Yes you have canceled this tour:{" "}
                <strong className={Classname.modalTourName}>
                  {params?.title}
                </strong>
                {refund && <span>And you will get {refund} in refund.</span>}
              </p>
              {reasons && reasons?.length > 0 && (
                <>
                  <span className="text-sm lg:text-sm mb-2 w-full font-semibold">
                    Cancelation reason
                  </span>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      className="mb-10"
                    >
                      {reasons?.map((item, index) => (
                        <MenuItem
                          value={item?.cancellationReasonCode}
                          key={index}
                        >
                          {item?.cancellationReasonText}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </div>
            <div className={Classname.bottomContent}>
              <button
                className={`${Classname.button} ${Classname.backButton}`}
                onClick={handleCancel}
              >
                No
              </button>
              <button
                className={`${Classname.button} ${Classname.confirmButton}`}
                onClick={handleConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main max-h-[80vh] overflow-scroll scroll-hide",
  content: "flex flex-col items-center px-4",
  modalTitle: "text-xl lg:text-3xl font-bold mb-6 mt-4 text-center max-w-[60%]",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-2 lg:py-10 bg-gray-100 h-14 px-4",
  button:
    "h-10 rounded-md flex items-center justify-center w-32 font-bold cursor-pointer",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",

  travellerItem:
    "flex justify-between items-center bg-gray-50 rounded-md px-2 py-2 mb-2 border border-gray-100",
  travellerTypeContainer: "flex flex-col items-center",
  travellerType: "font-bold",
  travellerAge: "text-sm text-gray-500",
  travellerChoiceContainer: "flex items-center ",
  travellerButton:
    "cursor-pointer w-5 h-5 rounded-full flex items-center justify-center text-white bg-primary1",
  travellerAmount: "w-10 flex items-center justify-center font-bold",
};
