import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

const Header7 = ({ logo, primaryColor, secondaryColor }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const companyLogo = logo ? (
    <img src={logo} alt="Company Logo" className="w-14" />
  ) : (
    <div className={`bg-[#D9D9D9] p-2 py-3 font-extrabold w-36 flex justify-center text-${secondaryColor}`}>
      Company Logo
    </div>
  );

  return (
    <div className="overflow-x-auto bg-white shadow-md grid justify-between gap-4 grid-cols-2 font-poppins px-6 py-6 origin-top" >
      <div className="col-span-1 origin-left" >
        {companyLogo}
      </div>
      <div className="md:hidden col-span-1 flex items-center justify-end" >
        <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
      </div>
      <div className={`col-span-2 md:col-span-1 justify-end flex flex-col md:flex-row items-center gap-10 ${menuVisible ? 'block' : 'hidden'} md:flex justify-end pr-10 origin-right`} >
        <div className={`flex flex-col md:flex-row items-center gap-10 md:bg-[#F6F6F6] px-6 py-4 rounded-full`} style={{color: primaryColor}}>
          <a>Home</a>
          <a>About</a>
          <a>Contact</a>
          <a>FAQ</a>
        </div>
      </div>
    </div>
  );
}

export default Header7;
