import React, { useEffect, useState } from 'react'
import Icon from '../../components/HOC/Icon'
import { formatMoney } from '../../features/utils/formatMoney'
import CartSummary from './CartSummary'
import Collapse from '../../components/mini/Collapse'
import getCart from '../../controllers/Cart/getCart'
import { useSelector } from 'react-redux'

export default function Cart() {
    const {userData} = useSelector(state => state.user)
    const [carts,setCarts] = useState([]);
    let bookedCarts = [];

    useEffect(() => {
        async function load() {
            await loadCartById();
            loadCart();
        }
        load();
    },[])

    async function loadCartById() {
        const cartId = window.localStorage.getItem('cartId');
        if(!cartId)
            return true;
        const res = await getCart(cartId)
        if(res.return) {
            const data = res?.data?.populatedOrders || [];
            const bookings = data?.map(obj => [obj?.flightBooking,obj?.hotelBooking,obj?.packageBooking].flat()).flat()
            if(bookings?.map(obj => (obj.status))?.every(status => status === 'Paid'))
                bookedCarts = data?.map(obj => obj?._id);
        }
    }

    function loadCart() {
        let carts = JSON.parse(window.localStorage.getItem('flightCart') || '[]');
        carts = carts?.filter(obj => !userData?.loggedIn || !bookedCarts.includes(obj?.booking?._id))
        window.localStorage.setItem('flightCart',JSON.stringify(carts))
        if(carts?.length === 0)
            window.localStorage.removeItem('cartId')
        setCarts(carts?.map(obj => templateFlightCart(obj)))
    }

    function handleRemove(id,type) {
        if(type === 'Flight')
            removeFlightFromCart(id);
    }

    function removeFlightFromCart(id) {
        let tempCarts = JSON.parse(window.localStorage.getItem('flightCart') || '[]');

        tempCarts = tempCarts?.filter(obj => obj?.booking?._id !== id)
        window.localStorage.setItem('flightCart',JSON.stringify(tempCarts || []))
        
        loadCart();
    }
    
  return (
    <div className='flex flex-col gap-4 pd-md'>
        <h5>Order Checkout</h5>
        {carts?.length ? 
            <div className='flex gap-4 flex-wrap'>
                <div className='flex-1 flex flex-col gap-4'>
                    {carts.map((obj,i) => (
                        <CartDisplay key={i} data={obj} remove={() => handleRemove(obj?.id,obj?.type)} />
                    ))}
                </div>
                <div className='w-full sm:w-auto'>
                    <CartSummary data={carts} />
                </div>
            </div>
        :
            <div className='flex justify-center py-10 items-center'>
                No Items
            </div>
        }
    </div>
  )
}

function templateFlightCart(obj) {
    const departure = obj?.params?.offers?.at(0)?.directions?.at(0)?.at(0)?.departure;
    const airline = obj?.params?.offers?.at(0)?.directions?.at(0)?.at(0)?.airline;

    let data = {
        price: obj?.params?.offers?.at(0)?.pricingInformation?.price?.totalPrice,
        airline: {
            image: airline?.image?.url,
            description: airline?.image?.description,
        },
        departureDate: departure?.date + ' ' + departure?.time,
        location: {
            from: departure?.city,
            to: obj?.params?.offers?.at(0)?.directions?.at(0)?.at(0)?.arrival?.city,
        },
        type: 'Flight',
        bookingId: obj?.booking?.bookingId,
        id: obj?.booking?._id,
        detail: (
            <div className='flex flex-col gap-2'>
                <span className='flex gap-2 items-center'>
                    <Icon icon='mdi:person' className='!w-4 !h-4 text-primary/60' />
                    {obj?.params?.travelersInfo?.length} X Passenger
                </span>
                {obj?.params?.offers?.at(0)?.directions?.flat()?.map(flight => (
                    <span className='flex gap-2 items-center'>
                        <Icon icon='mdi:seat-passenger' className='!w-4 !h-4 text-primary/60' />
                        {flight?.seatAvailability}x Seating ({flight?.departure?.location} - {flight?.arrival?.location}) 
                    </span>
                ))}
            </div>
        )
    }

    return data;
}

function CartDisplay({data,remove}) {    
    return (
        <div className='border rounded-md p-5 shadow'>
            <Collapse 
              dropdown={null}
              label={
                <div className='flex gap-2 w-full'>
                    <button onClick={remove}><Icon icon='material-symbols:delete-outline' className='text-orange-300 hover:text-red-500' /></button>
                    <div className='flex-1 flex flex-col gap-1'>
                        <b>{data?.bookingId}</b>
                        <b>
                            {data?.location?.from} to {data?.location?.to}
                        </b>
                        <div className='flex gap-4 items-center flex-wrap'>
                            <small className='flex gap-4'>
                                <span><img alt='' src={data?.airline?.image} className='w-[130px] max-h-[40px] object-cover ' /></span>
                                {/* {data?.airline?.description} */}
                            </small>
                            <small>
                                {data?.departureDate}
                            </small>
                        </div>
                    </div>
                    <b>{formatMoney(data.price)}</b>
                </div>
            }
            >
                {data?.detail}
            </Collapse>
        </div>
    )
}