import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { InputLabel } from '@mui/material';


function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    state,
    InputProps: { ref } = {},
  } = props;
  // const state = useSelector((state) => state.app);
  console.log(props)
  return (
    <span
      className={`font-bold cursor-pointer `}
      style={{color: state.secondaryColor}}
      id={id}
      ref={ref}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ? `${label}` : 'Select a date'}
    </span>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = useState(false);
  console.log(props)
  return (
    <DatePicker
      slots={{
        field: (fieldProps) => (
          <ButtonField
            {...fieldProps}
            state={props.state}
          />
        ),
        ...props.slots
      }}
      disablePast
      slotProps={{ field: { setOpen }}}
      {...props}
      state={props.state}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

const CustomDatePicker = ({state, travelCitys, setTravelCitys, index, type}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const updateDate = (date) => {
      if(type == 'departure'){
        const updatedCities = [...travelCitys];
        updatedCities[index]["departure"] = {date:date};
        setTravelCitys(updatedCities);
      }
      else{
        const updatedCities = [...travelCitys];
        updatedCities[index]["return"] = {date:date};
        setTravelCitys(updatedCities);
      }
    }
    const current = type == 'departure' ?  travelCitys[index]["departure"].date : travelCitys[index]["return"].date;
    return (
      <div className='z-20'>
        <div className="dropdown relative">
          {
            <div
              className="dropdown-toggle cursor-pointer mt-1"
            >
              {
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ButtonDatePicker
                    label={current == null ? null : dayjs(current.format('MM/DD/YYYY'), 'MM/DD/YYYY').format('ddd, MMM D YYYY')}
                    value={current}
                    style = {{color: state.secondaryColor}}
                    onChange={(newValue) => updateDate(newValue)}
                    state={state}
                  />
                </LocalizationProvider>
              }
            </div>
          }
          
        </div>
      </div>
    );
};

export default CustomDatePicker;
