import React from "react";

function GettingStartedVerticalLine({ active, height }) {
  return (
    <div className={`${height} w-5 flex justify-center `}>
      {active && <div className="draw-line w-[2px] bg-gray-300"></div>}
    </div>
  );
}

export default GettingStartedVerticalLine;
