import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setGettingStartedLoadingModal,
  setGettingStartedVideoModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: window.innerWidth > 600 ? 8 : 3,
};

export default function GettingStartedVideoModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    gettingStartedVideoModal,
    gettingStartedVideModalVideoId,
    gettingStartedVideModalName,
  } = useSelector((state) => state.modal);
  const [selected, setSelected] = useState(1);

  const handleClose = () => dispatch(setGettingStartedVideoModal(false));

  const handleChoose = (chosen) => {
    setSelected(chosen);
  };

  const handleGetStarted = () => {
    dispatch(setGettingStartedLoadingModal(true));
    setTimeout(() => {
      navigate("/gettingStarted/business/activate");
      dispatch(setGettingStartedLoadingModal(false));
    }, 3000);
  };

  return (
    <div>
      <Modal
        open={gettingStartedVideoModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="w-full flex items-center justify-end">
              <img
                src="/IconClose4.svg"
                alt=""
                className="w-7 h-7 object-contain cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="w-full max-w-4xl h-[50vh] bg-[#E6EFFF] rounded-lg p-1 cursor-pointer">
              <div className="w-full h-full rounded-lg bg-[#A2DDFF] flex items-center justify-center overflow-hidden">
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${gettingStartedVideModalVideoId}?rel=0`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <div className="w-full flex justify-center my-3 lg:my-8">
              <span className="text-base lg:text-xl text-center">
                {gettingStartedVideModalName}
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main px-4 lg:px-10 py2 lg:py-4 items-center",
  numberContainer: "flex gap-2 text-sm text-gray-500 mb-2",
  number:
    "w-8 h-8 rounded-full items-center justify-center bg-gray-300 text-xs flex flex-shrink-0",
  buttonContainer: "flex justify-end mt-4",
  button: "h-12 px-4 font-bold text-white bg-primary1 rounded-md",
  choice:
    "flex flex-col w-full lg:w-96 lg:min-h-[300px] items-center p-3 rounded-md cursor-pointer",
  choiceImageContainer: "w-full rounded-lg h-[150px] lg:h-[215px]",
  choiceImage: "w-full h-full object-contain",
  choiceName: "mt-1 lg:mt-3 font-bold text-sm text-center lg:text-lg",
  choiceDescription: "text-xs lg:text-base text-[#818083]",
};
