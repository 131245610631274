import React from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import TourManagementAvailabilityHeader from "../../../components/header/TourManagementAvailabilityHeader";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import { useNavigate } from "react-router-dom";

function TourManagementPackageAvailabilityPricePage() {
  const navigate = useNavigate();
  const handleTierPrice = () => {};
  const onContinue = () => {
    navigate("/tourManagement/create/availability/add-on");
  };

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={4} />
        <div className={Classname.detail}>
          <InfoCardLightening description="We have updated our experience. See what has changed here" />
          <TourManagementAvailabilityHeader active={3} />
          <div className={Classname.title}>Set the price for your activity</div>

          <div className="flex flex-wrap gap-10 mt-6">
            <div className={Classname.items}>
              <div>Price per participant</div>
              <div>1 to 5</div>
            </div>

            <div className="flex gap-10">
              <div className={Classname.items}>
                <div>Customer pays</div>
                <div className={Classname.gridItem}>
                  <input type="text" className={Classname.input} /> USD
                </div>
              </div>
              <div className={Classname.items}>
                <div>Commission</div>
                <div className={Classname.gridItem}>
                  <input
                    type="text"
                    placeholder="30"
                    className={Classname.inputSmall}
                  />{" "}
                  %
                </div>
              </div>
            </div>
            <div className="flex  gap-8">
              <div className={Classname.items}>
                <div>Price per participant</div>
                <div className={Classname.gridItem}>
                  <input type="text" className={Classname.inputPrice} /> USD
                </div>
              </div>
              <div
                className={Classname.highlightButton}
                onClick={handleTierPrice}
              >
                + Tier Price
              </div>
            </div>
          </div>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton} onClick={handleBack}>
              Back
            </button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Save and continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageAvailabilityPricePage;

const Classname = {
  items: "flex flex-col items-start gap-4",
  gridContainer: "grid grid-cols-4 gap-4",
  gridItem: "flex text-sm items-center justify-center h-8 gap-2 ",
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-24 h-8 border border-gray-300 rounded-md px-4",
  inputSmall: "flex w-10 h-8 border bg-gray-200 rounded-md px-2",
  inputPrice: "flex w-24 h-8 border bg-gray-200 rounded-md px-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
