import { CustomerHome } from "../../../components/templates/layouts/LayoutList";
import { isCustomerSite } from "../../../utils/getsubDomain";
import CreateFlightOrder from "./CreateFlightOrder";

export default function SearchContainer() {
  const customer = isCustomerSite();
  return customer ? (
    <CustomerHome />
  ) : <CreateFlightOrder />
}
  