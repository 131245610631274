import { useState } from "react"
import getCityCodes from '../../../controllers/Flight/getCityCodes';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import destinationImage from "../../../assets/images/Destination.png"
import Orders from "./Orders";
import Profile from "./Profile";
import TopDestination from "./Home/TopDestination";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/reducers/userSlice";

const IntroAndSearch = ({optionLabel = "iata", data})=>{
    const [destinations, setDestinations] = useState([])
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);

    var cancelTokenSource = null;
    async function load() {
        if (!value) return setDestinations([]);
        if (value?.icao) return false;
        
        if(cancelTokenSource) {
          cancelTokenSource.cancel('Request canceled.')
        }
        cancelTokenSource = axios.CancelToken.source();
        setLoading(true);
        const res = await getCityCodes(value,cancelTokenSource?.token);
        setLoading(false);
        if (res.return) {
          let data = res?.data?.data;
          if (optionLabel) data = data?.filter((obj) => obj[optionLabel]);
          setDestinations(data);
        }
    
      }

    const updateVlaue = (e)=>{
        setValue(e.target.value)
    }
    return(
        <div className="px-4 flex items-center flex-wrap justify-between mt-10 bg-white py-4">
            <div>
                <span className="text-2xl font-bold">Hello, {data.name}!</span>
                <p>Welcome back and explore the world.</p>
            </div>
            {/* <div className="flex gap-2 flex-wrap">
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        label="Type"
                        id="outlined-basic"
                        value={value}
                        onChange={updateVlaue}
                        className="w-28"
                    >
                        <MenuItem value="flights">Flights</MenuItem>
                        <MenuItem value="tours">Tours</MenuItem>
                        <MenuItem value="stays">Stays</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <TextField
                        id="outlined-basic"
                        label={<span className="flex items-center gap-1"> <span>Search for destinations</span><Icon icon="mdi:location" /> </span>}
                        variant="outlined" 
                        className="w-auto max-w-96"
                    />
                </FormControl>
                <Button className="rounded-md !bg-[#E9EDF1] !text-black font-semibold" >
                    Search
                </Button>

            </div> */}
        </div>
    )
}

const TabNavs = ({curNav, setCurNav}) =>{
    const navs = [
        {
            "label": "Dashboard",
            "icon": "material-symbols:dashboard",
            "id": 1,
        },
        {
            "label": "Orders",
            "icon": "material-symbols:order-approve",
            "id": 2,
        },
        // {
        //     "label": "Trips",
        //     "icon": "material-symbols:route-outline",
        //     "id": 3,
        // },
        // {
        //     "label":"Rewards",
        //     "icon": "mdi:medal",
        //     "id": 4,
        // },
        // {
        //     "label": "Support",
        //     "icon": "mdi:support",
        //     "id": 5,
        // },
    ]

    const dispatch = useDispatch();

    function handleLogout() {
        dispatch(logout());
        window.location.href = '/?view=login';
      }
    
    return(
        <div className="flex justify-between bg-white mt-10 flex-wrap">
            <div className="flex gap-2 flex-wrap">
                {navs.map((nav,i)=>(
                    <div className={`!w-25 flex gap-2 md:gap-0 md:flex-col justify-center items-center cursor-pointer py-4 px-3 rounded-md ${curNav == i+1 ? "bg-theme1 text-white": "text-[#B6BDC9]"}`} onClick={()=>setCurNav(i+1)}>
                        <Icon icon={nav.icon} className="text-2xl"/>
                        <span>{nav.label}</span>
                    </div>
                ))}
            </div>
            <div className="flex gap-2 text-[#B6BDC9]">
                <div className={`!w-25 flex flex-col justify-center items-center cursor-pointer py-4 px-3 rounded-md ${curNav == 6 ? "bg-theme1 text-white": "text-[#B6BDC9]"}`} onClick={()=>setCurNav(6)}>
                    <Icon icon="mingcute:user-3-fill" className="text-xl"/>
                    <span>Profile</span>
                </div>
                <div onClick={handleLogout} className={`!w-25 flex flex-col justify-center items-center cursor-pointer py-4 px-3 rounded-md`}>
                    <Icon icon="streamline:logout-1-solid" className="text-xl"/>
                    <span>Logout</span>
                </div>
            </div>
        </div>
    )
}


const NotificationCard = ()=>{
    return(
        <div className="bg-[#F5F7F9] px-2 mx-4 py-3 flex justify-between gap-2">
            <div>
                <div className="bg-white w-14 h-14 flex justify-center items-center rounded-md">
                    <Icon icon="solar:ticket-bold" className="text-[#BCC7D3] text-4xl"/>
                </div>
            </div>
            <div className="w-full">
                <div className="flex w-full justify-between">
                    <span className="font-bold text-lg">Ticket Purchase</span>
                    <div className="flex gap-2">
                        <span>confirmed</span>
                        <span>flight</span>
                    </div>
                </div>
                <p className="text-sm">One way ticket from Lagos to London with Turkish airline</p>
            </div>
        </div>
    )
}
const Notifications = ()=>{
    return(
        <div className="w-full bg-white py-2">
            <div className="w-full flex justify-between top-2 px-4 items-center">
                <span className="text-xl font-bold">Notifications</span>
                <Icon icon="majesticons:chevron-down-line" className="text-[#BCC7D3] text-xl"/>
            </div>
            <div className="mt-2 grid gap-2 h-80 overflow-y-scroll">
                { Array
                    .from({length: 5}, (_, i) => i + 1)
                    .map((_,i)=>(
                        <NotificationCard key={i}/>
                    ))
                }
            </div>
        </div>
    )
}

const DestinationCard = ()=>{
    return(
        <div className="bg-white rounded-md flex gap-2 items-center p-2 mt-3">
            <img src={destinationImage} className="rounded-md h-20"/>
            <div className="flex flex-col justify-between h-full">
                <div>
                    <span className="font-bold">Bournemouth Beach and Night Life</span>
                    <span className="flex gap-1 items-center">
                        <Icon icon="mdi:location" className="text-[#7CDEAC] text-xl"/>
                        <span className="text-sm">Paris, France</span>
                    </span>
                </div>
                <div className="flex w-full justify-between">
                    <span className="flex gap-1 items-center">
                        <Icon icon="mdi:star" className="text-[#F7B643] text-xl"/>
                        <span className="text-sm">4.8 tourist rating</span>
                    </span> 
                    <span className="font-bold text-sm">₦ 25,000</span>
                </div>
            </div>
        </div>
    )
}


const DestinationGroups = ()=>{
    const [destCategory, setDestCategory] = useState(1);
    return (
        <div className=" rounded-md">
            <div className="flex justify-between items-center ">
                <div className="flex gap-2">
                    <span onClick={()=>setDestCategory(1)} className={`${destCategory == 1? "text-[#333333]": "text-[#B6BDC9]"} text-md font-bold cursor-pointer`}>Most popular</span>
                    <span onClick={()=>setDestCategory(2)} className={`${destCategory == 2? "text-[#333333]": "text-[#B6BDC9]"} text-md font-bold cursor-pointer`}>Best Deals</span> 
                    <span onClick={()=>setDestCategory(3)} className={`${destCategory == 3? "text-[#333333]": "text-[#B6BDC9]" } text-md font-bold cursor-pointer`}>Near Me</span>
                </div>
                <div className="flex gap-1 font-bold items-center">
                    <span>Tours</span>
                    <Icon icon="majesticons:chevron-down-line" className="text-[#BCC7D3] text-xl"/>
                </div>
            </div>
            <div className="mt-3 grid grid-flow-row lg:grid-cols-2 gap-3">
                <DestinationCard />
                <DestinationCard />
                <DestinationCard />
                <DestinationCard />
                <DestinationCard />
                <DestinationCard />
            </div>
        </div>
    )
}

const UpcomingCard = ({type, data={}})=>{
    if(type == "flight"){
        return(
            <div className="bg-white rounded-md py-2 flex gap-2 flex-wrap p-3">
                <div className="bg-[#BCC7D3] flex justify-center items-center p-3 rounded-md">
                    <Icon icon="icon-park-solid:ticket" className="text-5xl text-[#6D7C94]"/>
                </div>
                <div>
                    <div className="flex gap-1 text-sm items-center font-bold">
                        <span className="font-bold">{data.from.name}</span>
                        <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{data.from.iata}</span>
                        <span>to</span>
                        <span className="font-bold">{data.to.name}</span>
                        <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{data.to.iata}</span>
                    </div>
                    <span className="text-sm">{data.airline}</span>
                    <div className="flex justify-end text-sm">{data.flightType}</div>
                </div>
            </div>
        )
    }
    else{
        return(
            <div>

            </div>
        )
    }
}

const UpcomingTrips = ()=>{
    const mockData = {
        "from": {
            "name": "Lagos",
            "iata": "LOS"
        },
        "to": {
            "name": "London",
            "iata": "LHR"
        },
        "date": "2022-09-10",
        "airline": "Emirates Airlines",
        "flightType": "One way",   
    }
    return(
        <div className="">
            <div className="w-full flex justify-between items-center">
                <div className="flex gap-2 font-bold items-center cursor-pointer">
                    <span>Upcoming trips</span>
                    <span className="flex justify-center items-center h-5 w-5 rounded-full bg-[#F9D849]">4</span>
                </div>
                <div className="flex gap-2 font-bold items-center cursor-pointer text-[#B6BDC9]">
                    <span className="">Done trips</span>
                    <span className="flex justify-center items-center h-5 w-5 rounded-full bg-[#E7E7E7]">4</span>
                </div>
            </div>
            <div className="mt-6 grid gap-2">
                <UpcomingCard 
                    type={"flight"} 
                    data={mockData}
                />
                <UpcomingCard 
                    type={"flight"} 
                    data={mockData}
                />
                <UpcomingCard 
                    type={"flight"} 
                    data={mockData}
                />
            </div>
        </div>
    )
}


const DestinationAndUpcoming = ()=>{
    return(
        <div className="lg:grid grid-flow-col gap-8 w-full mt-6">
            <DestinationGroups />
            <UpcomingTrips />
        </div>
    )
}



const HomeContent = ()=>{
    return(
        <div>
            <div className="lg:flex mt-10 gap-8">
                <div 
                    className="w-full "
                >
                    <TopDestination />
                </div>
                {/* <Notifications /> */}
            </div>
            {/* <DestinationAndUpcoming /> */}
        </div>
    )
}
const CurrentContent = ({curNav})=>{
    switch(curNav){
        case 1:
            return <HomeContent />
        case 2:
            return <Orders />
        case 3:
            return <div>Trips</div>
        case 4:
            return <div>Rewards</div>
        case 5:
            return <div>Support</div>
        case 6:
            return <Profile />
        default:
            return <HomeContent />
    
    }
}
const CustomerDashboard = ()=>{
    const {userData: {user}} = useSelector(state => state.user);
    const [curNav, setCurNav] = useState(1);


    return(
        <div className="bg-[#F5F7F9] px-6 md:px-20 pb-10">
            <TabNavs curNav={curNav} setCurNav={setCurNav}/>
            <IntroAndSearch data={{name:user?.firstName}}/>
            <CurrentContent curNav={curNav}/>
            
        </div>
    )
}

export default CustomerDashboard;