import React, { useState } from 'react'
import Button1 from '../../components/form/Button1'
import { useSnackbar } from 'notistack'
import queueTicket from '../../controllers/booking/queueTicket';
import holdFlightBooking from '../../controllers/booking/holdFlightBooking';

export default function HoldBooking({data,close,callback}) {
    const [loading,setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    console.log(data?.pnr)

    async function handleHold() {
      setLoading(true);
      const res = await holdFlightBooking(data?._id);
      setLoading(false);
      if(res.return) {
        enqueueSnackbar('Booking on hold.',{variant: 'success'})
        callback && callback()
      } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
        
  return (
    <div className='card p-10 flex flex-col gap-4 justify-center'>
        <h5>Confim hold for booking : {data?.pnr}</h5>
        <p>Do you want to hold this booking and pay later?</p>
        <div className='flex w-full gap-2'>
            <Button1 variant={'outlined'} className='!w-auto' onClick={close}>Cancel</Button1>
            <Button1 className='' loading={loading} onClick={handleHold}>Confirm</Button1>
        </div>
    </div>
  )
}
