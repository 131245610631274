import React, { useEffect, useState } from 'react'
import PolicyStatus from '../../../../components/flight/PolicyStatus';
import Button1 from '../../../../components/form/Button1';
import Modal1 from '../../../../components/DIsplay/Modal/Modal1';
import getFlightOfferPrice from '../../../../controllers/Flight/getOfferPrice';
import { CircularProgress } from '@mui/material';

export default function FareRule({orderData,changable,refundable}) {
    const [openFareRule,setOpenFareRule] = useState(false);
    const [loading,setLoading] = useState(false);
    
    useEffect(() => {
        if(openFareRule)
            load();
    },[openFareRule])

    async function load() {
        const directions = orderData?.flights
        directions.map(obj => {
            let seg = (obj?.segment || 1) - 1
            if(Array.isArray(directions[seg]))
                directions[seg].push(obj)
            else directions[seg] = [obj]
        })
        const payload = {
            supplier: orderData?.supplier,
            offers: [
                {
                    id: 1,
                    directions: orderData?.flights
                }
            ]
        }
        
        setLoading(true);
        const res = getFlightOfferPrice(payload);
        console.log(res);
        setLoading(false);
    }
  return (
    <div>
        <h5>Fare Rule</h5>
        <div className="flex flex-wrap gap-4 card p-4">
            <div className="flex-1">
            <PolicyStatus
                title="Order Change Policy"
                value={changable}
                text={
                changable
                    ? "This order is changeable"
                    : "This order is not changeable"
                }
            />
            </div>
            <div className="flex-1">
            <PolicyStatus
                title="Order Refund Policy"
                value={refundable}
                text={
                refundable
                    ? "This order is refundable up until the initial departure date"
                    : "This order is not refundable"
                }
            />
            </div>
            <div className="w-full">
                <div className='flex'>
                    {/* <div>
                        <Button1 onClick={() => setOpenFareRule(true)}>View fare rules</Button1>
                    </div> */}
                </div>
                <Modal1 open={openFareRule} setOpen={setOpenFareRule}>
                    <div>
                        {loading? 
                            <CircularProgress />
                        :
                            <div>
                                Fare Rule
                            </div>
                        }
                    </div>
                </Modal1>
            </div>
        </div>

    </div>
  )
}
