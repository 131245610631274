import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import moment from 'moment';


function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    state,
    InputProps: { ref } = {},
    className
  } = props;
  // const state = useSelector((state) => state.app);
  console.log(props)
  return (
    <span
      className={`font-bold text-lg cursor-pointer ${className||''}`}
      style={{color: state.secondaryColor}}
      id={id}
      ref={ref}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label ? `${label}` : 'Select a date'}
    </span>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = useState(false);
  console.log(props)
  return (
    <DatePicker
      slots={{
        field: (fieldProps) => (
          <ButtonField
            {...fieldProps}
            state={props.state}
          />
        ),
        ...props.slots
      }}
      disablePast
      slotProps={{ field: { setOpen }}}
      {...props}
      state={props.state}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

const CustomDatePicker = ({state, travelCitys, setTravelCitys, index, type, className, label, ...props}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const updateDate = (date) => {
      if(type == 'departure'){
        const updatedCities = [...travelCitys];
        updatedCities[index]["departure"] = {date:date};
        setTravelCitys(updatedCities);
      }
      else{
        const updatedCities = [...travelCitys];
        updatedCities[index]["return"] = {date:date};
        setTravelCitys(updatedCities);
      }
    }
    let minDate = moment().format('YYYY-MM-DD');
    try {
      if(props.hidePastDates) {
        const dates = (travelCitys?.slice(0,(index || 0) + (type === 'return' ? 1 : 0))?.map((obj,i) => {
          const list = [obj?.departure?.date];

          if(type !== 'return')
            list.push(obj?.return?.date)
          return list
        })?.flat())
        
        const dateStrings = (dates?.map(obj => moment(obj?.$d).format('YYYY-MM-DD')))?.sort((a,b) => moment(a).diff(moment(b)))
        console.log('this: ',dateStrings)
        minDate = dateStrings?.at(-1)
      }
    } catch(ex) {
      console.log('this: ',ex)
    }
    
    const current = type == 'departure' ?  travelCitys[index]["departure"].date : travelCitys[index]["return"].date;
    return (
      <div className='z-20'>
        <div className="dropdown relative">
          {
            <div
              className="dropdown-toggle cursor-pointer mt-1"
            >
              {
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ButtonDatePicker {...(props?.hidePastDates ? {minDate: dayjs(minDate)} : {})} className={className}
                    label={current == null ? label : dayjs(current.format('MM/DD/YYYY'), 'MM/DD/YYYY').format('ddd, MMM D YYYY')}
                    value={current}
                    style = {{color: state.secondaryColor}}
                    onChange={(newValue) => updateDate(newValue)}
                    state={state}
                  />
                </LocalizationProvider>
              }
            </div>
          }
          
        </div>
      </div>
    );
};

export default CustomDatePicker;
