import { Checkbox } from "@mui/material";
import React from "react";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function GettingStartedCheckbox({ active, text, visible, setActive }) {
  return (
    <div className={`items-center gap-2 my-4 flex`}>
      <Checkbox
        {...label}
        checked={active}
        onClick={() => setActive(!active)}
      />
      <span className="text-sm lg:text-base text-[#64647A]">{text}</span>
    </div>
  );
}

export default GettingStartedCheckbox;
