import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import TourManagementChoice from "../../../components/tour/TourManagementChoice";
import { tourPackageGuideOptions } from "../../../data/tour/packageData";

function TourManagementPackageGuideInfoPage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");

  const onContinue = () => {
    navigate("/tourManagement/create/food");
  };

  const onSelect = (option) => {
    setSelected(option);
  };

  const onSave = () => {};

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementSidebar selected={7} />
        <div className={Classname.detail}>
          <TourManagementChoice
            selected={selected}
            onSelect={onSelect}
            options={tourPackageGuideOptions}
            className="mb-10"
          />
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageGuideInfoPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
