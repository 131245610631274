import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

const Header8 = ({
  logo,
  primaryColor,
  secondaryColor,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const companyLogo = logo ? (
    <img src={logo} alt="Company Logo" className="w-14" />
  ) : (
    <div
      className={`bg-[#D9D9D9] p-2 py-3 font-extrabold w-36 flex justify-center text-${secondaryColor}`}
    >
      Company Logo
    </div>
  );
  const [currentNav, setCurrentNav] = useState(3)
  return (
    <div className="overflow-x-auto bg-white shadow-md grid justify-between gap-4 grid-cols-2 md:grid-cols-4 font-poppins px-6 py-6 origin-top" >
      <div className="col-span-1 origin-left">{companyLogo}</div>
      <div className="md:hidden col-span-1 flex items-center justify-end" >
        <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
      </div>
      <div
        className={`col-span-2 md:col-span-3 flex flex-col md:flex-row items-center gap-10 origin-left ${
          menuVisible ? "block" : "hidden"
        } md:flex justify-between`}
      >
        <div
          className={`col-span-2 md:col-span-1 justify-end flex flex-col md:flex-row items-center gap-10 md:flex`}
        >
          <div
            className={`flex flex-col md:flex-row items-center gap-6  px-6 py-4 font-bold`}
            style={{ color: primaryColor }}
          >
            <a className="flex gap-6 items-center">
              <span onClick={()=> setCurrentNav(1)} className="cursor-pointer px-3 py-2 rounded-lg transition-all duration-75" style={{color: currentNav == 1? "white":"black", background: currentNav == 1 ? secondaryColor : "none"}}>Flights</span>
              <span className="hidden md:block h-7 w-[1px] bg-black"></span>
            </a>
            <a onClick={()=> setCurrentNav(2)} className="flex gap-6 items-center">
            <span className="cursor-pointer px-3 py-2 rounded-lg transition-all duration-75" style={{color: currentNav == 2? "white":"black", background: currentNav == 2 ? secondaryColor : "none"}}>Hotels</span>
              <span className="hidden md:block h-7 w-[1px] bg-black"></span>
            </a>
            <a onClick={()=> setCurrentNav(3)} className="cursor-pointer px-3 py-2 rounded-lg transition-all duration-75" style={{color: currentNav == 3? "white":"black", background: currentNav == 3 ? secondaryColor : "none"}}>Tours</a>
          </div>
        </div>
        <div className="flex gap-4 col-span-1 justify-center md:justify-end items-center origin-left">
          <span className="flex gap-5 items-center">
              <div className="flex gap-2 items-center">
                  <HelpOutlineOutlinedIcon style={{ color: secondaryColor }} />
                  <p>Help</p>
              </div>
            <span className="hidden md:block h-7 w-[1px] bg-black"></span>
          </span>
          <span className="flex w-[120px] gap-2 items-center p-2 py-1 rounded-lg bg-[#EFF3F5]">
            <PersonOutlineOutlinedIcon style={{ color: secondaryColor }} />
            <p>My Account</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header8;
