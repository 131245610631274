import React, { useState } from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import { useNavigate } from "react-router-dom";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import CustomRadio from "../../../components/common/CustomRadio";

function TourManagementPackageAvailabilityPage() {
  const navigate = useNavigate();
  const [selectedOptionAvaliable, setSelectedOptionAvaliable] = useState(null);
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(null);

  const handleSelectAvaliable = (option) => {
    setSelectedOptionAvaliable(option);
  };
  const handleSelectPrice = (option) => {
    setSelectedOptionPrice(option);
  };

  const onContinue = () => {
    navigate("/tourManagement/create/availability/schedule");
  };

  const handleAddSchedule = () => {
    navigate("/tourManagement/create/availability/schedule");
  };
  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={4} />
        <div className={Classname.detail}>
          <InfoCardLightening description="We have updated our experience. See what has changed here" />
          <div className={Classname.title}>Availability & Pricing</div>
          <div className={Classname.description}>
            This will apply to all the schedules added to this option.
          </div>
          <div className={Classname.title}>
            Does your activity have fixed time slots or operating hours?
          </div>
          <CustomRadio
            label="Fixed time slots"
            description="Example: walking tour starting at 9:00 AM"
            selected={selectedOptionAvaliable}
            onSelect={handleSelectAvaliable}
          />
          <CustomRadio
            value=""
            label="Operating hours"
            description="Example: museum open from Mon to Sat, between 9:00 AM and 7:00 PM"
            selected={selectedOptionAvaliable}
            onSelect={handleSelectAvaliable}
          />

          <div className={Classname.title}>How do you set your prices?</div>
          <CustomRadio
            label="Price per person"
            description="Set different prices for adults, youth, child, etc."
            selected={selectedOptionPrice}
            onSelect={handleSelectPrice}
          />
          <CustomRadio
            value=""
            label="Price per group/vehicle"
            description="Price per group/vehicle"
            selected={selectedOptionPrice}
            onSelect={handleSelectPrice}
          />
          <button
            className={Classname.addressButton}
            onClick={handleAddSchedule}
          >
            Add new schedule
          </button>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default TourManagementPackageAvailabilityPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6 ",
  description: "flex text-base mb-4 mt-2",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  addressButton:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3 border-primary1 border rounded-lg mt-6",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
