import FlightOfferSort1 from "./FlightOfferSort1";
const FlightOfferSortList = ({cat,getCatInfo,sortByCat, id, loading})=>{
    switch(id){
        case "1":
            return <FlightOfferSort1 cat={cat} getCatInfo={getCatInfo} sortByCat={sortByCat} loading={loading}/>
        default:
            return <FlightOfferSort1 cat={cat} getCatInfo={getCatInfo} sortByCat={sortByCat} loading={loading}/>
    }
}

export default FlightOfferSortList;