import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URLdecode } from "../../utils/url";

function BookingQuestionMeeetingPoint({
  onChange,
  onUnitChange,
  question,
  arrivalQuestion,
}) {
  const { tour, tourAvailability } = useSelector((state) => state.tourData);
  const [locations, setLocations] = useState(tour?.locations || []);
  const [option, setOption] = useState("");
  const [meetingPoint, setMeetingPoint] = useState();
  const [pickupIncluded, setPickupIncluded] = useState(false);
  const [later, setLater] = useState();
  const params = URLdecode();
  let pickupType = arrivalQuestion?.answer;
  let freeTextAllowed =
    tour?.logistics?.travelerPickup?.allowCustomTravelerPickup;

  const getMeetingPoint = () => {
    let start = tour?.logistics?.start?.at(0)?.location?.ref;
    if (start) {
      let tempLoc = start;
      let meetLocation = locations?.find(
        (location) => location?.reference === tempLoc
      );
      setMeetingPoint({
        id: tempLoc,
        name: meetLocation?.name,
      });
    }
  };

  const parseLocations = () => {
    let travellerPickupLocations =
      tour?.logistics?.travelerPickup?.locations || [];
    let baseLocations = tour?.locations || [];
    if (baseLocations) {
      baseLocations = [...baseLocations].sort((a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        return nameA > nameB ? 1 : -1;
      });

      if (travellerPickupLocations) {
        baseLocations = baseLocations?.map((location) => {
          let travellerPickup = travellerPickupLocations?.find(
            (pickup) => pickup?.location?.ref === location?.reference
          );
          let updatedLocation = { ...location };
          if (travellerPickup) {
            updatedLocation = {
              ...updatedLocation,
              pickupType: travellerPickup?.pickupType,
            };
          }
          return updatedLocation;
        });
      }

      pickupType =
        arrivalQuestion?.answer === "AIR"
          ? "AIRPORT"
          : arrivalQuestion?.answer === "SEA"
          ? "PORT"
          : "HOTEL";
      let contactSupplier = baseLocations?.find(
        (loc) => loc?.reference === "CONTACT_SUPPLIER_LATER"
      );
      let pureLocations = baseLocations?.filter(
        (loc) =>
          loc?.reference !== "CONTACT_SUPPLIER_LATER" &&
          loc?.reference !== "MEET_AT_DEPARTURE_POINT"
      );
      if (pickupType) {
        let pickupTypeAvailable = baseLocations?.find((loc) => loc?.pickupType);
        if (pickupTypeAvailable) {
          if (pickupType === "AIRPORT" || pickupType === "PORT") {
            pureLocations = pureLocations.filter(
              (loc) => loc?.pickupType === pickupType
            );
          } else {
            pureLocations = pureLocations.filter(
              (loc) =>
                loc?.pickupType === "HOTEL" ||
                loc?.pickupType === "LOCATION" ||
                loc?.pickupType === "OTHER"
            );
          }
        }
      }
      setLocations(pureLocations);
      if (contactSupplier) setLater(contactSupplier);
    }
  };

  const determineIfPickupIncluded = () => {
    let productOptions = tour?.productOptions;
    let selectedProductOptionCode = params?.productOptionCode;
    if (productOptions) {
      if (selectedProductOptionCode) {
        let selectedProductOption = productOptions?.find(
          (productOption) =>
            productOption?.productOptionCode === selectedProductOptionCode
        );
        let description = selectedProductOption?.description;
        if (description && description?.includes("Pickup included")) {
          setPickupIncluded(true);
        }
      }
    }
  };

  const updatingPickup = () => {
    if (pickupIncluded) {
      if (option === "meetingpoint") {
        if (meetingPoint) {
          onChange("MEET_AT_DEPARTURE_POINT");
        }
      }
      if (option === "later") {
        if (later) {
          onChange(later?.reference);
        }
      }
      if (option === "custom" && freeTextAllowed) {
        onUnitChange("FREETEXT");
      } else {
        onUnitChange("LOCATION_REFERENCE");
      }
    } else {
      if (meetingPoint) {
        onChange("MEET_AT_DEPARTURE_POINT");
      }
    }
  };

  const unitChange = () => {
    if (
      question?.answer === "MEET_AT_DEPARTURE_POINT" &&
      question?.unit !== "LOCATION_REFERENCE"
    ) {
      onUnitChange("LOCATION_REFERENCE");
    }
  };

  useEffect(() => {
    getMeetingPoint();
    determineIfPickupIncluded();
  }, [tour, arrivalQuestion?.answer, pickupType]);

  useEffect(() => {
    parseLocations();
  }, [tour, arrivalQuestion?.answer, pickupType]);

  useEffect(() => {
    unitChange();
  }, [question]);

  useEffect(() => {
    updatingPickup();
  }, [option, pickupIncluded, meetingPoint]);

  useEffect(() => {
    if (question?.unit === "FREETEXT") {
      onChange("");
    }
  }, [question?.unit]);

  return (
    <div className="w-full flex flex-col">
      <span className={Classname.detailTitle}>Meeting and Pickup</span>
      <div className="w-full mt-2 flex flex-col">
        {pickupIncluded ? (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={(e) => setOption(e.target.value)}
              value={option}
            >
              {pickupIncluded && (
                <>
                  <FormControlLabel
                    value="pickup"
                    control={<Radio />}
                    label="I’d like to be picked up"
                  />

                  {option === "pickup" && (
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="h-12 mt-2"
                        value={question?.answer}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          onChange(e.target.value);
                        }}
                      >
                        {locations?.map((answer, index1) => (
                          <MenuItem value={answer?.reference} key={index1}>
                            <div className="flex gap-2 items-center">
                              <img
                                src="/IconLocationGreen.svg"
                                alt=""
                                className={"w-5 object-contain"}
                              />
                              <span>{answer?.name}</span>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              )}

              <FormControlLabel
                value="meetingpoint"
                control={<Radio />}
                label="I’ll make my own way to the meeting point"
              />

              {meetingPoint && option === "meetingpoint" && (
                <div className="flex gap-2 items-center py-2 px-4">
                  <img
                    src="/IconLocationGreen.svg"
                    alt=""
                    className={"w-5 object-contain"}
                  />
                  <span>{meetingPoint?.name}</span>
                </div>
              )}
              {freeTextAllowed && (
                <>
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom location"
                  />
                  {option === "custom" && (
                    <div className={Classname.detailInput}>
                      <input
                        type="text"
                        className={Classname.detailInputBar}
                        value={question?.answer}
                        maxLength={question?.maxLength}
                        onChange={(e) => onChange(e.target.value)}
                      />
                    </div>
                  )}
                </>
              )}
              {later && (
                <FormControlLabel
                  value="later"
                  control={<Radio />}
                  label="I'll decide later"
                />
              )}
            </RadioGroup>
          </FormControl>
        ) : (
          <>
            {meetingPoint && (
              <div className="flex gap-2 items-center py-2 ">
                <img
                  src="/IconLocationGreen.svg"
                  alt=""
                  className={"w-5 object-contain"}
                />
                <span>{meetingPoint?.name}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BookingQuestionMeeetingPoint;

const Classname = {
  detailTitle: "text-xl font-bold",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
};
