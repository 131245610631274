import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button1 from '../../form/Button1';
import getPlans from '../../../controllers/subscription/getPlans';
import { useLocation, useNavigate } from 'react-router-dom';
import getAccount from '../../../controllers/user/getAccount';
import { setUserData } from '../../../redux/reducers/userSlice';
import { Summary } from './Sumaary';
import { Planner } from './Planner';
import cancelSubscription from '../../../controllers/subscription/cancelSubscription';
import { enqueueSnackbar } from 'notistack';
import Modal1 from '../../DIsplay/Modal/Modal1';
import LearnMoreButton from '../../mini/LearnMoreButton';
import LearnAboutSubscription from '../../DIsplay/LearnMore/LearnAboutSubscription';


export default function Subscription() {
  const [data,setData] = useState([])
  const location = useLocation();
  const searcParam = new URLSearchParams(location.search)
  const [plan,setPlan] = useState(searcParam.get('plan'))
  const success = searcParam.get('success')
  const trxref = searcParam.get('trxref')
  const onGuide = searcParam.get('guide')
  const noCheck = searcParam.get('noCheck')
  const [loading,setLoading] = useState(true);
  const { user,subscriptionPlan,...userData } = useSelector((state) => state.user.userData);
  const [subscribed,setSubscribed] = useState(subscriptionPlan?.plan?._id && subscriptionPlan?.plan?.mode !== 'FREE');
  const [edit,setEdit] = useState(false);

  const [openCancel,setOpenCancel] = useState(false);
  const [cancelLoading,setCancelLoading] = useState(false);

  const [view,setView] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(success && trxref && onGuide)
      navigate('/gettingStarted/congratulations')
    load();
  },[])

  async function load() {
    setLoading(true);
    const res = await getPlans();
    if(res.return) {
      setData(res.data?.data)
    }
    setTimeout(() => 
      setLoading(false)
    ,1000)
  }

  async function checkSubscription() {
    const res = await getAccount();
    if(res.return) {
      if(res?.data?.subscriptionPlan?.plan && (res?.data?.subscriptionPlan?.plan?.mode !== 'FREE')) {
        if(onGuide)
          navigate('/gettingStarted/congratulations')
        setSubscribed(true)
      }
      dispatch(setUserData({
        ...userData,
        user: res?.data?.account,
        subscriptionPlan: res?.data?.subscriptionPlan
      }))
    }

  }

  async function handleCancel() {
    setCancelLoading(true);
    const res = await cancelSubscription(subscriptionPlan?._id);
    setCancelLoading(false);
    if(res.return) {
        enqueueSnackbar('Subscription was canceled',{variant: 'success'})
        checkSubscription();
        setOpenCancel(false);
    } else enqueueSnackbar(res?.msg,{variant: 'error'})
  }
  
  const currentPlan = (data || [])?.find(obj => (obj?._id === subscriptionPlan?.plan?._id))
  const selected = (data || [])?.find(obj => obj._id === plan)
  console.log(subscribed?'subscribed':'not subscribed')
  
  return !subscribed && !view && !edit ? 
    <div className={`flex flex-col gap-4 flex-1 bg-emptypage justify-center `}>
      <div className={` text-center flex flex-col items-center self-center gap-8`}>
        <div>You are currently not subscribed to any plan</div>
        <div className='flex gap-2 flex-wrap justify-center'>
          <LearnMoreButton label='Learn about subscriptions'
            component={<LearnAboutSubscription callback={({setOpen}) => 
              <div className='flex justify-center py-4'>
                <Button1 className='!w-auto !px-10' onClick={() => setOpen && setOpen(false)}>Get Started</Button1>
              </div>
            }
            />}
          />
          <div className='flex-1'>
            <Button1 onClick={() => setView(true)}>Subscribe</Button1>
          </div>
          {/* <InviteTeam reload={load} label='Invite a team member' /> */}
        </div>
      </div>
    </div>
    : 
    !success || edit ? (
    <div className='flex-1 flex flex-col gap-4'>
      {!currentPlan ?
        <h5>Choose subscription plan</h5>
      :
        <h5>Manage subscription</h5>
      }
      <hr />
      {loading ? 
        <div className='flex items-center justify-center my-5'>
          <CircularProgress />
        </div>
      : (!currentPlan || edit) ? 
        <div className='flex flex-col items-start gap-4 min-h-full content-max-w'>
          <div className='flex flex-col gap-4 sticky bottom-0 min-w-[400px] z-20'>
            <div className='sticky top-0'>
              <Planner plans={data} plan={plan} setPlan={setPlan} user={user} subscriptionPlan={subscriptionPlan} />
            </div>
          </div>
          <div className='sticky bottom-0 z-10'>
            <div className='sticky top-0'>
              <Summary plans={data} plan={plan} callback={() => checkSubscription()} user={user} subscriptionPlan={subscriptionPlan} />
            </div>
          </div>
        </div>
      :
        <div className='flex flex-col '>
          {/* <div className='px-4 -translate-y-[4px]'>
              <small className={`p-2 bg-secondary  border border-b-0 border-primary/25 rounded-t-md`}>Current Plan</small>
          </div> */}
          <div className='border border-primary/25'>
              {currentPlan ? 
                  <div className='flex gap-4 justify-between items-center flex-wrap bg-secondary p-4'>
                  <h5>{currentPlan?.name}</h5>
                  <div className='flex gap-3'>
                    <Button1 variant={'text'} onClick={() => setEdit(true)}>Change</Button1>
                    <Button1 variant={'text'} onClick={() => setOpenCancel(true)}>Cancel</Button1>
                  </div>
                  </div>
              :null}
          </div>
          <Modal1 open={openCancel} setOpen={setOpenCancel}>
              <div className='p-8 flex flex-col gap-6'>
                  <h5>Are you sure you want to cancel subscription plan?</h5>
                  <div className='flex gap-4'>
                      <Button1 variant={'outlined'} onClick={() => setOpenCancel(false)}>No, Close</Button1>
                      <Button1 loading={cancelLoading} className='!bg-red-500 !text-white' onClick={handleCancel}>Yes, Cancel</Button1>
                  </div>
              </div>
          </Modal1>
      </div>
      }
    </div>
  ) : success ? (
    <div className='flex-1 flex flex-col gap-4'>
      <h5>Subscription was Successful</h5>
      <div>Your Subscribtion for a {selected?.name} plan is Successful.</div>
      <hr />
      <div className='flex gap-4 self-start whitespace-nowrap'>
        <Button1 variant='outlined' onClick={() => setEdit(true)}>Change Plan</Button1>
        <Button1 to='/'>Continue</Button1>
      </div>
    </div>
  ) : null
}


