import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightCompareModal } from "../../redux/reducers/modalSlice";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const flights = [
  {
    date: "20-07-2024",
    route: "LOS - CDG",
    image: "/IconTurkish.svg",
    depTime: "12:25",
    stops: ["ADD", "CDG"],
    layoverTime: "4h 1m",
    arrivalTime: "12:25",
    addition: "+ 1 day",
    carryOn: true,
    bags: "2PC",
    price: "N3,000,000",
    cheapest: true,
  },
  {
    date: "20-07-2024",
    route: "LOS - CDG",
    image: "/IconTurkish.svg",
    depTime: "12:25",
    stops: ["ADD", "CDG"],
    layoverTime: "4h 1m",
    arrivalTime: "12:25",
    addition: "+ 1 day",
    carryOn: true,
    bags: "2PC",
    price: "N3,000,000",
  },
  {
    date: "20-07-2024",
    route: "LOS - CDG",
    image: "/IconTurkish.svg",
    depTime: "12:25",
    stops: ["ADD", "CDG"],
    layoverTime: "4h 1m",
    arrivalTime: "12:25",
    addition: "+ 1 day",
    carryOn: true,
    bags: "2PC",
    price: "N3,000,000",
  },
];

export default function FlightCompareModal() {
  const dispatch = useDispatch();
  const { flightCompareModal } = useSelector((state) => state.modal);

  const handleOpen = () => dispatch(setFlightCompareModal(true));
  const handleClose = () => dispatch(setFlightCompareModal(false));

  return (
    <div>
      <Modal
        open={flightCompareModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>Compare offers</span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className="flex flex-col w-full gap-2 bg-[#FFFBFB] overflow-x-scroll scroll-hide">
              {flights.map((flight, index) => (
                <Flight key={index} flight={flight} onSelect={handleClose} />
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Flight = ({ flight, onSelect }) => {
  const handleSelect = () => {};
  return (
    <div className="flex items-start bg-white px-4 gap-4 py-3 justify-between w-full flex-shrink-0">
      <div className="w-28 flex flex-col items-start flex-shrink-0">
        <span className={Classname.itemTitle}>{flight?.date}</span>
        <img src={flight?.image} alt="" className="h-12 object-contain" />
      </div>
      <div className="w-28 flex flex-col items-center flex-shrink-0">
        <span className={Classname.itemTitle}>Route</span>
        <span className="text-sm font-bold text-[#06E775] bg-[#06E775]/10 px-2 rounded-md">
          {flight?.route}
        </span>
      </div>
      <div className="w-28 flex flex-col items-start flex-shrink-0">
        <span className={Classname.itemTitle}>Dep time</span>
        <span className="text-sm">{flight?.depTime}</span>
      </div>
      <div className="w-28 flex flex-col items-center flex-shrink-0">
        <span className={Classname.itemTitle}>Stops</span>
        <span className="text-sm">{flight?.stops?.length || 0}</span>
        {flight?.stops?.length > 0 && (
          <div className="flex flex-col gap-1">
            {flight?.stops.map((stop, index) => (
              <span key={index} className="text-sm">
                {stop}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="w-28 flex flex-col items-start flex-shrink-0">
        <span className={Classname.itemTitle}>Layover time</span>
        <span className="text-sm">{flight?.layoverTime}</span>
      </div>
      <div className="w-28 flex flex-col items-start flex-shrink-0">
        <span className={Classname.itemTitle}>Arrival time</span>
        <span className="text-sm">
          {flight?.arrivalTime}{" "}
          <span className="text-primary1">{flight?.addition}</span>{" "}
        </span>
      </div>
      <div className="w-28 flex flex-col items-center flex-shrink-0">
        <span className={Classname.itemTitle}>Carry-on bag</span>
        <span className="text-sm">{flight?.carryOn ? "Yes" : "No"}</span>
      </div>
      <div className="w-28 flex flex-col items-center flex-shrink-0">
        <span className={Classname.itemTitle}>Bags</span>
        <span className="text-sm">{flight?.bags}</span>
      </div>
      <div className="w-28 flex flex-col items-center flex-shrink-0">
        <span className={Classname.itemTitle}>Price</span>
        <span className="text-sm">{flight?.price}</span>
      </div>
      <div className="w-28 flex flex-col items-center justify-center gap-2 h-full min-h-[80px] flex-shrink-0 pr-2 lg:pr-0">
        {flight?.cheapest && (
          <span className="text-sm font-bold text-[#0B662C] bg-[#0B662C]/10 px-2 rounded-md">
            Cheapest
          </span>
        )}
        <button
          className="w-full py-1 flex items-center justify-center rounded-md font-bold text-sm text-white bg-primary1"
          onClick={onSelect}
        >
          Select
        </button>
      </div>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main max-h-[80vh]",
  modalTitle: "text-xl lg:text-3xl font-bold text-center",
  itemTitle: "text-sm text-gray-400 mb-3",
};
