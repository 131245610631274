import React, {useEffect, useState} from 'react'
import { Icon } from '@iconify/react';
import { Popover } from '@mui/material';
import CustomTag from '../Utils/Tag';
import LondonImage from '../../../assets/images/london.png';
import QR from '../../../assets/images/QR.png';
import getBookings from '../../../controllers/booking/getBookings';
import { templateCustomerFlightOrderData } from '../../../data/order/customerOrdersData';
import Paginate from '../../DIsplay/Paginate';
import moment from 'moment';
import { getAgeType } from '../../../utils/booking/getAgeType';
import Button1 from '../../form/Button1';
import FlightPriceSummary from '../../flight/FlightPriceSummary';
import { getCurrencySymbol } from '../../../features/utils/countires';
import { formatMoney } from '../../../features/utils/formatMoney';
import { filter } from 'lodash';
import Modal1 from '../../DIsplay/Modal/Modal1';
import PaymentMethod from '../../flight/PaymentMethod';
import CancelOrder from '../flight/cancelOrder';
import { useSelector } from 'react-redux';
import getCustomerBookings from '../../../controllers/booking/customer/getBookings';


const FLightCard = ({data,handleSelect,reload}) => {
    // Menu Options - Make Payment, Cancel, Add Hotel, Add Tour, Add Insurance, Export Itinerary
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPayment,setOpenPayment] = useState(false);
    const [openCancellation,setOpenCancellation] = useState(false);
    console.log(data,'=')

    function action(val) {
        setAnchorEl(null);
        if(val === 'pay')
            setOpenPayment(true)
        else if(val === 'cancel')
            setOpenCancellation(data?.bookingData?._id);
    }
    

    return(
        <div className='lg:w-auto lg:flex gap-4 bg-white rounded-md hover:shadow-lg p-4'>
            {/* <div className='bg-[#BCC7D3] flex justify-center items-center w-full lg:w-56 h-56'>
                <Icon icon="ion:ticket-sharp" className='text-[120px] text-[#6D7C94]'/>
            </div> */}
            <div className='w-full py-3 p-3'>
                <div className='flex justify-between'>
                    <div className='flex gap-4'>
                        <div className='flex justify-between items-center text-sm'>
                            <span><span className='text-[#6D7C94]'>Order id:</span> {data.orderNumber}</span>
                        </div>
                        <div className='flex gap-2 h-6'>
                            {data?.bookingData?.status === 'Paid' ? 
                                <CustomTag color="#0B662C" text="Paid"/>
                            : data?.bookingData?.status === 'Booked' ?
                                <CustomTag color="#3B362C" text="Not Paid"/>
                            :
                                <CustomTag color="#aB662C" text="Cancelled"/>
                            }
                            {/* {data?.bookingData?.status} */}
                            {/* <CustomTag color="#2970F8" text="Flight"/> */}
                        </div>
                    </div>

                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                            className: "bg-[#F5F7F9]",
                        }}
                        transformOrigin={{
                            horizontal: 'right'
                        }}
                    >   
                        <div className='grid px-2 py-1 bg-[#F5F7F9] gap-1'>
                            {data?.bookingData?.status === 'Booked' ? 
                                <span
                                  onClick={() => action('pay')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <span>Make Payment</span>
                                </span>
                            : data.bookingData?.status === 'Paid' ? 
                                <span
                                  onClick={() => action('cancel')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <span>Cancel Booking</span>
                                </span>
                            :null}
                        </div>
                    </Popover>
                    <Icon icon="tabler:dots" className='text-xl cursor-pointer' onClick={(ev) => setTimeout(() => setAnchorEl(ev.target),500)}/>
                </div>
                <div className='flex justify-between  pt-4'>
                    <div className="flex flex-col gap-1 items-start">
                        <div className='flex items-center gap-2 flex-wrap'>
                            <span className="font-bold">{data.from.name}</span>
                            <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{data.from.iata}</span>
                        </div>
                        <span className={'hidden sm:inline-block'}>{data?.from?.airport}</span>
                    </div>
                    <div className='flex gap-1 items-center text-center'>
                        <Icon icon='mdi:clock-outline' className='text-primary/40' />
                        <div>
                            {data?.bookingData?.flights?.at(0)?.duration}
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 items-end'>
                        <div className='flex items-center justify-end gap-2 flex-wrap'>
                            <span className="font-bold">{data.to.name}</span>
                            <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{data.to.iata}</span>
                        </div>
                        <span className={'hidden sm:inline-block'}>{data.to.airport}</span>
                    </div>
                </div>
                <div className='flex justify-between flex-wrap items-center mt-4 text-sm'>
                    {/* <div className='grid'>
                        <span className='flex gap-2'>
                            <Icon icon="bx:bxs-calendar" className='text-[#6D7C94] text-lg'/>
                            <span className='text-[#6D7C94]'>Created on:</span>
                            {data.dateCreated}
                        </span>
                        <span className='flex gap-2'>
                            <Icon icon="bx:bxs-calendar" className='text-[#6D7C94] text-lg'/>
                            <span className='text-[#6D7C94]'>Activity date:</span>
                            {data.activityDate}
                        </span>
                    </div> */}
                    <div className='flex gap-4 justify-between items-center mt-4 text-sm font-BrFirmaBold text-[#6D7C94]'>
                        <span>{data.tripType}</span>
                        <span>{data.class}</span>
                    </div>
                    <div>
                        <button className='!bg-transparent !border !border-primary/10 rounded-md p-2 px-3 hover:shadow-inner' onClick={handleSelect}>View Details</button>
                    </div>
                </div>
            </div>
            <Modal1 open={openPayment} setOpen={setOpenPayment}>
                <PaymentMethod
                    callback={() => {
                        reload && reload();
                        setOpenPayment(false);
                    }}
                    flightBookingId={data?.bookingData?._id}
                    hide={["booklater"]}
                    expand
                 />
            </Modal1>
            <CancelOrder open={openCancellation} setOpen={setOpenCancellation} 
                callback={() => {
                    reload && reload();
                    setOpenCancellation(false);
                }}
            />
        </div>
    )
}

const TourCard = ({data}) =>{
    return(
        <div className='lg:w-auto lg:flex gap-4 bg-white rounded-md'>
            <div className='bg-[#BCC7D3] flex justify-center items-center w-full lg:w-56 h-56'>
                <img src={data.image} alt="London" className='w-full h-full object-cover'/>
            </div>
            <div className='w-full py-3 p-3'>
                <div className='flex justify-between'>
                    <div className='flex gap-2 h-6'>
                        <CustomTag color="#0B662C" text="Confirmed"/>
                        <CustomTag color="#2970F8" text="Flight"/>
                    </div>
                    <Icon icon="tabler:dots" className='text-xl'/>
                </div>
                <div className='flex justify-between items-center'>
                    <div className="flex gap-1 text-sm items-center font-bold mt-4">
                        {data.name}
                    </div>
                </div>
                <span className="flex gap-1 items-center text-sm mt-2">
                    <Icon icon="mdi:location" className="text-[#7CDEAC] text-xl"/>
                    <span className="text-sm text-[#6D7C94]">{data.location}</span>
                </span>
                <div className='flex justify-between items-center mt-4 text-sm'>
                    <span><span className='text-[#6D7C94]'>Order id:</span> {data.orderNumber}</span>
                </div>
                <div className='flex justify-between items-center mt-4 text-sm'>
                    <div className='grid'>
                        <span className='flex gap-2'>
                            <Icon icon="bx:bxs-calendar" className='text-[#6D7C94] text-lg'/>
                            <span className='text-[#6D7C94]'>Date created:</span>
                            {data.dateCreated}
                        </span>
                        <span className='flex gap-2'>
                            <Icon icon="bx:bxs-calendar" className='text-[#6D7C94] text-lg'/>
                            <span className='text-[#6D7C94]'>Activity date:</span>
                            {data.activityDate}
                        </span>
                    </div>
                    <span className='text-[#333333] font-BrFirmaBold cursor-pointer'>See details....</span>
                </div>
            </div>
        </div>
    )
}


const DetailBookingInfo = ({data}) => {
    const flights = data?.flightBooking?.at(0)?.flights;

    console.log(data);

    const formattedData = {
        grandTotal: data?.bookingData?.grandTotal,
        basePrice: data?.bookingData?.basePrice,
        currency: getCurrencySymbol(
          data?.bookingData?.currency,
        ),
        tax:
          data?.bookingData?.grandTotal -
          data?.bookingData?.basePrice,
        commission: data?.expectedCommission?.commission,
        ticketingFee: Number(data?.bookingData?.ticketingFee || data?.ticketingFee || 0),
        payable: data?.bookingData?.payable,
      };

    
    return (
        <div className='bg-white mt-8 p-3 w-full'>
            <div className='border-b border-[#EBEEF2] pb-4'>
                <div className='flex items-center w-full justify-between '>
                    <span className='font-bold '>Booking Information</span>
                    <span className='text-xs text-[#6D7C94]'>Last Synced: {data?.activityDate}</span>
                </div>
                <div className='flex gap-2 items-center mt-6'>
                    <img src={data?.bookingData?.flights?.at(0)?.airlineImage} alt="QR" className='w-8 h-8 object-cover'/>
                    <span className='text-[#6D7C94] text-sm'>{data?.bookingData?.flights?.at(0)?.airlineName}</span>
                </div>
                <div className='flex gap-4 justify-between'>
                    <div className='mt-6 font-bold'>
                        <div className='text-[#333333] text-sm'> Order ID </div>
                        <div className='text-[#6D7C94]'> {data?.bookingId} </div>
                    </div>
                    <div className='mt-6 font-bold'>
                        <div className='text-[#333333] text-sm'> Booking Date </div>
                        <div className='text-[#6D7C94]'> {moment(data?.bookingData?.bookingTime)?.format('ddd, MMM DD')}</div>
                    </div>
                </div>
                <div className='font-bold text-[#333333] mt-6'>
                    Status
                </div>
                <div className='flex mt-1 w-24 h-6'>
                    <CustomTag color="#074A28" text="Confirmed"/>
                </div>
                {/* <div className='font-bold text-[#333333] mt-6'>
                    Airline
                </div> */}
                <div className='mt-6'>
                    <div className='text-[#333333] text-sm font-bold'> Ticket Number </div>
                    <div className='text-[#6D7C94] cursor-pointer underline'> {data?.bookingData?.ticketNumber?.join(', ')} </div>
                </div>
                <div className='flex w-full justify-end'>
                    <span className='text-[#6D7C94] text-sm font-bold'>Export itinerary</span>
                </div>
            </div>

            {flights?.map((obj,i) => (
                <div key={i} className='mt-5 border-b border-[#EBEEF2] pb-4'>
                    <div className='flex w-full justify-between font-bold'>
                        <span>{(flights?.length - 1 === i) && data?.tripType === 'Return' ? 'Return' : 'Departure'}</span>
                        <span className='text-[#6D7C94]'>{obj?.departureDate}</span>
                    </div>
                    <div className='flex justify-end'>
                        <span className='text-[#6D7C94] text-sm'>{obj?.duration}</span>
                    </div>
                    <div className='mt-2 flex justify-center w-full'>
                        <div className="flex gap-1 text-sm items-center font-bold mt-4">
                            <span className="font-bold">{obj?.departureLocationName}</span>
                            <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{obj?.departureLocation}</span>
                            <span>to</span>
                            <span className="font-bold">{obj?.arrivalLocationName}</span>
                            <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{obj?.arrivalLocation}</span>
                        </div>
                    </div>
                    <div className='flex items-center w-full justify-between mt-4'>
                        <div className='flex gap-2 items-center'>
                            <img src={obj?.airlineImage} alt="QR" className='w-10 h-10 object-cover'/>
                            <span className='text-[#6D7C94]'>{obj?.airlineName}</span>
                        </div>
                        <span className='text-[#6D7C94] text-sm'>{obj?.cabinClass}</span>
                    </div>
                    <div className='mt-2 flex w-full gap-2 flex-wrap'>
                        <span className='text-[#6D7C94]'>{obj?.departureAirport}</span>
                        <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{obj?.departureLocation}</span>
                    </div>
                    <div>
                        <span className='text-sm font-bold'>{obj?.departureTime}</span>
                    </div>
                    <div className='flex justify-center gap-2 mt-4 text-sm'>
                        <CustomTag color="#0B662C" text="Day flight"/>
                        <div className='flex gap-1 text-[#333333] items-center'>
                            <Icon icon="mingcute:time-line" />
                            <span>{obj?.duration}</span>
                        </div>
                        <div className='flex gap-1 text-[#6A9BB4] items-center'>
                            <Icon icon="mdi:airplane" />
                            <span>{obj?.stops} stopovers</span>
                        </div>
                    </div>
                    <div className='mt-4 flex w-full gap-2 flex-wrap'>
                        <span className='text-[#6D7C94]'>{obj?.arrivalAirport}</span>
                        <span className="bg-[#E7E7E7] flex items-center justify-center h-7 px-1">{obj?.arrivalLocation}</span>
                    </div>
                    <span className='text-sm font-bold'>{obj?.arrivalTime}</span>
                </div>
            ))}

            {data?.bookingData?.travelers?.map((obj,i) => (
                <div className='mt-5 border-[#EBEEF2] pb-4' key={i}>
                    <div className='bg-[#333333] flex w-full justify-between text-white p-2 items-center'>
                        <div className='flex gap-2 items-center'>
                            <Icon icon="teenyicons:user-solid" />
                            <div>Traveler {i+1}</div>
                        </div>
                        <span className='capitalize'>{getAgeType(obj?.travelerType)}</span>
                    </div>
                    <div className='grid grid-cols-3 font-bold mt-2'>
                        <div className='place-self-start'>Name</div>
                        <div className='place-self-center'>Phone number</div>
                        <div className='place-self-end'>Gender</div>
                    </div>
                    <div className='grid grid-cols-3'>
                        <div className='place-self-start'>{obj?.firstName} {obj?.middleName} {obj?.lastName}</div>
                        <div className='place-self-center'>{obj?.phone}</div>
                        <div className='place-self-end'>{obj?.gender}</div>
                    </div>
                </div>
            ))}
            <div className='mt-5 border-[#EBEEF2] pb-4 flex flex-col gap-4'>
                <div className='font-bold'>Price Summary</div>
                <div>{data?.tripType} flight</div>
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-4 justify-between'>
                        <span>Fare: </span>
                        <span>{formatMoney(formattedData?.basePrice)}</span>
                    </div>
                    <div className='flex gap-4 justify-between'>
                        <span>Tax: </span>
                        <span>{formatMoney(formattedData?.tax)}</span>
                    </div>
                    <div className='flex gap-4 justify-between'>
                        <span>Ticketing Fee: </span>
                        <span>{formatMoney(formattedData?.ticketingFee)}</span>
                    </div>
                    <div className='flex gap-4 justify-between'>
                        <h5>Trip Total:</h5>
                        <h5>{formatMoney(formattedData?.grandTotal)}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Orders = () => {
    const {user} = useSelector(state => state.user.userData);
    const [curNav, setCurNav] = useState(1);
    const [selectedValue, setSelectedValue] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [flightData,setFlightData] = useState([])
    const [filter,setFilter] = useState('All');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        load();
    },[])
    
    async function load() {
        const res = await getCustomerBookings();
        console.log(res)
        let data = [];
        if (res.return) {
          res.data?.data?.map((obj) => {
            obj?.booking?.flightBooking?.map((flightObj) =>
              data.push({ ...(obj?.booking||{}), flightObj, type: "Flight" })
            );
            return true;
          });
          data = data?.map((obj) => templateCustomerFlightOrderData(obj));
        }

        setFlightData(data);
        // return data;
      }
    
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    const mockData = {
        'from': {
            'name': 'Lagos',
            'iata': 'LOS'
        },
        'to': {
            'name': 'Abuja',
            'iata': 'ABV'
        },
        "tripType": "One way",
        "class": "Economy",
        "orderNumber": "123456",
        "Airlines": "Air Peace",
        "dateCreated": "2023-10-10",
        "activityDate": "2023-10-10",
    }

    const mockData1 = {
        'name': "Catalina Island Day Trip from LAX area hotels with Undersea Adventure",
        'location': "London, United Kingdom",
        "tripType": "One way",
        "class": "Economy",
        "orderNumber": "123456",
        "Airlines": "Air Peace",
        "dateCreated": "2023-10-10",
        "activityDate": "2023-10-10",
        "image": LondonImage,
    }
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 h-full'>
            <div className='mt-8 col-span-1 lg:col-span-2'>
                <div className="flex flex-wrap justify-between items-center bg-white py-3 px-3">
                    <div className="flex gap-2">
                        <span onClick={()=>setCurNav(1)} className={`${curNav == 1? "text-[#333333]": "text-[#B6BDC9]"} text-md font-bold cursor-pointer`}>Flight</span> 
                        <span onClick={()=>setCurNav(2)} className={`${curNav == 2? "text-[#333333]": "text-[#B6BDC9]" } text-md font-bold cursor-pointer`}>Hotels</span>
                        <span onClick={()=>setCurNav(3)} className={`${curNav == 3? "text-[#333333]": "text-[#B6BDC9]" } text-md font-bold cursor-pointer`}>Tours</span>
                    </div>
                    <div className="flex gap-3 items-center flex-wrap relative">
                        <span>Export all itinerary</span>
                        <div aria-describedby={id} onClick={handleClick} className='flex items-center gap-1 font-bold'>
                            <span>Status</span>
                            <Icon icon="majesticons:chevron-down-line" className="text-[#BCC7D3] text-xl"/>
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                className: "bg-[#F5F7F9]",
                            }}
                            transformOrigin={{
                                horizontal: 'right'
                            }}
                        >   
                            <div className='grid px-2 py-1 bg-[#F5F7F9] gap-1'>
                                <span 
                                  onClick={() => setFilter('All')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <Icon icon="eva:done-all-fill" />
                                    <span>All</span>
                                </span>
                                <span 
                                  onClick={() => setFilter('Paid')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <Icon icon="ion:ticket" />
                                    <span>Paid</span>
                                </span>
                                {/* <span 
                                  onClick={() => setFilter('Used')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <Icon icon="bx:bxs-time-five" />
                                    <span>Used</span>
                                </span>
                                <span 
                                  onClick={() => setFilter('Frozen')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <Icon icon="game-icons:frozen-orb" />
                                    <span>Frozen</span>
                                </span> */}
                                <span 
                                  onClick={() => setFilter('Booked')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <Icon icon="material-symbols:pending-actions-rounded" />
                                    <span>Not Paid</span>
                                </span>
                                <span 
                                  onClick={() => setFilter('Canceled')}
                                  className='flex gap-1 items-center cursor-pointer px-2 py-1 hover:bg-white rounded-md'>
                                    <Icon icon="iconoir:cancel" />
                                    <span>Canceled</span>
                                </span>
                            </div>
                        </Popover>
                    </div>
                </div>
                {curNav === 1 ? 
                    <div >
                        <Paginate data={flightData?.filter(obj => filter === 'All' || obj?.bookingData?.status?.toLowerCase()?.includes(filter?.toLowerCase()))} render={(obj,i) =>
                            <FLightCard data={obj} key={i} handleSelect={() => setSelectedValue(obj)} />
                        }
                        className='mt-6 grid gap-4'
                        limit={10}
                        />
                    </div>
                :
                    <TourCard data={mockData1} />
                }
                </div>
            <div className='col-span-1 h-full relative min-h-screen flex'>
                <div className='sticky bottom-0 self-end'>
                    <DetailBookingInfo data={selectedValue || flightData?.at(0)} />
                </div>
            </div>
        </div>
    )
}

export default Orders