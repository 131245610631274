import React, { useState } from "react";
import GettingStartedStepTitle from "../../components/gettingStarted/GettingStartedStepTitle";
import GettingStartedVerticalLine from "../../components/gettingStarted/GettingStartedVerticalLine";
import GettingStartedCheckbox from "../../components/gettingStarted/GettingStartedCheckbox";
import GettingStartedVideo from "../../components/gettingStarted/GettingStartedVideo";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";
import { setGettingStartedLoadingModal } from "../../redux/reducers/modalSlice";
import { setUser } from "../../redux/reducers/userSlice";
import { useSnackbar } from "notistack";
import updateProfile from "../../controllers/user/updateProfile";
import { gettingStartedVideos } from "../../data/tour/gettingStartedData";

function GettingStartedLink() {
  const { user } = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [videoWatched, setVideoWatched] = useState(false);
  const [later, setLater] = useState(false);
  const [createLink, setCreateLink] = useState(false);
  const [name, setName] = useState(user?.detail?.agencyName || "");
  const [subdomain, setSubdomain] = useState(
    user?.detail?.agencyURL
      ? `${user?.detail?.agencyURL?.split(".")[1]}.${
          user?.detail?.agencyURL?.split(".")[2]
        }`
      : ""
  );

  const handleContinue = async () => {
    dispatch(setGettingStartedLoadingModal(true));
    const formData = new FormData();
    {
      name && formData.append("agencyName", name);
    }
    subdomain && formData.append("agencyURL", `${name}.${subdomain}`);
    formData.append("guideStep", "link");

    const res = await updateProfile(formData);
    dispatch(setGettingStartedLoadingModal(false));
    if (res.return) {
      navigate("/gettingStarted/subscription");
      if (res?.data) dispatch(setUser(res.data));
    } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center">
      <GettingStartedLoadingModal />
      <div className="flex w-full flex-col py-10 px-4 lg:px-10 flex-1 max-w-7xl">
        <span className="text-[#818083] text-sm lg:text-base">Step 4</span>
        <span className="text-lg lg:text-3xl my-2">Agency link</span>
        <span className="text-[#818083] text-sm lg:text-base">
          Create your own custom agency link
        </span>
        <GettingStartedStepTitle
          visible
          active={videoWatched}
          title={"How to create agency link"}
        />
        <div className="flex gap-6">
          <GettingStartedVerticalLine
            height="h-[320px]"
            active={videoWatched}
          />
          <div className="flex flex-1 flex-col">
            <GettingStartedVideo video={gettingStartedVideos.agencyLink} />
            <GettingStartedCheckbox
              text="Confirm if you have watched the training to continue."
              active={videoWatched}
              setActive={setVideoWatched}
            />
          </div>
        </div>
        {videoWatched && (
          <div className="uncollapse">
            <GettingStartedStepTitle
              visible
              active={later || name}
              title={"Create your agency link"}
            />
            <div className="flex gap-6">
              <GettingStartedVerticalLine height="h-[110px]" active={false} />
              <div className="flex flex-1 flex-col">
                <span className="text-[#818083] text-sm lg:text-base mb-2">
                  Get a personalized link to communicate and collaborate within
                  your team
                </span>
                {!createLink && (
                  <div>
                    {user?.detail?.agencyURL ? (
                      <div
                        className="flex gap-2 items-center my-2 cursor-pointer "
                        onClick={() => setCreateLink(true)}
                      >
                        <span className="text-sm text-primary1">
                          {user?.detail?.agencyURL}
                        </span>
                        <img
                          src="/IconEdit.svg"
                          alt=""
                          className="w-5 object-contain"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col lg:flex-row items-center gay-2 lg:gap-4">
                        <button
                          className="bg-primary1 rounded-md text-white text-sm lg:text-base font-bold px-6 lg:px-10 w-fit py-2 lg:py-3"
                          onClick={() => setCreateLink(true)}
                        >
                          Create agency link
                        </button>
                        <GettingStartedCheckbox
                          text="I will do later."
                          active={later}
                          setActive={setLater}
                        />
                      </div>
                    )}
                  </div>
                )}

                {createLink && (
                  <div className="w-full flex items-end gap-2 lg:gap-4 max-w-[600px]">
                    <input
                      type="text"
                      value={name}
                      placeholder="Choose a name e.g flyaway"
                      onChange={(e) => setName(e.target.value)}
                      className="py-3 rounded-md px-4 border border-gray-300 flex flex-1 w-full"
                    />
                    .
                    <FormControl className="flex flex-1 w-full h-12 pb-2">
                      <InputLabel>Select a domain</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subdomain}
                        label="Select a domain"
                        className="h-12 border-gray-300"
                        onChange={(e) => setSubdomain(e.target.value)}
                      >
                        <MenuItem value="mytravx.com">mytravx.com</MenuItem>
                        <MenuItem value="travx.agency">travx.agency</MenuItem>
                        <MenuItem value="travx.center">travx.center</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueActive={videoWatched && (later || (name && subdomain))}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedLink;
