export const stayPopularFilters = [
  "Breakfast included",
  "Hotel",
  "Air conditioned",
  "Fully refundable",
  "Airport road",
];

export const stayRatingFilters = [
  {
    label: "Any",
    value: "Any",
  },
  {
    label: "Wonderful 4+",
    value: "4 stars",
  },
  {
    label: "Good 3+",
    value: "3 stars",
  },
  {
    label: "Okay 2+",
    value: "2 stars",
  },
];

export const stayDayFilters = [
  {
    label: "M",
    value: "Monday",
  },
  {
    label: "T",
    value: "Tuesday",
  },
  {
    label: "W",
    value: "Wednesday",
  },
  {
    label: "T",
    value: "Thursday",
  },
  {
    label: "F",
    value: "Friday",
  },
  {
    label: "S",
    value: "Saturday",
  },
  {
    label: "S",
    value: "Sunday",
  },
];

export const stayPaymentTypeFilters = [
  {
    label: "Fully refundable",
    value: "fully",
  },
  {
    label: "Reserve now, pay later",
    value: "later",
  },
];

export const stayPropertyFilters = ["Hotel", "Apartment"];

export const stayAmenitiesFilters = [
  "Air conditioned",
  "Pool",
  "Parking",
  "WIFI",
  "Gym",
];

export const stayAmenitiesFiltersDetail = [
  { code: "2", title: "Air conditioned" },
  { code: "66", title: "Pool" },
  { code: "68", title: "Parking" },
  { code: "178", title: "WIFI" },
  { code: "35", title: "Gym" },
];
