import { Button, Menu, MenuItem, Popover, Radio } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { URLdecode } from "../../../utils/url";
import { formatDate } from "../../../utils/date";
import { useHotels } from "../../../hooks/useHotels";
import { useDispatch, useSelector } from "react-redux";
import {
  setHotelConfirmationPdfModal,
  setHotelConfirmationPdfModalEmail,
} from "../../../redux/reducers/modalSlice";
import HotelConfirmationPdfModal from "../../../components/modal/HotelConfirmationPdfModal";
import { useSnackbar } from "notistack";
import Loader from "../../../utils/loader";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../../../components/modal/LoadingModal";

function AccommodationConfirmationPage() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const { hotelConfirmationPdfModalSendingEmail } = useSelector(
    (state) => state.modal
  );
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const { getBooking, cancelAndRefund } = useHotels();

  const params = URLdecode();
  const rooms = params?.reservedRooms ? JSON.parse(params?.reservedRooms) : [];
  const images = params?.images ? JSON.parse(params?.images) : null;

  const [emailEnabled, setEmailEnabled] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [reload, setReload] = useState(false);

  const loadData = async () => {
    if (!params?.status) {
      await getBooking();
      setTimeout(() => {
        setReload(!reload);
      }, 1000);
    }
  };

  useEffect(() => {
    localStorage.setItem("hotelBookingId", params?.bookingId);
  }, []);

  useEffect(() => {
    loadData();
  }, [reload]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmail = () => {
    setEmailEnabled(true);
  };

  const handleExport = () => {
    dispatch(setHotelConfirmationPdfModal(true));
  };

  const handleShare = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return enqueueSnackbar("Email is required.", { variant: "warning" });
    }

    if (!emailPattern.test(email)) {
      return enqueueSnackbar("Invalid email format.", { variant: "warning" });
    }

    dispatch(setHotelConfirmationPdfModal(true));
    dispatch(setHotelConfirmationPdfModalEmail(email));
  };

  const handleCancel = async () => {
    handleClose();
    await cancelAndRefund();
  };

  const handleRefund = async () => {
    handleClose();
    await cancelAndRefund();
  };

  const handleTicket = () => {
    handleClose();
    handleScrollToBottom();
  };

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  function formatMonthDate(dateString) {
    const date = new Date(dateString);

    const options = { month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const cancellationPolicy = () => {
    let policies = [];
    let reservedRooms = params?.reservedRooms
      ? JSON.parse(params?.reservedRooms)
      : null;

    if (reservedRooms) {
      reservedRooms?.slice(0, 1).map((room, index) => {
        policies.push({
          title: "Cancellation Policy",
          detail: `Fully refundable till ${formatMonthDate(
            room?.lastCancellationDate
          )}`,
        });
      });
    }

    return policies;
  };

  const hotelPhone = () => {
    let value = "";
    let phoneNumbers = params?.phone ? JSON.parse(params?.phone) : null;
    if (phoneNumbers) {
      value = phoneNumbers[0]?.phoneNumber;
    }
    return value;
  };

  return (
    <div className={Classname.container}>
      <LoadingModal open={!params?.status} />
      <HotelConfirmationPdfModal />
      <div className={Classname.content}>
        {/* Page title */}
        <div className={Classname.titleContainer}>
          {`Stays > `} <span className={Classname.titleMain}>Confirmation</span>
        </div>
        <div className={Classname.detail}>
          <div className={Classname.tourDetail}>
            <div className={Classname.tourDetailTitleContainer}>
              <span className="font-bold">Stay details</span>
              <div className={Classname.tourDetailTitleButtonContainer}>
                <button
                  className={Classname.tourDetailTitleExportButton}
                  onClick={handleExport}
                >
                  Export itinerary
                </button>
                <div className="">
                  <button
                    className={Classname.tourDetailTitleExportButton}
                    onClick={handleClick}
                  >
                    Manage order
                  </button>
                  <Menu
                    id="basic-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleCancel}>Cancel order</MenuItem>
                    <MenuItem onClick={handleRefund}>Refund</MenuItem>
                    <MenuItem onClick={handleTicket}>Send ticket</MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
            <div className={Classname.tourNameContainer}>
              {images && (
                <div className={Classname.tourImageContainer}>
                  <img src={images[0]} alt="" className={Classname.tourImage} />
                </div>
              )}
              <div className={Classname.tourDetailContainer}>
                <span className={Classname.tourName}>{params?.name}</span>
                <div className={Classname.tourDetailLocationContainer}>
                  <img
                    src="/IconLocationGreen.svg"
                    alt=""
                    className={Classname.tourDetailLocationIcon}
                  />
                  <span>{params?.address}</span>
                </div>
                {hotelPhone() && (
                  <div
                    className={`${Classname.tourDetailLocationContainer} mt-2`}
                  >
                    <img
                      src="/IconPhone.svg"
                      alt=""
                      className={"w-4 object-contain ml-1"}
                    />
                    <span>{hotelPhone()}</span>
                  </div>
                )}
              </div>
            </div>
            <div className={Classname.dateContainer}>
              <div className={Classname.dateFigureContainer}>
                <div className={Classname.datePoint}></div>
                <div className={Classname.dateLine}></div>
                <div className={Classname.datePoint}></div>
              </div>
              <div className={Classname.dateDetailContainer}>
                <div className={Classname.dateDetailDate}>
                  Check-in date
                  <span className="font-bold">
                    {formatDate(params?.start_date)}
                  </span>{" "}
                </div>
                <div className={Classname.dateDetailDuration}>
                  Duration: {params?.nights} nights
                </div>
                <div className={Classname.dateDetailDate}>
                  Check-out date
                  <span className="font-bold">
                    {formatDate(params?.end_date)}
                  </span>{" "}
                </div>
              </div>
            </div>
            <div className={Classname.tagsContainer}>
              <div className={Classname.tag}>
                <img
                  src="/tourIconPerson.svg"
                  alt=""
                  className={Classname.tagIcon}
                />
                <span>{params?.travelerMix}</span>
              </div>
            </div>
            {/* <div className={Classname.orderTags}>
              <div className={Classname.orderTag}>
                <span className={Classname.orderTagTitle}>
                  Order change policy
                </span>
                <div className={Classname.orderTagDescription}>
                  <img
                    src="/IconCloseRed1.svg"
                    alt=""
                    className={Classname.orderTagIcon}
                  />
                  <span>This order is not changeable</span>
                </div>
              </div>
              <div className={Classname.orderTag}>
                <span className={Classname.orderTagTitle}>
                  Order refund policy
                </span>
                <div className={Classname.orderTagDescription}>
                  <img
                    src="/IconCheckmark1.svg"
                    alt=""
                    className={Classname.orderTagIcon}
                  />
                  <span>
                    This order is refundable up until the initial departure date
                  </span>
                </div>
              </div>
            </div> */}
            {params?.accommodationType && (
              <span className="font-bold text-xs mt-6">
                Accommodation type: {params?.accommodationType}
              </span>
            )}
            {params?.categoryType && (
              <span className="font-bold text-xs mt-6">
                Category type: {params?.categoryType}
              </span>
            )}
            <span className="font-bold text-lg mb-6 mt-10">Guest details</span>
            {rooms?.map((room, index) => (
              <div key={index} className="w-full flex flex-col">
                <div
                  className={`${Classname.tourNameContainer} p-4 bg-gray-100 mt-10 mb-4 rounded-lg`}
                >
                  <div className={Classname.tourDetailContainer}>
                    <span className="font-bold text-sm mb-1">
                      {index === 0 && "Holder name: "}
                      {room?.guests[0]?.title}{" "}
                      {room?.guests[0]?.firstName ||
                        room?.guests[0]?.first_name}{" "}
                      {room?.guests[0]?.lastName || room?.guests[0]?.last_name}
                    </span>
                    {/* <span className="font-bold text-sm mb-1">Room Info</span> */}
                    <span className="flex font-bold text-gray-600">
                      {room?.name}
                    </span>
                    <p>{room?.description}</p>
                    {room?.rateComments && (
                      <p className="mt-2">{room?.rateComments}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="w-full p-4 bg-green-200 rounded-md text-xs text-gray-600">
              <strong>Important remarks:</strong> Payable through{" "}
              <strong>
                {params?.supplier === "HotelBeds"
                  ? params?.hotelSupplier
                  : user?.detail?.agencyName}
              </strong>
              , acting as agent for the service operating company, details of
              which can be provided upon request.
              {params?.vat ? `VAT: ${params?.vat}` : ""} Reference:{" "}
              {params?.bookId}
            </div>
            {cancellationPolicy()?.map((policy, index) => (
              <div className={Classname.detailCTABenefit}>
                <span className={Classname.detailCTABenefitTitle}>
                  {policy?.title}
                </span>
                <div className={Classname.detailCTABenefitDescription}>
                  <img
                    src="/IconCheckmark1.svg"
                    alt=""
                    className={Classname.detailCTABenefitIcon}
                  />
                  <span>{policy?.detail}</span>
                </div>
              </div>
            ))}
            {emailEnabled ? (
              <div className="w-full flex flex-col">
                <div className={Classname.detailInputContainer}>
                  <span className={Classname.detailInputLabel}>
                    Enter order email*
                  </span>
                  <div className={Classname.detailInput}>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={Classname.detailInputBar}
                      placeholder="e.g xyz@gmail.coom"
                    />
                  </div>

                  {hotelConfirmationPdfModalSendingEmail ? (
                    <Loader loading />
                  ) : (
                    <button
                      className={Classname.detailCTAButton}
                      onClick={handleShare}
                    >
                      Share
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <button className={Classname.emailButton} onClick={handleEmail}>
                <img src="/IconMailIcon.svg" alt="" />
                <span>Share via email</span>
              </button>
            )}
          </div>

          <div className={Classname.information}>
            <span className={Classname.informationTitle}>
              Booking Information
            </span>
            <span className={Classname.informationDateContainer}>
              Order date:{" "}
              <strong className={Classname.informationDate}>
                {formatDate(params?.bookingDate)}
              </strong>{" "}
            </span>
            <span>Status</span>
            <div
              className={
                params?.status === "Paid" || params?.status === "Issued"
                  ? Classname.informationStatus
                  : Classname.cancelledStatus
              }
            >
              {params?.status}
            </div>
            {/* <div className={Classname.informationProviderContainer}>
              <div className={Classname.informationProviderColumn}>
                <span className={Classname.informationTitle}>Provider</span>
                <div className={Classname.informationProviderInfo}>
                  <img
                    src={placeholderImage}
                    alt=""
                    className={Classname.informationProviderImage}
                  />
                  <span>gb Travels</span>
                </div>
              </div>
              <div className={Classname.informationProviderColumn1}>
                <span className={Classname.informationTitle}>Issuing Date</span>
                <span className={Classname.informationProviderInfo}>
                  09/04/2023
                </span>
              </div>
            </div> */}
            <span className="mt-4">Order ID</span>
            <span className="font-bold">{params?.bookId}</span>
            {/* <span className="mt-4">Ticket Number</span>
            <span className={Classname.informationShow}>show</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationConfirmationPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",
  detail: "flex flex-col lg:flex-row w-full gap-10",
  tourDetail: "flex flex-col flex-1",
  tourDetailTitleContainer: "flex items-center justify-between w-full gap-8 ",
  tourDetailTitleButtonContainer: "gap-6 flex",
  tourDetailTitleExportButton:
    "h-12 px-4 rounded-md border-2 border-gray-300 font-bold mr-3",
  tourDetailTitleManageButton: "h-12 px-4 rounded-md bg-gray-300 font-bold",

  tourNameContainer:
    "flex flex-1 items-center mt-4 lg:mt-0 lg:gap-10 gap-2 pr-2 lg:pr-0",
  tourImageContainer:
    "flex flex-col lg:w-24 items-center gap-2 font-bold text-sm",
  tourImage: "w-full min-w-20 h-20 lg:h-24 object-cover",
  tourDetailContainer: "flex flex-col text-xs lg:text-md gap-3 lg:gap-0",
  tourName: "text-md lg:text-xl  font-bold",
  tourDetailLocationContainer: "gap-2 flex items-center",
  tourDetailLocationIcon: "",

  dateContainer: "flex w-full gap-4 mt-6",
  dateFigureContainer: "flex flex-col items-center py-1",
  datePoint: "w-3 h-3 rounded-full bg-primary1",
  dateLine: "border-r flex flex-1 border-primary1",
  dateDetailContainer: "flex flex-col gap-4",
  dateDetailDate: "flex items-center gap-2 lg:gap-4 text-sm lg:text-md ",
  dateDetailDuration: "text-xs lg:text-sm",

  tagsContainer: "flex items-center flex-wrap gap-4 mt-6",
  tag: "flex gap-2 items-center text-sm text-gray-500",
  tagIcon: "w-4 h-4 object-contain",

  orderTags: "flex flex-wrap w-full gap-6 mt-6 mb-10",
  orderTag: "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  orderTagTitle: "text-lg font-bold mb-1",
  orderTagDescription: "flex items-center gap-2 text-sm text-gray-600",
  orderTagIcon: "",

  touristPersonHeader:
    "flex flex-col lg:flex-row w-full justify-around lg:justify-between gap-4",
  touristPerson:
    "text-white bg-primary1 font-bold h-14 px-4 rounded-md flex items-center justify-center gap-2",
  touristPersonIcon: "",

  emailButton:
    "h-16 lg:h-24 w-full rounded-lg border-2 border-black text-black mt-5 lg:mt-10 lg:mb-20 flex items-center justify-center gap-2 font-bold",

  information:
    "flex flex-col flex-1 h-fit lg:max-w-md p-4 rounded-lg border border-gray-400 mb-10",
  informationTitle: "font-bold mt-2 mb-2",
  informationDateContainer: "flex gap-1 pb-3 border-b border-gray-300 mb-3",
  informationDate: "flex font-bold",
  informationStatus:
    "w-32 h-9 rounded-md border border-green-600 bg-green-600/10 text-green-600 flex items-center justify-center mt-2",
  cancelledStatus:
    "px-4 h-9 w-fit rounded-md border border-red-600 bg-red-600/10 text-red-600 flex items-center justify-center mt-2",
  informationProviderContainer: "flex flex-1 mt-2 mb-2 justify-between",
  informationProviderColumn: "flex flex-col",
  informationProviderColumn1: "flex flex-col items-end",
  informationProviderTitle: "",
  informationProviderInfo: "flex items-center gap-2 text-sm font-bold",
  informationProviderImage: "w-6 h-6 object-contain",
  informationShow: "underline text-primary1 underline-offset-2",
  belowSignin: "flex justify-around",

  detailInputContainer: "flex flex-col flex-1",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mt-4 mb-10",

  detailCTABenefit:
    "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2 mt-5",
  detailCTABenefitTitle: "text-lg font-bold mb-1",
  detailCTABenefitDescription: "flex items-center gap-2 text-sm text-gray-600",
  detailCTABenefitIcon: "",
};
