import React, { useEffect, useState } from "react";
import FilterCalendar from "../../components/form/FilterCalendar";
import moment from "moment";
import { useOverview } from "../../hooks/useOverview";
import { useSelector } from "react-redux";
import useDateFormat from "../../hooks/useDateFormat";

const EmptyList = ({ text }) => (
  <div className="flex flex-1 items-center justify-center text-center text-[#6D7C94]">
    {text}
  </div>
);

const Activities = ({ activities }) => {
  const { formatTime } = useDateFormat();
  const getName = (activity) => {
    let name = "";
    if (activity?.isStaff) {
      name = `${activity?.staff?.firstName} ${activity?.staff?.lastName}`;
    } else {
      name = `${activity?.account?.firstName} ${activity?.account?.lastName}`;
    }
    return name;
  };

  const BookedItem = ({ activity }) => {
    return <span className="text-[#0BCEB2]">BK389240</span>;
  };

  return (
    <div className="w-full flex flex-col gap-2 overflow-scroll">
      {activities?.map((activity, index) => (
        <div
          className="flex items-center gap-2 text-sm lg:text-base flex-wrap"
          key={index}
        >
          <img
            src="/IconClock1.svg"
            alt=""
            className="w-4 h-4 object-contain"
          />
          <span className="text-[#6D7C94] w-12">
            {formatTime(activity?.createdAt)}
          </span>
          <span className="text-[#818083] ">{getName(activity)}</span>
          <span>{activity?.details}</span>

          {/* <BookedItem activity={activity} /> */}
        </div>
      ))}
    </div>
  );
};

function Overview() {
  const { user } = useSelector((state) => state.user?.userData);
  const { recentActivities, teamActivities } = useSelector(
    (state) => state.overview
  );
  const { getRecentActivities, getTeamActivities } = useOverview();
  const [loadingRecent, setLoadingRecent] = useState(false);
  const [loadingTeam, setLoadingTeam] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    range: "week",
    date: moment().format("YYYY-MM-DD"),
  });

  const loadData = async () => {
    await getRecentActivities({ setLoading: setLoadingRecent });
    await getTeamActivities({ setLoading: setLoadingTeam });
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!user?.detail?.isVerified) return <></>;

  return (
    <div className="flex flex-1 flex-col ">
      {/* <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full my-4">
        <h5 className="font-main mb-4">Overview</h5>
      </div> */}
      {/* <div className="flex justify-end w-44 flex-0">
          <FilterCalendar
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            hideDropdown
          />
        </div> */}

      <div className="w-full flex flex-col lg:flex-row gap-3 lg:gap-6">
        <div className="flex flex-col gap-4 lg:flex-1 mb-6 bg-white py-6 px-2 lg:px-10 h-80">
          <div className="flex flex-wrapjustify-between">
            <h5 className="font-main">Recent activity</h5>
          </div>
          <Activities activities={recentActivities} />
        </div>
        <div className="flex flex-col gap-4 lg:flex-1 lg:max-w-[500px] mb-6 bg-white py-4 px-2 lg:px-10 h-80">
          <div className="flex flex-wrapjustify-between">
            <h5 className="font-main">Team</h5>
          </div>
          {teamActivities && teamActivities?.length === 0 ? (
            <EmptyList text="No team  activity recorded" />
          ) : (
            <Activities activities={teamActivities} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
