import React from 'react'
import { getsubDomain } from '../../../utils/getsubDomain';
import { useSelector } from 'react-redux';

export default function AboutHeader() {
  const {user,agent} = useSelector(state => state.user.userData);
	const agency = getsubDomain();
  const backgroundImage = agent?.detail?.crmData?.aboutUs?.backgroundImage || user?.detail?.crmData?.aboutUs?.backgroundImage;

  return (
    <div className="pd-10 flex items-center justify-center text-white relative min-h-[400px]">
        {backgroundImage ? 
          <img src={backgroundImage} className='absolute left-0 right-0 w-full h-full object-cover' />
        :null}
        <div className="absolute top-0 left-0 w-full h-full bg-black/25">
        </div>
        <h2 className="capitalize z-10">About {agency}</h2>
    </div>
  )
}
