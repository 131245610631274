import React, { useState } from 'react'
import moment from 'moment'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HomeCarousel from '../components/templates/layouts/HomeCarousel'
import Banner from '../components/templates/layouts/Banner'
import axios from 'axios'
import { MuiTelInput } from 'mui-tel-input'
import PhoneNumberInput from '../components/form/PhoneNumberInput'


export default function Test() {
  const dates = [
    "1/03/2012",
    "4/03/2012",
    "4/30/2012",
    "4/31/2012",
    "5/3/2012",
    "6/03/2012",
    "12/03/2012",
    "1/03/2013",
    "6/03/2013",
    "12/03/2013",
    "1/03/2014",
    "1/03/2022",
    "2/03/2022",
    "3/03/2022",
    "4/03/2022",
    "5/03/2022",
    "6/03/2022",
    "1/03/2023",
  ]

  console.log(Math.max(0,(moment().month() - moment('5/01/2022').month()) / 100))

  const usecases = [
    "weare.travx.agency",
    "weare.Travx.center",
    "weare.mytravx.com",
    "qa.mytravx.com",
    "weare.qa.mytravx.com",
    "weare.customer.qa.mytravx.com",
    "http://www.miles.onrender.com",
    "http://www.agency.miles.onrender.com",
    "http://miles.onrender.com",
    "http://agency.miles.onrender.com",
    "http://www.miles.app",
    "http://www.agency.miles.app",
    "http://miles.app",
    "http://agency.miles.app",
    "agency.miles.app",
    "www.agency.miles.app",
    "miles.app",
    "agency.localhost.et",
    "agency.localhost",
    "localhost.et",
    "localhost",
    "wetat.customer.localhost",
    "http://wetat.customer.intraverse.app"
  ]

  // Assuming you have the object as follows:
  const [file1,setFile1] = useState()
  const [file2,setFile2] = useState()

  function flattenObject(obj, prefix = '') {
    return Object.entries(obj).flatMap(([key, value]) => {
      const newPrefix = prefix ? `${prefix}[${key}]` : key;
  
      if (typeof value === 'object' && value !== null) {
        if (value instanceof File) {
          return [[newPrefix, value]];
        } else if (Array.isArray(value)) {
          return value.flatMap((item, index) => flattenObject(item, `${newPrefix}[${index}]`));
        } else {
          return flattenObject(value, newPrefix);
        }
      } else {
        return [[newPrefix, value]];
      }
    });
  }
  
  // Convert the nested object to a flat structure
  // Object.entries(obj).flatMap(([key, value]) => {
  //   if (Array.isArray(value)) {
  //     return value.flatMap((item, index) => {
  //       return Object.entries(item).map(([subKey, subValue]) => [`${key}[${index}][${subKey}]`, subValue]);
  //     });
  //   } else {
  //     return [[key, value]];
  //   }
  // });


  // Send the FormData object to the server using Axios
  async function postLogo() {
    const obj = {
      section: [
        {name: 'test', image: file1}, // 'file1' is a File object
        {name: 'test', image: file2}  // 'file2' is a File object
      ],
      otherField: {
        name: '',
        list: [
          {name: '', image: file2} // 'file3' is a File object
        ]
      }
    };

    console.log(obj)
  
    const flatFormData = flattenObject(obj)

    // Create a FormData object from the flat structure
    const formData = new FormData();
    flatFormData.forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios.post('/your-server-endpoint', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('Server response:', response.data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  const [phone,setPhone] = useState('')
  
  console.log(phone)
  
  return (
    <div className="flex flex-col gap-4 justify-between test w-full">
      <div className='relative test'>
        <HomeCarousel />

        <div className='max-w-[500px] z-20'>
          Explanation:

Import necessary modules:
React for components and state management.
Slider from react-slick for the carousel functionality.
Slick CSS and theme CSS for styling.
State for window dimensions:
windowDimensions state stores the current window width and height.
useEffect hook updates the state whenever the window resizes.
Slider settings:
dots: true enables navigation dots.
infinite: true allows looping through slides.
speed: 500 sets the slide transition speed.
slidesToShow: 1 displays one slide at a time.
slidesToScroll: 1 scrolls one slide at a time.
adaptiveHeight: true adjusts the slide height to fit the content.
Parent container styling:
width: '100%' and height: '100vh' make the container full width and full viewport height.
position: 'relative' is needed for positioning the slider within the container.
Slider content:
Each slide is wrapped in a div with a background color for demonstration.
The height: '100%' style ensures each slide fills the container's height.
Key points:

The adaptiveHeight setting in the slider settings is crucial for making each slide fill the container's height.
The parent container's position: 'relative' allows the slider to be positioned within it.
The useEffect hook ensures that the slider resizes correctly when the window dimensions change.
This code will create a full-screen carousel that adapts to the window size.
        </div>
      </div>
      {/* <MuiTelInput value={phone} onChange={(val)=>setPhone(val)} label="phone" defaultCountry="NG" className="col-span-1"/>
      <PhoneNumberInput value={phone} onChange={(val) => setPhone(val)} label='Phone' /> */}

      {/* <iframe src='https://my.journeyeasy.net/search/embed?refCode=MMR07T' className='h-[500px]'></iframe>
      <div className='absolute top-0 left-0 h-[500px] w-full -z-10'>
      </div> */}
        <input type='file' onChange={(ev) => setFile1(ev.target.files[0])} />
        <input type='file' onChange={(ev) => setFile2(ev.target.files[0])} />
        <input type='submit' onClick={postLogo} value='Submit' />

      <Banner {...{slidesToShow: 2}} />

      {/* {usecases.map((obj) => (
        <div>{obj} : {isCustomerSite(obj)}</div>
      ))} */}
      {/* <form>
    <input type="text" pattern="[A-Za-z]+" />
    <input type='submit' value='Submit' />
      </form> */}
    {/* <table border={1}>
      <thead>
        <td>Birth Date</td>
        <td>Age</td>
        <td>Infant</td>
        <td>Child</td>
        <td>Adult</td>
      </thead>
      {
        dates?.sort((a,b) => moment(b).diff(a)).map((obj) => {
          let infantValidate = validateAge(obj,'infant');
          let childValidate = validateAge(obj,'child');
          let adultValidate = validateAge(obj,'adult');
          return (
            <tbody>
              <td>
                {obj}
              </td>
              <td>
                {adultValidate[2]}
              </td>
              <td>
                {infantValidate[0] ? 'True': 'False'}
              </td>
              <td>
                {childValidate[0] ? 'True': 'False'}
              </td>
              <td>
                {adultValidate[0] ? 'True': 'False'}
              </td>
            </tbody>
        )})
      }
      </table> */}
    </div>
  )
}
