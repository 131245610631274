import React from 'react'
import Icon from '../../HOC/Icon';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function CartLink() {
    const state = useSelector(state => state.template)
    const carts = [];
    const flightCart = JSON.parse(window.localStorage.getItem('flightCart') || '[]')
    carts.push(...flightCart)
  return (
    <Link to='/cart' className='flex gap-1 items-center relative'>
        <div className='absolute -top-[5px] -right-[13px] flex items-center justify-center w-4 h-4 text-sm rounded-full p-2 font-bold text-white !bg-opacity-10' style={{backgroundColor: state.secondaryColor}}>
            {carts?.length || 0}
        </div>
        <Icon icon='mdi:cart' style={{color: state.secondaryColor}}/>
    </Link>
  )
}
