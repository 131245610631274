import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import CountriesInput from "../../../components/form/CountriesInput";

function TourManagementPackageLocationPage() {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");

  const onContinue = () => {
    navigate("/tourManagement/create/keyword");
  };

  const onSave = () => {};

  const OnCountryChange = (selected) => {
    setCountry(selected);
  };

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <div className="hidden lg:flex">
          <TourManagementSidebar selected={4} />
        </div>
        <div className={Classname.detail}>
          <span className={Classname.title}>Where will customers visit?</span>
          <p className={Classname.description}>
            List all the major cities, sites, and attractions that your
            customers will visit during your experience. Add as many relevant
            locations as possible.
          </p>
          <CountriesInput
            label={" "}
            value={country || ""}
            onChange={(val) => OnCountryChange(val)}
          />
          <span className="mt-10 text-gray-500">SUGGESTIONS</span>
          <div className={Classname.suggestionContainer}>
            <div className={Classname.suggestion}>Naples, Italy</div>
            <div className={Classname.suggestion}>Naples, Italy</div>
          </div>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageLocationPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  suggestionContainer: "flex gap-4 mt-4",
  suggestion:
    "text-primary1 text-sm border border-primary1 px-3 py-1 rounded-md",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
