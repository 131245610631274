import React from "react";

function WhiteLabelWaySection() {
  const items = [
    {
      title: "Subscribe",
      description: "Subscribe to the website plan on Intraverse.",
    },
    {
      title: "Customize",
      description: "Customize every content on your website to fit your brand.",
    },
    {
      title: "Start Selling",
      description: "Publish to your own domain and start selling instantly.",
    },
  ];
  return (
    <div className="flex flex-col w-full px-2 py-10 items-center self-center max-w-7xl">
      <span className="text-xl lg:text-3xl font-bold text-center mt-10 mb-10">
        The fastest way to sell travel
      </span>
      <div className="flex flex-col lg:flex-row justify-between w-full gap-5">
        {items?.map((item, index) => (
          <div
            className="flex flex-row lg:flex-col gap-3 items-center lg:items-start w-full lg:w-96"
            key={index}
          >
            <div className="w-12 flex-shrink-0 lg:w-24 h-12 lg:h-24 rounded-md flex items-center justify-center font-bold text txet-sm lg:text-lg bg-[#E7F6FF]">
              {index + 1}
            </div>
            <div className="flex flex-col lg:gap-1">
              <span className="font-bold text-base lg:text-lg">
                {item?.title}
              </span>
              <span className="text-sm lgg:text-base">{item?.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhiteLabelWaySection;
