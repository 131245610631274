export const pricingData = [
  {
    name: "Starter",
    description: "Pay as you go for starter businesses",
    price: "$5",
    per: "per user/month",
    paidin: "paid in Naira equivalent",
    keyFeatures: [
      "Sell flights, stays, tours & insurance",
      "Use own logo",
      "Price markups",
      "Airline commission sharing",
      "Revenue sharing from stays, tours, insurance and ancillaries",
      "Issue tickets with our authority",
      "Add ancillaries - bags and seats",
      "Branded email notifications",
      "2 staff users included",
    ],
  },
  {
    name: "Premium",
    description: "Enhance team productivity and coordination",
    price: "$20",
    per: "per business /month",
    paidin: "paid in local currency equivalent",
    keyFeatures: [
      "All starter features",
      "Use own PCC / Office ID",
      "Open PNR & Share",
      "Accept payment directly",
      "Self ticket re-issue, date change and ticket management",
      "Invoice with payment link",
      "Up-to 5 staff users included",
    ],
  },
  {
    name: "Ultimate",
    description:
      "Designed for travel businesses aiming to achieve rapid, confident growth.",
    price: "$50",
    per: "per business /month",
    paidin: "paid in Naira equivalent",
    keyFeatures: [
      "All premium features",
      "Own customer selling website",
      "Customers search and book flights, hotels & tours themselves",
      "Share PNR",
      "Issue own tickets if an IATA agent",
      "Up-to 10 staff users included",
      "API Integration on existing website",
      "$500 website setup fee",
    ],
  },
  {
    name: "Enterprise",
    description: "Get a full custom implementation done by our dedicate team.",
    price: "$50",
    per: "Fee",
    paidin: "Contact Us",
    keyFeatures: [
      "Everything in premium",
      "Enterprise-level pricing",
      "Volume discount",
      "Tailored monetisation strategy support",
      "Dedicated technical support",
      "Use your own IATA accreditation",
    ],
  },
];

export const pricingData1 = [
  {
    title: "Starter",
    monthlyPricing: "5",
    anualPricing: "55",
    description: "For starter travel businesses",
  },
  {
    title: "Premium",
    monthlyPricing: "20",
    anualPricing: "220",
    description: "Enhance team productivity and coordination",
  },
  {
    title: "Ultimate",
    monthlyPricing: "50",
    anualPricing: "550",
    description:
      "Designed for travel businesses aiming to achieve rapid, confident growth.",
  },
];

export const enterprisePricingData = {
  title: "Enterprise",
  description: "For enterprises who want to scale quickly with confidence",
  features: [
    "Discounts on high volume ",
    "Tailored solution ",
    "Unlimited users ",
    "Dedicated technical support ",
    "Use your own IATA accreditation",
  ],
};

export const pricingFeatures = [
  {
    title: "Sell flights, hotels, tours & insurance",
    starter: "+",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Use own logo",
    starter: "+",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Price Markups",
    starter: "+",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Airline commission sharing",
    starter: "+",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Revenue sharing from stays, tours, insurance and ancillaries",
    starter: "+",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "View Analytical reports",
    starter: "+",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Team",
    starter: "Single user",
    premium: "5 users",
    ultimate: "10 users",
  },
  {
    title: "Sell using your own office ID / PCC",
    starter: "-",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Self ticket re-issue, date change and ticket management",
    starter: "-",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Directly receive payments from your customers",
    starter: "-",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Invoice with payment link",
    starter: "-",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Flexi-payment option for customers",
    starter: "-",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Branded content and customized email notifications",
    starter: "-",
    premium: "+",
    ultimate: "+",
  },
  {
    title: "Own branded website for customers",
    starter: "-",
    premium: "-",
    ultimate: "+",
  },
  {
    title: "Customers search and book flights, hotels & tours themselves",
    starter: "-",
    premium: "-",
    ultimate: "+",
  },
  {
    title: "Customer website setup fee",
    starter: "-",
    premium: "-",
    ultimate: "$500 one-time",
  },
];
