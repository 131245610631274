
import FlightOfferFilter1 from "./OfferFilter1";
const OfferFilterList = ({ cats, orgi, data, setData, id}) => {
    switch(id){
        case "1":
            return <FlightOfferFilter1 cats={cats} orgi={orgi} data={data} setData={setData} />
        default:
            return <FlightOfferFilter1 cats={cats} orgi={orgi} data={data} setData={setData} />
    }
}

export default OfferFilterList;
