import React, { useEffect, useRef, useState } from "react";
import { formatDate as dateFormatting } from "../../utils/date";
import { useSelector } from "react-redux";
import { formatMoney } from "../../features/utils/formatMoney";
import { URLdecode, URLencode } from "../../utils/url";
import CalendarInput1 from "../form/CalendarInput1";
import useDateFormat from "../../hooks/useDateFormat";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useLoadLocalStorage from "../../hooks/useLoadLocalStorage";

function TourCTASection() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isPastDate, formatDate, capitalizeFirstLetter } = useDateFormat();

  const params = URLdecode();
  const [tour, setTour] = useState();
  useLoadLocalStorage(`tour ${params?.productCode}`, setTour);

  const [date, setDate] = useState(params?.date);
  const [adult, setAdult] = useState(
    parseInt(params?.adult) ||
      (tour?.bookingRequirements?.requiresAdultForBooking ? 1 : 0)
  );
  const [editDate, setEditDate] = useState(false);
  const [editTraveller, setEditTraveller] = useState(false);
  const [ageBands, setAgeBands] = useState(tour?.pricingInfo?.ageBands || []);
  const [disabledDays, setDisabledDays] = useState([]);
  const calendarRef = useRef([...Array(10)]);

  const paxMixDetail = () => {
    let detail = params?.paxMix ? JSON.parse(params?.paxMix) : null;

    if (!detail) {
      detail = tour?.pricingInfo?.ageBands;
      let numberOfTravellers = 0;

      detail = detail?.map((age) => {
        let travellers = 0;
        if (age?.ageBand === "ADULT") {
          travellers =
            tour?.bookingRequirements?.minTravelersPerBooking ||
            age?.minTravelersPerBooking ||
            1;
          numberOfTravellers +=
            tour?.bookingRequirements?.minTravelersPerBooking ||
            age?.minTravelersPerBooking ||
            1;
        }
        return {
          ageBand: age?.ageBand,
          startAge: age?.startAge,
          endAge: age?.endAge,
          numberOfTravelers: travellers || age?.minTravelersPerBooking || 0,
          max: age?.maxTravelersPerBooking,
          min: age?.minTravelersPerBooking,
        };
      });
      if (numberOfTravellers === 0) {
        if (detail && detail?.length > 0) {
          detail[0] = {
            ...detail[0],
            numberOfTravelers:
              tour?.bookingRequirements?.minTravelersPerBooking || 1,
          };
        }
      }
    }
    setAgeBands(detail);
  };

  const setupDisabledDays = () => {
    let days = tour?.availabilityInfo?.availabilityDays;
    let daysToDisable = [];
    if (days) {
      if (!days?.SUNDAY) {
        daysToDisable.push(0);
      }
      if (!days?.MONDAY) {
        daysToDisable.push(1);
      }
      if (!days?.TUESDAY) {
        daysToDisable.push(2);
      }
      if (!days?.WEDNESDAY) {
        daysToDisable.push(3);
      }
      if (!days?.THURSDAY) {
        daysToDisable.push(4);
      }
      if (!days?.FRIDAY) {
        daysToDisable.push(5);
      }
      if (!days?.SATURDAY) {
        daysToDisable.push(6);
      }
    }

    setDisabledDays(daysToDisable);
  };

  useEffect(() => {
    setupDisabledDays();
  }, [tour]);

  useEffect(() => {
    paxMixDetail();
  }, [params?.paxMix, tour]);

  if (!tour) return <></>;

  const travellerString = () => {
    let travellers = [];

    let detail = params?.paxMix ? JSON.parse(params?.paxMix) : ageBands;

    detail?.map((mix, index) => {
      if (mix?.numberOfTravelers > 0) {
        travellers.push(
          `${mix?.numberOfTravelers} ${capitalizeFirstLetter(mix?.ageBand)}`
        );
      }
    });

    if (travellers.length === 0) {
      return "No travellers";
    }

    if (travellers.length === 1) {
      return travellers[0];
    }

    return travellers.slice(0, -1).join(", ") + " and " + travellers.slice(-1);
  };

  const handleSubstractTraveler = (index) => {
    let tempAgeBands = [...ageBands];
    let ageBand = tempAgeBands[index];
    if (
      ageBand.numberOfTravelers > 0 &&
      ageBand.numberOfTravelers > ageBand.min
    ) {
      tempAgeBands[index] = {
        ...ageBand,
        numberOfTravelers: ageBand?.numberOfTravelers - 1,
      };
      setAgeBands(tempAgeBands);
    }
  };

  const handleAddTraveler = (index) => {
    let maxAmountOfTravelers =
      tour?.bookingRequirements?.maxTravelersPerBooking;
    let tempAgeBands = [...ageBands];
    let ageBand = tempAgeBands[index];
    let totalTravelers = 0;
    tempAgeBands?.map((band) => {
      if (band.numberOfTravelers) {
        totalTravelers += band.numberOfTravelers;
      }
    });
    if (
      totalTravelers < maxAmountOfTravelers &&
      ageBand.numberOfTravelers < ageBand.max
    ) {
      tempAgeBands[index] = {
        ...ageBand,
        numberOfTravelers: ageBand?.numberOfTravelers + 1,
      };
      setAgeBands(tempAgeBands);
    }
  };

  const handleUpdateSearch = () => {
    let totalTravelers = 0;
    ageBands?.map((band) => {
      if (band.numberOfTravelers) {
        totalTravelers += band.numberOfTravelers;
      }
    });
    if (isPastDate(date)) {
      enqueueSnackbar("Departure date can't be a past date", {
        variant: "error",
      });
      return;
    }
    if (tour.bookingRequirements?.requiresAdultForBooking) {
      let numberOfAdult = ageBands.find(
        (age) => age.ageBand === "ADULT"
      )?.numberOfTravelers;
      if (!numberOfAdult) {
        numberOfAdult = ageBands.find(
          (age) => age.ageBand === "TRAVELER"
        )?.numberOfTravelers;
      }
      if (!numberOfAdult) {
        numberOfAdult = ageBands.find(
          (age) => age.ageBand === "SENIOR"
        )?.numberOfTravelers;
      }
      if (numberOfAdult > 0) {
      } else {
        enqueueSnackbar("An adult is required to book this package", {
          variant: "error",
        });
        return;
      }
    }
    if (totalTravelers < tour.bookingRequirements.minTravelersPerBooking) {
      enqueueSnackbar(
        `At least ${tour.bookingRequirements.minTravelersPerBooking} traveler is required to book this package`,
        {
          variant: "error",
        }
      );
      return;
    }

    let parameters = {
      ...params,
      date: formatDate(date),
      paxMix: JSON.stringify(ageBands),
      check: true,
    };

    let url = `/tour/singleTour?${URLencode(parameters)}`;
    setEditDate(false);
    setEditTraveller(false);
    navigate(url);
  };

  return (
    <div className={Classname.detailCTAContainer}>
      {tour?.farePrice ? (
        <span className={Classname.detailCTAPriceContainer}>
          <strong className={Classname.detailCTAPrice}>
            From {formatMoney(tour?.farePrice)}
          </strong>
          <span>per person</span>
        </span>
      ) : (
        <></>
      )}
      <span className={Classname.detailCTASelectTitle}>
        Select date and Travelers
      </span>
      {/* Date selection */}
      <>
        {editDate ? (
          <div>
            <CalendarInput1
              ref={(el) => (calendarRef.current[0] = el)}
              className="w-full border border-primary/20 rounded-md p-3 py-4 mb-4"
              value={date || ""}
              onChange={(value) => {
                setDate(value?.start || value);
                if (calendarRef.current?.at(1)) {
                  calendarRef.current[1]?.toggle(
                    calendarRef.current[1]?.ref?.current
                  );
                }
                calendarRef.current[0]?.toggle();
              }}
              config={{ validDates: [new Date()] }}
              disabledDays={disabledDays}
            />
          </div>
        ) : (
          <div className={Classname.detailCTAEditSection}>
            <img
              src="/IconDate.svg"
              alt=""
              className={Classname.detailCTAEditIcon}
            />
            <span className={Classname.detailCTAEditText}>
              {dateFormatting(date)}
            </span>
            <img
              src="/IconEdit.svg"
              alt=""
              className={Classname.detailCTAEditEditingIcon}
              onClick={() => setEditDate(true)}
            />
          </div>
        )}
      </>
      {/* Travellers selection */}
      {tour?.pricingInfo?.ageBands &&
        tour?.pricingInfo?.ageBands?.length > 0 && (
          <>
            {editTraveller ? (
              <div className="w-full flex flex-col my-2">
                <span className="text-xs mb-2">
                  You can select up to{" "}
                  {tour?.bookingRequirements?.maxTravelersPerBooking} travellers
                  in total.{" "}
                </span>
                {ageBands?.map((ageBand, index) => (
                  <div className={Classname.travellerItem} key={index}>
                    <div className={Classname.travellerTypeContainer}>
                      <span className={Classname.travellerType}>
                        {capitalizeFirstLetter(ageBand?.ageBand)}
                      </span>
                      <span className={Classname.travellerAge}>
                        {ageBand?.startAge} to {ageBand?.endAge}
                      </span>
                    </div>
                    <div className={Classname.travellerChoiceContainer}>
                      <div
                        className={
                          ageBand.numberOfTravelers > (ageBand?.min || 0)
                            ? Classname.travellerButton
                            : Classname.travellerButtonGray
                        }
                        onClick={() => handleSubstractTraveler(index)}
                      >
                        -
                      </div>
                      <div className={Classname.travellerAmount}>
                        {ageBand?.numberOfTravelers}
                      </div>
                      <div
                        className={
                          ageBand.numberOfTravelers < ageBand?.max
                            ? Classname.travellerButton
                            : Classname.travellerButtonGray
                        }
                        onClick={() => handleAddTraveler(index)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={Classname.detailCTAEditSection}>
                <img
                  src="/IconPerson.svg"
                  alt=""
                  className={Classname.detailCTAEditIcon}
                />
                <span className={Classname.detailCTAEditText}>
                  {travellerString()}
                </span>
                <img
                  src="/IconEdit.svg"
                  alt=""
                  className={Classname.detailCTAEditEditingIcon}
                  onClick={() => setEditTraveller(true)}
                />
              </div>
            )}
          </>
        )}

      <button
        className={Classname.detailCTAButton}
        onClick={handleUpdateSearch}
      >
        Check availability
      </button>
      {tour?.cancellationPolicy && (
        <div className={Classname.detailCTABenefit}>
          <span className={Classname.detailCTABenefitTitle}>
            Cancellation policy
          </span>
          <div className={Classname.detailCTABenefitDescription}>
            <img
              src="/IconCheckmark1.svg"
              alt=""
              className={Classname.detailCTABenefitIcon}
            />
            <span>{tour?.cancellationPolicy?.description}</span>
          </div>
        </div>
      )}
      {/* <div className={Classname.detailCTABenefit}>
        <span className={Classname.detailCTABenefitTitle}>
          Pay nothing today
        </span>
        <div className={Classname.detailCTABenefitDescription}>
          <img
            src="/IconCheckmark1.svg"
            alt=""
            className={Classname.detailCTABenefitIcon}
          />
          <span>Book now and only pay 3 days before</span>
        </div>
      </div> */}
    </div>
  );
}

export default TourCTASection;

const Classname = {
  detailCTAContainer:
    "flex flex-col flex-1 lg:max-w-sm border-2 border-gray-300 p-4 h-fit rounded-lg",
  detailCTAtagsContainer: "flex flex-wrap gap-4 mb-4",
  detailCTAtag1:
    "text-sm font-semibold text-red-700 bg-red-600/20 px-2 py-1 rounded-md",
  detailCTAtag2:
    "text-sm font-semibold text-yellow-800 bg-yellow-600/20 px-2 py-1 rounded-md",
  detailCTAtag3:
    "text-sm font-semibold text-green-600 bg-green-600/20 px-2 py-1 rounded-md",
  detailCTATitleContainer:
    "flex items-center justify-between text-sm text-gray-600 mb-6",
  detailCTATitleProvider: "text-lg font-bold mt-4 text-black",

  detailCTABenefit:
    "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  detailCTABenefitTitle: "text-lg font-bold mb-1",
  detailCTABenefitDescription: "flex items-center gap-2 text-sm text-gray-600",
  detailCTABenefitIcon: "",
  detailCTAPriceContainer: "text-gray-600 flex flex-col mb-4",
  detailCTASelectTitle: "font-bold text-lg mb-2",
  detailCTAEditSection:
    "h-14 w-full border border-gray-400 rounded-md flex items-center px-4 gap-2 mb-3",
  detailCTAEditIcon: "",
  detailCTAEditEditingIcon: "cursor-pointer p-2",
  detailCTAEditText: "flex flex-1",
  detailCTAPrice: "font-bold text-xl text-black",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mb-4",
  mobileOverviewSeparator: "mb-2",

  travellerItem:
    "flex justify-between items-center bg-gray-50 rounded-md px-2 py-2 mb-2 border border-gray-100",
  travellerTypeContainer: "flex flex-col items-center",
  travellerType: "font-bold",
  travellerAge: "text-sm text-gray-500",
  travellerChoiceContainer: "flex items-center ",
  travellerButton:
    "cursor-pointer w-5 h-5 rounded-full flex items-center justify-center text-white bg-primary1",
  travellerButtonGray:
    "cursor-pointer w-5 h-5 rounded-full flex items-center justify-center text-white bg-gray-300",
  travellerAmount: "w-10 flex items-center justify-center font-bold",
};
