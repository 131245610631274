import React from "react";
import Button1 from "../../components/form/Button1";

function WhiteLabelHeroSection() {
  const handleTry = () => {};
  return (
    <div className="flex flex-col w-full bg-black h-fit lg:h-screen items-center justify-end px-2">
      <span className="font-bold font-main text-white text-lg lg:text-3xl max-w-4xl text-center mt-20 lg:mt-0">
        Start selling travel today on your own website
      </span>
      <span className="max-w-4xl text-sm lg:text-base text-[#6D7C94] mt-5 text-center">
        Customize your travel website, and start selling. Let’s get your
        business off the ground with our intuitive tools!
      </span>
      <div>
        <Button1 to='/website?try=true' className="!bg-primary1 !rounded-md !font-bold !text-sm !text-white !px-8 !py-3 !my-5">
          Try it now
        </Button1>
      </div>
      <img
        src="/WhiteLabelHero.png"
        alt=""
        className="w-full max-w-[800px] object-contain"
      />
    </div>
  );
}

export default WhiteLabelHeroSection;
