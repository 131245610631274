import { AccessTime, FlightOutlined } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import getFlightDuration from "../../features/flight/getFlightDuration";

export default function FlightMulticityDisplay({
  flight: data,
  body,
  segments,
}) {
  let flight = data;
  //test
  const departureDateTime = moment(
    `${flight?.departureDate} ${flight?.departureTime}`,
    "YYYY-MM-DD HH:mm"
  );
  const arrivalDateTime = moment(
    `${flight?.arrivalDate} ${flight?.arrivalTime}`,
    "YYYY-MM-DD HH:mm"
  );
  const duration = getFlightDuration(
    departureDateTime,
    arrivalDateTime,
    "short"
  );

  const airlines = segments
    ?.map((obj) =>
      obj?.flights?.map((flight) => ({
        name: flight?.carrierName,
        icon: flight?.carrierIcon,
      }))
    )
    ?.flat();
  let uniqueAirlines = airlines?.reduce((acc, curr) => {
    if (acc.findIndex((item) => item.name === curr.name) === -1) {
      acc.push(curr);
    }
    return acc;
  }, []);
  uniqueAirlines = uniqueAirlines?.filter(
    (obj) => obj.name !== flight?.flights[0].carrierName
  );

  return (
    flight &&
    flight?.flights[0] && (
      <div className="flex flex-1 flex-col px-4 pt-4 ">
        {Array(4)
          .fill("")
          ?.map((_, index) => (
            <div className="flex flex-col items-center" key={index}>
              {index !== 0 && (
                <div className="w-full lg:w-[80%] flex items-center">
                  <div className="flex flex-1 border-b"></div>
                  <span className="rounded-full px-3 py-2 text-xs font-bold bg-[#F0F0F0] text-gray-500">
                    Route {index + 1}
                  </span>
                  <div className="flex flex-1 border-b"></div>
                  <div className="flex lg:w-[200px] border-b"></div>
                </div>
              )}
              <div className="flex flex-col lg:flex-row w-full">
                <div className="flex flex-1 flex-col mt-4">
                  <div className="items-center flex gap-2 w-fit">
                    <p className="w-full text-sm text-[#818083]">
                      {flight?.flights[0].carrierName}
                    </p>
                    <div className="text-[10px] text-primary1 bg-primary1/10 px-2 py-1 rounded-md font-bold">
                      AMADEUS
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row w-full">
                    <div className="flex flex-1">
                      <div className="flex sm:flex-col items-center p-3 md:w-auto lg:h-32  text-center">
                        <div className="flex items-center w-14 lg:w-20 lg:justify-center">
                          <img
                            alt="airline"
                            src={flight?.flights[0].carrierIcon}
                            className=" h-14 lg:h-20 object-contain"
                          />
                        </div>
                        <div className="h-full flex-1 border-l hidden lg:flex"></div>
                      </div>

                      <div className="flex flex-1 mr-6">
                        {/* <p className=''>{flight?.flights[0].departureAirportName}</p> */}
                        <div className="flex flex-1 justify-between items-center relative gap-2 mt-4">
                          <div className="flex flex-col justify-center gap-1 h-full items-end text-xs text-end max-w-[200px]">
                            <div className="flex items-center">
                              <b>{flight?.departureTime}</b>
                            </div>
                            <span className="text-end hidden lg:flex font-bold">
                              {flight?.departureAirport}(
                              {flight?.departureLocation})
                            </span>{" "}
                            <span className="text-end flex lg:hidden">
                              {flight?.departureLocation}
                            </span>{" "}
                            <span className="hidden lg:flex">
                              {flight?.departureCity}
                            </span>
                          </div>
                          <div className="flex flex-1 items-center flex-col ">
                            <div className="flex flex-1 w-full items-center ">
                              <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
                              <div className="border-b flex-1 border-[#A4B1C1]"></div>
                              <div className="rounded-full bg-[#F0F0F0] px-2 py-1 flex items-center justify-center relative group">
                                <img
                                  src="/IconPlane.svg"
                                  alt=""
                                  className="w-3 object-contain gap-2"
                                />
                                <span className="text-xs text-primary1 ml-1">
                                  {flight?.numberOfStops || 0} stop
                                </span>
                                <div className=" absolute hidden group-hover:flex bg-primary1 py-1 px-3 text-white text-xs rounded-md text-center whitespace-nowrap bottom-8">
                                  3 hours 30 mins
                                </div>
                              </div>
                              <div className="border-b flex-1 border-[#A4B1C1]"></div>
                              <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
                            </div>
                            <small className="whitespace-nowrap py-1 flex lg:hidden items-center gap-1">
                              <AccessTime className="!w-4 !h-4" />
                              {duration}
                            </small>
                          </div>
                          <div className="flex flex-col justify-center gap-1 h-full items-start text-xs text-start max-w-[200px]">
                            <div className="flex items-center">
                              <b>{flight?.arrivalTime}</b>
                            </div>
                            <span className="text-start flex lg:hidden font-bolg">
                              {flight?.arrivalLocation}
                            </span>{" "}
                            <span className="text-start hidden lg:flex">
                              {flight?.arrivalAirport}({flight?.arrivalLocation}
                              )
                            </span>{" "}
                            <span className="hidden lg:flex">
                              {flight?.arrivalCity}
                            </span>
                          </div>
                        </div>
                      </div>
                      <small className="whitespace-nowrap py-1 hidden lg:flex items-center gap-1">
                        <AccessTime className="!w-4 !h-4" />
                        {duration}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row lg:flex-col lg:ml-4 mb-2 lg:p-2 px-4 gap-2 justify-end lg:justify-center items-end lg:items-center w-full lg:max-w-[200px] lg:flex-1 lg:border-l lg:border-dashed border-primary/10">
                  <div className="flex items-end justify-center">
                    <div className="flex items-center justify-center relative">
                      <img
                        src="/IconBag1.svg"
                        alt=""
                        className="h-6 object-contain"
                      />
                      <div className="absolute w-full h-full text-white text-[8px] items-center justify-center top-1 flex">
                        12KG
                      </div>
                    </div>
                    <div className="flex items-center justify-center relative group">
                      <img
                        src="/IconBag2.svg"
                        alt=""
                        className="h-10 object-contain"
                      />
                      <div className="absolute w-full h-full text-white text-[8px] items-end justify-center bottom-1 flex">
                        1PC
                      </div>
                      <div className=" absolute hidden group-hover:flex bg-primary1 py-1 px-3 text-white text-xs rounded-md text-center whitespace-nowrap bottom-8">
                        Checked bag
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  );
}
