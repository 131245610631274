// import { Dropdown } from '@mui/base/Dropdown';
// import { MenuButton } from '@mui/base/MenuButton';
// import { Menu } from '@mui/base/Menu';
// import { MenuItem } from '@mui/base/MenuItem';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const DropDownSelect = ({state, data, handleDataChange}) => {
//     return(
//         <div className='font-poppins'>
//             <Dropdown>
//                 <MenuButton className="rounded-md px-5 py-4 text-l cursor-pointer bg-white border-0 shadow-md flex gap-2 justify-center items-center">
//                     {state.label}
//                     <ArrowDropDownIcon />
//                 </MenuButton>
//                 <Menu 
//                 slots={{ listbox: 'li' }}
//                 className='list-none'
//                 >
//                     <div className='list-none flex flex-col gap-2 mt-2 bg-white rounded-md w-full px-4 py-3 shadow-md'>
//                         {data.map((item)=>(
//                             <MenuItem className='my-2 cursor-pointer'>
//                                 <div onClick={()=> handleDataChange(item)} >{item.label}</div>
//                             </MenuItem>
//                         ))}
//                     </div>
//                 </Menu>
//             </Dropdown>
//         </div>
//     )
// }
// export default DropDownSelect;


import { Select, MenuItem, Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';

const DropDownSelect = ({state, data, handleDataChange, className, ...props}) => {
    return(
        <div className='font-poppins'>
            <Select {...props}
                value={state.value}
                onChange={(e) => handleDataChange(data.find(item => item.value === e.target.value))}
                displayEmpty
                className={"rounded-md text-l !pr-2 cursor-pointer bg-white border-0 shadow-md flex gap-2 justify-center items-center "+className}
                IconComponent={() => <ArrowDropDownIcon className='!-translate-x-5' />}
            >
                <MenuItem value="" disabled>
                    {state.label}
                </MenuItem>
                {data.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};


export const DropDownSelect2 = ({state, data, handleDataChange}) => {
    const [anchorEl,setAnchorEl] = useState();

    function handleChange(item) {
        handleDataChange(item)
        setAnchorEl();
    }

    return(
        <div className='font-poppins'>
            <span
                value={state.value}
                onClick={(ev) => setAnchorEl(ev.currentTarget)}
                className="bg-transparent !cursor-pointer border-0 flex gap-2"
            >
                {state.label}
                <ArrowDropDownIcon />
            </span>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className='bg-secondary flex flex-col'>
                    {data.map((item) => (
                        <button key={item.value} value={item.value} className='p-4 py-2 text-start hover:bg-theme1/10'
                            onClick={() => handleChange(item)}
                        >
                            {item.label}
                        </button>
                    ))}
                </div>
            </Popover>
        </div>
    );
};

export default DropDownSelect;