import React from "react";
import { useNavigate } from "react-router-dom";

function GettingStartedFooter({
  onPrevious,
  onContinue,
  continueActive,
  previousActive = true,
  className,
  continueText,
  previousText,
}) {
  const navigate = useNavigate();

  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious();
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className={`w-full flex items-center justify-between border-t border-[#64647A]/10 pt-4 ${className}`}
    >
      <div>
        {previousActive && (
          <button
            onClick={handlePrevious}
            className="border border-[#64647A]/10 text-[#64647A] bg-[#FAFAFA] text-sm lg:text-base py-2 px-6 lg:px-10 rounded-lg"
          >
            {previousText || "Previous"}
          </button>
        )}
      </div>
      <div>
        <button
          onClick={() => {
            if (continueActive) {
              onContinue();
            }
          }}
          className={`border text-sm lg:text-base py-2 px-6 lg:px-10 rounded-lg ${
            continueActive
              ? "text-white bg-primary1"
              : "bg-[#6D7C94] text-[#E8F4FF]"
          }`}
        >
          {continueText || "Continue"}
        </button>
      </div>
    </div>
  );
}

export default GettingStartedFooter;
