import React, { useEffect, useState } from "react";
import Icon from "../../components/HOC/Icon";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import checkTaskComplete from "../../features/profile/checkTaskComplete";
import {
  setGettingStartedVideoModal,
  setGettingStartedVideoModalName,
  setGettingStartedVideoModalVideoId,
} from "../../redux/reducers/modalSlice";
import { getsubDomain } from "../../utils/getsubDomain";
import { gettingStartedVideos } from "../../data/tour/gettingStartedData";
import { setUserData } from "../../redux/reducers/userSlice";
import getAccount from "../../controllers/user/getAccount";

export const profileTasks = [
  {
    title: "Activate my business",
    description:
      "Submit your business details to start selling live travel products",
    link: "/welcome",
    video: {
      title: "How to activate my business",
      link: gettingStartedVideos.activateBusiness,
    },
    icon: <Icon icon="basil:image-outline" className="text-primary/50" />,
  },
  {
    title: "Choose a subscription plan",
    description: "Choose a plan that matches your business needs",
    video: {
      title: "How to choose a subscription plan",
      link: gettingStartedVideos.subscription,
    },
    link: "/settings/agency/subscription",
    icon: <Icon icon="mdi:invite" className="text-primary/50" />,
  },
  {
    title: "Add Company Logo",
    description: "Let your customers be able to identify you with your logo",
    video: {
      title: "How to add company logo",
      link: gettingStartedVideos.logo,
    },
    link: "/settings/agency/brand",
    icon: <Icon icon="basil:image-outline" className="text-primary/50" />,
  },
  {
    title: "Generate your agency link",
    description: "Generate a link to collaborate with your team",
    video: {
      title: "How to generate your agency link",
      link: gettingStartedVideos.agencyLink,
    },
    link: "/settings/agency/link",
    icon: <Icon icon="ep:discount" className="text-primary/50" />,
  },
  {
    title: "Setup markups",
    description: "Add markups or mark down on the travel products",
    video: {
      title: "How to set up markups",
      link: gettingStartedVideos.markup,
    },
    link: "/settings/order/",
    icon: <Icon icon="ep:discount" className="text-primary/50" />,
  },
  {
    title: "Invite team members",
    description: "Invite your staff or developer to your team ",
    video: {
      title: "How to invite team members",
      link: gettingStartedVideos.team,
    },
    link: "/settings/team",
    icon: <Icon icon="mdi:invite" className="text-primary/50" />,
  },
  {
    title: "Add your bank account",
    description: "Set up how and when you want to be paid",
    video: {
      title: "How to add your bank account",
      link: gettingStartedVideos.bank,
    },
    link: "/settings/finance/method",
    icon: <Icon icon="vaadin:cash" className="text-primary/50" />,
  },
  {
    title: "Setup your payment gateway",
    description:
      "Add your paystack details to receive instant payment from your customers",
    video: {
      title: "How to setup your payment gateway",
      link: gettingStartedVideos.gateway,
    },
    link: "/settings/finance/gateway",
    icon: (
      <Icon
        icon="fluent:collections-add-20-filled"
        className="text-primary/50"
      />
    ),
  },
];
export default function PendingTasks() {
  const {
    userData: { user },
    userData,
  } = useSelector((state) => state?.user);
  const [tasks, setTasks] = useState([]);
  const subdomain = getsubDomain();
  const [lockLoad,setLockLoad] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subdomain) load();
    //eslint-disable-next-line
  }, []);

  async function getAccountUpdate() {
    if(lockLoad)
      return false;
    setLockLoad(true);
    const res = await getAccount();
    if(res.return) {
      // if(JSON.stringify(res?.data?.account || {}) !== JSON.stringify(user||{})) {
        dispatch(setUserData({...userData,user: res?.data?.account}))
        load();
      // }
    }
    setLockLoad(false);
  }
  
  // useEffect(() => {
  //   const activateBusiness = tasks?.find(obj => obj.title === 'Activate my business')?.complete

  //   console.log(tasks)
  //   const t = !activateBusiness && setInterval(() => {
  //     getAccountUpdate()
  //   },4000)
    
  //   return () => t && clearInterval(t)

  //   //eslint-disable-next-line
  // },[tasks])
  async function load() {
    const res = await checkTaskComplete(user, userData);
    setTasks(res);
  }

  function handleWatch(task) {
    dispatch(setGettingStartedVideoModal(true));
    dispatch(setGettingStartedVideoModalVideoId(task?.video?.link));
    dispatch(setGettingStartedVideoModalName(task?.video?.title));
  }

  const TaskDisplay = ({ index, task }) => (
    <div
      className={
        "flex gap-4 justify-between items-center py-4 border-b " +
        (index === 0 ? "" : "opacity-50")
      }
    >
      <div className="self-start">{task?.icon}</div>
      <div className="flex-1">
        <b className="">{task?.title}</b>
        <div>{task?.description}</div>
        <button
          className="text-sm flex gap-2 items-center text-blue-600 my-2"
          onClick={() => handleWatch(task)}
        >
          <Icon icon="octicon:play-24" className="!w-4 !h-4" />
          Watch how
        </button>
      </div>
      <Link
        to={index === 0 && !task?.pending ? task?.link : "#"}
        className="flex gap-2 text-theme1"
      >
        <div className="">{task?.pending ? 
          <span className="flex flex-col items-end">
            {task?.pending || 'Pending'}
            <small
             onClick={() => getAccountUpdate()}
             className="flex gap-1 items-center"><Icon icon='mdi:reload' className={`!w-4 !h-4 ${lockLoad ? 'animate-spin':''}`} /> check status</small>
          </span>
        : task?.title}</div>
        {!task?.pending ? 
          <Icon icon="iconamoon:arrow-right-2-bold" />
        :null}
      </Link>
    </div>
  );
  // console.log(tasks?.filter(obj => !obj?.complete)?.length,tasks)
  return tasks?.filter((obj) => !obj?.complete)?.length ? (
    <div className="flex flex-1 flex-col ">
      {/* <h5 className="font-main mb-4">Next steps</h5> */}

      <div className="flex flex-col gap-4 w-full mb-6 bg-white py-10 px-2 lg:px-10">
        <div className="flex flex-wrap gap-4 justify-between">
          <div className="py-4">
            <h5 className="font-main">Onboarding</h5>
            <p>Complete these tasks to complete your registration process</p>
          </div>
          <div className="flex gap-4 py-4 self-start items-center">
            <div className="flex overflow-hidden rounded-full bg-primary/10 h-3 w-[200px]">
              {tasks
                ?.sort((a, b) => (a?.complete ? -1 : 1))
                .map((obj, i) =>
                  obj?.complete ? (
                    <div key={i} className="bg-theme1 flex-1"></div>
                  ) : (
                    <div key={i} className="flex-1"></div>
                  )
                )}
            </div>
            {tasks?.filter((obj) => obj?.complete)?.length} of {tasks?.length}
          </div>
        </div>
        {tasks
          ?.filter((obj) => !obj.complete)
          ?.map((task, i) => (
            <TaskDisplay index={i} key={i} task={task} />
          ))}
      </div>
    </div>
  ) : null;
}
