import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import RatingStars from "../common/RatingStars";
import { useTours } from "../../hooks/useTours";
import { Pagination } from "@mui/material";
import useDateFormat from "../../hooks/useDateFormat";
import Loader from "../../utils/loader";

function TourReview() {
  const { tour, tourAvailability } = useSelector((state) => state.tourData);
  const { getTourReviews } = useTours();
  const { formatDate2 } = useDateFormat();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const reviewRef = useRef(null);
  const [scrollUp, setScrollUp] = useState(false);

  const totalReviews = tour?.reviews?.totalReviews;
  const limit = 20;

  const loadData = async () => {
    const data = await getTourReviews(setLoading, limit, (page - 1) * limit);
    if (data) {
      setReviews(data);
    }
  };

  useEffect(() => {
    loadData();
  }, [tour, page]);

  useEffect(() => {
    if ((reviewRef.current, scrollUp)) {
      reviewRef.current?.scrollIntoView({});
      setScrollUp(false);
    }
  }, [reviews, scrollUp]);

  const handlePageChange = (e, p) => {
    if (loading) return;
    setPage(p);
    setScrollUp(true);
  };

  const stars = () => {
    let data = [];
    let reviews = tour?.reviews?.reviewCountTotals
      ? [...tour?.reviews?.reviewCountTotals]
      : [];
    const totalReviews = tour?.reviews?.totalReviews;

    if (totalReviews) {
      reviews.sort((a, b) => b.rating - a.rating);

      reviews = reviews.map((review) => {
        let percent = 0;
        if (review?.count) {
          percent = parseInt((review?.count / totalReviews) * 100);
        }
        return {
          label: `${review?.rating} star${review?.rating !== 1 ? "s" : ""}`,
          ...review,
          percent,
        };
      });

      data = reviews;
    }

    return data;
  };

  const rating = () => {
    let text = "";
    let averageRating = tour?.reviews?.combinedAverageRating;
    if (averageRating) {
      text = averageRating.toFixed(1);
    }
    return text;
  };

  return (
    <div className="flex w-full my-20 flex-col">
      <span className="font-bold text-2xl mb-2">Reviews</span>
      <div className="flex w-full gap-20" ref={reviewRef}>
        <div className="flex flex-col">
          {tour?.reviews?.combinedAverageRating && (
            <div className="flex items-center gap-4 my-2">
              <span className="font-bold text-3xl">{rating()}</span>
              <RatingStars rating={tour?.reviews?.combinedAverageRating} />
            </div>
          )}
          <span className="text-sm">
            {tour?.reviews?.totalReviews} Review
            {tour?.reviews?.totalReviews === 1 ? "" : "s"}{" "}
          </span>
        </div>
        <div className="flex flex-col flex-1 mb-10">
          <span className="text-sm mb-3">
            Total review count and overall rating
          </span>
          {stars()?.map((star, index) => (
            <div
              className="flex w-full gap-3 my-1 items-center justify-between text-primary1"
              key={index}
            >
              <span className="w-16">{star?.label}</span>
              <div className="flex flex-1 bg-gray-100 rounded-full overflow-hidden h-3">
                <div
                  className={`h-full rounded-full  bg-primary1`}
                  style={{ width: `${star?.percent || 0}%` }}
                ></div>
              </div>
              <span className="flex justify-end min-w-[40px] ">
                {star?.count}
              </span>
            </div>
          ))}
        </div>
      </div>
      {!loading && reviews && reviews?.length > 0 ? (
        <>
          {reviews?.map((review, index) => (
            <div key={index} className="w-full flex flex-col mb-10">
              <div className="w-full flex items-start gap-2">
                <div className="pt-1">
                  {review?.rating && (
                    <RatingStars size="small" rating={review?.rating} />
                  )}
                </div>
                <div className="flex flex-1 font-bold text-sm lg:text-base">
                  {review?.title}
                </div>
              </div>
              <span className="text-xs mb-2">
                {review?.userName}, {formatDate2(review?.publishedDate)}
              </span>
              <p className="text-sm lg:text-base">{review?.text}</p>
              <div className="w-full flex gap-2 flex-wrap mt-4">
                {review?.photosInfo &&
                  review?.photosInfo?.map((photo, idx) => (
                    <img
                      src={
                        photo?.photoVersions[photo?.photoVersions?.length - 1]
                          ?.url
                      }
                      alt=""
                      className="w-32 h-32 rounded-xl object-cover bg-gray-100"
                      key={idx}
                    />
                  ))}
              </div>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
      {loading && <Loader loading={loading} />}
      {totalReviews > 20 && (
        <div className="w-full flex items-center justify-center mb-10 mt-20">
          <Pagination
            count={Math.ceil(totalReviews / limit)}
            variant="outlined"
            shape="rounded"
            size="medium"
            page={page}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}

export default TourReview;
