import React, { useEffect, useState } from "react";
import PricingFeaturesSection from "./PricingFeaturesSection";
import {
  enterprisePricingData,
  pricingData1,
} from "../../data/tour/pricingData";
import getPlans from "../../controllers/subscription/getPlans";
import { useSelector } from "react-redux";
import { URLencode } from "../../utils/url";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../features/utils/formatMoney";

function PricingHeroSection1() {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const [type, setType] = useState("Monthly");
  const [userType, setUserType] = useState("business");
  const [pricingPlans, setPricingPlans] = useState([]);

  const subscription = userData?.subscriptionPlan;

  const loadData = async () => {
    const res = await getPlans();
    if (res.return) {
      setPricingPlans(res.data?.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSetType = (selected) => {
    setType(selected);
  };

  const handleSelectUserType = (selected) => {
    setUserType(selected);
  };

  const handleSelect = (plan) => {
    let parameters = {
      plan: plan,
    };

    navigate(`/settings/agency/subscription?${URLencode(parameters)}`);
  };

  const currentIndex = pricingPlans?.findIndex(pricing => subscription?.plan?._id === pricing?._id)

  return (
    <div className="flex flex-col w-full">
      <div className={ClassName.container}>
        <div className={ClassName.tagline} data-aos="fade-up">
          Pricing that scales with you.
        </div>
        <p className={ClassName.subTagline} data-aos="fade-up">
          Choose the plan that suites your business needs
        </p>
        {/* <div className="w-full max-w-[500px] flex my-4" data-aos="fade-up">
          <div
            className={`flex flex-1 items-center justify-center gap-3 cursor-pointer border rounded-l-lg h-11 ${
              userType === "business"
                ? "text-white border-primary1 bg-primary1"
                : "text-[#6D7C94] font-bold border-black/10"
            }`}
            onClick={() => handleSelectUserType("business")}
          >
            <img
              src={
                userType === "business"
                  ? "/IconTravelWhite.svg"
                  : "/IconTravel.svg"
              }
              alt=""
              className="w-6 h-6 object-contain"
            />
            <span>Travel Business</span>
          </div>
          <div
            className={`flex flex-1 items-center justify-center gap-3 cursor-pointer border rounded-r-lg h-11 ${
              userType === "individual"
                ? "text-white border-primary1 bg-primary1"
                : "text-[#6D7C94] font-bold border-black/10"
            }`}
            onClick={() => handleSelectUserType("individual")}
          >
            <img
              src={
                userType === "individual"
                  ? "/IconPerson1White.svg"
                  : "/IconPerson1.svg"
              }
              alt=""
              className="w-6 h-6 object-contain"
            />
            <span>Independents</span>
          </div>
        </div> */}

        {userType === "business" && (
          <div className={ClassName.buttons} data-aos="fade-up">
            <button
              className={`${ClassName.button} ${
                type === "Monthly"
                  ? ClassName.buttonActive
                  : ClassName.buttonInActive
              }`}
              onClick={() => handleSetType("Monthly")}
            >
              Monthly
            </button>
            <button
              className={`${ClassName.button} ${
                type === "Anually"
                  ? ClassName.buttonActive
                  : ClassName.buttonInActive
              }`}
              onClick={() => handleSetType("Anually")}
            >
              Annually
            </button>
          </div>
        )}
        <div className={ClassName.prices} data-aos="fade-up">
          {userType === "business" ? (
            <>
              {pricingPlans.map((pricing, index) => (
                <div key={index} className={ClassName.priceContainer}>
                  <span className={ClassName.priceTitle}>{pricing?.name}</span>
                  <span className="flex text-center items-center justify-center text-sm font-semibold  my-2">
                    {pricing.description}
                  </span>
                  <span className="text-sm flex items-start justify-center gap-1">
                    ₦
                    <span className={ClassName.pricePrice}>
                      {type === "Anually"
                        ? formatMoney(12 * pricing.price, " ")
                        : formatMoney(pricing?.price, " ")}
                    </span>
                  </span>
                  <span className="my-1 text-sm items-center justify-center text-center">
                    per user/{type === "Monthly" ? "month" : "year"}
                  </span>
                  <span className="my-1 text-sm items-center justify-center text-center">
                    paid in local currency
                  </span>
                  <div className="flex flex-col flex-1"></div>
                  {subscription?.isSubscriptionActive &&
                  subscription?.plan?._id === pricing?._id ? (
                    <button className={ClassName.priceButtonActive}>
                      Current plan
                    </button>
                  ) : (
                    <button
                      className={ClassName.priceButton}
                      onClick={() => handleSelect(pricing?._id)}
                    >
                      {subscription?.isSubscriptionActive
                        ? (index < currentIndex ? 'Downgrade plan' : "Upgrade plan")
                        : "Start with 30-day free trial"}
                    </button>
                  )}
                </div>
              ))}
              {/* <div className={ClassName.priceContainer}>
                <span className={ClassName.priceTitle}>
                  {enterprisePricingData.title}
                </span>
                <span className="flex text-center items-center justify-center text-sm font-semibold  my-2">
                  {enterprisePricingData.description}
                </span>
                <ul className="list-disc space-y-2 px-4">
                  {enterprisePricingData.features?.map((feature, index) => (
                    <li key={index} className="text-xs my-1">
                      {feature}
                    </li>
                  ))}
                </ul>
                <div className="flex flex-col flex-1"></div>
                <button
                  className={ClassName.priceButton}
                  onClick={() => handleSelect(enterprisePricingData?.title)}
                >
                  Start with 30-day free trial
                </button>
              </div> */}
            </>
          ) : (
            <div className={ClassName.priceContainer}>
              <span className={ClassName.priceTitle}>Pay as you go</span>
              <span className="flex text-center items-center justify-center text-sm font-semibold  my-2">
                Everything you need to start selling travel for individual users
              </span>
              <span className="text-sm flex items-start justify-center gap-1">
                $<span className={ClassName.pricePrice}>0</span>
              </span>
              <span className="my-1 text-sm items-center justify-center text-center">
                Zero upfront cost
              </span>
              <div className="flex flex-col flex-1"></div>
              <button
                className={ClassName.priceButton}
                onClick={() => handleSelect("Free")}
              >
                Get started
              </button>
            </div>
          )}
        </div>
      </div>
      {userType === "business" && <PricingFeaturesSection />}
    </div>
  );
}

export default PricingHeroSection1;

const ClassName = {
  container:
    "w-full min-h-screen flex flex-col items-center pt-20 bg-white relative overflow-hidden pb-20 px-basicx xl:px-0 font-main",
  tagline: "text-4xl sm:text-6xl font-semibold text-center max-w-4xl",
  subTagline: "text-center text-sm sm:text-base max-w-2xl mt-2 sm:mt-4",
  buttons: "flex items-center justify-center gap-2 sm:gap-4 mt-10 z-10",
  button:
    "h-12 sm:h-16 w-32 sm:w-48 flex items-center justify-center rounded-xl text-sm font-semibold ",
  buttonActive: "bg-black text-white",
  buttonInActive: "bg-[#F5F7F9] text-black/50",
  prices:
    "w-fit rounded-2xl p-3 md:p-6 bg-[#F5F7F9] max-w-7xl mt-10 gap-5 flex flex-col md:flex-row flex-wrap justify-between",
  priceContainer:
    "bg-gradient-to-b from-[#E5E5FE] to-[#CCE1FF] rounded-xl flex flex-col p-4 min-h-72 flex-1 lg:w-[300px] min-w-[300px] max-w-[500px] flex-shrink-0 ",
  priceTitle: "w-full text-center mt-2 mb-2 text-4xl font-bold",
  pricePrice: "text-black text-4xl text-center font-semibold mb-4",
  priceDescription: "text-tertiary",
  priceButton:
    "h-12 w-full rounded-lg  mt-4 flex items-center justify-center text-white font-semibold text-xs sm:text-sm bg-primary1 hover:bg-black transition-all duration-300",
  priceButtonActive:
    "h-12 w-full rounded-lg  mt-4 flex items-center justify-center text-white font-semibold text-xs sm:text-sm bg-gray-500 transition-all duration-300",
  priceSeparator: "flex flex-col flex-1",
};
