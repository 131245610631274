import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";

const Header4 = ({
  logo,
  primaryColor,
  secondaryColor,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const companyLogo = logo ? (
    <img src={logo} alt="Company Logo" className="w-14" />
  ) : (
    <div
      className={`bg-[#D9D9D9] p-2 py-3 font-extrabold w-36 flex justify-center text-${secondaryColor}`}
    >
      Company Logo
    </div>
  );

  return (
    <div className="overflow-x-auto bg-white shadow-md grid justify-between gap-4 grid-cols-2 md:grid-cols-4 font-poppins px-6 py-6 origin-top" >
      <div className="col-span-1 origin-left" >{companyLogo}</div>
      <div className="md:hidden col-span-1 flex items-center justify-end" >
        <MenuIcon onClick={toggleMenu} className="cursor-pointer" />
      </div>
      <div
        className={`col-span-2 md:col-span-3 flex flex-col md:flex-row items-center gap-10 ${
          menuVisible ? "block" : "hidden"
        } md:flex justify-between`}
        
      >
        <div
          className={`flex flex-col md:flex-row justify-between items-center gap-10`}
          style={{ color: primaryColor }}
        >
          <div className="flex flex-col md:flex-row gap-10 items-center">
            <a>Home</a>
            <a>About Us</a>
            <a>Contact Us</a>
          </div>
        </div>
        <div
          className={`flex flex-col md:flex-row gap-10 items-center cursor-pointer`}
          style={{ color: secondaryColor }}
        >
            <div className="flex flex-col md:flex-row gap-10 items-center">
            <a>FAQ</a>
          </div>
          <Button
            style={{ background: secondaryColor }}
            size="large"
            variant="contained"
          >
            {" "}
            Signup{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header4;
