import React from 'react'
import NameInput from '../../form/NameInput'
import EmailInput from '../../form/EmailInput'
import PhoneNumberInput from '../../form/PhoneNumberInput'
import { MenuItem, TextField } from '@mui/material'
import Button1 from '../../form/Button1'
import { useSelector } from 'react-redux'

export default function ContactForm() {
    const {user,agent} = useSelector(state => state.user.userData);
    const crmData = (agent || user)?.detail?.crmData;
    
  return (
    <div className="flex flex-col gap-5 max-w-[600px]" id="query">
        <h4>Send us your query</h4>
        <p>Drop your query in this contact form and we’ll be in touch with you shortly.</p>
        <form className="flex flex-wrap gap-5">
            <div className="flex-1 min-w-[40%]">
                <NameInput label='Name' />
            </div>
            <div className="flex-1 min-w-[40%]">
                <NameInput label='Sur Name' />
            </div>
            <div className="flex-1 min-w-[40%]">
                <EmailInput />
            </div>
            <div className="flex-1 min-w-[40%]">
                <PhoneNumberInput />
            </div>
            <div className="w-full flex flex-col gap-1">
                What can we help you with?
                <TextField select label='Please select a category' className="w-full">
                    {crmData?.contact?.categories?.map((val,i) => (
                        <MenuItem key={i}>{val}</MenuItem>
                    ))}
                    <MenuItem>Other</MenuItem>
                </TextField>
            </div>
            <div className="w-full flex flex-col gap-1">
                Comments
                <TextField multiline rows={4} 
                    placeholder="Please provide us with more details if possible"
                    />
            </div>
            <div className="flex justify-end w-full">
                <div>
                    <Button1>Send Message</Button1>
                </div>
            </div>
        </form>
    </div>
  )
}
