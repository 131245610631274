import React from "react";

function GettingStartedVideo({ video = "sY2SFVanH8o" }) {
  return (
    <div className="w-full lg:w-[500px] h-[270px] max-w-[500px]  bg-[#E6EFFF] rounded-lg p-1 cursor-pointer">
      <div className="w-full h-full rounded-lg bg-[#A2DDFF] flex items-center justify-center overflow-hidden">
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${video}?rel=0`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default GettingStartedVideo;
