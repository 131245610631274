export function getSupplierName(defName='') {
    let name = defName;
    switch(name) {
        case 'Intra1A':
            name = 'Amadeus-A1';
             break;
        case 'Intra2A':
            name = 'Amadeus-A2';
            break;
        case 'Intra3A':
            name = 'Amadeus-A3';
            break;
        case 'Intra1T':
            name = 'Travel Port';
            break;
        case 'Intra1S':
            name = 'Sabre';
            break;
        case 'Intra1FR':
            name = 'TravX';
            break;
        case 'Intra1K':
            name = 'TravX1';
            break;
        case 'Intra1PK':
            name = 'TravX2';
            break;
        case 'Intra1XA':
            name = 'TravX3';
            break;
        case 'Intra1MR':
            name = 'TravX4';
            break;
        default: ;
    }
    return name
}

export function isGDSSupplier(name) {
    const named = getSupplierName(name)

    return "Amadeus-A1 Amadeus-A2 Amadeus-A3 Travel Port Sabre"?.includes(named)
} 