import { MuiColorInput } from "mui-color-input";
import { useDispatch, useSelector } from "react-redux";
import { setPrimaryColor, setSecondaryColor } from "../../redux/reducers/template/templateSlice";


const AddColors = () => {
  const state = useSelector((state) => state.template);
  const dispatch = useDispatch();

  const handlePrimaryColor = (newValue) => {
    // setPrimaryColor(newValue);
    dispatch(setPrimaryColor(newValue))
  };
  const handleSecondaryColor = (newValue) => {
    // setSecondaryColor(newValue);
    dispatch(setSecondaryColor(newValue))
  };
  return (
    <div className="mt-10">
      <span className="font-poppins font-bold text-[#818083]">
        {" "}
        Add your colors
      </span>
      <div className="flex gap-4 mt-2 w-full justify-between">
        <div className="w-1/2">
          <p className="my-1 font-poppins text-[#818083] text-sm">
            Primary color
          </p>
          <MuiColorInput
            className="w-full"
            value={state.primaryColor}
            onChange={handlePrimaryColor}
            format="hex"
            size="small"
            adornmentPosition="end"
          />
        </div>
        <div className="w-1/2">
          <p className="my-1 font-poppins text-[#818083] text-sm">
            Secondary color
          </p>
          <MuiColorInput
            className="w-full"
            value={state.secondaryColor}
            onChange={handleSecondaryColor}
            format="hex"
            size="small"
            adornmentPosition="end"
          />
        </div>
      </div>
    </div>
  );
};
export default AddColors;
