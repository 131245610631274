import { useEffect, useRef } from "react";
import { isCustomerSite } from "../../../utils/getsubDomain"
import FooterList from "../footers/footerList";
import { useSelector } from "react-redux";

export default function Terms() {
	const { user, agent } = useSelector(state => state.user.userData)
	const crmData = (agent || user)?.detail?.crmData;

	const terms = JSON.parse(crmData?.terms || '{}')

	console.log(terms)
	const customer = isCustomerSite();
	const ref = useRef();

	useEffect(() => {
		ref.current?.scrollIntoView({ behavior: 'smooth' })
	}, [])

	return (
		<div ref={ref}>
			<div className="flex flex-col items-center px-10">
				<div className="pd-md max-w-full pb-10 w-full">
					<div>
						<p class="c20"><span class="c16 c22"></span></p>
					</div>
					<h5 class="c23 flex justify-center"><span class="c16 c7">{terms?.title || 'TERMS OF SERVICE'}</span></h5>
					<p class="c23"><span class="c7">&nbsp;</span></p>
					{/* <p class="c17"><span class="c9">Last updated:&nbsp;</span><span class="c0">02/22/2024</span></p> */}
					{/* <p class="c17"><span class="c14">&nbsp;</span></p> */}
					{terms?.sections?.map((obj, i) => (
						<div key={i} className="w-full">
							<b>{obj?.title}</b>
							<div dangerouslySetInnerHTML={{ __html: obj?.content }} />
						</div>
					))}
				</div>
			</div>
			<FooterList />
		</div>
	)
}