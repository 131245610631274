import React, { useEffect, useState } from "react";
import SearchInput from "../../components/form/SearchInput";
import Button1 from "../../components/form/Button1";
import CustomTable from "../../components/Table/CustomTable";
import { CloseOutlined } from "@mui/icons-material";
import { ProfilePicture } from "../../components/form/ProfilePicture";
import { Icon } from "@iconify/react";
import Modal1 from "../../components/DIsplay/Modal/Modal1";
import TextInput from "../../components/form/TextInput";
import EmailInput from "../../components/form/EmailInput";
import PhoneNumberInput from "../../components/form/PhoneNumberInput";
import SelectInput from "../../components/form/SelectInput";
import { MenuItem } from "@mui/material";
import getAccounts from "../../controllers/user/getAccounts";
import { enqueueSnackbar } from "notistack";
import getSavedTravelers from "../../controllers/Travelers/getSavedTravelers";
import moment from "moment";

const temp = [
  {
    status: "OK",
    name: "John Doe",
    email: "johndoe@gmail.com",
    phoneNumber: "+234-940067965",
    gender: "Male",
    dob: "23rd Feb,1999",
    nationality: "Nigerian",
    orders: 5,
    userId: "123",
    date: "7:30pm 24/04/2023",
    active: true,
  },
  {
    status: "SA",
    name: "Doe John",
    email: "doecho@gmail.com",
    phoneNumber: "+234-940067965",
    gender: "Male",
    dob: "23rd Feb,1999",
    nationality: "Nigerian",
    orders: 10,
    userId: "123",
    date: "7:30pm 24/04/2023",
    active: false,
  },
];
function UserManagement() {
  const [selected, setSelected] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, []);

  async function load() {
    setLoading(true);
    const res = await getSavedTravelers()
    // const res = await getAccounts();
    setLoading(false);
    if (res.return) {
      console.log(res.data?.data);
      let dataMod = res.data?.data.map((data) => ({
        ...data,
        name: `${data.firstName} ${data.lastName}`,
        documentId: data?.document?.number,
        nationality: data?.document?.nationality
      }));
      setData(dataMod || []);
    } else enqueueSnackbar("Failed fetching users!", { variant: "error" });
  }
  const columns = [
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    { field: "gender", headerName: "Gender" },
    { field: "travelerType", headerName: "Type" },
    { field: "birthDate", headerName: "Birth Date" },
    { field: "documentId", headerName: "Document Id" },
    { field: "nationality", headerName: "Nationality" },
    { field: "createdAt", headerName: "Date Registered",
      renderCell: (params) => params?.value && moment(params.value)?.format('DD/MM/YYYY HH:mm A')
    },
  ];

  function handleRowSelect(rows) {
    if (rows.length)
      setSelected(
        data.find((obj, i) => {
          return obj.id === rows[0];
        }),
      );
  }

  return (
    <div className="pd-md flex flex-wrap-reverse gap-4">
      <div className="flex flex-1 max-w-full flex-col gap-5 ">
        <div className="flex flex-col gap-4">
          <h5>Customers</h5>
          {/* <div className="flex gap-2 items-center">
            <SearchInput />
          </div> */}
        </div>
        <CustomTable
          searchProps={{
            searchable: true
          }}
          loading={loading}
          columns={columns}
          rows={data}
          className="min-w-[555px]"
          onRowSelectionModelChange={(val) => handleRowSelect(val)}
        />
      </div>
      {/* <div className="flex justify-center">
        {selected ? (
          <Detail data={selected} close={() => setSelected()} />
        ) : null}
      </div> */}
    </div>
  );
}

const Row = ({ name, value }) => (
  <div className="flex gap-2">
    <p className="w-[30%]">{name}</p>
    <b className="w-[68%]">{value}</b>
  </div>
);



export default React.memo(UserManagement);
