import React, { useEffect, useRef, useState } from 'react'
import TextInput from '../../../form/TextInput';
import Button1 from '../../../form/Button1';


export default function ValuesForm({obj,handleChange}) {
    const [data,setData] = useState({title:'',description: ''});
    const inputRefs = useRef([]);
    
    useEffect(() => {
        setData(typeof(obj) !== 'boolean' ? obj : {title:'',description: ''})
    },[obj])

    // useEffect(() => {
    //     inputRefs?.current?.map(elem => elem.addEventListener('blur',save(data)))
    // },[inputRefs?.current?.length])

    function save() {
        // if (inputRefs.current?.every(elem => !elem?.contains(document.activeElement))) {
            handleChange && handleChange(data);
        // }
    }
    
    return (
        <div className='border bg-secondary relative flex flex-col'>
            {/* <div className='self-end m-2 p-1 hover:shadow border' onClick={() => remove && remove()}>
                <Icon icon='mdi:delete' className='text-red-600 !w-4 !h-4' />
            </div> */}
            <div className='flex flex-col gap-4 p-4 '>
                <div className='flex flex-col gap-4 '>
                    <TextInput label='Title' value={data?.title} onChange={(ev) => setData({...data,title: ev.target.value})} 
                        inputRef={(ref) => inputRefs.current[0] = ref}
                    />
                    <TextInput label='Description' multiline rows={4}
                        inputRef={(ref) => inputRefs.current[1] = ref}
                        value={data?.description} onChange={(ev) => setData({...data,description: ev.target.value})} 
                        />
                </div>
                <div>
                    <Button1 onClick={save}>Save</Button1>
                </div>
            </div>
        </div>
    )
}