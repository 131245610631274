import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getFlightOfferPrice from "../../../../controllers/Flight/getOfferPrice";
import getFlightOffers from "../../../../controllers/Flight/getFlightOffers";
import { setBookingData } from "../../../../redux/reducers/flight/flightBookingSlice";
import autoRebook from "../../../../controllers/booking/autoRebook";
import Modal1 from "../../../../components/DIsplay/Modal/Modal1";
import { CircularProgress } from "@mui/material";
import Button1 from "../../../../components/form/Button1";
import { offerSearchTemp } from '../../../../data/flight/offerSearchData'
import Icon from "../../../../components/HOC/Icon";
import { encrypt } from "../../../../features/utils/crypto";
import convertFlightObject from "../../../../features/utils/flight/flightOfferObj";
import { formatMoney, getNumber } from "../../../../features/utils/formatMoney";


export function StatusCard({data:defData,pay,cancel,hold,issueTicket,ticketData,reload,setNewStatus}) {
    const [data,setData] = useState({
      ...defData,
      status: defData?.status,
      time: defData?.ticketingExpiry
    })
    const [timeExp,setTimeExp] = useState('- - -');
    const [rebooking,setRebooking] = useState(false);
    const [loading,setLoading] = useState({rebook:false})
  
    const {bookingData} = useSelector(state => state.flightBooking);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [priceChanged,setPriceChanged] = useState(false);
  
    console.log(data)
    console.log(moment('2024-06-11T05:47:27.225Z').utc().format('YYYY-MM-DD HH:mm:ss.SSS'),moment().format('YYYY-MM-DD HH:mm:ss.SSS'))
    useEffect(() => {
      if(data?.status === 'Booked' || data?.status === 'Paid') {
        const t = setInterval(() => {
          const diffInSeconds = moment.utc(data?.time).diff(moment.utc(), 'seconds');
          
          // Format the difference in the "5h 10m 5s" format
          const hours = Math.floor(diffInSeconds / 3600);
          const minutes = Math.floor((diffInSeconds % 3600) / 60);
          const seconds = diffInSeconds % 60;
  
          setTimeExp(`${hours}h ${minutes}m ${seconds}s`)
          if(hours + minutes + seconds < 0) {
            clearInterval(t)
            setData({...data,status: data?.status === 'Paid' ? 'Expired' : 'ExpiredBeforePayment'})
            setNewStatus && setNewStatus('Expired')
          }
        },1000)
      }
    },[])
  
    async function getPrice(obj) {
      const req = {
        supplier: 'Intra1',
        offers: defData?.flights
      }
      // if(qObj.adjustment)
      //   req['adjustment'] = qObj.adjustment;
      
      // setLoading(true);
      const res = await getFlightOfferPrice(req);
      // setLoading(false);
      if(res.return) {
        
      } 
      // else setOffer(null)
    }
  
    async function search(obj) {
      const newRes = await getFlightOffers(obj,null);
      return newRes;
    }
    
    function rebook() {
      const searchObj = {...offerSearchTemp,
        originDestinations: data?.destinations?.map(obj => ({
          from: obj?.from,
          to: obj?.to,
          departure: {
            date: obj?.departureDate
          },
          date: obj?.departureDate
        })),
        destinations: data?.destinations?.map(obj => ({
          departureLocation: obj?.from,
          arrivalLocation: obj?.to,
          date: obj?.departureDate
        })),
        passengers: {
          adult: data?.adult?.passengerCount || undefined,
          child: data?.child?.passengerCount || undefined,
          infant: data?.infant?.passengerCount || undefined,
        },
        cabinClass: ['Economy'],
        // cabinClass: [...new Set(data?.flights?.map(obj => convertCabinName(obj?.cabinClass)))],
        flightFilters: {
          "allowedCarriers": [
            ...new Set(data?.flights?.map(obj => obj?.airline))
          ]
        }
      };
      
      // search(obj)
      // return getPrice(searchObj)
      
      let enc = encrypt(JSON.stringify(searchObj));
  
      dispatch(setBookingData({...bookingData,offer: null,time: null,travelers: data?.travelers}))
  
      window.open(`/order/new/flight/offers?q=${enc}`,'_blank');
      setRebooking(false);
      // navigate(`/order/new/flight/offers?q=${enc}`);
      
    }

  
    async function handleAutoRebook({acceptPriceChange=false}) {
      const payload = {
        flightBookingId: data?._id,
        acceptPriceChange
      }
      setRebooking(true)
      
      setLoading({...loading,rebook: true})
      // test cases
      // const res = await new Promise((resolve) => setTimeout(() => resolve({return: 0,msg: 'Failed',data: []}),2000))
      const res = await autoRebook(payload);
      setLoading({...loading,rebook: false})
      if(res.return) {
        setRebooking('success')
        if(res?.data?._id)
          navigate('/order/flight/'+res?.data?._id)
      } 
      else if(res?.msg === 'Price Changed') {
        // let data = (res.data?.map(obj => convertFlightObject(obj)) || [])
        setRebooking('price_change')
        let resData = [{totalAmount: 1000000}]
        setPriceChanged({
          price: resData?.at(0)?.totalAmount,
          change: resData?.at(0)?.totalAmount - (getNumber(data?.payable))
        })
      } else setRebooking('failed')
    }
    
    const Pending = () => (
      <div className="border p-4 flex gap-4 items-ceter flex-wrap">
        <div className="flex gap-2 items-center">
          <Icon icon='icon-park-outline:ticket' />
          Awaiting Payment
        </div>
        <div className="flex-1 flex gap-2 items-center whitespace-nowrap flex-wrap">
          <div className="flex-1 flex flex-col items-center">
            Time to pay
            <b className="text-red-500">{timeExp}</b>
          </div>
  
          <div className="flex gap-2 items-center">
            <Button1 variant='outlined' onClick={() => cancel && cancel()}>Cancel</Button1>
            {/* {!data?.isHeldBooking ? 
              <Button1 variant='outlined' onClick={() => hold && hold()}>Hold</Button1>
            :null} */}
            <Button1 variant='outlined' onClick={() => pay && pay()}>Pay</Button1>
          </div>
        </div>
      </div>
    )
  
    const ExpiredBeforePayment = () => (
      <div className="border p-4 flex flex-wrap gap-4">
        <div className="flex gap-2 items-center">
          <Icon icon='icon-park-outline:ticket' />
          This order has been cancelled due to non-payment 
        </div>
        <div className="flex-1 flex gap-2 flex-wrap justify-end whitespace-nowrap">
          <div>
            <Button1 variant='outlined' onClick={handleAutoRebook}>Rebook</Button1>
          </div>
          <div>
            <Button1 className='intercomButton'>Contact live support</Button1>
          </div>
        </div>
      </div>
    )
    
    const Expired = () => (
      <div className="border p-4 flex flex-col gap-4 flex-wrap">
        <div className="flex gap-2 items-center flex-wrap">
          <b className="text-red-500">Expired</b>
          <Button1 variant='outlined' onClick={handleAutoRebook}>Rebook</Button1>
        </div>
        <p>
          We did not receive an authorization to issue ticket from you hence we couldn’t confirm your request. 
          You are required to select the issue ticket once payment is made in order to confirm ticket issuance.
        </p>
      </div>
    )
  
    
    const Payed = () => (
      <div className="border p-4 flex gap-4 items-center flex-wrap">
        <div className="flex gap-2 items-center">
          <Icon icon='icon-park-outline:ticket' />
          Payment received
        </div>
        <div className="flex-1 flex gap-2 items-center whitespace-nowrap flex-wrap">
          <div className="flex-1 flex flex-col items-center">
            Time to issue
            <b className="text-red-500">{timeExp}</b>
          </div>
  
          <div className="flex gap-2 items-center flex-wrap">
            <Button1 variant='outlined' onClick={() => cancel && cancel()}>Cancel</Button1>
            <Button1 onClick={() => issueTicket && issueTicket()}>Issue ticket</Button1>          
            {/* <span variant='outlined' className="border border-orange-300 bg-orange-100 rounded-md p-2 text-orange-400">Awaiting approval</span> */}
          </div>
        </div>
      </div>
    )
  
    const Canceled = () => (
      <div className="border p-4 flex gap-4 items-center flex-wrap">
        <div className="flex gap-2 items-center">
          <Icon icon='icon-park-outline:ticket' />
          <b className="text-red-500">Canceled</b>
        </div>
        <div className="flex-1 flex gap-2 items-center whitespace-nowrap">
          <div className="flex-1 flex flex-col items-center">
          </div>
  
          <div className="flex gap-2 flex-wrap">
            {/* <span variant='outlined' className="border border-orange-300 bg-orange-100 rounded-md p-2 text-orange-400">Awaiting approval</span> */}
            <Button1 variant='outlined' onClick={handleAutoRebook}>Rebook</Button1>
            <Button1 className='intercomButton'>Contact live support</Button1>
          </div>
        </div>
      </div>
    )
    const Ticketed = ({ticketNo,ticketStatus}) => (
      <div className="border p-4 flex flex-wrap gap-4 items-ceter">
        <div className="flex gap-2 items-center">
          <Icon icon='uim:process' className='!text-green-500 !w-5 !h-5' />
          <span className='flex flex-col'>
            Ticket request in process
            <p>Ref ID: {ticketNo}</p>  
          </span>
        </div>
        <div className="flex-1 flex gap-2 items-center whitespace-nowrap">
          <div className="flex-1 flex flex-col items-center">
          </div>
  
          <div className="flex gap-2 flex-wrap">
            <span variant='outlined' className="border border-orange-300 bg-orange-100 rounded-md p-2 text-orange-400">
              {ticketStatus === 'WaitingConfirmation' ? 
              'Awaiting airline confirmation'
              : ticketStatus === 'Pending' ?
              'Contacting issuing airline'
              : 'Request in queue'
              }
            </span>
            <div className="flex">
              <Button1 className='intercomButton'>Contact live support</Button1>
            </div>
          </div>
        </div>
      </div>
    )
    
    let elem = null;
  
    if(data?.status === 'Expired')
      elem = <Expired />
    else if(data?.status === 'Canceled' || data?.status === "ExpiredBeforePayment")
      elem = <ExpiredBeforePayment />
    else if(data?.status === 'Booked')
      elem = <Pending />
    else if(data?.status === 'Paid')
      elem = <Payed />
    else if(data?.status === 'AutoCanceled')
      elem = <Canceled />
    else if(data?.status === 'PendingTicketIssueApproval')
      elem = <Ticketed ticketStatus={ticketData?.status} ticketNo={ticketData?.ticketNo} />
    else if(data?.status === 'PendingTicketIssue')
      elem = <Ticketed ticketStatus={ticketData?.status} ticketNo={ticketData?.ticketNo} />
  
    return <div className="flex flex-col gap-2">
      {data?.isRebooked ? 
        <div className="">
          <span className="rounded-full scale-90 inline-block p-2 text-xs bg-orange-500/70 text-white">Rebooked</span>
        </div>
      :null}
      <div className="bg-secondary">
        {elem}
      </div>
      <Modal1 open={rebooking} setOpen={() => setRebooking(false)}>
        <div className="card !p-10 min-w-[200px] min-h-[200px] flex flex-col gap-4">
          <div className="flex items-center flex-1 justify-center">
            {!loading?.rebook ? 
              rebooking === 'failed' ?
                <div className="flex flex-col gap-6">
                  <div>
                    <h5>The offer is no longer available</h5>
                    <p>Click on continue to book for a similar offer.</p>
                  </div>
                  <div className='flex flex-wrap justify-between gap-4'>
                    <div>
                      <Button1 variant={'outlined'} onClick={() => setRebooking(false)}>Cancel</Button1>
                    </div>
                    <div>
                      <Button1 onClick={rebook}>Continue</Button1>
                    </div>
                  </div>
                </div>
              : rebooking === 'price_change' ? 
                <div>
                  <div className="self-center max-w-[500px] ">
                    <div className='card p-0 m-2 rounded-md flex flex-col'>
                      {/* <h4 className='py-2'>You have been gone for too long.</h4> */}
                      <h5 className='py-2 self-center'>Price Change</h5>
                      {/* <p> The flight offer may have changed. Click the button to get the latest price.</p> */}
                      <div className="text-primary/60">There has been a price change by :  
                        <b className={`inline-block p-1 ${priceChanged?.change < 0 ? 'text-green-500':'text-red-400'}`}>{priceChanged?.change < 0 ? '-':'+'} {formatMoney(Math.abs(priceChanged?.change))}</b>
                      </div>
                      <p>
                        The updated price is <b className="font-bold text-primary">{formatMoney(priceChanged?.price)}.</b>
                      </p>
                      <p>
                        Do you want to continue or pick a different offer
                      </p>
                      <br />
                      <div className='flex  flex-wrap justify-between gap-4'>
                        <Button1 className='!w-auto self-end' variant='outlined' onClick={() => navigate('/order/new/flight')}><span className='hidden sm:inline-block pr-1'>New</span> Offer</Button1>
                        {/* <Button1 className='btn1 !w-auto self-end' onClick={() => (getPrice && getPrice()) || window.location.reload()}>Refresh</Button1> */}
                        <Button1 loading={loading?.rebook === true} className='btn1 !w-auto self-end' onClick={() => handleAutoRebook({acceptPriceChange: true})}>Continue</Button1>
                      </div>
                    </div>
                  </div>
                </div>
              : <div>
                  Your order has been booked
                </div>
            :
              <CircularProgress />
            }
          </div>
        </div>
      </Modal1>
    </div>
    // Pending = "Pending",
    // Paid = "Paid",
    // Failed = "Failed",
    // Booked = "Booked",
    // Issued = "Issued",
    // PendingTicketIssueApproval = "PendingTicketIssueApproval",
    // TicketIssueDenied = "TicketIssueDenied",
    // PendingTicketIssue = "PendingTicketIssue",
    // Expired = "Expired",
    // AutoCanceled = "AutoCanceled",
    // Canceled = "Canceled",
    // TicketCanceled = "TicketCanceled",
    // Refunded = "Refunded",
    // TicketCancelationRequested = "TicketCancelationRequested",
  
  }