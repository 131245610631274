import HeadersList from "../headers/headersList.js";
import SearchBarList from '../Searchbars/searchBarList.js'
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../footers/Footer";
import { Icon } from "@iconify/react";
import { CustomerHome } from "../layouts/LayoutList.js";
import FooterList from "../footers/footerList.js";
import { useParams } from "react-router-dom";
import AboutList from "../about/AboutList.js";
import ContactList from "../contact/ContactList.js";
import Terms from "../about/Terms.js";


const Preview = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const state = useSelector((state)=> state.template);
  const {user} = useSelector(state => state.user.userData)
  const {id} = useParams();
  const previewRef = useRef(null);
  const [scale, setScale] = useState(0.7)
  
  useEffect( ()=>{
    if(previewRef.current){
      let windowWidth = window.innerWidth;
      setScale(previewRef.current?.offsetWidth / windowWidth)
    }
  },[])
  const updateScale = ()=>{
    if(previewRef.current){
      let windowWidth = window.innerWidth;
      setScale(previewRef.current?.offsetWidth / windowWidth)
    }
  }
  useEffect(()=>{
    window.addEventListener('resize', updateScale)
    if(fullScreen){
      setScale(1)
    }
    else{
      updateScale()
    }
    return ()=>{
      window.removeEventListener('resize', updateScale)
    }
  },[fullScreen])

    
  return (
    <div className={`${fullScreen? "absolute top-0 left-0 w-full h-full": "" } transition-all duration-75 bg-[#F8F8F8] rounded-md md:col-span-2`} ref={previewRef}
      style={{color: state.primaryColor, fontFamily: state.fontFamily}}
    >
      <div
        className={`w-full h-auto rounded-lg`}
      >
        <div className="bg-white relative origin-top-left top-0">
          <div className="w-full">
              <HeadersList overrideImage={user?.detail?.agencyLogo} />
          </div>
        </div>
        {id === 'about' ? 
          <AboutList preview />
        : id === 'contact' ?
          <ContactList />
        : id === 'terms' ? 
          <Terms />
        :
          <CustomerHome />
        }
      </div>
    </div>
  );
};

export default Preview;
