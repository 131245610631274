import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmHotelBookingModal } from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { useHotels } from "../../hooks/useHotels";
import { URLdecode } from "../../utils/url";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ConfirmHotelBookingModal({}) {
  const dispatch = useDispatch();
  const { bookRooms } = useHotels();
  const { confirmHotelBookingModalPaymentOption, confirmHotelBookingModal } =
    useSelector((state) => state.modal);

  const params = URLdecode();

  const [loading, setLoading] = useState(false);

  const handleOpen = () => dispatch(setConfirmHotelBookingModal(true));
  const handleClose = () => dispatch(setConfirmHotelBookingModal(false));

  const handleConfirm = async () => {
    await bookRooms(setLoading);
  };

  return (
    <div>
      <Modal
        open={confirmHotelBookingModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>Confirm booking</span>
              <p className={Classname.modalDescription}>
                By clicking confirm you have booked this hotel:{" "}
                <strong className={Classname.modalTourName}>
                  {params?.name}
                </strong>
              </p>
            </div>
            <div className={Classname.bottomContent}>
              {loading ? (
                <div className={Classname.wait}>Please wait...</div>
              ) : (
                <>
                  <button
                    className={`${Classname.button} ${Classname.backButton}`}
                    onClick={handleClose}
                  >
                    Go Back
                  </button>
                  <button
                    className={`${Classname.button} ${Classname.confirmButton}`}
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content: "flex flex-col items-center px-4",
  modalTitle: "text-xl lg:text-3xl font-bold mb-6 mt-10 text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
