import { useDispatch, useSelector } from "react-redux";
import fetchServer from "../controllers/fetchServer";
import {
  setHotelAvailability,
  setHotels,
} from "../redux/reducers/tour/hotelDataSlice";
import { URLdecode, URLencode } from "../utils/url";
import { setAdultsNo, setChildrenNo } from "../redux/reducers/tour/dataSlice";
import { useSnackbar } from "notistack";
import { setConfirmHotelBookingModal } from "../redux/reducers/modalSlice";
import { useNavigate } from "react-router-dom";
import {
  setRecentActivities,
  setTeamActivities,
} from "../redux/reducers/overviewSlice";

export function useOverview() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getRecentActivities = async ({ setLoading, limit = 10, skip }) => {
    setLoading && setLoading(true);

    await fetchServer({
      method: "GET",
      url: `/main/v1/activityLogs/own?populate=account&limit=${limit}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setRecentActivities(res.data.data.data));
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });

    setLoading && setLoading(false);
  };

  const getTeamActivities = async ({ setLoading, limit = 10, skip }) => {
    setLoading && setLoading(true);

    await fetchServer({
      method: "GET",
      url: `/main/v1/activityLogs/own?populate=staff&filterBy=isStaff&filterValue=true&limit=${limit}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setTeamActivities(res.data.data.data));
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });

    setLoading && setLoading(false);
  };

  return {
    getRecentActivities,
    getTeamActivities,
  };
}
