import React, { useState } from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import CustomRadio from "../../../components/common/CustomRadio";
import { useNavigate } from "react-router-dom";
import TransportationChoiceModal from "../../../components/modal/TransportationChoiceModal";

function TourManagementPackageTransportationPage() {
  const navigate = useNavigate();
  const [transportOkay, setTransportOkay] = useState(null);
  const [customerTravelOkay, setCustomerTravelOkay] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [editedVehicle, setEditedVehicle] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleTransportOkay = (option) => {
    setTransportOkay(option);
  };
  const handleCustomerTravelOkay = (option) => {
    setCustomerTravelOkay(option);
  };

  const handleSelectVehicle = (vehicle) => {
    let vehicles1 = [...vehicles];
    if (editedVehicle) {
      vehicles1[editedVehicle - 1] = vehicle;
      setVehicles(vehicles1);
    } else {
      setVehicles([...vehicles1, vehicle]);
    }
    setEditedVehicle(null);
  };

  const handleRemoveVehicle = (index) => {
    let vehicles1 = vehicles.filter((_, idx) => idx !== index);
    setVehicles(vehicles1);
  };

  const handleEditVehicle = (index) => {
    setModalOpen(true);
    setEditedVehicle(index + 1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const onContinue = () => {
    navigate("/tourManagement/create/extrainfo");
  };

  const handleEntry = () => {
    setModalOpen(true);
  };

  return (
    <div className={Classname.container}>
      <TransportationChoiceModal
        open={modalOpen}
        setOpen={setModalOpen}
        onBack={handleCloseModal}
        onConfirm={handleSelectVehicle}
      />
      <TourManagementHeader />
      <div className={Classname.content}>
        <div className="hidden lg:flex">
          <TourManagementSidebar selected={9} />
        </div>

        <div className={Classname.detail}>
          <div className={Classname.title}>
            Is transportation used during this activity?
          </div>
          <p className={Classname.description}>
            Provide the main transportation type(s) that customers use during
            the experience, like a Segway or bike. Transportation used for
            pickup and drop-off will be added later.
          </p>

          <CustomRadio
            label="No"
            value="no"
            selected={transportOkay}
            onSelect={handleTransportOkay}
          />
          <CustomRadio
            label="Yes"
            value="yes"
            selected={transportOkay}
            onSelect={handleTransportOkay}
          />

          <div className={Classname.vehiclesContainer}>
            {vehicles.map((vehicle, index) => (
              <div className={Classname.vehicle} key={index}>
                <span className={Classname.vehicleName}>{vehicle}</span>
                <span
                  className={Classname.vehicleEdit}
                  onClick={() => handleEditVehicle(index)}
                >
                  Edit
                </span>
                <span
                  className={Classname.vehicleRemove}
                  onClick={() => handleRemoveVehicle(index)}
                >
                  Remove
                </span>
              </div>
            ))}
          </div>

          <div className={Classname.highlightButton} onClick={handleEntry}>
            + Add entry
          </div>

          <div className={Classname.title}>
            Do customers travel to a different city/town during the activity?
          </div>
          <CustomRadio
            label="Yes"
            value="yes"
            description="Example: going from Lagos to Abuja"
            selected={customerTravelOkay}
            onSelect={handleCustomerTravelOkay}
          />
          <CustomRadio
            label="No"
            value="no"
            description="Example: going from one part of Lagos to another part of Lagos"
            selected={customerTravelOkay}
            onSelect={handleCustomerTravelOkay}
          />

          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageTransportationPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",

  vehiclesContainer: "flex flex-1 flex-col py-4 gap-3 font-bold",
  vehicle:
    "w-full max-w-md flex items-center px-2 h-14 rounded-md border border-gray-400 gap-3",
  vehicleName: "flex flex-1",
  vehicleEdit: "text-primary1 cursor-pointer",
  vehicleRemove: "text-red-600 cursor-pointer",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
