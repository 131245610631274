import React, { useEffect, useState } from "react";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import GettingStartedStepTitle from "../../components/gettingStarted/GettingStartedStepTitle";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";
import { useDispatch, useSelector } from "react-redux";
import { setGettingStartedLoadingModal } from "../../redux/reducers/modalSlice";
import { useSnackbar } from "notistack";
import updateProfile from "../../controllers/user/updateProfile";
import { setUser } from "../../redux/reducers/userSlice";
import getPlans from "../../controllers/subscription/getPlans";
import { URLdecode } from "../../utils/url";
import { Summary } from "../../components/ProfileSurvey/Subscription/Sumaary";

function GettingStartedSubscribe() {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = URLdecode();

  const [pricingPlans, setPricingPlans] = useState([]);

  const loadData = async () => {
    dispatch(setGettingStartedLoadingModal(true));
    const res = await getPlans();
    if (res.return) {
      setPricingPlans(res.data?.data);
    }
    dispatch(setGettingStartedLoadingModal(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (params?.success) {
      handleContinue(true);
    }
  }, [params?.success]);

  const handleContinue = async (success) => {
    if (success) {
      dispatch(setGettingStartedLoadingModal(true));

      const formData = new FormData();
      formData.append("guideStep", "subscription");

      const res = await updateProfile(formData);
      dispatch(setGettingStartedLoadingModal(false));

      if (res.return) {
        if (res?.data) dispatch(setUser(res.data));
        navigate("/gettingStarted/logo");
      } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center">
      <GettingStartedLoadingModal />
      <div className="flex w-full flex-col py-10 px-4 lg:px-10 flex-1 max-w-7xl uncollapse1">
        <GettingStartedStepTitle
          active={true}
          visible
          title="Choose billing cycle"
        />
        <div className="w-full flex overflow-scroll gap-4 scroll-hide pb-4 ">
          <Summary
            plan={params?.plan}
            plans={pricingPlans}
            callback={() => handleContinue()}
            subscriptionPlan={userData?.subscriptionPlan}
          />
        </div>
        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueText={"Skip and subscribe later"}
          continueActive={true}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedSubscribe;
