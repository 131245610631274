import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  bookingData: {},
  compareFlights: [],
  shareFlights: [],
};
const UDStorage = window.localStorage.getItem("bookingData");
if (UDStorage) {
  try {
    initialState = { bookingData: JSON.parse(UDStorage) };
  } catch (ex) {
    console.log("Failed reading bookingData from storage");
  }
} else console.log("Got none from LS");

export const flightBookingSlice = createSlice({
  name: "flightBooking",
  initialState,
  reducers: {
    setBookingData: (state, action) => {
      try {
        state.bookingData = action.payload;
        window.localStorage.setItem(
          "bookingData",
          JSON.stringify(action.payload)
        );
      } catch (ex) {
        console.log("Failed reading booking data from storage");
      }
    },
    setCompareFlights: (state, action) => {
      state.compareFlights = action.payload;
    },
    setShareFlights: (state, action) => {
      state.shareFlights = action.payload;
    },
    clearBookingData: (state) => {
      state.bookingData = initialState;
      window.localStorage.removeItem("bookingData");
    },
  },
});

export const {
  setBookingData,
  clearBookingData,
  setCompareFlights,
  setShareFlights,
} = flightBookingSlice.actions;

export default flightBookingSlice.reducer;
