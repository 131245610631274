export const gettingStartedVideos = {
  activateBusiness: "pueGlgQWQTg",
  logo: "qruC9aBVJLk",
  markup: "GCMPEMj7rT0",
  bank: "VlYym4JGzyg",
  subscription: "nIbaxLj6G0w",
  agencyLink: "rCAatp2jjVo",
  team: "bOOPPQ8X-JM",
  gateway: "rCAatp2jjVo",
};
