import React, { useState } from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import { useNavigate } from "react-router-dom";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import CustomRadio from "../../../components/common/CustomRadio";

function TourManagementPackageConnectivityPage() {
  const navigate = useNavigate();
  const [selectedOptionReservation, setSelectedOptionReservation] =
    useState(null);

  const handleSelectReservation = (option) => {
    setSelectedOptionReservation(option);
  };

  const onContinue = () => {
    navigate("/tourManagement/create/availability");
  };
  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={3} />
        <div className={Classname.detail}>
          <InfoCardLightening
            description={
              <div className="flex flex-col gap-1">
                <span>
                  We have updated our experience. See what has changed
                </span>
                <li>If you use a reservation system, please click here</li>
                <li>If you use a reservation system, please click here</li>
              </div>
            }
          />
          <div className={Classname.title}>Connectivity Settings</div>
          <div className={Classname.description}>
            Intraverse supports API integrations with industry-leading channel
            managers, ticketing and reservation systems, allowing suppliers to
            manage all of their bookings and availability in one place. Learn
            more.
          </div>

          <div className={Classname.title}>
            Do you use an online reservation system?
          </div>
          <CustomRadio
            label="No, I don’t"
            selected={selectedOptionReservation}
            onSelect={handleSelectReservation}
          />
          <CustomRadio
            value=""
            label="Yes, I use a reservation system"
            selected={selectedOptionReservation}
            onSelect={handleSelectReservation}
          />

          <div className={Classname.buttonsContainer}>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageConnectivityPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6 ",
  description: "flex text-base mb-4 mt-2",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  addressButton:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3 text-blue-500 border rounded-lg",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
