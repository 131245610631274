import React from "react";
import { useSelector } from "react-redux";
import { getsubDomain } from "../../utils/getsubDomain";
import { useNavigate } from "react-router-dom";
import { getTestLevel } from "../../utils/testLevel";
import BetaTesting from "../mini/BetaTesting";

function GettingStartedWelcomeSection() {
  const { user } = useSelector((state) => state.user.userData);
  const navigate = useNavigate();

  const handleBookFlight = () => {
    navigate("/order/new/flight");
  };
  const handleBookHotel = () => {
    navigate("/accommodation/new");
  };
  const handleBookTour = () => {
    navigate("/tour/new");
  };
  const handleBuyInsurance = () => {};

  return (
    <div className="w-full rounded-xl shadow-lg bg-[#EEF5FF] mt-6 lg:mt-10 overflow-hidden">
      <div className="flex flex-col flex-1 p-3 lg:p-5 relative z-10">
        <img
          src="/GettingStartedWelcome.svg"
          alt=""
          className="hidden lg:flex h-[218px] object-contain absolute -bottom-16 right-0 z-10"
        />
        <div className="text-xl lg:text-3xl font-bold flex flex-col lg:flex-row lg:mt-4 z-20">
          Welcome to{" "}
          <span className="text-primary1 lg:ml-2">
            {getsubDomain() || "Intraverse"}
          </span>
        </div>
        <div className="text-xl lg:text-3xl font-bold z-20">
          {user?.firstName}!
        </div>
        <span className="my-5 text-sm lg:text-base z-20">
          Are you ready to place an order for flight, stay or tour?
        </span>
      </div>
      <div className="p-4 flex flex-wrap py-6 bg-white px-[5%] justify-center lg:justify-start z-30">
        <div className={Classname.button} onClick={handleBookFlight}>
          <div className={`${Classname.buttonContent} hover:bg-[#1E61DC]/5`}>
            <div className={`${Classname.buttonIconContainer} bg-[#1E61DC]/10`}>
              <img
                src="/IconFlight.svg"
                alt=""
                className={Classname.buttonIcon}
              />
            </div>
            <span className={Classname.buttonName}>Book a flight</span>
          </div>
        </div>
        <div className={Classname.button} onClick={handleBookHotel}>
          <div className={`${Classname.buttonContent} hover:bg-[#FFEAEA]/30`}>
            <div className={`${Classname.buttonIconContainer} bg-[#FFEAEA]`}>
              <img
                src="/IconHotel.svg"
                alt=""
                className={Classname.buttonIcon}
              />
            </div>
            <span className={Classname.buttonName}>Book a hotel
              <BetaTesting />
            </span>
          </div>
        </div>
        {/* {getTestLevel() <= getTestLevel("qa") ? ( */}
          <div className={Classname.button} onClick={handleBookTour}>
            <div className={`${Classname.buttonContent} hover:bg-[#E8DDFF]/30`}>
              <div className={`${Classname.buttonIconContainer} bg-[#E8DDFF]`}>
                <img
                  src="/IconTour.svg"
                  alt=""
                  className={Classname.buttonIcon}
                />
              </div>
              <span className={Classname.buttonName}>Book a tour
                <BetaTesting />
              </span>
            </div>
          </div>
        {/* ) : null} */}
        {getTestLevel() <= getTestLevel("dev") ? (
          <div className={Classname.button} onClick={handleBuyInsurance}>
            <div className={`${Classname.buttonContent} hover:bg-[#C2E7E2]/30`}>
              <div className={`${Classname.buttonIconContainer} bg-[#C2E7E2]`}>
                <img
                  src="/IconInsurance.svg"
                  alt=""
                  className={Classname.buttonIcon}
                />
              </div>
              <span className={Classname.buttonName}>Buy insurance</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default GettingStartedWelcomeSection;

const Classname = {
  button:
    "flex lg:flex-1 items-center hover:scale-105 cursor-pointer transition-all z-20",
  buttonContent:
    "flex flex-col lg:flex-row gap-2 items-center p-6 rounded-lg transition-all",
  buttonName: "font-bold text-sm lg:text-lg",
  buttonIconContainer:
    "w-20 h-20 rounded-lg flex items-center justify-center flex-shrink-0",
  buttonIcon: "h-10 w-10 object-contain",
};
