import React from 'react'

export default function AboutCard2({obj,className}) {
  return (
    <div className={"flex flex-col items-center gap-6 max-w-[500px] min-w-[40%] text-center "+className}>
        <div className="w-full h-[170px] flex items-center justify-center bg-primary/10 overflow-hidden">
          {obj?.image ? 
            <img src={obj?.image} className='w-full h-full object-cover' />
          :null}
        </div>
        <div className='flex flex-col gap-6'>
          <h4>{obj?.title}</h4>
          <p>{obj?.description}</p>
        </div>
    </div>
  )
}
