import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../../HOC/Icon'
import { useSelector } from 'react-redux'

export default function GuideLinks() {
    const state = useSelector(state => state.template);
    const {user,agent} = useSelector(state => state.user.userData);
    const crmData = (agent || user)?.detail?.crmData;

  return (
    <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-10 md:px-20 mt-10 md:mt-20">
        {crmData?.quickInfo?.map((obj,i) => (
            <Link to={obj?.other || '#'} className=" px-2 py-3" style={{border: "1px solid #EBEFF9"}}>
                <div className="flex justify-between w-full">
                    <div className="flex gap-3">
                        <img src={obj?.image} className='!max-w-6 !max-h-6' />
                    {/* <Icon icon="uiw:date" className="text-3xl" style={{color: state.secondaryColor}}/> */}
                    <div>
                        <h1 className="font-bold text-xl">{obj?.title}</h1>
                        <p>{obj?.description}</p>
                    </div>
                    </div>
                    <Icon icon="ph:arrow-right-bold" className="text-3xl text-[#D9A513]"/>
                </div>
            </Link>
        ))}
        {/* <Link to='/order' className=" px-2 py-3" style={{border: "1px solid #EBEFF9"}}>
        <div className="flex justify-between w-full">
            <div className="flex gap-3">
            <Icon icon="uiw:date" className="text-3xl" style={{color: state.secondaryColor}}/>
            <div>
                <h1 className="font-bold text-xl">Manage your trip</h1>
                <p>Make updates or cancel a booking.</p>
            </div>
            </div>
            <Icon icon="ph:arrow-right-bold" className="text-3xl text-[#D9A513]"/>
        </div>
        </Link>
        <div className=" px-2 py-3" style={{border: "1px solid #EBEFF9"}}>
        <div className="flex justify-between w-full">
            <div className="flex gap-3">
            <Icon icon="gridicons:chat" className="text-3xl" style={{color: state.secondaryColor}}/>
            <div>
                <h1 className="font-bold text-xl">Need help?</h1>
                <p>Our travel sharks are available 24/7</p>
            </div>
            </div>
            <Icon icon="ph:arrow-right-bold" className="text-3xl text-[#D9A513]"/>
        </div>
        </div>
        <div className=" px-2 py-3" style={{border: "1px solid #EBEFF9"}}>
        <div className="flex justify-between w-full">
            <div className="flex gap-3">
            <Icon icon="gridicons:refund" className="text-3xl text-[#1E61DC]" style={{color: state.secondaryColor}}/>
            <div>
                <h1 className="font-bold text-xl">track your refund</h1>
                <p>Check the status of a refund currently in progress</p>
            </div>
            </div>
            <Icon icon="maki:arrow" className="text-3xl text-[#D9A513]"/>
        </div>
        </div> */}
    </div>

  )
}
