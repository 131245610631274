import React from "react";
import { isCustomerSite } from "../../../../utils/getsubDomain";
import SearchResults from "../../../../components/templates/searchResults/SearchResults";
import OffersListV2 from "./OfferList-v2";

export default function OffersContainerV2() {
  const customer = isCustomerSite();

  return customer ? <SearchResults /> : <OffersListV2 />;
}
