import React, { useEffect, useState } from "react";
import Button1 from "../../../components/form/Button1";
import PaystackButton from "../../../components/Paystack/PaystackButton";
import { generateRef } from "../../../utils/generateRef";
import { useSelector } from "react-redux";
import addCard from "../../../controllers/settings/finance/addCard";
import { useSnackbar } from "notistack";
import Icon from "../../../components/HOC/Icon";
import { CircularProgress } from "@mui/material";
import getSavedCards from "../../../controllers/Bank/getSavedCards";
import removeSavedCard from "../../../controllers/Bank/removeSavedCard";
import Modal1 from "../../../components/DIsplay/Modal/Modal1";

export default function PaymentSetting() {
  const { user } = useSelector((state) => state.user.userData);
  const [cards,setCards] = useState([])
  const [loadingCards,setLoadingCards] = useState(false);
  const [loadingRemove,setLoadingRemove] = useState(false);
  const [openRemove,setOpenRemove] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    loadCards();
  },[])
  
  async function loadCards() {
    setLoadingCards(true);
    const res = await getSavedCards();
    setLoadingCards(false);
    if(res.return) {
      setCards(res?.data?.data)
    } else enqueueSnackbar(res?.msg,{variant: 'error'})
  }
  
  const handleAddCard = async (reference) => {
    const res = await addCard(reference);
    if(res.return) {
      enqueueSnackbar('Card Added Successfully',{variant: 'success'})
    } else enqueueSnackbar(res?.msg,{variant: 'error'})
  };

  const handleRemove = async () => {
    setLoadingRemove(true);
    const res = await removeSavedCard(openRemove);
    setLoadingRemove(false);
    if(res.return) {
      enqueueSnackbar('Card Removed',{variant: 'success'})
      loadCards();
    } else enqueueSnackbar(res?.msg,{variant: 'error'})
    setOpenRemove(false);
  }

  return (
    <div className="content-max-w flex flex-col gap-4">
      {/* <p>
        Our pricing model is designed to be flexible and cost-effective. With
        pay as you go, there are no initial fees or monthly obligations. You
        only pay for the specific services or resources you utilize. If you
        don't possess IATA accreditation, you have the option to utilize our
        Serviced Content, which incurs a charge of 1% based on the total order
        value.
      </p> */}
      {/* <p>
        We offer automatic volume discounts, ensuring you receive the most
        competitive prices available. If you have any inquiries or would like to
        explore enterprise pricing options, please don't hesitate to contact our
        Sales team.
      </p> */}
      <div className="flex gap-4 justify-between items-center">
        <div>
          <h5>Cards</h5>
          <p>
            Make payment on Intraverse using your saved cards.
          </p>
        </div>
        <span className="self-start">
          <PaystackButton
            config={{
              amount: 5000,
              reference: generateRef("AC-"),
              email: user?.email,
              channels: ['card'],
            }}
            onSuccess={(reference) => {
              handleAddCard(reference?.reference);
            }}
          >
            <Button1>Add Card</Button1>
          </PaystackButton>
        </span>
      </div>
      <hr />
      <div className="">
        {
          loadingCards ? 
            <div className="flex items-center justify-center">
              <CircularProgress />
            </div>
          :
            cards?.map((card,i) => (
              <div key={i} className="py-1 flex flex-col gap-3">
                <div className="flex gap-4 justify-between items-center w-full">
                  <div>
                    <span className="capitalize">{card?.bank?.toLowerCase()}</span>
                    <p>{card?.bin} {card?.last4}</p>
                  </div>
                  <div className='flex flex-col gap-1 text-xs'>
                    <span className='flex gap-2 justify-between'>Expiry <span>{card?.exp_month}/{card?.exp_year}</span></span>
                    <span className='flex gap-2 justify-between capitalize'><span>{card?.card_type}</span></span>
                  </div>
                  <div className="">
                    <button className="" onClick={() => setOpenRemove(card?._id)}>
                      <Icon icon='mdi:delete' className='text-red-500' />
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            ))}
      </div>
      <Modal1 open={openRemove} setOpen={setOpenRemove}>
        <div className="card flex flex-col gap-4 p-6">
          <h5>Are you sure you want to remove card?</h5>
          <div className="flex gap-4">
            <Button1 variant='outlined' onClick={() => setOpenRemove(false)}>Cancel</Button1>
            <Button1 loading={loadingRemove} onClick={handleRemove} className='!bg-red-500 !text-white'>Delete</Button1>
          </div>
        </div>
      </Modal1>
    </div>
  );
}
