import React, { useState } from "react";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";

function GettingStartedCongratulations() {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center">
      <GettingStartedLoadingModal />
      <div className="flex w-full flex-col py-10 px-4 lg:px-10 flex-1 max-w-7xl">
        <span className="text-lg lg:text-3xl my-2">Congratulations</span>
        <span className="text-[#818083] text-sm lg:text-base">
          You have completed the setup
        </span>
        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueActive={true}
          continueText={"Complete"}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedCongratulations;
