import React, { useEffect, useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import CountriesInput from "../../../components/form/CountriesInput";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import LocationInput from "../../../components/form/LocationInput";
import { useDispatch, useSelector } from "react-redux";
import EditTravelersModal from "../../../components/modal/EditTravelersModal";
import { setStaysFilter } from "../../../redux/reducers/tour/filterSlice";
import { URLencode } from "../../../utils/url";
import useDateFormat from "../../../hooks/useDateFormat";
import { useHotels } from "../../../hooks/useHotels";
import { setEditHotelTravellersModal } from "../../../redux/reducers/modalSlice";

function AccommodationNewOrderPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { travellersString } = useHotels();
  const { formatDate, isPastDate, compareDate, isSameDate } = useDateFormat();
  const { adultsNo, childrenNo, rooms } = useSelector((state) => state.data);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [country, setCountry] = useState(null);
  const [locationError, setLocationError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [center, setCenter] = useState({ latitude: "", longitude: "" });
  const [travelersModal, setTravelersModal] = useState(false);

  const calendarRef = useRef([...Array(10)]);

  useEffect(() => {
    setLocationError("");
    setStartDateError("");
    setEndDateError("");
  }, [country, startDate, endDate]);

  const handleSetDate = (val, index) => {
    if (index === 0) {
      setStartDate(val);
    } else if (index === 1) {
      setEndDate(val);
    }

    if (calendarRef.current?.at(index + 1)) {
      calendarRef.current[index + 1]?.toggle(
        calendarRef.current[index + 1]?.ref?.current
      );
    }
    calendarRef.current[index]?.toggle();
  };

  const OnSearch = () => {
    if (!country) return setLocationError("Please select a location");
    if (isPastDate(startDate)) {
      setStartDateError("Start date can't be a past date");
      return;
    }
    if (isPastDate(endDate)) {
      setEndDateError("End date can't be a past date");
      return;
    }
    if (compareDate(endDate, startDate)) {
      setEndDateError("End date can't be a before start date");
      return;
    }
    if (isSameDate(startDate, endDate)) {
      setEndDateError("Start data and End date can't be the same");
      return;
    }

    const formattedDateStart = formatDate(startDate);
    const formattedDateEnd = formatDate(endDate);

    const params = {
      lat: center.latitude,
      lng: center.longitude,
      location: country?.label,
      start_date: formattedDateStart,
      end_date: formattedDateEnd,
      adult: adultsNo,
      children: childrenNo,
      rooms: JSON.stringify(rooms),
    };

    navigate(`/accommodation/results?${URLencode(params)}`);
  };

  return (
    <div className={Classname.container}>
      <EditTravelersModal open={travelersModal} setOpen={setTravelersModal} />
      <div className={Classname.content}>
        {/* Page title */}
        {/* <div className={Classname.titleContainer}>
          {`Stays > Orders >`}{" "}
          <span className={Classname.titleMain}>New Order</span>
        </div> */}

        {/* <span className="text-xl lg:text-4xl font-bold mt-5">
          Accommodations
        </span> */}

        {/* Form container */}
        <div className={Classname.formContainer}>
          <div className={Classname.form}>
            <span className={Classname.formTitle}>Search for hotels</span>
            <span className={Classname.formInputLabel}>Enter location</span>
            <LocationInput
              value={country}
              placeholder="eg. Lagos, London"
              setValue={(place) => setCountry(place)}
              setCenter={setCenter}
            />
            {locationError && (
              <span className="text-sm text-red-500 mt-2">{locationError}</span>
            )}
            <div className="flex w-full gap-4 flex-col lg:flex-row">
              <div className="flex flex-1 flex-col">
                <span className={Classname.formInputLabel}>From</span>
                <CalendarInput1
                  ref={(el) => (calendarRef.current[0] = el)}
                  className="w-full border border-primary/20 rounded-md p-2"
                  value={startDate || ""}
                  onChange={(value) => handleSetDate(value?.start || value, 0)}
                  config={{ validDates: [new Date()] }}
                />
                {startDateError && (
                  <span className="text-sm text-red-500 mt-2">
                    {startDateError}
                  </span>
                )}
              </div>
              <div className="flex flex-1 flex-col">
                <span className={Classname.formInputLabel}>To</span>
                <CalendarInput1
                  ref={(el) => (calendarRef.current[1] = el)}
                  className="w-full border border-primary/20 rounded-md p-2"
                  value={endDate || ""}
                  onChange={(value) => handleSetDate(value?.start || value, 1)}
                  config={{ validDates: [startDate || new Date()] }}
                />
                {endDateError && (
                  <span className="text-sm text-red-500 mt-2">
                    {endDateError}
                  </span>
                )}
              </div>
            </div>

            <span className={Classname.formInputLabel}>Travelers</span>
            <input
              type="text"
              value={travellersString()}
              onClick={() => dispatch(setEditHotelTravellersModal(true))}
              className="w-full h-11 rounded-md px-4 border border-gray-300"
            />

            <button className={Classname.formButton} onClick={OnSearch}>
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationNewOrderPage;

const Classname = {
  container:
    "flex w-full relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full flex-col flex-1 ",
  titleContainer: "w-full text-xl font-bold text-gray-400",
  titleMain: "text-black",

  formContainer: "flex w-full flex-1 items-center justify-center px-0 sm:px-10",
  form: "w-full max-w-600px flex flex-col",
  formTitle: "font-black text-primaryText text-2xl mb-6 text-center mt-14",
  formInputLabel: "flex font-bold text-base mb-2 mt-6 text-gray-500",
  formButton:
    "w-full text-white bg-theme1 font-bold h-12 flex items-center justify-center rounded-xl mt-10",
};
