import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: "500px",
};

export default function RoomAmenitiesModal({ open, setOpen, room }) {
  let amenities = room?.facilities || [];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!amenities || amenities?.length === 0) return <></>;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>
                {room?.name} - amenities
              </span>
              {amenities?.map((amenity, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 bg-gray-50 rounded-md w-full py-2 my-1 px-3"
                >
                  <span className="text-gray-800">{amenity?.description}</span>
                  {amenity?.mandatory && (
                    <span className="text-gray-400 text-sm"> (Mandatory)</span>
                  )}
                </div>
              ))}
              <div></div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-center px-4 max-h-[80vh] overflow-scroll scroll-hide",
  modalTitle: "text-lg lg:text-xl font-bold mb-6 mt-10 text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
