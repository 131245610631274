import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusinessTypeChooseModal,
  setGettingStartedLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import Loader from "../../utils/loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
};

export default function GettingStartedLoadingModal() {
  const dispatch = useDispatch();
  const { gettingStartedLoadingModal } = useSelector((state) => state.modal);

  const handleClose = () => dispatch(setGettingStartedLoadingModal(false));

  return (
    <div>
      <Modal
        open={gettingStartedLoadingModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <Loader loading={true} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-1 h-full items-center justify-center",
};
