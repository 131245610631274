import { useSelector } from "react-redux"
import Footer2 from "./Footer2";
import Footer from "./Footer";

const FooterList = ({id}) => {
    const state = useSelector((state)=> state.template);  
    switch (id || state.footerStyle){
        case "1":
            return <Footer state={state} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        case "2":
            return <Footer2 state={state} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
        default:
            return <Footer  state={state} logo={state.logoImage ? state.logoImage : undefined} primaryColor={state.primaryColor} secondaryColor={state.secondaryColor}/>
    }
}

export default FooterList