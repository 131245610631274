import React from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";

function TourManagementPackageOptionsPage() {
  const navigate = useNavigate();

  const handleCreateOption = () => {
    navigate("/tourManagement/create/optionsSetup");
  };

  const onContinue = () => {
    navigate("/tourManagement/create/optionsSetup");
  };
  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementSidebar selected={12} />
        <div className={Classname.detail}>
          <div className={Classname.title}>Add option(s) to your product</div>
          <div className={Classname.description}>
            Options allow you to customize your activity and attract more
            customers. For example, your options can have different:
          </div>
          <div className="mb-6">
            <li>durations (1 or 2 hours)</li>
            <li>
              group sizes (10 or 20 people) or set-ups (private or public)
            </li>
            <li>inclusions (with or without lunch)</li>
            <li>ways to start the activity (meeting point or hotel pickup)</li>
          </div>
          <div className={Classname.description}>
            The option is where the pricing/availability are stored, and where
            bookings are made. So you need at least one option per product to
            start receiving bookings.
          </div>
          <button
            className={Classname.optionButton}
            onClick={handleCreateOption}
          >
            Create new option
          </button>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageOptionsPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full text-gray-600",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4 mt-2",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  optionButton:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3 border-primary1 border rounded-lg",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
