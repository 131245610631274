import { useState } from "react";
import { Button, TextField } from "@mui/material"
import { Icon } from "@iconify/react"
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Logo from "../../Logo/Logo";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import login from "../../../controllers/Auth/login";
import staffLogin from "../../../controllers/Auth/staff/login";
import { setUserData } from "../../../redux/reducers/userSlice";
import { loginReqData } from "../../../data/user/Auth/loginReq";
import Button1 from "../../form/Button1";
import loginCustomer from "../../../controllers/Auth/customer/login";


const Login = ()=>{
    const [passwordVisible,setPasswordVisible] = useState(false)
    const [data, setData] = useState({ ...loginReqData, confirmPassword: "" });
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect')

    const {userData: {agent}} = useSelector(state => state?.user)

    async function handleSubmit(ev) {
        ev.preventDefault();

        setLoading(true);
        let res = {return: 0,msg: 'Something went wrong on our end! Please contact support. 0xSTLOG'}
        // if(agent && (agent.email !== data.email))
        // res = await staffLogin({...data,agentId: agent?._id});
        // else
        res = await loginCustomer(data);
        setLoading(false);
        if (res.return) {
        // enqueueSnackbar("You are logged in.", { variant: "success" });
        dispatch(
            setUserData({
            loggedIn: true,
            accessToken: res?.data?.token,
            user: res?.data?.account,
            agent
            }),
        );
        } else {
        enqueueSnackbar(res?.msg || "Invalid Credentials.", { variant: "error" });
        if (res?.data?.detail?.accountStatus === "Inactive")
            navigate("/?view=verify&email=" + data.email+(redirect?'&redirect='+redirect:''));
        }
    }

    async function handleGoogleAuth() {
        let curUrl = new URLSearchParams({ callbackUrl: window.location.href });
        window.location.href =
        process.env.REACT_APP_API + "/main/v1/auth/google?" + curUrl;
    }
    return(
        <div className="grid grid-cols-3">
            <div className="col-span-1 bg-[#F5F7F9] h-screen">
                <div className="flex justify-center items-center xbg-[#D9D9D9] m-6 px-3 py-2 font-bold w-40">
                    <Logo iconOnly />
                </div>
            </div>
            <div className="col-span-2 bg-white flex justify-center items-center">
                <form onSubmit={handleSubmit} className="w-96 flex justify-center">
                    <div className="w-full">
                        <div className="font-bold flex justify-center">Signin</div>
                        {/* <div variant="contained" className="!w-full cursor-pointer border rounded-sm !border-[#BCC7D3] !bg-white !text-[#333333] py-3 px-4 flex justify-center items-center gap-2 !mt-4"
                            onClick={() => !loading && handleGoogleAuth()}
                        >
                            <Icon icon="devicon:google" />
                            <span className="font-bold">Login with Google</span>
                        </div>
                        <div className="flex w-full justify-center !mt-4 font-bold">
                            Or
                        </div> */}
                        <TextField type="email" required id="outlined-basic" label="Email" variant="outlined" className="rounded-sm px-4 w-full !mt-4" 
                            value={data.email}
                            placeholder=""
                            onChange={(ev) => setData({ ...data, email: ev.target.value })}
                        />
                        <FormControl variant="outlined" fullWidth className="!mt-4" required>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={passwordVisible ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                    >
                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                                value={data.password}
                                onChange={(ev) => setData({ ...data, password: ev.target.value })}
                    
                            />
                        </FormControl>
                        <Button1 loading={loading} type='submit' variant="contained" className="!w-full border rounded-sm !border-[#BCC7D3] py-3 px-4 flex justify-center items-center gap-2 !mt-4">
                            <span className="font-bold text-white">Login</span>
                        </Button1>
                        <div className="!mt-3 flex flex-col items-center">
                            <div className="text-[#6D7C94] text-sm">Don’t have an account? <Link to="?view=register" className="cursor-pointer text-[#A0D3ED]">Sign up</Link></div>
                            <Link to='/?view=reset' className="text-sm text-[#333333] mt-3 font-bold
                            ">I have forgotten my password</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;