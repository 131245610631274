import moment from "moment";


export function templateCustomerFlightOrderData(obj) {
  // let data = mergeRecursive({...obj},ordersDataTemplate)
  const bookingData = obj?.flightBooking?.at(0);

  const getTripType = (flights) => {
    if(flights?.length === 1)
      return "One Way";
    else if(flights?.at(0)?.departureLocation === flights?.at(-1)?.arrivalLocation)
      return "Return";
    else return "Multi City"
  }
  
  const data = {
    ...obj,
    'from': {
        'name': bookingData?.flights?.at(0)?.departureLocationName,
        'iata': bookingData?.flights?.at(0)?.departureLocation,
        'airport': bookingData?.flights?.at(0)?.departureAirport,
    },
    'to': {
        'name': bookingData?.flights?.at(0)?.arrivalLocationName,
        'iata': bookingData?.flights?.at(0)?.arrivalLocation,
        'airport': bookingData?.flights?.at(0)?.arrivalAirport,
    },
    "tripType": getTripType(bookingData?.flights),
    "class": bookingData?.flights?.at(0)?.cabinClass,
    "orderNumber": obj?.bookingId,
    "Airlines": bookingData?.flights?.at(0)?.arrivalAirport,
    "dateCreated": moment(obj?.createdAt)?.format('DD/MM/YYYY HH:mm'),
    "activityDate": moment(obj?.updatedAt)?.format('DD/MM/YYYY HH:mm'),
    bookingData,
  }

  return data;
}