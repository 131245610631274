import { useEffect, useRef } from "react";
import FooterList from "../../footers/footerList";
import AboutHeader from "../AboutHeader";
import CoreValues from "../CoreValues";
import BlogLink from "../BlogLink";
import WhatWeBelieve2 from "../WhatWeBelieve2";
import WhoWeAre2 from "../WhoWeAre2";
import CoreValues2 from "../CoreValues2";
import AboutCard2 from "../Card2";
import { useSelector } from "react-redux";

export default function About2({preview,noScroll}) {
	const {user,agent} = useSelector(state => state.user.userData);
	const data = (agent || user)?.detail?.crmData?.aboutUs;
	const ref = useRef();
	

	useEffect(() => {
		if(!noScroll)
			ref.current?.scrollIntoView({behavior: 'smooth'})
	},[])

	return ( 
		<div ref={ref}>
			<AboutHeader />

			<div className="flex flex-wrap-reverse gap-5 pd-md mt-10">
				<div className="flex justify-evenly flex-wrap gap-6 gap-y-32 flex-1">
					{data?.sections?.map((obj,i) => (
						<AboutCard2 key={i} obj={obj} className={i%2!==0?'sm:flex-col-reverse':''} />
					))}
				</div>
				{/* <div className="w-[450px] h-[240px] flex items-center justify-center bg-primary/10">
					Image
				</div> */}
			</div>
			<div className="flex flex-col items-center bg-primary/5 ">
				<div className="sm:max-w-[60%]">
					<CoreValues2 data={data?.values} />
				</div>
			</div>
			<BlogLink data={data?.blog}/>
			<FooterList />
		</div>
	)
}