import React from 'react'
import { getsubDomain } from '../../../utils/getsubDomain';

export default function CoreValues({data}) {
    const agency = getsubDomain();

  return !data?.length ? null : (
        <div className="flex flex-col gap-10 pd-md">
            <div>
                <h4 className="capitalize">{agency} Core Values</h4>
            </div>
            <div className="flex flex-wrap gap-x-6 gap-y-6 justify-evenly">
                {data?.map((obj,i) => (
                    <div key={i} className='sm:max-w-[250px] bg-secondary p-6 flex flex-col gap-4'>
                        <h5>{obj?.title}</h5>
                        <p>{obj?.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
