import React, { useEffect, useRef, useState } from 'react'
import TextInput from '../../../form/TextInput';
import { FileUploader } from 'react-drag-drop-files';
import Icon from '../../../HOC/Icon';
import Button1 from '../../../form/Button1';
import { Add } from '@mui/icons-material';


export default function SectionForm({user,obj,handleChange}) {
    const [image,setImage] = useState();
    const [data,setData] = useState({image:'',title:'',description: ''});
    const inputRefs = useRef([]);

    useEffect(() => {
        setData(typeof(obj) !== 'boolean' ? obj : {image:'',title:'',description: ''})
    },[obj])

    function handleImage(file) {
        setImage({file,url: window.URL.createObjectURL(file)})
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            const base64Data = fileReader.result.split(',')[1];
            setData({...obj,image: base64Data,url: window.URL.createObjectURL(file)})
        }

    }

    function save() {
        handleChange && handleChange(data);
    }
    
    console.log(data)
    return (
        <div className='border border-primary/25 relative flex flex-col'>
            <div className='flex flex-col gap-4 p-4 pt-0'>
                <FileUploader handleChange={handleImage} name={'files'} types={["JPG","JPEG","PNG","GIF"]}>
                    <div className="flex h-20 mb-2">
                        <div className="flex justify-center items-center bg-silverColor h-full w-20 rounded-md">
                            {image?.url || data?.url || data?.savedImage ? (
                            <img
                                src={image?.url || data?.url || data?.savedImage}
                                className="h-20 w-20 rounded-md object-scale-down"
                            />
                            ) : (
                            <Add fontSize="large" />
                            )}
                        </div>
                        <div className="flex flex-col justify-center ml-2">
                            <div>
                            <h5 className="text-xl font-BrFirmaBold font-bold my-0">
                                Image
                            </h5>
                            {/* <p className="font-BrFirmaRegular text-[#818083] my-0 mt-2">
                                Pick a background . The upload file size limit is
                                1MB.
                            </p> */}
                            </div>
                        </div>
                    </div>
                </FileUploader>
                <TextInput label='Title' value={data?.title||''} onChange={(ev) => setData({...data,title: ev.target.value})} 
                    inputRef={(ref) => inputRefs.current[0] = ref}
                />
                <TextInput label='Description' multiline rows={4}
                    value={data?.description||''} onChange={(ev) => setData({...data,description: ev.target.value})} 
                    inputRef={(ref) => inputRefs.current[1] = ref}
                />
                <div>
                    <Button1 onClick={save} variant='text'>Save</Button1>
                </div>
            </div>
        </div>
    )
}