import { profileSteps } from "../../components/ProfileSurvey/New/ProfileSurvey";

export default function checkProfileComplete(user) {
  let steps = (profileSteps.map(obj => ({label: obj.label,complete: false})));

  steps = steps.map(obj => {
    const temp = obj;
    const detail = user?.detail;
    const contact = user?.detail?.contact

    switch(temp.label) {
      case 'Business Detail':
        if(detail?.agencyType && detail?.tradingName)
          temp.complete = true;
        break;
      
      case 'Legal Entity':
        if(detail?.registeredBusinessName && detail?.typeOfBusiness && detail?.legalInfo?.taxIdentification && detail?.legalInfo?.companyNumber)
          temp.complete = true;
        break;

      case 'Key Contact':
        // if(contact?.firstName && contact?.lastName && contact?.email && contact?.phoneNumber && contact?.position)
        if(contact?.email)
          temp.complete = true;
        break;
      
      case 'Upload Documents':
        // CACCertificateDoc
        // CACCO2Doc
        // CACC07Doc
        // identityVerificationDoc
        temp.optional = true;
        if(detail?.agencyType === 'starterBusiness' || (detail?.CACCertificateDoc && detail?.identityVerificationDoc && detail?.CACCO2Doc && detail?.CACC07Doc))
          temp.complete = true;
        break;

        // case 'training':
      //   if(detail?.haveScheduledTraining)
      //     temp.complete = true;
      //   break;

      default: break;
    }

    return temp;
  })

  return steps;
}