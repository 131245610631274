import React from "react";
import RevenueAnalytics from "./RevenuAnalytics";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CreateOrder from "../Orders/CreateOrder";
import { getsubDomain } from "../../utils/getsubDomain";
import { def } from "../../config";
import PendingTasks from "./PendingTasks";
import { getTestLevel } from "../../utils/testLevel";


export default function Dashboard() {
  const { user, agent } = useSelector((state) => state.user.userData);
  const agency = getsubDomain();

  return (
    <div className="pd-md flex flex-col gap-4 mb-16 ">
      <div className="flex gap-6 flex-wrap ">
        <div className="flex gap-1 whitespace-nowrap">
          Welcome back,
          <b>
            {user?.firstName} {user?.lastName}
          </b>
        </div>
        <div className="flex-1 flex flex-wrap md:flex-nowrap gap-4 justify-end items-center">
          {/* <Link className='btn whitespace-nowrap flex-1 md:flex-none' to="/">View pending orders</Link> */}
          {def.devTest ? 
            <Link
              className="p-2 px-4 rounded-md flex-1 md:flex-none border-primary border flex gap-3 whitespace-nowrap items-center"
              to="/order?status=needsReview"
            >
              {/* <span className="bg-theme1/20 text-xs font-bold w-5 h-5 flex items-center justify-center">
                5
              </span> */}
              Airline-initiated changes
            </Link>
          :null}
          {def?.devTest ? 
            <Link
              className="p-2 px-4 rounded-md flex-1 md:flex-none border-primary border flex gap-3 whitespace-nowrap items-center"
              to="/order?status=onHold"
            >
              {/* <span className="bg-theme1/20 text-xs font-bold w-5 h-5 flex items-center justify-center">
                15
              </span> */}
              Book on-hold orders
            </Link>
          :null}
          <div className="flex items-center">
            <CreateOrder label='Create a new order' />
            {/* <Button1
              className="btn-theme1 whitespace-nowrap flex-1 md:flex-none !w-auto "
              href="/order?create"
            >
              Create a new order
            </Button1> */}
          </div>
        </div>
      </div>
      
      {/* {getTestLevel(def.devStatus) < 2 && user?.detail?.isVerified ? 
        <PendingTasks />
      :null} */}

      {(!agency || agent?.email === user?.email) && <RevenueAnalytics />}
    </div>
  );
}
