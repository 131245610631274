import React from "react";

function WhiteLabelFeaturesSection() {
  const items = [
    {
      title: "Customize every content on your website",
      image: "/WhiteLabelFeature1.svg",
      description:
        "Offer your customer a seamless travel booking experience, customised to your brand.",
    },
    {
      title: "Focus on what matters",
      image: "/WhiteLabelFeature2.svg",
      description:
        "We take care of the intricate details of travel expertise, accreditation, and airline partnerships, so you don't have to—no need for additional development resources. Your customers can easily book flights with over 900 top airlines worldwide, along with accommodations, tours, and insurance. We save you time and resources so that you can focus on what matters most - running your business.",
    },
    {
      title: "Earn more money",
      image: "/WhiteLabelFeature3.svg",
      description:
        "Take control of the entire travel experience. Effortlessly add markups to all travel products using our user-friendly dashboard. Upsell your customers with accommodations, things to do and insurance products.",
    },
  ];
  return (
    <div className="flex flex-col w-full px-2 py-10 items-center bg-[#F8F8F8] ">
      <div className="flex flex-col w-full max-w-7xl">
        <span className="text-xl lg:text-3xl font-bold text-center mt-10 mb-10">
          An easy to use travel booking platform
        </span>
        {items?.map((item, index) => (
          <div
            className={`flex flex-col gap-4 w-full items-center my-4 lg:my-10 ${
              index % 2 === 0 ? "lg:flex-row-reverse" : "lg:flex-row"
            }`}
            key={index}
          >
            <div className="w-full justify-end flex flex-1 ">
              <img src={item?.image} alt="" className="w-full object-contain" />
            </div>
            <div className="flex flex-col flex-1 p-4">
              <span className="font-bold text-base lg:text-lg mb-2">
                {item?.title}
              </span>
              <p className="text-sm lgg:text-base">{item?.description}</p>
            </div>
          </div>
        ))}
        <WhiteLabelCTASection />
      </div>
    </div>
  );
}

function WhiteLabelCTASection() {
  return (
    <div className="w-full rounded-md shadow-md px-2 lg:px-6 py-3 lg:py-4 flex flex-col lg:flex-row fle items-center justify-between bg-[#F0F6FC] gap-2">
      <div className="flex flex-col lg:gap-2">
        <span className="text-lg lg:text-3xl font-bold">
          Get your website today!
        </span>
        <span className="text-sm lg:text-base tetx-[#6D7C94]">
          Sell flights, hotels, and tours from about the world.
        </span>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <a
          href="tel:09030002629"
          className="bg-primary1 rounded-md text-white font-bold tetx-sm lg:text-base px-3 py-1"
        >
          09030002629
        </a>
        <a
          href="mailto:hello@intraverse.africa"
          className="bg-white rounded-md text-primary1 font-bold tetx-sm lg:text-base px-3 py-1"
        >
          hello@intraverse.africa
        </a>
      </div>
    </div>
  );
}

export default WhiteLabelFeaturesSection;
