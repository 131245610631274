import React, { useState } from "react";
import layoutPreview from '../../../../assets/images/preview.png';
import layout1 from '../../../../assets/images/layouts/layout1.png';
import layout2 from '../../../../assets/images/layouts/layout2.png';
import layout3 from '../../../../assets/images/layouts/layout3.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "../../../../redux/reducers/template/templateSlice";


const ChoseTemplate = () => {
  const layouts = [
    // {img: layoutPreview},
    {img: layout1,value: 1},
    {img: layout2,value: 2},
    {img: layout3,value: 3},
  ]
  const state = useSelector(state => state.template);
  const [selected,setSelected] = useState(Number(state.layoutType || 1))
  const dispatch = useDispatch();

  console.log('layout: ',state?.layoutType)

  function handleSelect(obj) {
    setSelected(obj.value)
    dispatch(setTemplate({...state,layoutType: obj.value}))
  }
  return (
    <div className="mt-10 px-10">
      <div>
        <h1 className="text-4xl font-BrFirmaBold font-bold my-0">
            Build your website 
        </h1>
        <h2 className="font-BrFirmaBold mt-12">Choose a site layout</h2>
        <p className="font-BrFirmaRegular my-2">
        Select a website layout that matches your business and customize accordingly
        </p>
      </div>
      <div className="mt-10 flex items-center gap-5">
        <div className="bg-[#d2d2d2] p-2 text-white rounded-full text-xl flex justify-center items-center cursor-pointer">
            <NavigateBeforeIcon />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {layouts?.map((obj,i) => (
            <div key={i} className={`col-span-1 max-w-44 rounded-lg p-2 flex justify-center items-center cursor-pointer ${selected === obj?.value ? 'bg-theme1':'bg-[#D9D9D9]'}`}
              onClick={() => handleSelect(obj)}
            >
                <img src={obj.img} className="w-44"/>
            </div>
          ))}
            {/* <div className="col-span-1 max-w-44 rounded-lg bg-[#D9D9D9] p-2 flex justify-center items-center cursor-pointer">
                <img src={layoutPreview} className="w-44"/>
            </div> */}
            {/* <div className="col-span-1 max-w-44 rounded-lg bg-[#D9D9D9] p-2 flex justify-center items-center cursor-pointer">
                <img src={layoutPreview} className="w-44"/>
            </div>
            <div className="col-span-1 max-w-44 rounded-lg bg-[#D9D9D9] p-2 flex justify-center items-center cursor-pointer">
                <img src={layoutPreview} className="w-44"/>
            </div>
            <div className="col-span-1 max-w-44 rounded-lg bg-[#D9D9D9] p-2 flex justify-center items-center cursor-pointer">
                <img src={layoutPreview} className="w-44"/>
            </div>
            <div className="col-span-1 max-w-44 rounded-lg bg-[#D9D9D9] p-2 flex justify-center items-center cursor-pointer">
                <img src={layoutPreview} className="w-44"/>
            </div> */}
        </div>
        <div className="bg-[#d2d2d2] p-2 text-white rounded-full text-xl flex justify-center items-center cursor-pointer">
            <NavigateNextIcon />
        </div>
      </div>
    </div>
  );
};

export default ChoseTemplate;
