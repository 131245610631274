import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@iconify/react';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import getCityCodes from '../../../../controllers/Flight/getCityCodes';
import { Input } from '@mui/material';
import axios from "axios";


var cancelTokenSource = null;

const Dropdown = ({ optionLabel = "iata", state, selectedOption, setSelectedOption, index, location }) => {
    // const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [options, setOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    useEffect(() => {
      load();
    }, [value]);

    useEffect(() => {
      if(isOpen)
        focusInput()
    },[isOpen])


    async function load() {
      if (!value) return setOption([]);
      if (value?.icao) return false;
      
      if(cancelTokenSource) {
        cancelTokenSource.cancel('Request canceled.')
      }
      cancelTokenSource = axios.CancelToken.source();
      
      setLoading(true);
      const res = await getCityCodes(value,cancelTokenSource?.token);
      setLoading(false);
      if (res.return) {
        let data = res?.data?.data;
        if (optionLabel) data = data?.filter((obj) => obj[optionLabel]);
        setOption(data);
      }
  
    }
    const handleOptionClick = (option) => {
      if(location === "from"){
        const updatedCities = [...selectedOption];
        updatedCities[index]["from"] = option;
        setSelectedOption(updatedCities);
      }
      else{
        const updatedCities = [...selectedOption];
        updatedCities[index]["to"] = option;
        setSelectedOption(updatedCities);
      }
        setIsOpen(false); 
    };
    const current = location == "from" ?  selectedOption[index]["from"] : selectedOption[index]["to"];
    const updateVlaue = (e)=>{
      setValue(e.target.value)
    }

    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      } else {
        setTimeout(focusInput, 100);
      }
    };

    console.log(selectedOption)

    return (
      <div className='z-20'>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          }  absolute left-0 top-0 w-full h-full`}
          onClick={() => setIsOpen(false)}
        ></div>
        <div className="dropdown relative">
          {
            <div
              className="dropdown-toggle cursor-pointer"
              onClick={(e) => {
                setIsOpen(!isOpen);
                // focusInput();
              }}
            >
              {current?.country ? (
                <div>              
                  {/* <div className={`!font-bold !text-sm flex items-center h-8`} style={{ color: state.secondaryColor }}>
                    {current.city}
                    {current.iata? " ("+current.iata+")" : ""}
                  </div> */}
                  <div>
                    {current.name? current.name : ""}
                  </div>
                </div>
              ) : (
                <span className={`flex items-center font-bold text-sm `} style={{ color: state.secondaryColor }}>
                  Select a location
                </span>
              )}
            </div>
          }
          {isOpen && (
            <div className="realtive z-10 shadow-lg bg-white rounded-lg absolute right-0 left-0 font-poppins" style={{border: "1px solid #cecece"}}>
            <Input 
              placeholder='Enter location'
              className='sticky top-0 px-3 py-2 w-full'
              value={value}
              onChange={updateVlaue}
              onFocus={()=>setIsOpen(true)}
              onBlur={()=> setTimeout(() => setIsOpen(false),500)}
              inputRef={inputRef}
            />
              <div className='overflow-y-auto min-h-20 max-h-60'>
                {loading && <div className="text-center py-2 pt-3">Loading...</div>}
                {(!loading && value == "") && <div className="text-center py-2 pt-3">Enter a location</div>}
                {(!loading && value != "" && options.length == 0) &&  <div className="text-center py-2 pt-3">No location found</div>}
                {options.map((option) => (
                  <div
                    className="flex items-center justify-between px-3 py-4 cursor-pointer transition-all duration-75 hover:bg-slate-100"
                    key={option.name}
                    onClick={(e) => {e.preventDefault();handleOptionClick(option)}}
                  >
                    <div className="flex justify-between gap-3 overflow-hidden">
                      <Icon
                        className="text-4xl text-[#6D7C94]"
                        icon="mdi:airport"
                      />
                      <div className="flex flex-col truncate">
                        <span className="font-bold text-[15px] truncate">
                          {option.name}
                        </span>
                        <div className="font-light truncate">
                          <span>{option.city}</span>,<span>{option.country}</span>
                        </div>
                      </div>
                    </div>
                    <span className="font-bold" style={{ color: state.secondaryColor }}>
                      {option.iata}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default Dropdown;
