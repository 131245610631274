import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import InfoCardLightening from "../../../components/management/InfoCardLightening";

function TourManagementPackageNamePage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");

  const onContinue = () => {
    navigate("/tourManagement/create/description");
  };

  const onSave = () => {};

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <div className="hidden lg:flex">
          <TourManagementSidebar selected={2} />
        </div>

        <div className={Classname.detail}>
          <span className={Classname.title}>
            What is the customer-facing title of your package?
          </span>
          <input
            type="text"
            placeholder="Enter your details"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={Classname.input}
          />
          <InfoCardLightening
            className="mt-10 mb-10"
            description="Start with the main location followed by a colon (:), and include the activity type (e.g. tour), and the important inclusions or unique selling points."
          />
          <span className={Classname.title}>
            Create a package reference code 
            <span className="text-sm text-gray-500 font-normal">
              (optional)
            </span>
          </span>
          <p className={Classname.description}>
            To help you keep track of your packages on Intraverse, you can add
            your internal code. If you do not add one, we will assign one
            automatically.
          </p>
          <input
            type="text"
            placeholder="Enter your details"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
            className={Classname.input}
          />
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageNamePage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
