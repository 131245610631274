import { Check } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TicketManagementFlightChangeStepsHeader from "../../../components/TicketManagement/TicketManagementFlightChangeStepsHeader";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementUploadDocument from "../../../components/TicketManagement/TicketManagementUploadDocument";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementAddRemark from "../../../components/TicketManagement/TicketManagementAddRemark";

function TicketManagementFlightChangeDate() {
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      {/* <div className="w-full lg:text-lg font-bold text-gray-400">
        {`Orders > FEW34X >`} <span className="text-black">Change Date</span>
      </div> */}
      <TicketManagementHeader />
      <TicketManagementFlightChangeStepsHeader step={1} />
      <TicketManagementPassengerSelection />
      <SelectReason />
      <TicketManagementUploadDocument />
      <TicketManagementAddRemark />
      <TicketManagementContactDetails />
      <SelectJourney />
      <TicketActionButtons />
    </div>
  );
}

const SelectReason = () => {
  const [reason, setReason] = useState("");

  const reasons = [
    "Voluntary",
    "Flight reschedule/ Cancellation",
    "Re-issue due to medical reasons",
    "Re-issue due to visa refusal",
    "Other reasons",
  ];

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Select a reason for change</span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          There are two types of changes: Voluntary and Involuntary changes.
          Voluntary changes refer to changes that are made in accordance to the
          fare rules set by airlines. These include modifications that are
          allowed within the allowed guideline. Any other change is considered
          involuntary. Involuntary changes require a document to support reason
          for change. The document would serve as an evidence for reasons behind
          the change and help ensure a smooth processing.
        </span>
      </div>
      <div className="max-w-sm">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reason}
            placeholder="Select a reason"
            onChange={(e) => setReason(e?.target?.value)}
          >
            {reasons.map((r, index) => (
              <MenuItem key={index} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const journeys = [
  {
    selected: true,
    name: "LOS - ADD",
    date: "",
    cabin: "",
    cabinOptions: ["J", "K"],
  },
  {
    selected: false,
    name: "ADD - LHR",
    date: "",
    cabin: "",
    cabinOptions: ["J", "K"],
  },
  {
    selected: false,
    name: "LHR - ADD",
    date: "",
    cabin: "",
    cabinOptions: ["J", "K"],
  },
  {
    selected: false,
    name: "ADD - LOS",
    date: "",
    cabin: "",
    cabinOptions: ["J", "K"],
  },
];

const SelectJourney = () => {
  const [segments, setSegments] = useState(journeys);
  const calendarRef = useRef([...Array(segments.length)]);

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">Select journey to change</span>
      <span className="text-sm text-gray-500 mb-2">
        Select the segment to change
      </span>
      {segments?.map((segment, index) => (
        <div
          className={`flex lg:items-center gap-2 w-full px-2 py-2 rounded-md bg-white ${
            segment.selected ? "justify-between" : ""
          }`}
          key={index}
        >
          <div className="flex flex-col lg:flex-row flex-1 items-center gap-2">
            <div
              className={`flex flex-1 items-center min-h-[48px] w-full text-sm lg:max-w-[30%] rounded-md px-4 border-2 ${
                segment.selected ? "bg-primary1/20" : ""
              } border-gray-200 text-sm`}
            >
              {segment.name}
            </div>
            {segment.selected && (
              <div className="w-full lg:max-w-[30%]">
                <CalendarInput1
                  ref={(el) => (calendarRef.current[index] = el)}
                  className="w-full border border-primary/20 rounded-md p-2"
                  value={segment.date || ""}
                  onChange={(value) => {
                    calendarRef.current[index]?.toggle();
                    setSegments(
                      segments.map((p, idx) =>
                        idx === index
                          ? { ...p, date: value?.start || value }
                          : p
                      )
                    );
                  }}
                  config={{ validDates: [new Date()] }}
                />
              </div>
            )}
            {segment.selected && (
              <div className="w-full lg:max-w-[30%]">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={segment.cabin}
                    placeholder="Select cabin"
                    className="h-10"
                    onChange={(e) => {
                      setSegments(
                        segments.map((p, idx) =>
                          idx === index ? { ...p, cabin: e.target.value } : p
                        )
                      );
                    }}
                  >
                    {segment.cabinOptions.map((cabin, idx) => (
                      <MenuItem value={cabin} key={idx}>
                        {cabin}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            <div
              className={`w-8 h-8 rounded-sm text-xs hidden lg:flex items-center justify-center cursor-pointer ${
                segment.selected
                  ? "bg-primary1 text-white"
                  : "text-[#5A657C] bg-[#CFD9E0]"
              }`}
              onClick={() => {
                setSegments(
                  segments.map((p, idx) =>
                    idx === index ? { ...p, selected: !p.selected } : p
                  )
                );
              }}
            >
              <Check color={"inherit"} fontSize="inherit" />
            </div>
          </div>
          <div
            className={` w-8 h-8 rounded-sm text-xs flex lg:hidden mt-2 items-center justify-center cursor-pointer ${
              segment.selected
                ? "bg-primary1 text-white"
                : "text-[#5A657C] bg-[#CFD9E0]"
            }`}
            onClick={() => {
              setSegments(
                segments.map((p, idx) =>
                  idx === index ? { ...p, selected: !p.selected } : p
                )
              );
            }}
          >
            <Check color={"inherit"} fontSize="inherit" />
          </div>
        </div>
      ))}
    </div>
  );
};

const TicketActionButtons = () => {
  const handleCancel = () => {};

  const handleSearch = () => {};
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSearch}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Search Flights
      </button>
    </div>
  );
};

export default TicketManagementFlightChangeDate;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
