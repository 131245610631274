import React from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import TourManagementAvailabilityHeader from "../../../components/header/TourManagementAvailabilityHeader";
import { useNavigate } from "react-router-dom";

function TourManagementPackageAvailabilityAddOnPage() {
  const navigate = useNavigate();
  const handleTierPrice = () => {};
  const onContinue = () => {
    navigate("/tourManagement/create/location");
  };

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={4} />
        <div className={Classname.detail}>
          <InfoCardLightening description="We have updated our experience. See what has changed here" />
          <TourManagementAvailabilityHeader active={4} />
          <div className={Classname.title}>
            Add-ons <span className={Classname.titleOptional}>(optional)</span>{" "}
          </div>
          <div className={Classname.title}>What’s an add-on?</div>
          <div>
            Sell additional services or items for your activities to enhance
            travellers’ experience.
          </div>
          <div className="flex items-center gap-4">
            <input
              type="text"
              className={Classname.input}
              placeholder="Please select"
            />
            <div className="bg-blue-700 h-12 mt-2 w-12 rounded-md flex items-center justify-center cursor-pointer">
              <img src="/IconSearchWhite.svg" />
            </div>
          </div>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton} onClick={handleBack}>
              Back
            </button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Save and continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageAvailabilityAddOnPage;

const Classname = {
  gridContainer: "grid grid-cols-4 gap-4",
  gridItem: "flex text-sm items-center h-8 gap-2 p-4",
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  titleOptional: "text-sm text-gray-500 font-normal",
  input: "flex w-full h-12 border border-gray-300 rounded-md px-4 mt-2",
  inputSmall: "flex w-10 h-8 border bg-gray-200 rounded-md px-2 mt-2",
  inputPrice: "flex w-24 h-8 border bg-gray-200 rounded-md px-2 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 mt-10 cursor-pointer text-primary1 py-4 text-blue-500",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
