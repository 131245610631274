import { AccessTime, FlightOutlined } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import getFlightDuration from "../../features/flight/getFlightDuration";

export default function FlightDisplayV2({ flight: data, body, segments }) {
  let flight = data;
  //test
  const departureDateTime = moment(
    `${flight?.departureDate} ${flight?.departureTime}`,
    "YYYY-MM-DD HH:mm"
  );
  const arrivalDateTime = moment(
    `${flight?.arrivalDate} ${flight?.arrivalTime}`,
    "YYYY-MM-DD HH:mm"
  );
  const duration = getFlightDuration(
    departureDateTime,
    arrivalDateTime,
    "short"
  );

  const airlines = segments
    ?.map((obj) =>
      obj?.flights?.map((flight) => ({
        name: flight?.carrierName,
        icon: flight?.carrierIcon,
      }))
    )
    ?.flat();
  let uniqueAirlines = airlines?.reduce((acc, curr) => {
    if (acc.findIndex((item) => item.name === curr.name) === -1) {
      acc.push(curr);
    }
    return acc;
  }, []);
  uniqueAirlines = uniqueAirlines?.filter(
    (obj) => obj.name !== flight?.flights[0].carrierName
  );

  return (
    flight &&
    flight?.flights[0] && (
      <div className="flex flex-1 flex-col px-4 pt-4 ">
        <div className="items-center flex gap-2 w-fit">
          <p className="w-full text-sm text-[#818083]">
            {" "}
            <span className="text-black">DEPARTURE</span> •{" "}
            {flight?.flights[0].carrierName}
          </p>
          <div className="text-[10px] text-primary1 bg-primary1/10 px-2 py-1 rounded-md font-bold">
            AMADEUS
          </div>
        </div>
        <div className="flex">
          <div className="flex sm:flex-col flex-wrap-reverse items-center lg:justify-center md:justify-center p-3 md:w-auto  text-center">
            {uniqueAirlines?.length ? (
              <div className="flex flex-wrap gap-2 items-center w-full py-2">
                {uniqueAirlines?.map((obj, i) => (
                  <div
                    className="h-[30px]"
                    key={i}
                    title="Interchanging Airlines"
                  >
                    <img
                      alt="airline"
                      src={obj?.icon}
                      className="w-full h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            ) : null}
            <div className="flex items-center w-14 lg:w-20 lg:justify-center">
              <img
                alt="airline"
                src={flight?.flights[0].carrierIcon}
                className=" h-14 lg:h-20 object-contain"
              />
            </div>
          </div>

          <div className="flex flex-1 mr-6">
            {/* <p className=''>{flight?.flights[0].departureAirportName}</p> */}
            <div className="flex flex-1 justify-between items-center relative gap-2 mt-4">
              <div className="flex flex-col justify-center gap-1 h-full items-end text-xs text-end max-w-[200px]">
                <div className="flex items-center">
                  <b>{flight?.departureTime}</b>
                </div>
                <span className="text-end hidden lg:flex font-bold">
                  {flight?.departureAirport}({flight?.departureLocation})
                </span>{" "}
                <span className="text-end flex lg:hidden">
                  {flight?.departureLocation}
                </span>{" "}
                <span className="hidden lg:flex">{flight?.departureCity}</span>
              </div>
              <div className="flex flex-1 items-center flex-col ">
                <div className="flex flex-1 w-full items-center ">
                  <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
                  <div className="border-b flex-1 border-[#A4B1C1]"></div>
                  <div className="rounded-full bg-[#F0F0F0] px-2 py-1 flex items-center justify-center relative group">
                    <img
                      src="/IconPlane.svg"
                      alt=""
                      className="w-3 object-contain gap-2"
                    />
                    <span className="text-xs text-primary1 ml-1">
                      {flight?.numberOfStops || 0} stop
                    </span>
                    <div className=" absolute hidden group-hover:flex bg-primary1 py-1 px-3 text-white text-xs rounded-md text-center whitespace-nowrap bottom-8">
                      3 hours 30 mins
                    </div>
                  </div>
                  <div className="border-b flex-1 border-[#A4B1C1]"></div>
                  <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
                </div>
                <small className="whitespace-nowrap py-1 flex lg:hidden items-center gap-1">
                  <AccessTime className="!w-4 !h-4" />
                  {duration}
                </small>
              </div>
              <div className="flex flex-col justify-center gap-1 h-full items-start text-xs text-start max-w-[200px]">
                <div className="flex items-center">
                  <b>{flight?.arrivalTime}</b>
                </div>
                <span className="text-start flex lg:hidden font-bolg">
                  {flight?.arrivalLocation}
                </span>{" "}
                <span className="text-start hidden lg:flex">
                  {flight?.arrivalAirport}({flight?.arrivalLocation})
                </span>{" "}
                <span className="hidden lg:flex">{flight?.arrivalCity}</span>
              </div>
            </div>
          </div>
          <small className="whitespace-nowrap py-1 hidden lg:flex items-center gap-1">
            <AccessTime className="!w-4 !h-4" />
            {duration}
          </small>
        </div>
        <div className="pt-4 border-t-2 w-full mt-4"></div>
        <div className="items-center flex gap-2 w-fit">
          <p className="w-full text-sm text-[#818083]">
            {" "}
            <span className="text-black">RETURN</span> •{" "}
            {flight?.flights[0].carrierName}
          </p>
          <div className="text-[10px] text-primary1 bg-primary1/10 px-2 py-1 rounded-md font-bold">
            AMADEUS
          </div>
        </div>

        <div className="flex">
          <div className="flex sm:flex-col flex-wrap-reverse items-center lg:justify-center md:justify-center p-3 md:w-auto  text-center">
            {uniqueAirlines?.length ? (
              <div className="flex flex-wrap gap-2 items-center w-full py-2">
                {uniqueAirlines?.map((obj, i) => (
                  <div
                    className="h-[30px]"
                    key={i}
                    title="Interchanging Airlines"
                  >
                    <img
                      alt="airline"
                      src={obj?.icon}
                      className="w-full h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            ) : null}
            <div className="flex items-start lg:items-center w-14 lg:w-20 justify-start lg:justify-center">
              <img
                alt="airline"
                src={flight?.flights[0].carrierIcon}
                className=" h-14 lg:h-20 object-contain"
              />
            </div>
          </div>

          <div className="flex flex-1 mr-6">
            <div className="flex flex-1 justify-between items-center relative gap-2 mt-4">
              <div className="flex flex-col justify-center gap-1 h-full items-end text-xs text-end max-w-[200px]">
                <div className="flex items-center">
                  <b>{flight?.departureTime}</b>
                </div>
                <span className="text-start lg:text-end font-bold flex lg:hidden">
                  {flight?.departureLocation}
                </span>{" "}
                <span className="text-start lg:text-end hidden lg:flex">
                  {flight?.departureAirport}({flight?.departureLocation})
                </span>{" "}
                <span className="hidden lg:flex">{flight?.departureCity}</span>
              </div>
              <div className="flex flex-1 items-center flex-col ">
                <div className="flex flex-1 w-full items-center ">
                  <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
                  <div className="border-b flex-1 border-[#A4B1C1]"></div>
                  <div className="rounded-full bg-[#F0F0F0] px-2 py-1 flex items-center justify-center relative group">
                    <img
                      src="/IconPlane.svg"
                      alt=""
                      className="w-3 object-contain gap-2"
                    />
                    <span className="text-xs text-primary1 ml-1">
                      {flight?.numberOfStops || 0} stop
                    </span>
                    <div className=" absolute hidden group-hover:flex bg-primary1 py-1 px-3 text-white text-xs rounded-md text-center whitespace-nowrap bottom-8">
                      3 hours 30 mins
                    </div>
                  </div>
                  <div className="border-b flex-1 border-[#A4B1C1]"></div>
                  <div className="w-2 h-2 rounded-full border border-[#A4B1C1]"></div>
                </div>
                <small className="whitespace-nowrap py-1 flex lg:hidden items-center gap-1">
                  <AccessTime className="!w-4 !h-4" />
                  {duration}
                </small>
              </div>
              <div className="flex flex-col justify-center gap-1 h-full items-start text-xs text-start max-w-[200px]">
                <div className="flex items-center relative">
                  <b>{flight?.arrivalTime}</b>
                  <span className="text-primary1 text-xs -top-4 lg:-top-2 left-4 lg:left-8 absolute whitespace-nowrap">
                    +1 day
                  </span>
                </div>
                <span className="text-end lg:text-start hidden lg:flex">
                  {flight?.arrivalAirport}({flight?.arrivalLocation})
                </span>{" "}
                <span className="text-end lg:text-start flex lg:hidden font-bold">
                  {flight?.arrivalLocation}
                </span>{" "}
                <span className="hidden lg:flex">{flight?.arrivalCity}</span>
              </div>
            </div>
          </div>
          <small className="whitespace-nowrap py-1 hidden lg:flex items-center gap-1">
            <AccessTime className="!w-4 !h-4" />
            {duration}
          </small>
        </div>
      </div>
    )
  );
}
