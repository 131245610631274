import { store } from "../../redux/store";
import fetchServer from "../fetchServer";


export default async function payForCart(data) {
  var result = {return: 0,msg: 'Failed checking out cart',data: []}

  let token = store.getState().user.userData.accessToken || store.getState().user.userData.agent.token;

  let headers = { Authorization: `Bearer ${token}` };

  await fetchServer({method: "POST",url: `/payment/v1/payment/cart`,data,
    headers
  })
  .then((res) => {
    if(res) {
      if(res.status === 200) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      } else if (res?.data?.error) result["msg"] = res.data.error;
    } 
  })
  .catch((err) => {
    console.log(err.message,err);
    if(err.message === 'Network Error!') {
      result['msg'] = 'Network Error!';
      result['error'] = 'Please check your connection.';
    }
  })

  return result;
}