import React, { useEffect, useState } from 'react'
import getPlans from '../../controllers/subscription/getPlans';
import { CircularProgress } from '@mui/material';
import { PricePlanCard } from '../Welcome/LearnPricing';

export default function SubscriptionPlans() {
    const [loading,setLoading] = useState(true)
    const [pricePlans,setPricePlans] = useState([])
  
    useEffect(() => {
      load();
    },[])
  
    async function load() {
      setLoading(true);
      const res = await getPlans();
      if(res.return) {
        setPricePlans(res.data?.data?.filter(obj => obj?.isActive))
      }
      setTimeout(() => 
        setLoading(false)
      ,1000)
    }
  
  return (
    <div className='flex flex-col gap-3 pd-md items-center'>
      <div className='w-full'>
        <h5>Choose a subscription plan</h5>
        <p>Select a plan that suits your business needs</p>
      </div>
      <div className='w-full'>
        <hr />
      </div>
      <div className='flex flex-wrap sm:flex-nowrap snap-x snap-mandatory snap-always overflow-y-hidden overflow-x-auto min-w-[100px] max-w-full gap-4 py-6'>
          {loading ? 
          <div className='flex flex-col items-center justify-center'>
              <CircularProgress />
          </div>
          :
          pricePlans.map((obj,i) => (
              <PricePlanCard obj={{...obj}} noCheck={true} key={i} />
              ))
          }
      </div>
    </div>
  )
}
