import { useSelector } from "react-redux";
import LogoStandard from "../../../Logo/Logo";

const Logo = (state) => {
  const {user} = useSelector(state => state.user.userData)
  state = state.state;
    const companyLogo = state.logoImage ? (
        <img src={state.logoImage} alt="Company Logo" className="w-14" />
      ) : (
        <div className={`bg-[#D9D9D9] p-2 py-3 font-extrabold w-36 flex justify-center text-center`} >
          Company Logo
        </div>
      );

    return <LogoStandard iconOnly overrideImage={user?.detail?.agencyLogo} />
    return (
        <div className='overflow-hidden relative width-full'>
            {companyLogo}
        </div>
    );


};

export default Logo;