import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import CountriesInput from "../../../components/form/CountriesInput";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TourManagementPackageKeywordsPage() {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const onContinue = () => {
    navigate("/tourManagement/create/guide");
  };

  const onSave = () => {};

  const OnKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <div className="hidden lg:flex">
          <TourManagementSidebar selected={5} />
        </div>
        <div className={Classname.detail}>
          <span className={Classname.title}>
            Add keywords to your product  {" "}
            <span className="text-sm text-gray-500 font-normal">
              (optional)
            </span>
          </span>
          <p className={Classname.description}>
            Keywords work as tags for your product and help customers find it
            when they search by a theme or their interests. Try to use all 15
            for maximum reach.
          </p>
          <div className="mb-4"></div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Search keywords
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={keyword}
              label="Search keywords"
              onChange={OnKeywordChange}
            >
              <MenuItem value="Architecture">Architecture</MenuItem>
              <MenuItem value="Culture">Culture</MenuItem>
              <MenuItem value="Food">Food</MenuItem>
              <MenuItem value="History">History</MenuItem>
            </Select>
          </FormControl>
          <span className="mt-10 text-gray-500">SUGGESTIONS</span>
          <div className={Classname.suggestionContainer}>
            <div className={Classname.suggestion}>Architecture</div>
            <div className={Classname.suggestion}>Culture</div>
            <div className={Classname.suggestion}>Food</div>
            <div className={Classname.suggestion}>History</div>
          </div>
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageKeywordsPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  suggestionContainer: "flex flex-wrap gap-4 mt-4",
  suggestion:
    "text-primary1 text-sm border border-primary1 px-3 py-1 rounded-md",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
