import React, { useState } from 'react'
import Icon from '../../../components/HOC/Icon'
import ReactQuill from 'react-quill';
import Button1 from '../../../components/form/Button1';
import updateProfile from '../../../controllers/user/updateProfile';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../redux/reducers/userSlice';
import { enqueueSnackbar } from 'notistack';

export default function Policy() {
    const {user} = useSelector(state => state.user.userData);
    const parsedPolicy = JSON.parse(user?.detail?.cancellationPolicy || '{}')
    const [editObj,setEditObj] = useState();
    const [viewObj,setViewObj] = useState();
    const data = [
        {key: 'cancellationPolicy',label: 'Cancellation Policy',content: parsedPolicy?.cancellationPolicy},
        {key: 'privacyPolicy',label: 'Privacy Policy',content: parsedPolicy?.privacyPolicy},
        {key: 'terms',label: 'Terms and conditions',content: parsedPolicy?.terms},
    ]

    const dispatch = useDispatch();
    
    async function handleSubmit(obj) {
        const modObj = {
            ...parsedPolicy,
            ...obj
        }
        const res = await updateProfile({cancellationPolicy: JSON.stringify(modObj)})
        if(res.return) {
            dispatch(setUser(res?.data))
            enqueueSnackbar(res.msg,{variant: 'success'})
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }

    if(viewObj)
    return (
        <div className='flex flex-col gap-4'>
            <div>
                <button onClick={() => setViewObj()} className='flex gap-2 items-center'><Icon icon='ep:arrow-left-bold' className='!w-4 !h-4' />{viewObj?.label}</button>
            </div>
            <ViewPolicy data={viewObj} />
        </div>
    )
    
  return !editObj ? (
    <div className='flex flex-col gap-6'>
        <h5>Business Policies</h5>
        <div className='flex flex-col gap-6'>
            {data?.map((obj,i) => (
                <div key={i} className='flex gap-4 justify-between w-[300px]'>
                    <button onClick={() => setViewObj(obj)}>{obj?.label}</button>
                    <div className='flex gap-4 items-center'>
                        {/* <button onClick={() => setViewObj(obj)}><Icon icon='mdi:eye' className='text-primary/70' /></button> */}
                        <button onClick={() => setEditObj(obj)}><Icon icon='ic:round-edit' className='text-theme1' /></button>
                    </div>
                </div>
            ))}
        </div>

    </div>
  ) : (
    <div className='flex flex-col gap-4'>
        <div>
            <button onClick={() => setEditObj()} className='flex gap-2 items-center'><Icon icon='ep:arrow-left-bold' className='!w-4 !h-4' /> {editObj?.label}</button>
        </div>
        <PolicyForm callback={handleSubmit} data={editObj} />
    </div>
  )
}

// cancellationPolicy: {
//     type: String,
//   },
//   customerService: {
//     email: String,
//     phone: String,
//   },

function ViewPolicy({data}) {

    return (
        <div className='flex gap-4 flex-col'>
            {/* <b>{data?.label}</b> */}
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
    )
}

function PolicyForm({data,callback}) {
    const [value,setValue] = useState(data?.content || '');
    const [loading,setLoading] = useState(false);

    function handleChange(val) {
        setValue(val)
    }
    
    async function handleSubmit() {
        setLoading(true);
        callback && await callback({[data?.key]: value})
        setLoading(false);
    }
    
    return (
        <div className='flex flex-col gap-4'>
            <div>
                {/* <h5>{data?.label}</h5> */}
                <p>Enter your {(data?.label || 'policy')?.toLowerCase()} notice which your customers will see when they place an order:</p>
            </div>
            <ReactQuill value={value} className='h-[200px]'
                onChange={(content,delta,source,editor) => handleChange(editor.getHTML())}
                theme="snow"
                modules={{
                    toolbar: [
                    [{ 'header': [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'],
                    ['clean']
                    ]
                }}
            />
            <Button1 loading={loading} onClick={handleSubmit}>Save Policy</Button1>
        </div>
    )
}