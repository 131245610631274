import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URLdecode } from "../../utils/url";
import useDateFormat from "../../hooks/useDateFormat";
import { useTours } from "../../hooks/useTours";

function BookingQuestionMeetingAndPickup({ question, index }) {
  const { handleAnswerChange } = useTours();
  const { tour, tourAvailability, tourGroupQuestions } = useSelector(
    (state) => state.tourData
  );
  const arrivalQuestion = tourGroupQuestions.find(
    (q) => q.id === "TRANSFER_ARRIVAL_MODE"
  );

  const [locations, setLocations] = useState(tour?.locations || []);
  const [option, setOption] = useState("");
  const [meetingPoint, setMeetingPoint] = useState();
  const [pickupIncluded, setPickupIncluded] = useState(false);
  const [later, setLater] = useState();
  const params = URLdecode();
  let pickupType = arrivalQuestion?.answer;
  let freeTextAllowed =
    tour?.logistics?.travelerPickup?.allowCustomTravelerPickup;

  const getMeetingPoint = () => {
    let start = tour?.logistics?.start?.at(0)?.location?.ref;
    if (start) {
      let tempLoc = start;
      let meetLocation = locations?.find(
        (location) => location?.reference === tempLoc
      );
      setMeetingPoint({
        id: tempLoc,
        name: meetLocation?.name,
      });
    }
  };

  const parseLocations = () => {
    let travellerPickupLocations =
      tour?.logistics?.travelerPickup?.locations || [];
    let baseLocations = tour?.locations || [];
    if (baseLocations) {
      baseLocations = [...baseLocations].sort((a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        return nameA > nameB ? 1 : -1;
      });

      if (travellerPickupLocations) {
        baseLocations = baseLocations?.map((location) => {
          let travellerPickup = travellerPickupLocations?.find(
            (pickup) => pickup?.location?.ref === location?.reference
          );
          let updatedLocation = { ...location };
          if (travellerPickup) {
            updatedLocation = {
              ...updatedLocation,
              pickupType: travellerPickup?.pickupType,
            };
          }
          return updatedLocation;
        });
      }

      pickupType =
        arrivalQuestion?.answer === "AIR"
          ? "AIRPORT"
          : arrivalQuestion?.answer === "SEA"
          ? "PORT"
          : "HOTEL";
      let contactSupplier = baseLocations?.find(
        (loc) => loc?.reference === "CONTACT_SUPPLIER_LATER"
      );
      let pureLocations = baseLocations?.filter(
        (loc) =>
          loc?.reference !== "CONTACT_SUPPLIER_LATER" &&
          loc?.reference !== "MEET_AT_DEPARTURE_POINT"
      );
      if (pickupType) {
        let pickupTypeAvailable = baseLocations?.find((loc) => loc?.pickupType);
        if (pickupTypeAvailable) {
          if (pickupType === "AIRPORT" || pickupType === "PORT") {
            pureLocations = pureLocations.filter(
              (loc) => loc?.pickupType === pickupType
            );
          } else {
            pureLocations = pureLocations.filter(
              (loc) =>
                loc?.pickupType === "HOTEL" ||
                loc?.pickupType === "LOCATION" ||
                loc?.pickupType === "OTHER"
            );
          }
        }
      }
      setLocations(pureLocations);
      if (contactSupplier) setLater(contactSupplier);
    }
  };

  const determineIfPickupIncluded = () => {
    let productOptions = tour?.productOptions;
    let selectedProductOptionCode = params?.productOptionCode;
    if (productOptions) {
      if (selectedProductOptionCode) {
        let selectedProductOption = productOptions?.find(
          (productOption) =>
            productOption?.productOptionCode === selectedProductOptionCode
        );
        let description = selectedProductOption?.description;
        if (description && description?.includes("Pickup included")) {
          setPickupIncluded(true);
        }
      }
    }
    if (
      tour?.logistics?.travelerPickup?.pickupOptionType === "PICKUP_EVERYONE" ||
      tour?.logistics?.travelerPickup?.pickupOptionType ===
        "PICKUP_AND_MEET_AT_START_POINT"
    ) {
      setPickupIncluded(true);
    }
  };

  const updatingPickup = () => {
    if (pickupIncluded) {
      if (option === "meetingpoint") {
        if (meetingPoint) {
          handleAnswerChange({
            value: "MEET_AT_DEPARTURE_POINT",
            field: question.id,
            group: true,
            questionIndex: index,
          });
        }
      }
      if (option === "later") {
        if (later) {
          handleAnswerChange({
            value: later?.reference,
            field: question.id,
            group: true,
            questionIndex: index,
          });
        }
      }
      if (option === "custom" && freeTextAllowed) {
        handleAnswerChange({
          value: "FREETEXT",
          field: question.id,
          group: true,
          questionIndex: index,
          unit: true,
        });
      } else {
        handleAnswerChange({
          value: "LOCATION_REFERENCE",
          field: question.id,
          group: true,
          questionIndex: index,
          unit: true,
        });
      }
    } else {
      if (meetingPoint) {
        handleAnswerChange({
          value: "MEET_AT_DEPARTURE_POINT",
          field: question.id,
          group: true,
          questionIndex: index,
        });
      }
    }
  };

  const unitChange = () => {
    if (
      question?.answer === "MEET_AT_DEPARTURE_POINT" &&
      question?.unit !== "LOCATION_REFERENCE"
    ) {
      handleAnswerChange({
        value: "LOCATION_REFERENCE",
        field: question.id,
        group: true,
        questionIndex: index,
        unit: true,
      });
    }
  };

  useEffect(() => {
    getMeetingPoint();
    determineIfPickupIncluded();
  }, [tour, arrivalQuestion?.answer, pickupType]);

  useEffect(() => {
    parseLocations();
  }, [tour, arrivalQuestion?.answer, pickupType]);

  useEffect(() => {
    unitChange();
  }, [question]);

  useEffect(() => {
    updatingPickup();
  }, [option, pickupIncluded, meetingPoint]);

  useEffect(() => {
    if (question?.unit === "FREETEXT") {
      handleAnswerChange({
        value: "",
        field: question.id,
        group: true,
        questionIndex: index,
      });
    }
  }, [question?.unit]);

  const handleMeetingPointSelect = () => {
    for (let i = 0; i < tourGroupQuestions?.length; i++) {
      if (tourGroupQuestions[i].id === "PICKUP_POINT") {
        handleAnswerChange({
          value: "MEET_AT_DEPARTURE_POINT",
          field: tourGroupQuestions[i].id,
          group: true,
          questionIndex: i,
        });
      }
      if (tourGroupQuestions[i].id === "TRANSFER_ARRIVAL_MODE") {
        handleAnswerChange({
          value: "OTHER",
          field: tourGroupQuestions[i].id,
          group: true,
          questionIndex: i,
        });
      }
    }
  };

  const handleCustomLocationSelect = () => {
    for (let i = 0; i < tourGroupQuestions?.length; i++) {
      if (
        tourGroupQuestions[i].id === "TRANSFER_ARRIVAL_MODE" &&
        arrivalQuestion
      ) {
        handleAnswerChange({
          value: "OTHER",
          field: tourGroupQuestions[i].id,
          group: true,
          questionIndex: i,
        });
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <span className={Classname.detailTitle}>Meeting and Pickup</span>
      <div className="w-full mt-2 flex flex-col">
        {pickupIncluded ? (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={(e) => setOption(e.target.value)}
              value={option}
            >
              {pickupIncluded && (
                <>
                  <FormControlLabel
                    value="pickup"
                    control={<Radio />}
                    label="I’d like to be picked up"
                  />

                  {option === "pickup" && (
                    <>
                      <ArrivalQuestions
                        locations={locations}
                        freeTextAllowed={freeTextAllowed}
                      />
                    </>
                  )}
                </>
              )}

              <FormControlLabel
                value="meetingpoint"
                control={<Radio onClick={() => handleMeetingPointSelect()} />}
                label="I’ll make my own way to the meeting point"
              />

              {meetingPoint && option === "meetingpoint" && (
                <div className="flex gap-2 items-center py-2 px-4">
                  <img
                    src="/IconLocationGreen.svg"
                    alt=""
                    className={"w-5 object-contain"}
                  />
                  <span>{meetingPoint?.name}</span>
                </div>
              )}
              {freeTextAllowed && (
                <>
                  <FormControlLabel
                    value="custom"
                    control={
                      <Radio onClick={() => handleCustomLocationSelect()} />
                    }
                    label="Custom location"
                  />
                  {option === "custom" && (
                    <div className={Classname.detailInput}>
                      <input
                        type="text"
                        className={Classname.detailInputBar}
                        value={question?.answer}
                        maxLength={question?.maxLength}
                        onChange={(e) => {
                          handleAnswerChange({
                            value: e.target.value,
                            field: question.id,
                            group: true,
                            questionIndex: index,
                          });
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {later && (
                <FormControlLabel
                  value="later"
                  control={<Radio />}
                  label="I'll decide later"
                />
              )}
            </RadioGroup>
          </FormControl>
        ) : (
          <>
            {meetingPoint && (
              <div className="flex gap-2 items-center py-2 ">
                <img
                  src="/IconLocationGreen.svg"
                  alt=""
                  className={"w-5 object-contain"}
                />
                <span>{meetingPoint?.name}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BookingQuestionMeetingAndPickup;

const ArrivalQuestions = ({ locations, freeTextAllowed }) => {
  const { capitalizeFirstLetter } = useDateFormat();
  const { handleAnswerChange } = useTours();
  const { tour, tourAvailability, tourGroupQuestions } = useSelector(
    (state) => state.tourData
  );

  return (
    <div className={Classname.detailInputsContainer}>
      {tourGroupQuestions?.map((question, index) =>
        !question?.pickup ? (
          <></>
        ) : question?.id === "PICKUP_POINT" ? (
          <div className="flex flex-col w-full">
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12 mt-2"
                value={question?.answer}
                onChange={(e) => {
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  });
                }}
              >
                {locations?.map((answer, index1) => (
                  <MenuItem value={answer?.reference} key={index1}>
                    <div className="flex gap-2 items-center">
                      <img
                        src="/IconLocationGreen.svg"
                        alt=""
                        className={"w-5 object-contain"}
                      />
                      <span>{answer?.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : question.allowedAnswers ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {question.allowedAnswers?.map((answer, idx) => (
                  <MenuItem value={answer} key={idx}>
                    {capitalizeFirstLetter(answer)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : question?.unit === "LOCATION_REFERENCE" ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {locations?.map((answer, index1) => (
                  <MenuItem value={answer?.reference} key={index1}>
                    <div className="flex gap-2 items-center">
                      <img
                        src="/IconLocationGreen.svg"
                        alt=""
                        className={"w-5 object-contain"}
                      />
                      <span>{answer?.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div
            key={index}
            className={
              question?.units
                ? Classname.detailInputContainer1
                : Classname.detailInputContainer
            }
          >
            <div className={Classname.detailInputContainer}>
              <span className={Classname.detailInputLabel}>
                {question?.label}
                {question?.required === "MANDATORY" ? "*" : ""}
              </span>
              <div className={Classname.detailInput}>
                <input
                  type="text"
                  className={Classname.detailInputBar}
                  value={question?.answer}
                  maxLength={question?.maxLength}
                  onChange={(e) =>
                    handleAnswerChange({
                      value: e.target.value,
                      field: question.id,
                      group: true,
                      questionIndex: index,
                    })
                  }
                />
              </div>
            </div>

            {question?.units &&
              (question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
                ? freeTextAllowed
                : true) && (
                <div className={"w-44 mt-2"}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="h-12"
                      value={question?.unit}
                      onChange={(e) =>
                        handleAnswerChange({
                          value: e.target.value,
                          field: question.id,
                          group: true,
                          questionIndex: index,
                          unit: true,
                        })
                      }
                    >
                      {question?.units?.map((unit, idx) => (
                        <MenuItem value={unit} key={idx}>
                          {capitalizeFirstLetter(unit)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
          </div>
        )
      )}
    </div>
  );
};

const Classname = {
  detailTitle: "text-xl font-bold",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
  detailInputsContainer: "flex justify-between gap-4 flex-wrap",
  detailInputContainer: "flex flex-col flex-1 min-w-[45%]",
  detailInputContainer1:
    "flex flex-row flex-grow flex-1 w-full items-end gap-4",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
};
