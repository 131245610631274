import {
  ArrowForward,
  Check,
  ChevronLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TicketManagementFlightOrderDetailStatusContainer from "../../../components/TicketManagement/TicketManagementFlightOrderDetailStatusContainer";
import { Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import { useDispatch } from "react-redux";
import { setConfirmFlightRefundRequestModal } from "../../../redux/reducers/modalSlice";
import ConfirmFlightRefundRequestModal from "../../../components/modal/ConfirmFlightRefundRequestModal";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import { URLdecode } from "../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import getBooking from "../../../controllers/booking/getBooking";
import moment from "moment";
import CustomMenu from "../../../components/utils/CustomMenu";
import { OrderMenus } from "../../Orders/OrdersData";
import Icon from "../../../components/HOC/Icon";
import { StatusCard } from "../../Orders/Flight/Booked/StatusCard";
import { getAgeType } from "../../../utils/booking/getAgeType";
import StatusBar from "../../Orders/Flight/Booked/StatusBar";

function TicketManagementFlightOrderDetail() {
  const {id} = useParams()
  const [order, setOrder] = useState();
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id]);

  const fetch = async () => {
    if(!id) return setLoading(false);

    setLoading(true);
    const res = await getBooking(id);
    setLoading(false);
    if (res.return) {
      setOrder(res.data);
    }
  };

  return (
    <div className="flex flex-col w-full p-4 bg-[#F8F8F8]">
      <TicketManagementHeader />
      <TicketManagementLoadingModal />
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex flex-col flex-1">
          <TicketUpdateStatusHeader />
          <TicketActionChoices order={order} />
          <OrderDetail order={order} reload={fetch} />
          <FlightDetails order={order} />
          <Passengers order={order} />
          <Baggage />
          <FareRules order={order} />
          <Contact />
        </div>
        {/* <TicketManagementFlightOrderDetailStatusContainer order={order} /> */}
        <div className="self-start bg-secondary w-full sm:w-auto">
          <StatusBar
            // changeable={changable}
            needsReview={order?.booking?.flightBooking[0]?.needsReview}
            data={order}
            // cancelOrder={() => setOpenCancelOrder(orderData?._id)}
          />
        </div>
      </div>
    </div>
  );
}

export default TicketManagementFlightOrderDetail;

const TicketActionChoices = ({order}) => {
  const bookingData = order?.booking?.flightBooking?.at(0);
  const voidTime = new Date(bookingData?.voidDeadline || null)

  const navigate = useNavigate();
  const params = URLdecode();
  const choices = [
    {
      name: "Add bag",
      onClick: () => {
        navigate("/order/flight/ticket/addbag");
      },
    },
    {
      name: "Change Date",
      onClick: () => {
        navigate("/order/flight/ticket/changedate");
      },
    },
    {
      name: "Change Name",
      onClick: () => {
        navigate("/order/flight/ticket/changeinfo");
      },
    },
    {
      name: "Refund",
      onClick: () => {
        navigate("/order/flight/ticket/refund");
      },
    },
    {
      name: "Void",
      onClick: () => {
        navigate("/order/flight/ticket/void/"+order?.booking?._id);
      },
      state: {
        disabled: voidTime - new Date() <= 0,
      }
    },
  ];
  if (params?.status) return <></>;

  return (
    <div className="flex gap-2 lg:gap-4 p-2 lg:p-4 bg-white border-[2px] rounded-md flex-wrap">
      {choices.map(({ name, onClick, state }, index) => (
        <button disabled={state?.disabled}
          key={index}
          className={"flex flex-1 py-2 lg:py-3 px-2 rounded-md border-[2px] font-bold text-[#71717E] border-[#71717E] items-center justify-center text-xs lg:text-base "+(state?.disabled ? 'cursor-not-allowed':'')}
          onClick={onClick}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

const TicketUpdateStatusHeader = ({order}) => {
  const params = URLdecode();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bookingData = order?.booking?.flightBooking?.at(0);
  const voidTime = new Date(bookingData?.voidDeadline || null)


  if (!params?.status) return <></>;

  const DateChangeReviewStatus = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
          <img
            src="/IconTicketStatus.svg"
            alt=""
            className="w-3 object-contain"
          />
        </div>
        <span>Date change request is under review</span>
      </div>
    );
  };
  const NameChangeReviewStatus = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
          <img
            src="/IconTicketStatus.svg"
            alt=""
            className="w-3 object-contain"
          />
        </div>
        <span>Name change request is under review</span>
      </div>
    );
  };
  const RefundReviewStatus = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
          <img
            src="/IconTicketStatus.svg"
            alt=""
            className="w-3 object-contain"
          />
        </div>
        <span>Name change request is under review</span>
      </div>
    );
  };
  const ReIssueRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Re-issue rejected</span>
        </div>
        <p className="text-gray-400 font-normal">
          The status has been changed to Reissue Rejected. The reason: If
          YVR-YQR is changed to September 29, 2024, it will not be possible to
          connect flights
        </p>
      </div>
    );
  };
  const NameChangeRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Name change rejected</span>
        </div>
        <p className="text-gray-400 font-normal">
          The status has been changed to name change Rejected. The reason:
          Airline rejected request
        </p>
      </div>
    );
  };
  const RefundRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Refund requested was rejected</span>
        </div>
        <p className="text-gray-400 font-normal">
          The status has been changed to Reissue Rejected. The reason: If
          YVR-YQR is changed to September 29, 2024, it will not be possible to
          connect flights
        </p>
      </div>
    );
  };
  const VoidRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Void was rejected</span>
        </div>
        <p className="text-gray-400 font-normal">
          LCC tickets can not be voided
        </p>
      </div>
    );
  };
  const ChangeOrderProcessing = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3 text-primary1">
        <img
          src="/IconFlightTicket.svg"
          alt=""
          className="w-7 object-contain"
        />

        <span>Change order processing</span>
      </div>
    );
  };
  const RefundProcessing = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3 text-primary1">
        <img
          src="/IconFlightTicket.svg"
          alt=""
          className="w-7 object-contain"
        />

        <span>Change order processing</span>
      </div>
    );
  };
  const VoidProcessing = () => {
    return (
      <div className="flex flex-col gap-2 py-3 text-primary1">
        <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base text-primary1">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>

          <span>Void processing</span>
        </div>
        <p className="text-gray-400 font-normal ml-10">
          We are currently processing your void request. The final decision is
          dependent on the ticket fare rules and airline policy
        </p>
      </div>
    );
  };
  const ChangeDateIssuedStatus = () => {
    const actionButtons = [
      {
        name: "Change",
        onClick: () => {
          navigate("/order/flight/ticket/changedate");
        },
      },
      {
        name: "Refund",
        onClick: () => {
          navigate("/order/flight/ticket/refund");
        },
      },
      {
        name: "Void",
        onClick: () => {
          navigate("/order/flight/ticket/void/"+order?.booking?._id);
        },
        state: {
          disabled: voidTime - new Date() <= 0,
        }  
      },
    ];
    return (
      <div className="flex flex-col lg:flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-8 h-8 rounded-sm text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <span>Ticket Issued</span>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          {actionButtons.map(({ name, onClick, state }, index) => (
            <button disabled={state?.disabled}
              key={index}
              className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
              onClick={onClick}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    );
  };
  const ChangeNameIssuedStatus = () => {
    const actionButtons = [
      {
        name: "Change",
        onClick: () => {
          navigate("/order/flight/ticket/chnagename");
        },
      },
      {
        name: "Refund",
        onClick: () => {
          navigate("/order/flight/ticket/refund");
        },
      },
      {
        name: "Void",
        onClick: () => {
          navigate("/order/flight/ticket/void/"+order?.booking?._id);
        },
        state: {
          disabled: voidTime - new Date() <= 0,
        }  
      },
    ];
    return (
      <div className="flex flex-col lg:flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-8 h-8 rounded-sm text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <span>Name change completed</span>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          {actionButtons.map(({ name, onClick, state }, index) => (
            <button disabled={state?.disabled}
              key={index}
              className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
              onClick={onClick}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    );
  };
  const ChangeApprovedStatus = ({ targetDate }) => {
    const [time, setTime] = useState("5h 0m 0s");
    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date();
        const difference = new Date(targetDate) - now;

        if (difference <= 0) {
          clearInterval(timerId);
          setTime("0h 0m 0s");
          return;
        }

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTime(`${hours}h ${minutes}m ${seconds}s`);
      };

      const timerId = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timerId);
    }, [targetDate]);
    const handleCancel = () => {};
    const handlePay = () => {};
    return (
      <div className="flex flex-col lg:flex-row items-center gap-6 text-sm lg:text-base py-3">
        <div className="w-full flex flex-1 flex-col gap-2">
          <div className="w-full flex justify-between gap-3">
            <div className="text-[#0B662C] bg-[#0B662C]/10 px-5 py-1 rounded-md w-fit h-fit">
              Change request approved
            </div>
            <div className="flex flex-col items-end">
              <span className="text-gray-500 text-xs lg:text-sm font">
                Time left to pay
              </span>
              <div className="flex gap-1 items-center">
                <img
                  src="/IconClock1.svg"
                  alt=""
                  className="w-3 object-contain"
                />
                <span className="font-bold text-[#F0373C]">{time}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <img src="/IconMoney.svg" alt="" className="w-6 object-contain" />
            <span className="font-bold">Fee to be paid: N250,000</span>
          </div>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          <button
            className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-white bg-primary1 justify-center"
            onClick={handlePay}
          >
            Pay
          </button>
        </div>
      </div>
    );
  };
  const AwaitingRefundConfirmation = ({ targetDate }) => {
    const [time, setTime] = useState("5h 0m 0s");
    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date();
        const difference = new Date(targetDate) - now;

        if (difference <= 0) {
          clearInterval(timerId);
          setTime("0h 0m 0s");
          return;
        }

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTime(`${hours}h ${minutes}m ${seconds}s`);
      };

      const timerId = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timerId);
    }, [targetDate]);
    const handleCancel = () => {};
    const handleConfirm = () => {
      dispatch(setConfirmFlightRefundRequestModal(true));
    };
    return (
      <div className="flex flex-col lg:flex-row items-center gap-6 text-sm lg:text-base py-3">
        <div className="w-full flex flex-1 flex-col gap-2">
          <div className="w-full flex justify-between gap-3">
            <div className="text-[#D9A513] bg-[#D9A513]/10 border border-[#D9A513] px-5 py-1 rounded-md w-fit h-fit">
              Awaiting your confirmation
            </div>
            <div className="flex flex-col items-end">
              <span className="text-gray-500 text-xs lg:text-sm font">
                Time left to confirm
              </span>
              <div className="flex gap-1 items-center">
                <img
                  src="/IconClock1.svg"
                  alt=""
                  className="w-3 object-contain"
                />
                <span className="font-bold text-[#F0373C]">{time}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <img src="/IconMoney.svg" alt="" className="w-6 object-contain" />
            <span className="font-bold">Refund amount: N250,000</span>
          </div>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          <button
            className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-white bg-primary1 justify-center"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  };
  const RefundedStatus = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex  gap-3">
          <div className="w-6 lg:w-8 h-6 lg:h-8 rounded-sm flex-shrink-0 text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <div className="flex flex-col mt-1">
            <span>Refunded, to re-imbursed</span>
            <p className="text-gray-400 font-normal">
              We will credit your Intraverse wallet once we receive the refund
              from airline
            </p>
          </div>
        </div>
      </div>
    );
  };
  const VoidedStatus = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex  gap-3">
          <div className="w-6 lg:w-8 h-6 lg:h-8 rounded-sm flex-shrink-0 text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <div className="flex flex-col mt-1">
            <span>Voided, to be re-imbursed</span>
            <p className="text-gray-400 font-normal">
              We will credit your Intraverse wallet once completed
            </p>
          </div>
        </div>
      </div>
    );
  };

  const StatusContent = () => {
    switch (params?.status) {
      case "datePending":
        return <DateChangeReviewStatus />;
      case "namePending":
        return <NameChangeReviewStatus />;
      case "refundPending":
        return <RefundReviewStatus />;
      case "dateRejected":
        return <ReIssueRejected />;
      case "nameRejected":
        return <NameChangeRejected />;
      case "refundRejected":
        return <RefundRejected />;
      case "voidRejected":
        return <VoidRejected />;
      case "changeProcessing":
        return <ChangeOrderProcessing />;
      case "refundProcessing":
        return <RefundProcessing />;
      case "voidProcessing":
        return <VoidProcessing />;
      case "dateIssuedSuccess":
        return <ChangeDateIssuedStatus />;
      case "nameIssued":
        return <ChangeNameIssuedStatus />;
      case "dateApproved":
        return <ChangeApprovedStatus targetDate="2024-08-16T10:30:00" />;
      case "refundApproved":
        return <AwaitingRefundConfirmation targetDate="2024-08-16T10:30:00" />;
      case "refundSuccess":
        return <RefundedStatus />;
      case "voidSuccess":
        return <VoidedStatus />;

      default:
        return <></>;
    }
  };

  return (
    <div className="w-full bg-white mb-4 border p-4">
      <ConfirmFlightRefundRequestModal />
      <StatusContent />
    </div>
  );
};

const OrderDetail = ({order,reload}) => {
  const { id } = useParams();

  const params = URLdecode();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openExport, setOpenExport] = useState(false);
  const [openEmailExport, setOpenEmailExport] = useState(false);
  const [openPDFExport, setOpenPDFExport] = useState(false);
  const [openInsurance, setOpenInsurance] = useState(false);
  const [openIssueTicket, setOpenIssueTicket] = useState(false);
  const [openHoldBooking, setOpenHoldBooking] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openCancelOrder, setOpenCancelOrder] = useState(false);
  const [newStatus,setNewStatus] = useState();

  const orderData = order?.booking?.flightBooking?.at(0);
  const ticketData = order?.ticketQueue?.at(0);


  let orderType = orderData ? "flight" : "";


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {};

  const handleCancel = () => {};

  const handleRefund = () => {};

  const handleTicket = () => {};

  let tripType = orderData?.flights?.length > 1 ? '' : 'One way'
  if(orderData?.flights?.length > 1) {
    if(orderData?.flights?.at(0)?.departureLocation === orderData?.flights?.at(-1)?.arrivalLocation)
      tripType = 'Round trip'
  }

  
  return (
    <div className="flex flex-col w-full shadow-md mt-4">
      <StatusCard data={orderData} ticketData={ticketData}
        {...{
          pay: () => setOpenPayment(orderData?._id),
          cancel: () => setOpenCancelOrder(orderData?._id),
          issueTicket: () => setOpenIssueTicket(orderData),
          hold: () => setOpenHoldBooking(orderData),
          reload: () => reload && reload(),
          setNewStatus,
        }}
      />

      <div className="w-full flex flex-col lg:flex-row bg-white rounded-t-md items-center justify-between p-4 gap-4 border-[#1E61DC]">
        <div className="flex w-full items-center flex-1 gap-3">
          <div className="text-[#1E61DC] text-sm font-bold px-4 py-2 rounded-md bg-[#DFEAFF]">
            {tripType}
          </div>
          <div className="flex flex-1 justify-between text-xl font-bold">
            <span>{orderData?.flights?.at(0)?.departureLocationName}</span>
            <ArrowForward />
            <span>{orderData?.flights?.at(0)?.arrivalLocationName}</span>
          </div>
        </div>
        <div className="flex w-full flex-1 lg:justify-end">
          <button className={Classname.button} onClick={handleExport}>
            Export itinerary
          </button>
          <CustomMenu
            element={
              <button className="!min-w-[180px] !text-primary flex gap-2 !bg-primary/10 p-2 px-3 whitespace-nowrap">
                Manage this order
                <Icon icon="mdi:arrow-down-drop" />
              </button>
            }
          >
            <OrderMenus
              data={{ id, status: newStatus || orderData?.status, orderType }}
              inDetail
              actions={{
                addInsurance: (id) => setOpenInsurance(id),
                pay: () => setOpenPayment(orderData?._id),
                cancelOrder: () => setOpenCancelOrder(orderData?._id),
                issueTicket: () => setOpenIssueTicket(orderData),
              }}
              />
          </CustomMenu>
          {/* <div className="">
            <button className={Classname.button} onClick={handleClick}>
              Manage order
            </button>
            <Menu
              id="basic-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleCancel}>Cancel order</MenuItem>
              <MenuItem onClick={handleRefund}>Refund</MenuItem>
              <MenuItem onClick={handleTicket}>Send ticket</MenuItem>
            </Menu>
          </div> */}
        </div>
      </div>
      <BookInfo flightBooking={orderData} />
    </div>
  );
};
function BookInfo({flightBooking}) {
  const data = [
    {label: 'Air Order ID',value: flightBooking?.orderId},
    {label: 'Order date',value: moment(flightBooking?.createdAt)?.format('DD/MM/YYYY HH:mm A')},
    {label: 'PNR',value: flightBooking?.pnr},
    {label: 'Airline PNR',value: ""},
  ]
  return (
    <div className="p-5 border-t-4 bg-theme1/5 border-t-theme1 flex gap-6 overflow-x-auto">
      {data?.map((obj,i) => (
          <div className="flex flex-col" key={i}>
            <p>{obj?.label}</p>
            <div>{obj?.value}</div>
          </div>
      ))}
    </div>
  )
}


const routes = [
  {
    airline: { name: "Turkis airline", image: "/IconTurkish.svg" },
    departure: {
      city: "Lagos",
      code: "LOS",
      date: "Wed Aug 07",
      time: "11:50am",
      airport: "Murtala mohammed internation airport",
    },
    arrival: {
      city: "Lagos",
      code: "LOS",
      date: "Wed Aug 07",
      time: "11:50am",
      airport: "Hamad internation airport",
    },
    length: "12h 50m",
    type: "ECONOMY",
    class: "N",
    seat: "1406",
    detailsOpen: false,
  },
];
const FlightDetails = ({order}) => {
  const orderData = order?.booking?.flightBooking?.at(0);
  
  const routes = orderData?.flights?.map((flight,i) => (
    {
      airline: { name: flight?.airlineName, image: flight?.airlineImage },
      departure: {
        city: flight?.departureLocationName,
        code: flight?.departureLocation,
        date: flight?.departureDate,
        time: flight?.departureTime,
        airport: flight?.departureAirport,
      },
      arrival: {
        city: flight?.arrivalLocationName,
        code: flight?.arrivalLocation,
        date: flight?.arrivalDate,//"Wed Aug 07",
        time: flight?.arrivalTime,//"11:50am",
        airport: flight?.arrivalAirport,
      },
      length: flight?.duration,
      type: flight?.cabinClass,
      class: flight?.bookingClass,
      seat: (flight?.seats||[])?.join(', '),
      detailsOpen: false,
    }
  ))

  console.log(orderData,routes)

  const [details, setDetails] = useState(routes);

  useEffect(() => {
    setDetails(routes)
  },[order])
  return (
    <div className="w-full flex flex-col mt-6">
      <span className="font-bold text-xl mb-3">Flight details</span>
      {details?.map((route, index) => (
        <div className="flex w-full flex-col">
          <div className="lg:items-center gap-2 flex-col lg:flex-row flex justify-between p-4 bg-white shadow-md rounded-lg">
            <div className="flex gap-4 items-center">
              <span className="min-w-[24px] h-6 rounded-md text-white font-bold items-center justify-center flex text-sm bg-primary1">
                {index + 1}
              </span>
              <div className="flex flex-col gap-2">
                <span className="text-sm lg:text-base font-bold">
                  {route?.departure?.city}({route?.departure?.code}) —{" "}
                  {route?.arrival?.city}({route?.arrival?.code})
                </span>
                <div className="flex items-center gap-2">
                  <img
                    src={route?.airline?.image}
                    alt=""
                    className="w-6 h-6 object-contain"
                  />
                  <span className="text-sm lg:text-base">
                    {route?.airline?.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-4 items-start text-sm lg:text-base">
              <div className="flex flex-col">
                <span>{route?.departure?.date}</span>
                <span>{route?.departure?.time}</span>
              </div>
              <div className="flex flex-col">
                <span>{route?.arrival?.date}</span>
                <span>{route?.arrival?.time}</span>
              </div>
              <div className="flex flex-col text-primary1">
                <span>{route.length}</span>
              </div>
            </div>
            <div className="flex gap-4 items-start justify-end text-sm lg:text-base">
              <div
                className="flex items-center gap-1 cursor-pointer"
                onClick={() => {
                  setDetails(
                    details.map((p, idx) =>
                      idx === index ? { ...p, detailsOpen: !p.detailsOpen } : p
                    )
                  );
                }}
              >
                <span className="text-sm lg:text-base text-[#818083] ">
                  Details
                </span>
                {route.detailsOpen ? (
                  <KeyboardArrowUp color="#818083" />
                ) : (
                  <KeyboardArrowDown color="#818083" />
                )}
              </div>
            </div>
          </div>
          {route.detailsOpen && (
            <div className="w-full px-2 lg:px-6 py-3 lg:py-6 bg-white border mt-3 flex flex-col">
              <div className="flex gap-4 mb-4 h-32 lg:h-full">
                <div className=" flex flex-col items-center h-32 lg:h-full justify-between py-4 lg:py-1">
                  <div className="w-3 h-3 rounded-full bg-primary1"></div>
                  <div className="w-1 h-full flex flex-1 py-4 bg-gray-200"></div>
                  <div className="w-3 h-3 rounded-full bg-primary1"></div>
                </div>
                <div className="flex flex-col flex-1 h-full justify-between">
                  <span className=" text-sm lg:text-base text-gray-400 mb-4">
                    <strong className="text-black mr-2">
                      {route.departure.date}
                    </strong>
                    Departing from {route.departure.airport}
                  </span>
                  <div className="text-xs lg:text-sm items-center flex gap-1 text-gray-400">
                    <img
                      src="/IconClock.svg"
                      alt=""
                      className="w-3 object-contain"
                    />
                    <span>{route.length}</span>
                  </div>
                  <span className=" text-sm lg:text-base text-gray-400 mt-4">
                    <strong className="text-black mr-2">
                      {route.arrival.date}
                    </strong>
                    Arriving at {route.arrival.airport}
                  </span>
                </div>
              </div>
              <div className="flex flex-wrap gap-4 lg:gap-10 text-gray-400 text-sm lg:text-base font-semibold">
                <span>{route?.type}</span>
                <span>{route?.class}</span>
                <span>{route?.seat}</span>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Passengers = ({order}) => {
  const orderData = order?.booking?.flightBooking?.at(0);

  const handleItinerary = () => {};
  return (
    <div className="w-full flex flex-col mt-6">
      <span className="font-bold text-xl mb-3">Passenger</span>
      {orderData?.travelers
        ?.map((passenger, index) => (
          <div className="flex w-full flex-col shadow-md mb-6" key={index}>
            <div className="flex flex-col lg:flex-row items-end bg-white rounded-t-lg border-b">
              <div className="items-center flex gap-4 px-4 py-2 flex-1 w-full ">
                <div className="flex gap-4 items-center">
                  <span className="min-w-[24px] h-6 rounded-md text-white font-bold items-center justify-center flex text-sm bg-primary1">
                    {index + 1}
                  </span>
                  <div className="flex flex-col gap-2">
                    <span className="text-sm lg:text-base font-bold text-primary1">
                      {passenger?.firstName} {passenger?.middleName} {passenger?.lastName}
                      {/* Ike/ John Chinedu */}
                    </span>
                  </div>
                </div>
                <div className="flex gap-1 items-start">
                  <span className="text-[#032D5F] capitalize">{getAgeType(passenger?.travelerType)} {passenger?.gender}</span>
                </div>
              </div>
              {index === 0 && (
                <div className="text-primary1 uppercase bg-primary1/10 rounded-md py-1 px-3 w-fit text-xs my-1 mr-2">
                  Date change requested
                </div>
              )}
            </div>
            <div className="flex flex-col w-full shadow-md">
              <div className="p-4 bg-white flex flex-wrap gap-y-4 lg:gap-4 w-full">
                <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                  <span className="text-sm lg:text-base text-[#818083] ">
                    Date of birth
                  </span>
                  <span className=" text-sm">{passenger?.birthDate}</span>
                </div>
                <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                  <span className="text-sm lg:text-base text-[#818083] ">
                    Passport No
                  </span>
                  <span className="text-sm">{passenger?.document?.number}</span>
                </div>
                <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2 lg:items-center">
                  <span className="text-sm lg:text-base text-[#818083] ">
                    Passport Expiry
                  </span>
                  <span className="text-sm">{passenger?.document?.expiryDate}</span>
                </div>
                <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                  <span className="text-sm lg:text-base text-[#818083] ">
                    Nationality
                  </span>
                  <span className="text-sm">{passenger?.document?.nationality}</span>
                </div>
                <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2">
                  <span className="text-sm lg:text-base text-[#818083] ">
                    Ticket No
                  </span>
                  {passenger?.tickets?.map((ticket) => (
                    <Copy key={ticket} value={ticket?.number} />
                  ))}
                </div>
                <div className="flex lg:flex-1 w-1/2 lg:w-fit flex-col gap-2 lg:items-center">
                  <span className="text-sm lg:text-base text-[#818083] ">
                    Itinerary
                  </span>
                  <img
                    src="/IconItinerary.svg"
                    alt=""
                    className="w-4 h-4 object-contain cursor-pointer"
                    onClick={() => handleItinerary()}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const Copy = ({ value }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value);
      enqueueSnackbar("Copied to clipboard!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to copy to clipboard!", { variant: "error" });
    }
  };
  return (
    <div
      className="flex gap-1 items-center mb-2 cursor-pointer"
      onClick={handleCopy}
    >
      <span className="text-xs text-primary1">{value}</span>
      <img src="/IconCopy.svg" alt="" />
    </div>
  );
};

const Baggage = () => {
  return (
    <div className="w-full flex flex-col mt-6">
      <span className="font-bold text-xl mb-3">Baggage</span>
      <div className="flex flex-col justify-between p-4 bg-white shadow-md rounded-lg border">
        <span>Passenger 1:</span>
        <span>2PC</span>
      </div>
    </div>
  );
};

const FareRules = ({order}) => {
  const handleViewFareRules = () => {};

  const orderData = order?.booking?.flightBooking?.at(0);

  const refundable = orderData?.isRefundable;
  const changable = orderData?.isChangeable;

  const Card = ({title,description,status}) => {
    return (
      <div className="flex flex-col flex-1 rounded-xl p-4 bg-[#F0F6FC]">
        <span className="text-[#032D5F] font-bold text-lg">
          {title}
        </span>
        <div className="items-center gap-2 mt-2 flex">
          {status ? 
            <img
              src="/IconCheckmark1.svg"
              alt=""
              className="w-6 h-6 object-contain"
            />        
          :
            <img
              src="/IconClose5.svg"
              alt=""
              className="w-6 h-6 object-contain"
              />
          }
          <span className="text-sm">{!status ? description?.at(0) : description?.at(1)}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col mt-6">
      <span className="font-bold text-xl mb-3">FareRules</span>
      <div className="flex flex-col justify-between p-4 bg-white shadow-md rounded-lg border">
        <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
          <Card title='Order change policy' 
            description={['This order is not changeable','This order is changeable']} 
            status={changable}
          />
          <Card title='Order refund policy' 
            description={['This order is not refundable','This order is refundable up until the initial departure date']} 
            status={refundable}
          />
        </div>
        <span
          onClick={handleViewFareRules}
          className="font-bold text-lg text-primary1 cursor-pointer mt-4"
        >
          View Fare Rules
        </span>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="w-full flex flex-col mt-6">
      <span className="font-bold text-xl mb-3">Contact</span>
      <div className="flex w-full flex-col shadow-md mb-6">
        <div className="flex flex-col w-full shadow-md">
          <div className="p-4 bg-white flex flex-wrap gap-4">
            <div className="flex flex-1 flex-col gap-2">
              <span className="text-sm lg:text-base text-[#818083] ">Name</span>
              <span className=" text-sm">Intraverse Limited</span>
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <span className="text-sm lg:text-base text-[#818083] ">
                E-mail
              </span>
              <span className="text-sm">hello@intraverse.africa</span>
            </div>
            <div className="flex flex-1 flex-col gap-2 lg:items-center">
              <span className="text-sm lg:text-base text-[#818083] ">
                Phone no
              </span>
              <span className="text-sm">+234 9030002629</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Classname = {
  button:
    "h-10 lg:h-12 px-2 lg:px-4 rounded-md border-2 border-gray-300 font-bold mr-3 text-sm lg:text-base",
};
