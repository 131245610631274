import React from "react";
import { Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPages } from "../../../../redux/reducers/template/templateSlice";


const ApplyPage = () => {
  const state = useSelector((state) => state.template);
  const dispatch = useDispatch();
  const updateHeaderStyle = (value) => {
    dispatch(setSelectedPages(value))
  };
  const pages = [
    {
        'id': 'about',
        'name': "About us",
        'link': '/about'
    },
    {
        'id': 'contact',
        'name': "Contact us",
    },
    {
        'id': 'destination',
        'name': "Destinations",
    },
    {
        'id': 'policy',
        'name': "Privacy Policy",
    },
    {
        'id': 't&c',
        'name': "Terms and conditions",
    },
    {
        'id': 'faq',
        'name': "FAQs",
    },
  ]
  let currentHost = window.location.protocol + "//"+ window.location.host;

  return (
    <div className="px-10 mt-10 col-span-5">
      <div>
        <h1 className="text-4xl font-BrFirmaBold font-bold my-0">
        Select the pages that apply
        </h1>
        <p className="font-BrFirmaRegular my-2">
            These logo, colors, and font will be used to design your site. You can always change them later.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12 pr-10">
        {pages.map((page)=>(
            <div className={`border-solid border-2 ${state.selectedPages.includes(page.id) ? 'bg-[#C4E9E3] border-[#1EA994]' :'bg-[#F8F8F8] border-[#F8F8F8]'} px-3 col-span-1 relative cursor-pointe rounded-lg`} onClick={()=> updateHeaderStyle(page.id )}>
                <div className="flex gap-2">
                    <Radio size="small" color="success" checked={state.selectedPages.includes(page.id)}/>
                    <p className={`font-poppins font-bold py-2 ${state.selectedPages.includes(page.id) ? 'text-[#1EA994]': 'text-black'}`}>{page.name}</p>
                </div>
                <div className="w-full relative h-60 overflow-hidden">
                    <div className=" h-[90%] bg-[#D9D9D9] top-0 left-0 w-full"
                    >
                    </div>
                </div>
            </div>
        ))}
      </div>
    </div>
    );
}
export default ApplyPage