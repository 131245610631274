export const tourPackageOptions = [
  {
    title: "Attraction ticket",
    description: "Entry to a landmark theme park show",
    value: "ticket",
  },
  {
    title: "Tour",
    description: "Guided walking tour, day trip, city cruise",
    value: "tour",
  },
  {
    title: "City card",
    description: "A pass for multiple attraction or transport within a city",
    value: "city",
  },
  {
    title: "Hop-on Hop-off ticket",
    description: "Entry to a hop-on hop-off bus or boat",
    value: "hop-on",
  },
  {
    title: "Transfer",
    description: "Transportation services such as airport or bus transfers",
    value: "transfer",
  },
  {
    title: "Rental",
    description: "Modes of transports, sports euipment, Wi-Fi routers",
    value: "rental",
  },
  {
    title: "Order",
    description: "Multiple activities sold together",
    value: "order",
  },
];

export const tourPackageGuideOptions = [
  {
    title: "Nobody",
    value: "nobody",
  },
  {
    title: "Tour guide",
    description:
      "Leads a group of customers through a tour and explains things about the destination or attraction.",
    value: "guide",
  },
  {
    title: "Host or greeter",
    description:
      "Provides an introduction, purchases a ticket, or waits in line with customers, but doesn't provide a full tour of the attraction.",
    value: "host",
  },
  {
    title: "Instructor",
    description:
      "Shows customers how to use equipment or teaches them how to do something.",
    value: "instructor",
  },
  {
    title: "Driver",
    description:
      "Drives the customer somewhere but doesn’t explain anything along the way.",
    value: "driver",
  },
];
