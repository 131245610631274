import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'lodash';
import { crmData } from '../../../../data/customer/CRM';
import Icon from '../../../HOC/Icon';
import Modal1 from '../../../DIsplay/Modal/Modal1';
import Button1 from '../../../form/Button1';
import TextInput from '../../../form/TextInput';
import { FileUploader } from 'react-drag-drop-files';
import updateProfile from '../../../../controllers/user/updateProfile';
import { setUser } from '../../../../redux/reducers/userSlice';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import CreateFlightOrder from '../../../../pages/Orders/Flight/CreateFlightOrder';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const ModalContext = createContext(null);

var cancelTokenSource = null; 

export default function TermsCRM() {
    const {user} = useSelector(state => state.user.userData);
    let {terms} = clone(user?.detail?.crmData || crmData);
    // dbData.sections = dbData?.sections?.map(obj => ({...obj,image: undefined,savedImage: obj?.image}))
    const defData = clone({terms});
    const [data,setData] = useState((defData))
    const [modalOpen,setModalOpen] = useState({title: false})
    const [loading,setLoading] = useState({submit: false})
    const dispatch = useDispatch();

    async function handleUpload(ev,newData) {
        ev?.preventDefault();
        // return console.log('payload: ',data)
        // if(bg?.file) {
        //     const formData = new FormData();
        //     formData.append('crmData.aboutUs.backgroundImage',bg?.file);
        //     const res = await updateProfile(formData)
        //     if(res.return) {
        //         enqueueSnackbar('Image Uploaded',{variant: 'success'})
        //     } else enqueueSnackbar(res.msg,{variant: 'error'})
        // }

        if(cancelTokenSource)
            cancelTokenSource.cancel('Canceled for another request')
        cancelTokenSource = axios.CancelToken.source()
        
        setLoading({...loading,submit: true});
        const res = await updateProfile({crmData: {...(user?.detail?.crmData || {}),...(newData || data || {})}},cancelTokenSource?.token)
        setLoading({...loading,submit: false});
        console.log(res)
        if(res.return) {
            enqueueSnackbar('Page Updated',{variant: 'success'})
            console.log(res.data)
            dispatch(setUser(res.data))
        } else enqueueSnackbar(res.msg,{variant: 'error'})

    }

    
    const EditModal = ({children,title,...props}) => {
        const [value,setValue] = useState('')

        function handleSubmit() {
            const updData = {...data,...value};
            setData(updData)
            handleUpload(null,updData)
            props?.setOpen && props?.setOpen(false);
        }
        
        return (
            <Modal1 {...props}>
                <div className='p-5 flex flex-col gap-4'>
                    <div className='flex gap-4 justify-between py-4'>
                        <b>{title}</b>
                        <Icon icon='mdi:close' onClick={() => props?.setOpen()} />
                    </div>
                    <ModalContext.Provider value={{value,setValue}}>
                        {children}
                    </ModalContext.Provider>
                    <div className='flex gap-4'>
                        <div>
                            <Button1 variant='text' onClick={() => props?.setOpen()}>Close</Button1>
                        </div>
                        <Button1 onClick={() => handleSubmit()}>Save</Button1>
                    </div>
                </div>
            </Modal1>
        )
    }

    let parsedData = {}
    try {
        parsedData = JSON.parse(data?.terms || '{}')
    } catch(ex) {}

    function handleRemoveSection(i) {
        try {
            const temp = {...parsedData};
            temp.sections = temp?.sections?.filter((_,ind) => ind !== i)
            const newData = {terms: JSON.stringify(temp)};
            setData(newData);
            handleUpload(null,newData)
        } catch(ex) {}
    }


    const sections = [
        {title: 'Terms and conditions',
            section: [
                {
                    label: 'Hero Text',
                    body: parsedData?.title,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,title: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Change <Icon icon='tabler:edit' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.title} setOpen={() => setModalOpen({...modalOpen,title: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    return (
                                        <div className=''>
                                            <TextInput label={'Title Text'} onChange={(ev) => setValue({terms: JSON.stringify({sections: parsedData?.sections,title: ev.target.value})})} />
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>
                    </div>
                },
            ]
        },
        {title: 'Sections',
            section: [
                {
                    label: 'List',
                    body: <TextListComponent handleDelete={(i) => handleRemoveSection(i)} data={parsedData?.sections} 
                        render={(obj,i) => (
                            <div>{obj?.title}</div>
                        )}
                     />,
                    action: (section) => <div>
                        <button onClick={() => setModalOpen({...modalOpen,quickInfo: true})}
                            className='text-xs flex gap-2 bg-primary/5 p-2 px-4'>Add <Icon icon='material-symbols:add' className='!w-4 !h-4' /></button>
                        <EditModal open={modalOpen.quickInfo} setOpen={() => setModalOpen({...modalOpen,quickInfo: false})} title={section.title} >
                            <ModalContext.Consumer>
                                {(obj) => {
                                    const {value,setValue} = obj || {};

                                    let parsedTerms = {}
                                    try {
                                        parsedTerms = JSON.parse(value?.terms || '{}')
                                    } catch(ex) {}

                                    function handleChange(obj) {
                                        try {
                                            const temp = [...(parsedData?.sections||[]),{}];
                                            console.log(' -> ',temp,parsedData?.sections);
                                            temp[temp.length-1] = {...(parsedTerms?.sections?.at(-1) || {}),...(obj||{})}
                                            setValue({terms: JSON.stringify({title: parsedData?.title || '' ,sections: temp})});
                                            console.log({terms: JSON.stringify({title: parsedData?.title || '' ,sections: temp})})
                                        } catch(ex) {console.log(ex)}
                                    }
                                
                                    return (
                                        <div className='flex flex-col gap-4 w-[500px]'>
                                            <TextInput label='Title' onChange={(ev) => handleChange({title: ev.target.value})} />
                                            <ReactQuill value={parsedTerms?.sections?.at(-1)?.content || ''} 
                                                onChange={(content,delta,source,editor) => handleChange({content: editor.getHTML()})}
                                                theme="snow"
                                                modules={{
                                                  toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                    ['link', 'image'],
                                                    ['clean']
                                                  ]
                                                }}
                                            />
                                            {/* <CKEditor
                                                data={parsedTerms?.sections?.at(-1)?.content || ''}
                                                editor={ClassicEditor}
                                                onReady={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                }}
                                                onChange={(ev,editor) => {
                                                    try {
                                                        handleChange({content: editor.getData()})
                                                    } catch(ex) {}
                                                }}
                                                config={{
                                                    toolbar: [
                                                        'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'
                                                    ],
                                                }}
                                            /> */}
                                        </div>
                                    )
                                }}
                            </ModalContext.Consumer>
                        </EditModal>

                    </div>
                }
            ]
        },
    ]

    let currentHost = window.location.protocol + "//"+ window.location.host;
    const iframeRefScale = useRef();
    const [scale,setScale] = useState(.3);

    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
          setScale((iframeRefScale.current.offsetWidth / screenWidth));
        }
    }
      useEffect(()=>{
        window.addEventListener('resize', updateScale)
        updateScale()
        return ()=>{
          window.removeEventListener('resize', updateScale)
        }
      },[])

    
  return (
    <div className='pd-md flex gap-4 flex-wrap md:flex-nowrap overflow-hidden'>
        <div className='md:w-[65%]'>
            <div className='pb-4'>
                <h3>Edit Terms and Conditions page content</h3>
                <p>These logo, colors, and font will be used to design your site. You can always change them later.</p>
            </div>
            <div className='flex flex-col gap-4'>
                {
                    sections?.map((section,i) => (
                        <div>
                            <div className='bg-theme1/10 p-4 py-2'>{section?.title}</div>
                            {section?.section?.map((obj,j) => (
                                <div key={section?.title+obj?.label+j} className='flex gap-4 p-4 border-b'>
                                    <div className='w-[150px]'>{obj?.label}</div>
                                    <div className='flex-1 max-w-[64%] overflow-hidden'>{obj?.body}</div>
                                    <div>{obj?.action(section)}</div>
                                </div>
                            ))}
                        </div>
                    ))
                }
            </div>
        </div>
        <div
            className="origin-top-left md:w-[30%]"
        >
            <iframe src={currentHost+'/preview/template/terms'} className='w-screen origin-top-left overflow-hidden min-h-[90vh]'
                style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
            />
        </div>
    </div>
  )
}

function getImageSource(val) {
    if((val||"")?.startsWith('http'))
        return val;
    else return "data:image/jpeg;base64,"+val
}

function ImageListComponent({data,handleDelete,imageKey,render}) {

    if(!data?.length) return null;

    return (
        (
            <div className='flex gap-4 max-w-full flex-wrap'>
                {data?.map((obj,i) => (
                    <div key={i}>
                        <div className='w-40 relative'>
                            <div className='absolute z-10 top-0 right-0 p-1 rounded-md m-2 bg-red-200 cursor-pointer'
                                onClick={() => handleDelete && handleDelete(i)}
                             >
                                <Icon icon='material-symbols:delete-outline' className='text-red-500 !w-5 !h-5' />
                            </div>
                            {!render ? 
                                <img src={getImageSource(imageKey ? obj[imageKey] : obj)} className='w-full h-32 object-cover' />
                            :
                                render(obj,i)
                            }
                        </div>
                    </div>
                ))}
            </div>
        )
    )
}

function TextListComponent({data,render,handleDelete}) {

    return (
        (
            <div className='flex gap-4 max-w-full flex-wrap'>
                {data?.map((val,i) => (
                    <div key={i}>
                        <div className='px-3 py-1 bg-primary/20 rounded-full flex items-center capitalize gap-2 relative'>
                            {render ? render(val,i) : val}
                            <div className='rounded-md  cursor-pointer'
                                onClick={() => handleDelete && handleDelete(i)}
                             >
                                <Icon icon='mdi:close' className='!w-4 !h-4' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    )
}