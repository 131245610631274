export default function PrivacyPolicy() {
    return (
        <div className="flex flex-col gap-5 items-center">
            <div className="w-[600px] max-w-full">
                <div className="p-5 flex justify-end">
                    <a href='/' className="self-end">Home</a>
                </div>

                <div>
                    <p class="c12 c6 c31"><span class="c19 c14 c28"></span></p>
                </div>
                <h5 class="c32 flex justify-center"><span class="c15 c25">PRIVACY POLICY</span></h5>
                <p class="c12"><span class="c19">&nbsp;</span></p>
                <p class="c12"><span class="c8">Effective date: 02/22/2024</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0 start" start="1">
                    <li class="c1 c6"><span class="c4">Introduction</span></li>
                </ol>
                <p class="c13 c6"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">Welcome to&nbsp;</span><span class="c9">INTRAVERSE AFRICA</span><span
                        class="c0">.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">INTRAVERSE AFRICA&nbsp;(&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;)
                        operates&nbsp;https://www.intraverse.app and Intraverse Agent App mobile application&nbsp;(hereinafter referred
                        to as &ldquo;</span><span class="c9">Service</span><span class="c0">&rdquo;).</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">Our Privacy Policy governs your visit to&nbsp;https://www.intraverse.app and Intraverse
                        Agent App mobile application, and explains how we collect, safeguard and disclose information that results from
                        your use of our Service. </span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">We use your data to provide and improve Service. By using Service, you agree to the
                        collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy
                        Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">Our Terms and Conditions (&ldquo;</span><span class="c9">Terms</span><span
                        class="c0">&rdquo;) govern all use of our Service and together with the Privacy Policy constitutes your
                        agreement with us (&ldquo;</span><span class="c9">agreement</span><span class="c0">&rdquo;).</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="2">
                    <li class="c1 c6"><span class="c4">Definitions</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c9">SERVICE&nbsp;</span><span class="c0">means the&nbsp;https://www.intraverse.app website
                        and Intraverse Agent App mobile application&nbsp;operated by&nbsp;INTRAVERSE AFRICA.</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c9">PERSONAL DATA</span><span class="c0">&nbsp;means data about a living individual who can
                        be identified from those data (or from those and other information either in our possession or likely to come
                        into our possession).</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c9">USAGE DATA</span><span class="c0">&nbsp;is data collected automatically either generated
                        by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</span>
                </p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c9">COOKIES</span><span class="c0">&nbsp;are small files stored on your device (computer or
                        mobile device).</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c9">DATA CONTROLLER</span><span class="c0">&nbsp;means a natural or legal person who (either
                        alone or jointly or in common with other persons) determines the purposes for which and the manner in which any
                        personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of
                        your data.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c15">DATA PROCESSORS (OR SERVICE PROVIDERS)</span><span class="c8">&nbsp;means any natural or
                        legal person who processes the data on behalf of the Data Controller. We may use the services of various Service
                        Providers in order to process your data more effectively.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c15">DATA SUBJECT&nbsp;</span><span class="c8">is any living individual who is the subject of
                        Personal Data.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c15">THE USER&nbsp;</span><span class="c8">is the individual using our Service. The User
                        corresponds to the Data Subject, who is the subject of Personal Data.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="3">
                    <li class="c1 c6"><span class="c4">Information Collection and Use</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">We collect several different types of information for various purposes to provide and
                        improve our Service to you.</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="4">
                    <li class="c1 c6"><span class="c4">Types of Data Collected</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2 c6"><span class="c9">Personal Data</span></p>
                <p class="c2 c6"><span class="c0">While using our Service, we may ask you to provide us with certain personally
                        identifiable information that can be used to contact or identify you (&ldquo;</span><span class="c9">Personal
                        Data</span><span class="c0">&rdquo;). Personally identifiable information may include, but is not limited
                        to:</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_26-1 start" start="1">
                    <li class="c1"><span class="c0">Email address</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_26-1" start="2">
                    <li class="c1"><span class="c0">First name and last name</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_26-1" start="3">
                    <li class="c1"><span class="c0">Phone number</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_26-1" start="4">
                    <li class="c1"><span class="c0">Address, State, Province, ZIP/Postal code, City</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_26-1" start="5">
                    <li class="c1"><span class="c0">Cookies and Usage Data</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">We may use your Personal Data to contact you with newsletters, marketing or promotional
                        materials and other information that may be of interest to you. You may opt out of receiving any, or all, of
                        these communications from us&nbsp;by following the unsubscribe link or by emailing
                        at&nbsp;support@intraverse.africa.</span></p>
                <p class="c13 c23"><span class="c8">&nbsp;</span></p>
                <p class="c2 c6"><span class="c9">Usage Data</span></p>
                <p class="c2"><span class="c0">We may also collect information that your browser sends whenever you visit our Service or
                        when you access Service by or through a mobile device (&ldquo;</span><span class="c9">Usage Data</span><span
                        class="c0">&rdquo;).</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">This Usage Data may include information such as your computer&#39;s Internet Protocol
                        address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and
                        date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span>
                </p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">When you access Service with a mobile device, this Usage Data may include information
                        such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device,
                        your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other
                        diagnostic data.</span></p>
                <p class="c13 c23"><span class="c8">&nbsp;</span></p>
                <p class="c2 c6"><span class="c9">Tracking Cookies Data</span></p>
                <p class="c2"><span class="c0">We use cookies and similar tracking technologies to track the activity on our Service and
                        we hold certain information.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">Cookies are files with a small amount of data which may include an anonymous unique
                        identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking
                        technologies are also used such as beacons, tags and scripts to collect and track information and to improve and
                        analyze our Service.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">You can instruct your browser to refuse all cookies or to indicate when a cookie is being
                        sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">Examples of Cookies we use:</span></p>
                <p class="c2 c6"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-1 start" start="1">
                    <li class="c1"><span class="c9">Session Cookies:</span><span class="c0">&nbsp;We use Session Cookies to operate our
                            Service.</span></li>
                </ol>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-1" start="2">
                    <li class="c1"><span class="c9">Preference Cookies:</span><span class="c0">&nbsp;We use Preference Cookies to
                            remember your preferences and various settings.</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-1" start="3">
                    <li class="c1"><span class="c9">Security Cookies:</span><span class="c0">&nbsp;We use Security Cookies for security
                            purposes.</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-1" start="4">
                    <li class="c1"><span class="c9">Advertising Cookies:</span><span class="c0">&nbsp;Advertising Cookies are used to
                            serve you with advertisements that may be relevant to you and your interests.</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="5">
                    <li class="c1 c6"><span class="c4">Use of Data</span></li>
                </ol>
                <p class="c13 c6"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">INTRAVERSE AFRICA&nbsp;uses the collected data for various purposes:</span></p>
                <p class="c13 c6"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1 start" start="1">
                    <li class="c1"><span class="c0">to provide and maintain our Service;</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="2">
                    <li class="c1"><span class="c0">to notify you about changes to our Service; </span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="3">
                    <li class="c1"><span class="c0">to allow you to participate in interactive features of our Service when you choose
                            to do so; </span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="4">
                    <li class="c1"><span class="c0">to provide customer support; </span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="5">
                    <li class="c1"><span class="c0">to gather analysis or valuable information so that we can improve our Service;
                        </span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="6">
                    <li class="c1"><span class="c0">to monitor the usage of our Service;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="7">
                    <li class="c1"><span class="c0">to detect, prevent and address technical issues;</span></li>
                </ol>
                <p class="c5"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="8">
                    <li class="c1"><span class="c0">to fulfill any other purpose for which you provide it;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="9">
                    <li class="c1"><span class="c0">to carry out our obligations and enforce our rights arising from any contracts
                            entered into between you and us, including for billing and collection;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="10">
                    <li class="c1"><span class="c0">to provide you with notices about your account and/or subscription, including
                            expiration and renewal notices, email-instructions, etc.;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="11">
                    <li class="c1"><span class="c0">to provide you with news, special offers and general information about other goods,
                            services and events which we offer that are similar to those that you have already purchased or enquired
                            about unless you have opted not to receive such information;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="12">
                    <li class="c1"><span class="c0">in any other way we may describe when you provide the information;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_28-1" start="13">
                    <li class="c1"><span class="c0">for any other purpose with your consent. </span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="6">
                    <li class="c1 c6"><span class="c4">Retention of Data</span></li>
                </ol>
                <p class="c13 c6"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2"><span class="c0">We will retain your Personal Data only for as long as is necessary for the purposes set
                        out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our
                        legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
                        disputes, and enforce our legal agreements and policies.</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">We will also retain Usage Data for internal analysis purposes. Usage Data is generally
                        retained for a shorter period, except when this data is used to strengthen the security or to improve the
                        functionality of our Service, or we are legally obligated to retain this data for longer time periods.</span>
                </p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="7">
                    <li class="c1 c6"><span class="c4">Transfer of Data</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">Your information, including Personal Data, may be transferred to &ndash; and maintained
                        on &ndash; computers located outside of your state, province, country or other governmental jurisdiction where
                        the data protection laws may differ from those of your jurisdiction.</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">If you are located outside Nigeria and choose to provide information to us, please note
                        that we transfer the data, including Personal Data, to our servers located in different locations outside
                        Nigeria and process it there.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">Your consent to this Privacy Policy followed by your submission of such information
                        represents your agreement to that transfer.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2 c26"><span class="c0">INTRAVERSE AFRICA&nbsp;will take all the steps reasonably necessary to ensure that
                        your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data
                        will take place to an organisation or a country unless there are adequate controls in place including the
                        security of your data and other personal information.</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="8">
                    <li class="c1 c6"><span class="c4">Disclosure of Data</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">We may disclose personal information that we collect, or you provide:</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_29-1 start" start="1">
                    <li class="c1 c6"><span class="c9">Disclosure for Law Enforcement.</span></li>
                </ol>
                <p class="c13 c21"><span class="c0">Under certain circumstances, we may be required to disclose your Personal Data if
                        required to do so by law or in response to valid requests by public authorities.</span></p>
                <p class="c12 c22"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_29-1" start="2">
                    <li class="c1 c6"><span class="c9">Business Transaction.</span></li>
                </ol>
                <p class="c13 c21"><span class="c0">If we or our subsidiaries are involved in a merger, acquisition or asset sale, your
                        Personal Data may be transferred.</span></p>
                <p class="c12 c16"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_29-1" start="3">
                    <li class="c13 c6 c24 li-bullet-0"><span class="c9">Other cases. We may disclose your information also:</span></li>
                </ol>
                <p class="c13 c6 c27"><span class="c9">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_29-2 start" start="1">
                    <li class="c1"><span class="c0">to our subsidiaries and affiliates;</span></li>
                </ol>
                <p class="c13 c17"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="9">
                    <li class="c1 c6"><span class="c4">Security of Data</span></li>
                </ol>
                <p class="c13 c6"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2"><span class="c0">The security of your data is important to us but remember that no method of transmission
                        over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable
                        means to protect your Personal Data, we cannot guarantee its absolute security.</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="10">
                    <li class="c1 c6"><span class="c4">Your Data Protection Rights Under General Data Protection Regulation
                            (GDPR)</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">If you are a resident of the European Union (EU) and European Economic Area (EEA), you
                        have certain data protection rights, covered by GDPR. &ndash; See more at </span><span class="c7 c14"><a
                            class="c10"
                            href="https://www.google.com/url?q=https://eur-lex.europa.eu/eli/reg/2016/679/oj&amp;sa=D&amp;source=editors&amp;ust=1708611955312601&amp;usg=AOvVaw2421VFkQpbFtkR5Jhw0tdu">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a></span><span
                        class="c0">&nbsp;</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use
                        of your Personal Data.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">If you wish to be informed what Personal Data we hold about you and if you want it to be
                        removed from our systems, please email us at support@intraverse.africa. </span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">In certain circumstances, you have the following data protection rights:</span></p>
                <p class="c2 c6"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_30-1 start" start="1">
                    <li class="c1"><span class="c0">the right to access, update or to delete the information we have on you;</span></li>
                </ol>
                <p class="c13 c21"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_30-1" start="2">
                    <li class="c1"><span class="c0">the right of rectification. You have the right to have your information rectified if
                            that information is inaccurate or incomplete;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_30-1" start="3">
                    <li class="c1"><span class="c0">the right to object. You have the right to object to our processing of your Personal
                            Data;</span></li>
                </ol>
                <p class="c13 c21"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_30-1" start="4">
                    <li class="c1"><span class="c0">the right of restriction. You have the right to request that we restrict the
                            processing of your personal information;</span></li>
                </ol>
                <p class="c13 c21"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_30-1" start="5">
                    <li class="c1"><span class="c0">the right to data portability. You have the right to be provided with a copy of your
                            Personal Data in a structured, machine-readable and commonly used format;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_30-1" start="6">
                    <li class="c1"><span class="c0">the right to withdraw consent.&nbsp;You also have the right to withdraw your consent
                            at any time where we rely on your consent to process your personal information;</span></li>
                </ol>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">Please note that we may ask you to verify your identity before responding to such
                        requests. Please note, we may not able to provide Service without some necessary data.</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">You have the right to complain to a Data Protection Authority about our collection and
                        use of your Personal Data. For more information, please contact your local data protection authority in the
                        European Economic Area (EEA).</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="11">
                    <li class="c1 c6"><span class="c4">Service Providers</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">We may employ third party companies and individuals to facilitate our Service
                        (&ldquo;</span><span class="c9">Service Providers</span><span class="c0">&rdquo;), provide Service on our
                        behalf, perform Service-related services or assist us in analysing how our Service is used.</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">These third parties have access to your Personal Data only to perform these tasks on our
                        behalf and are obligated not to disclose or use it for any other purpose.</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="12">
                    <li class="c1 c6"><span class="c4">Analytics</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">We may use third-party Service Providers to monitor and analyze the use of our
                        Service.</span></p>
                <p class="c13 c6"><span class="c8">&nbsp;</span></p>
                <p class="c2 c6 subtitle"><span class="c15">Google Analytics</span></p>
                <p class="c2"><span class="c0">Google Analytics is a web analytics service offered by Google that tracks and reports
                        website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared
                        with other Google services. Google may use the collected data to contextualise and personalise the ads of its
                        own advertising network.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18 c14">For more information on the privacy practices of Google, please visit the Google
                        Privacy Terms web page: </span><span class="c7 c14"><a class="c10"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708611955314556&amp;usg=AOvVaw2fqZtnjlv8lTRFNwTpkK_f">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c2"><span class="c7 c14">&nbsp;</span></p>
                <p class="c2"><span class="c0">We also encourage you to review the Google&#39;s policy for safeguarding your data:
                    </span><span class="c7 c14"><a class="c10"
                            href="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245&amp;sa=D&amp;source=editors&amp;ust=1708611955314717&amp;usg=AOvVaw1cmfmSNk95NmRGiifVQXhB">https://support.google.com/analytics/answer/6004245</a></span><span
                        class="c0">.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2 c6"><span class="c9">Firebase</span></p>
                <p class="c2"><span class="c0">Firebase is analytics service provided by Google Inc.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">You may opt-out of certain Firebase features through your mobile device settings,
                        such as your device advertising settings or by following the instructions provided by Google in their Privacy
                        Policy: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708611955314981&amp;usg=AOvVaw1lWxhEekVGcSkKYHegrcVy">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">For more information on what type of information Firebase collects, please visit the
                        Google Privacy Terms web page: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708611955315161&amp;usg=AOvVaw195F1z1FDcgXipCIrYGLY6">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <p class="c2"><span class="c15">Segment.io</span></p>
                <p class="c2"><span class="c8">Segment.io is a web traffic analysis tool. You can read the Privacy Policy for Segment.io
                        here: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://segment.com/legal/privacy/&amp;sa=D&amp;source=editors&amp;ust=1708611955315362&amp;usg=AOvVaw3-ZYkNtP9779ifvVgqqkYe">https://segment.com/legal/privacy/</a></span><span
                        class="c8">.</span></p>
                <p class="c2 subtitle"><span class="c15">&nbsp;</span></p>
                <p class="c2 c6 subtitle"><span class="c15">Mixpanel</span></p>
                <p class="c2"><span class="c8">Mixpanel is provided by Mixpanel Inc.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">You can prevent Mixpanel from using your information for analytics purposes by
                        opting-out. To opt-out of Mixpanel service, please visit this page: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://mixpanel.com/optout/&amp;sa=D&amp;source=editors&amp;ust=1708611955315710&amp;usg=AOvVaw1zS93LkyhJVEjN92B8jQC3">https://mixpanel.com/optout/</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">For more information on what type of information Mixpanel collects, please visit the
                        Terms of Use page of Mixpanel: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://mixpanel.com/terms/&amp;sa=D&amp;source=editors&amp;ust=1708611955315897&amp;usg=AOvVaw1tX1YCGIn9lqBYnOCnx1G_">https://mixpanel.com/terms/</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="13">
                    <li class="c1 c6"><span class="c4">CI/CD tools</span></li>
                </ol>
                <p class="c2 c6"><span class="c0">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">We may use third-party Service Providers to automate the development process of our
                        Service. </span></p>
                <p class="c2 c6"><span class="c9">&nbsp;</span></p>
                <p class="c2 c6"><span class="c9">GitHub</span></p>
                <p class="c2 c6"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">GitHub is provided by GitHub, Inc.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">GitHub is a development platform to host and review code, manage projects, and build
                        software.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">For more information on what data GitHub collects for what purpose and how the protection
                        of the data is ensured, please visit GitHub Privacy Policy page:</span><span class="c7 c14">&nbsp;</span><span
                        class="c7 c14"><a class="c10"
                            href="https://www.google.com/url?q=https://help.github.com/en/articles/github-privacy-statement&amp;sa=D&amp;source=editors&amp;ust=1708611955316343&amp;usg=AOvVaw0SAy6SdsncS7eUoSm7RMAi">https://help.github.com/en/articles/github-privacy-statement</a></span><span
                        class="c0">.</span></p>
                <p class="c2 c6"><span class="c9">&nbsp;</span></p>
                <p class="c2 c6"><span class="c9">GitLab CI/CD</span></p>
                <p class="c2 c6"><span class="c9">&nbsp;</span></p>
                <p class="c2"><span class="c0">GitLab CI/CD is provided by GitLab, Inc.</span></p>
                <p class="c2"><span class="c9">&nbsp;</span></p>
                <p class="c2"><span class="c0">GitLab CI (Continuous Integration) service is a part of GitLab that build and test the
                        software whenever developer pushes code to application. </span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">GitLab CD (Continuous Deployment) is a software service that places the changes of every
                        code in the production which results in every day deployment of production. </span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c0">For more information on what data GitLab CI/CD collects for what purpose and how the
                        protection of the data is ensured, please visit GitLab CI/CD Privacy Policy page: </span><span class="c7 c14"><a
                            class="c10"
                            href="https://www.google.com/url?q=https://about.gitlab.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1708611955316694&amp;usg=AOvVaw0BsOX3Xt-9ucOk9Zul5YXr">https://about.gitlab.com/privacy/</a></span><span
                        class="c0">.</span></p>
                <p class="c12"><span class="c15 c20">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="14">
                    <li class="c1 c6"><span class="c4">Behavioral Remarketing</span></li>
                </ol>
                <p class="c13 c6"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2"><span class="c0">INTRAVERSE AFRICA&nbsp;uses remarketing services to advertise on third party websites to
                        you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads
                        based on your past visits to our Service.</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2 c6 subtitle"><span class="c15">Google Ads (AdWords)</span></p>
                <p class="c2"><span class="c8">Google Ads (AdWords) remarketing service is provided by Google Inc.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">You can opt-out of Google Analytics for Display Advertising and customize the Google
                        Display Network ads by visiting the Google Ads Settings page: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=http://www.google.com/settings/ads&amp;sa=D&amp;source=editors&amp;ust=1708611955317226&amp;usg=AOvVaw3ofShpJkXYxnW4fBUK1vyP">http://www.google.com/settings/ads</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <p class="c2"><span class="c8">Google also recommends installing the Google Analytics Opt-out Browser Add-on &ndash;
                    </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1708611955317396&amp;usg=AOvVaw3Sr9_wP_vh5SrK2Sw3cM9w">https://tools.google.com/dlpage/gaoptout</a></span><span
                        class="c8">&nbsp;&ndash; for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with
                        the ability to prevent their data from being collected and used by Google Analytics.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c8">For more information on the privacy practices of Google, please visit the Google Privacy
                        Terms web page: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708611955317583&amp;usg=AOvVaw1Vt6g3D-Mzt1qNNw68fBFq">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c2 subtitle"><span class="c15">&nbsp;</span></p>
                <p class="c2 c6 subtitle"><span class="c15">Facebook</span></p>
                <p class="c2"><span class="c8">Facebook remarketing service is provided by Facebook Inc.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">You can learn more about interest-based advertising from Facebook by visiting this
                        page: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://www.facebook.com/help/164968693837950&amp;sa=D&amp;source=editors&amp;ust=1708611955317958&amp;usg=AOvVaw1Qm7OPZNiJqwtd0_xm1uJT">https://www.facebook.com/help/164968693837950</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <p class="c2"><span class="c8 c18">To opt-out from Facebook&#39;s interest-based ads, follow these instructions from
                        Facebook: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://www.facebook.com/help/568137493302217&amp;sa=D&amp;source=editors&amp;ust=1708611955318168&amp;usg=AOvVaw2EcSgNX3UW7lFsxgaFKnub">https://www.facebook.com/help/568137493302217</a></span>
                </p>
                <p class="c2"><span class="c7">&nbsp;</span></p>
                <p class="c2"><span class="c8">Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising
                        established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating
                        companies through the Digital Advertising Alliance in the USA </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1708611955318335&amp;usg=AOvVaw3e_PJJfiWT2mVVF0nhylrq">http://www.aboutads.info/choices/</a></span><span
                        class="c8">, the Digital Advertising Alliance of Canada in Canada </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=http://youradchoices.ca/&amp;sa=D&amp;source=editors&amp;ust=1708611955318401&amp;usg=AOvVaw3biQGjfNrlICCr018j8gAz">http://youradchoices.ca/</a></span><span
                        class="c8">&nbsp;or the European Interactive Digital Advertising Alliance in Europe </span><span class="c7"><a
                            class="c10"
                            href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&amp;sa=D&amp;source=editors&amp;ust=1708611955318464&amp;usg=AOvVaw1W48mybhVqKyzBe-Td58SH">http://www.youronlinechoices.eu/</a></span><span
                        class="c8">, or opt-out using your mobile device settings.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c8">For more information on the privacy practices of Facebook, please visit Facebook&#39;s
                        Data Policy: </span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://www.facebook.com/privacy/explanation&amp;sa=D&amp;source=editors&amp;ust=1708611955318657&amp;usg=AOvVaw2NCngSYb2DGlf_eOe4d3tf">https://www.facebook.com/privacy/explanation</a></span>
                </p>
                <p class="c2 subtitle"><span class="c15">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="15">
                    <li class="c1 c6"><span class="c4">Payments</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">We may provide paid products and/or services within Service. In that case, we use
                        third-party services for payment processing (e.g. payment processors).</span></p>
                <p class="c2"><span class="c0">&nbsp;</span></p>
                <p class="c2"><span class="c8">We will not store or collect your payment card details. That information is provided
                        directly to our third-party payment processors whose use of your personal information is governed by their
                        Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security
                        Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover.
                        PCI-DSS requirements help ensure the secure handling of payment information.</span></p>
                <p class="c2"><span class="c8">&nbsp;</span></p>
                <p class="c2 c6"><span class="c8">The payment processors we work with are:</span></p>
                <p class="c11"><span class="c8">&nbsp;</span></p>
                <p class="c11 c6 subtitle"><span class="c15">Paystackl:</span></p>
                <p class="c11"><span class="c8">Their Privacy Policy can be viewed
                        at&nbsp;https://paystack.com/privacy/merchant</span><span class="c7"><a class="c10"
                            href="https://www.google.com/url?q=https://www.paypal.com/webapps/mpp/ua/privacy-full&amp;sa=D&amp;source=editors&amp;ust=1708611955319341&amp;usg=AOvVaw1nM9gwW3m3HZOJw5oedt5z">https://www.paypal.com/webapps/mpp/ua/privacy-full</a></span>
                </p>
                <p class="c11"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="16">
                    <li class="c1 c6"><span class="c4">Links to Other Sites</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">Our Service may contain links to other sites that are not operated by us. If you click a
                        third party link, you will be directed to that third party&#39;s site. We strongly advise you to review the
                        Privacy Policy of every site you visit.</span></p>
                <p class="c13"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2"><span class="c8">We have no control over and assume no responsibility for the content, privacy policies or
                        practices of any third party sites or services.</span></p>
                <p class="c11"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="17">
                    <li class="c1 c6"><span class="c4">Children&#39;s Privacy</span></li>
                </ol>
                <p class="c5 c6"><span class="c8">&nbsp;</span></p>
                <p class="c5 c6"><span class="c8">Our Services are not intended for use by children under the age of 13
                        (&ldquo;</span><span class="c15">Children</span><span class="c8">&rdquo;). </span></p>
                <p class="c5 c6"><span class="c8">&nbsp;</span></p>
                <p class="c5 c6"><span class="c8">We do not knowingly collect personally identifiable information from Children under
                        13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware
                        that we have collected Personal Data from Children without verification of parental consent, we take steps to
                        remove that information from our servers.</span></p>
                <p class="c2 c6"><span class="c0">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="18">
                    <li class="c1 c6"><span class="c4">Changes to This Privacy Policy</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2"><span class="c0">We may update our Privacy Policy from time to time. We will notify you of any changes by
                        posting the new Privacy Policy on this page.</span></p>
                <p class="c13"><span class="c18 c15">&nbsp;</span></p>
                <p class="c2"><span class="c0">We will let you know via email and/or a prominent notice on our Service, prior to the
                        change becoming effective and update &ldquo;effective date&rdquo; at the top of this Privacy Policy.</span></p>
                <p class="c13"><span class="c8">&nbsp;</span></p>
                <p class="c2"><span class="c0">You are advised to review this Privacy Policy periodically for any changes. Changes to
                        this Privacy Policy are effective when they are posted on this page.</span></p>
                <p class="c12"><span class="c8">&nbsp;</span></p>
                <ol class="c3 lst-kix_list_27-0" start="19">
                    <li class="c1 c6"><span class="c4">Contact Us</span></li>
                </ol>
                <p class="c2 c6"><span class="c4">&nbsp;</span></p>
                <p class="c2 c6"><span class="c0">If you have any questions about this Privacy Policy, please contact us:</span></p>
                <p class="c2 c6"><span class="c0">&nbsp;</span></p>
                <p class="c11"><span class="c8">By email: support@intraverse.africa.</span></p>
                <p class="c11"><span class="c8">&nbsp;</span></p>
                <div>
                    <p class="c6 c30"><span class="c0"></span></p>
                </div>
            </div>
        </div>
    )
}