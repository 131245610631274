import React, { useEffect, useRef, useState } from 'react';
import ChoseTemplate from './choseTemplate';
import GenerateLinkHome from './generateLinkHome';
import Preview from '../../../components/templates/preview/Preview';
import HeaderStyle from './headerStyle';
import FooterStyle from './footerStyle';
import SearchBarStyle from './searchBarStyle';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../components/templates/Header';
import updateProfile from '../../../controllers/user/updateProfile';
import Button1 from '../../../components/form/Button1';
import { enqueueSnackbar } from 'notistack';
import ApplyPage from './applyPage';
import AboutLayout from './AboutLayout';
import ContactLayout from './ContactLayout';


const components = [
    <ChoseTemplate />,
    <GenerateLinkHome />,
    <HeaderStyle />,
    <SearchBarStyle />,
    <FooterStyle />,
    <ApplyPage />,
    <AboutLayout />,
    <ContactLayout />,
]

const CurrentFormRender = (id)=>{
    try {
        return React.cloneElement(components[id],{step: id});
    } catch(ex) {}
}


const GenerateLink = () => {
    const state = useSelector((state)=> state.template);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const step = searchParams.get('step')
    const [currentForm, setCurrentForm] = useState(step || 0)
    const [fullScreen, setFullScreen] = useState(false);
    const iframeRef = useRef(null);
    const [scale, setScale] = useState(0.4);
    const iframeRefScale = useRef(null);
    const [loading,setLoading] = useState(false);

    console.log(state)
    
    const updateCurrentForm = (action)=>{
        if(action === 'next'){
            if(currentForm < components?.length - 1)
            setCurrentForm(currentForm+1)
        }
        else if(action === 'back'){
            setCurrentForm(currentForm-1)
        }
    }
    let currentHost = window.location.protocol + "//"+ window.location.host;
    console.log(currentHost,state)
    
    let curHeight = window.innerHeight;
    const updateScale = () => {
        let screenWidth = window.innerWidth;
        if (iframeRefScale.current) {
          setScale((iframeRefScale.current.offsetWidth / screenWidth));
        }
    }
      useEffect(()=>{
        window.addEventListener('resize', updateScale)
        updateScale()
        return ()=>{
          window.removeEventListener('resize', updateScale)
        }
      },[])
      const handleFullScreen = () => {
        console.log(currentHost)
        window.open(currentHost+"/preview", "_blank");
    }
    async function save() {
        setLoading(true);
        const res = await updateProfile({templateData: JSON.stringify(state)})
        setLoading(false);
        if(res.return) {
            enqueueSnackbar('Saved',{variant: 'success'})
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
    return (
        <div>
            <Header />
            <div className={`grid grid-cols-1 xl:grid-cols-5 gap-2 h-full px-4`}>
                <div className="col-span-3">
                    {CurrentFormRender(currentForm)}
                    <div className="flex justify-between place-items-end mx-10 my-10">
                    {currentForm > 0 ? (
                        <Button variant="text" onClick={() => updateCurrentForm("back")}>
                        Go back
                        </Button>
                    ) : (
                        <div></div>
                    )}
                    {currentForm < components?.length - 1 ? (
                        <Button onClick={() => updateCurrentForm("next")}>Next</Button>
                    ) : (
                        <div>
                            <Button1 variant='text' loading={loading} onClick={() => save()}>Save</Button1>
                        </div>
                    )}
                    </div>
                </div>
                <div ref={iframeRefScale} className="col-span-2 " xstyle={{height: curHeight-100}}>
                    <div className="shadow-md h-full">
                        <div className="w-full flex justify-center mt-4 mb-4">
                            <Link to="/order/new/flight/template/1" target='_blank'>
                                <Button variant="outlined" className="bg-silverColor">
                                    Full screen preview
                                </Button>
                            </Link>
                        </div>
                        <div
                        className="origin-top-left h-full"
                        >
                        <iframe
                            ref={iframeRef}
                            src={currentHost+"/order/new/flight/template/1"}
                            className={`w-screen overflow-hidden border-0 origin-top-left`}
                            style={{ height: `${100 / scale}%`, transform: `scale(${scale})` }}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateLink;
