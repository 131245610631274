export function getPhone(value) {
    let code = '';
    let number = '';
    try {
        if(value?.includes(' ')) {
            code = value?.split(' ')?.at(0) || code;
            code = code?.replaceAll('+','');
            number = value?.split(' ').slice(1)?.join('')
        }
    } catch(ex) {}
    try {
        number = number.replace(/\D/g,'')
    } catch(ex) {}
    
    return code+'-'+number;
}