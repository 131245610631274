import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmFlightNameChangeModal,
  setConfirmTourBookingModal,
  setTicketManagementLoadingModal,
} from "../../redux/reducers/modalSlice";
import { useState } from "react";
import { Checkbox, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ConfirmFlightNameChangeModal() {
  const dispatch = useDispatch();
  const { confirmFlightNameChangeModal } = useSelector((state) => state.modal);

  const handleOpen = () => dispatch(setConfirmTourBookingModal(true));
  const handleClose = () => dispatch(setConfirmFlightNameChangeModal(false));

  return (
    <div>
      <Modal
        open={confirmFlightNameChangeModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className={Classname.content}>
              <span className={Classname.modalTitle}>
                Changed passenger details
              </span>
              <Compare />
              <CTASection />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Compare = () => {
  const details = {
    previous: [
      { title: "Title", value: "Mr" },
      { title: "First name", value: "James" },
      { title: "Middle name", value: "Dennis" },
      { title: "Last name", value: "Davis" },
    ],
    new: [
      { title: "Title", value: "Mr" },
      { title: "First name", value: "Chinema", change: true },
      { title: "Middle name", value: "Dave", change: true },
      { title: "Last name", value: "Davis" },
    ],
  };
  return (
    <div className="flex flex-row lg:flex-col justify-between lg:justify-start w-full mb-4 gap-3">
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-between">
        <div className="bg-[#DBDBDB] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          Previous details
        </div>
        {details.previous.map((item, index) => (
          <span className="text-sm lg:text-sm" key={index}>
            {item?.title}:{" "}
            <span
              className={item?.change ? "bg-[#FDF3D7] font-bold" : "font-bold"}
            >
              {item.value}
            </span>
          </span>
        ))}
      </div>
      <div className="flex flex-col lg:w-full gap-3 lg:flex-row lg:items-center justify-between">
        <div className="bg-[#FDF3D7] rounded-md w-full lg:w-40 h-9 flex items-center justify-center font-bold text-sm">
          New details
        </div>
        {details?.new?.map((item, index) => (
          <span className="text-sm lg:text-sm" key={index}>
            {item?.title}:{" "}
            <span
              className={item?.change ? "bg-[#FDF3D7] font-bold" : "font-bold"}
            >
              {item.value}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

const CTASection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [change, setChange] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleGoBack = () => {
    dispatch(setConfirmFlightNameChangeModal(false));
  };

  const handleConfirm = () => {
    dispatch(setConfirmFlightNameChangeModal(false));
    dispatch(setTicketManagementLoadingModal(true));

    setTimeout(() => {
      dispatch(setTicketManagementLoadingModal(false));
      navigate("/order/flight/ticket/confirmation?type=nameChange");
    }, 2000);
  };

  return (
    <div className="w-full flex flex-col gap-4 py-4 lg:py-10 ">
      <div className="w-full flex items-center justify-between gap-10">
        <button
          onClick={handleGoBack}
          className="px-3 lg:px-10 font-bold flex items-center justify-center h-12"
        >
          Go Back
        </button>
        <button
          onClick={handleConfirm}
          className="lg:px-10 font-bold flex-1 flex items-center justify-center h-12 max-w-sm bg-primary1 rounded-md text-white"
        >
          Confirm Change
        </button>
        <div className="hidden lg:flex"></div>
      </div>
      <div className="flex w-full items-center justify-center gap-2">
        <Checkbox
          checked={agree}
          onClick={() => setAgree(!agree)}
          size="small"
        />
        <span className="text-xs lg:text-sm">
          I agree to Intraverse{" "}
          <a href="" className="text-primary1">
            Service Agreement, Terms & Conditions and Privacy Policy
          </a>
        </span>
      </div>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main",
  content: "flex flex-col items-center px-4",
  modalTitle:
    "text-xl lg:text-3xl font-bold mb-6 py-4 border-b w-full text-center",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
