import { useState } from "react";
import { Icon } from "@iconify/react";
import { Button, TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { getsubDomain } from "../../../utils/getsubDomain";
import signup from "../../../controllers/Auth/signup";
import { signupReqData } from '../../../data/user/Auth/signupReq'
import Logo from "../../Logo/Logo";
import Button1 from "../../form/Button1";
import signupCustomer from "../../../controllers/Auth/customer/signup";


const EmailOrGoogle = ({setCurrentPage,handleGoogleAuth})=>{
    return(
        <div className="w-96 flex justify-center">
            <div className="w-full">
                <div className="font-bold flex justify-center">Create an account</div>
                {/* <Button variant="contained" 
                 className="!w-full border rounded-sm !border-[#BCC7D3] !bg-white !text-[#333333] py-3 px-4 flex justify-center items-center gap-2 !mt-4"
                 onClick={() => handleGoogleAuth && handleGoogleAuth()}
                >
                    <Icon icon="devicon:google" />
                    <span className="font-bold">Sign up with Google</span>
                </Button>
                <div className="flex w-full justify-center !mt-4 font-bold">
                    Or
                </div> */}
                <Button variant="contained" className="!w-full border rounded-sm !border-[#BCC7D3] py-3 px-4 flex justify-center items-center gap-2 !mt-4 " onClick={()=> setCurrentPage(1)}>
                    <span className="font-bold text-white">Continue with Email</span>
                </Button>
            </div>
        </div>
    )
}
const SignUpForm = ({setCurrentPage})=>{
    const [passwordVisible,setPasswordVisible] = useState(false);
    const [confirmPasswordVisible,setConfirmPasswordVisible] = useState(false);
    const [phone,setPhone] = useState('');

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email')
    const referredBy = searchParams.get('ref')
    const redirect = searchParams.get('redirect')
    // let type = searchParam.get('type')
    const [data,setData] = useState({...signupReqData,referredBy,userType: "Customer",confirmPassword: '',email: email || ''});
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [terms,setTerms] = useState(false);
    const [type,setType] = useState(email || false);
    const {userData: {agent}} = useSelector(state => state?.user)
    const agency = getsubDomain();



    async function handleSubmit(ev) {
        ev?.preventDefault();
        

        const {password,confirmPassword} = data;
        if(password !== confirmPassword)
            return enqueueSnackbar('Password doesn\'t match',{variant: 'error'})

        setLoading(true);
        let res = {return: 0,msg: 'Something went wrong on our end! Please contact support. 0xSTLOG'}
        // console.log(agent)
        // if(agent)
        // res = await acceptInvitation({firstName,lastName,email,password,agentId: agent?._id,hash: agent?.hash})
        // else
        res = await signupCustomer({...data,phone})
        // res = await signup({...data,phone});
        setLoading(false);
        if(res.return) {
        enqueueSnackbar('Registered Successfully.',{variant: 'success'});
            if(agency)
                navigate('/')
            else    
                setTimeout(() => {
                    navigate(`?view=verify&email=${data.email}${redirect?'&redirect='+redirect:''}`)
                },2000)
        } else enqueueSnackbar(res.msg || 'Registration Failed.', {variant: 'error'})
    }
    
    
    return(
        <div className="col-span-2 bg-white flex justify-center items-center">
            <div className="w-[500px] flex justify-center">
                <form onSubmit={handleSubmit} className="w-full grid gap-3">
                    <div className="font-bold text-xl">Create an account</div>
                    <div className="grid lg:grid-cols-2 gap-2">
                        <TextField id="outlined-basic" label="Firstname" variant="outlined" className="rounded-sm px-4 w-full"
                            value={data.firstName}
                            onChange={(ev) => setData({...data,firstName: ev.target.value})}
                         />
                        <TextField id="outlined-basic" label="Lastname" variant="outlined" className="rounded-sm px-4 w-full " 
                            value={data.lastName}
                            onChange={(ev) => setData({...data,lastName: ev.target.value})}
                        />
                    </div>
                    <TextField type="email" id="outlined-basic" label="Email" variant="outlined" className="rounded-sm px-4 w-full " 
                        value={data.email}
                        onChange={(ev) => setData({...data,email: ev.target.value})}          
                    />
                    <MuiTelInput value={phone} onChange={(val)=>setPhone(val)} label="phone" defaultCountry="NG" className="col-span-1"/>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={passwordVisible ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>setPasswordVisible(!passwordVisible)}
                                edge="end"
                                >
                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Password"
                            value={data.password}
                            onChange={(ev) => setData({...data,password: ev.target.value})}  
                        />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={confirmPasswordVisible ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={()=>setConfirmPasswordVisible(!confirmPasswordVisible)}
                                edge="end"
                                >
                                {confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Confirm Password"
                            value={data.confirmPassword}
                            onChange={(ev) => setData({...data,confirmPassword: ev.target.value})}
                        />
                    </FormControl>
                    <TextField id="outlined-basic" label="Referal code (optional)" variant="outlined" className="rounded-sm px-4 w-full " 
                        value={data.referredBy}
                        onChange={(ev) => setData({...data,referredBy: ev.target.value})}
                    />
                    <div className="">
                        <FormControlLabel control={<Checkbox icon={<div className="h-3 w-3 bg-[#D9D9D9]" />} checkedIcon={<div className="h-3 w-3 bg-[#AEE4FF] text-black" />} />} label={<span className="text-sm text-[#6D7C94]">I’d like to receive occasionaly updates</span>} />
                        <FormControlLabel control={<Checkbox icon={<div className="h-3 w-3 bg-[#D9D9D9]" />}
                            onChange={(ev) => setTerms(ev.target.checked)}
                            checkedIcon={<div className="h-3 w-3 bg-[#AEE4FF] text-black" />} />} label={<span className="text-sm text-[#6D7C94]">I’ve read and agree with Miles Terms & Privacy Policy</span>} />
                    </div>
                    <Button1 loading={loading} disabled={!terms} variant="contained" type={'submit'} className="!w-full border rounded-sm !border-[#BCC7D3] px-4 flex justify-center items-center gap-2 mt-2 !bg-[#1C2D5C]" 
                        // onClick={()=> setCurrentPage(2)}
                    >
                        <span className="font-bold text-white">Create account</span>
                    </Button1>
                </form>
            </div>
        </div>
    )
}

const Question = ({questions, currentQuestion, setAnswers, answers})=>{
    const handleOptionClick = (questionIndex, optionIndex) => {
        setAnswers((prevAnswers) => {
            const newAnswers = [...prevAnswers];
            newAnswers[questionIndex] = newAnswers[questionIndex].map((value, index) =>
                index === optionIndex ? !value : value
            );
            return newAnswers;
        });
    };
    return(
        <div className="w-[500px] flex justify-center">
            <div>
                <div className="font-bold text-xl">{questions[currentQuestion].title}</div>
                <div className="mt-3 text-[#6D7C94]">{questions[currentQuestion].description}</div>
                <div className="flex flex-wrap gap-3 my-3">
                    {questions[currentQuestion].options.map((option,i)=>(
                        <div key={i} onClick={()=>handleOptionClick(currentQuestion, i)} className={`border-2 cursor-pointer ${answers[currentQuestion][i]? 'border-[#AEE4FF]' : ""}  bg-[#F5F7F9] py-2 px-3 rounded-sm`}>{option}</div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const AllQuestions = ()=>{
    const questions = [
        {
            "title": "What kind of traveler do you identify as?",
            "description": "Sharing this information will contribute to an improved experience for you, without any limitations on accessing features.",
            "options": [
                "Leisure Traveler",
                "Business Traveler",
                "Adventure traveler",
                "Eco-tourist",
                "Culture traveler",
            ]
        },
        {
            "title": "What is your preferred travel destination type?",
            "description": "Sharing this information will contribute to an improved experience for you, without any limitations on accessing features.",
            "options": [
                "Beach & Coastlines",
                "Mountains & Hiking",
                "City",
                "Countryside",
                "Historic sites",
                "Natural wonders",
                "Wildlife reserve",
                "Exotic",
                "Winter sport locations",
            ]
        },
        {
            "title": "What is your usual travel budget range?",
            "description": "Sharing this information will contribute to an improved experience for you, without any limitations on accessing features.",
            "options": [
                "Budget",
                "Mid-range",
                "Premium",
                "Luxury",
            ]
        },
        {
            "title": "Which of the following services are you most interested in while traveling?",
            "description": "Sharing this information will contribute to an improved experience for you, without any limitations on accessing features.",
            "options": [
                "Accommodation",
                "Dining",
                "Local Tour",
                "Shopping",
                "Adventure activities",
                "Nightlife",
                "Cultural experiences"
            ]
        }
    ]
    const [answers, setAnswers] = useState(() => {
        return questions.map((question) => question.options.map(() => false));
    });
    const [currentQuestion, setCurrentQuestion] = useState(0);
    return(
        <div>
            {questions.map((question,i)=>(
                currentQuestion === i &&
                <Question key={i} questions={questions} currentQuestion={i} setAnswers={setAnswers} answers={answers}/>
            ))}
            <div className="flex justify-between items-center">
                <div>
                    {
                        currentQuestion < questions.length-1 ?
                        <Button variant="contained" onClick={()=>setCurrentQuestion(currentQuestion+1)} className="!bg-[#1C2D5C]">Next</Button> :
                        <Button variant="contained" className="!bg-[#1C2D5C]">Finish</Button>
                    }
                </div>
                <div className="flex gap-2 items-center">
                    {currentQuestion > 0 && <Button onClick={()=>setCurrentQuestion(currentQuestion-1)} className="!text-[#333333]">Go back</Button>}
                    <Button variant="contained" className="!bg-[#A0D3ED] !text-[#333333]">Skip</Button>
                </div>
            </div>
        </div>
    )
}
const Register = ()=>{

    async function handleGoogleAuth() {
        let curUrl = new URLSearchParams({callbackUrl: window.location.href})
        window.location.href = (process.env.REACT_APP_API+'/main/v1/auth/google?'+curUrl)
    }
    const [currentPage, setCurrentPage] = useState(0);
    const GetPage = ({currentPage})=>{
        switch(currentPage){
            case 0:
                return <EmailOrGoogle handleGoogleAuth={handleGoogleAuth} setCurrentPage={setCurrentPage}/>
            case 1:
                return <SignUpForm setCurrentPage={setCurrentPage}/>
            case 2:
                return <AllQuestions/>
            default:
                return <EmailOrGoogle handleGoogleAuth={handleGoogleAuth}/>
        }
    }
    return(
        <div className="grid grid-cols-3">
            <div className="col-span-1 p-5 bg-[#F5F7F9] h-screen">
                {/* <div className="flex justify-center items-center bg-[#D9D9D9] m-6 px-3 py-2 font-bold w-40">
                    Company Logo
                </div> */}
                <Logo />
            </div>
            <div className="col-span-2 bg-white flex flex-col gap-4 justify-center items-center py-4">
                <GetPage currentPage={currentPage}/>
                <div className="text-[#6D7C94] text-sm">Already have an account? <Link to="?view=login" className="cursor-pointer text-[#A0D3ED]">Login</Link></div>
            </div>
        </div>
    )
}

export default Register;