import React from "react";
import { Icon } from "@iconify/react";

const Modals = ({ openModals, toggleModals, children, title }) => {
  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full items-center justify-center bg-gray-500 bg-opacity-75 ${
        openModals ? "flex" : "hidden"
      } `}
    >
      <div className={Classname.modalContent}>
        <div className={Classname.header}>
          <div>
            <h2 className={Classname.title}>{title}</h2>
          </div>
          <button onClick={toggleModals}>
            <Icon
              icon="material-symbols:close"
              className="text-secondary text-3xl"
            />
          </button>
        </div>
        <div className={Classname.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modals;

const Classname = {
  modalContent:
    "bg-white max-w-[90vw] rounded-b-lg shadow-md max-h-[90vh] mx-4",
  header:
    "bg-primary1 px-2.5 py-1 sm:px-6 sm:py-5 flex justify-between items-center",
  content: "p-6 flex flex-col gap-4 sm:gap-6",
  title: "font-bold text-secondary text-xs sm:text-2xl",
};
