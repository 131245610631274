import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTemplate } from '../../../../redux/reducers/template/templateSlice';
import { Radio } from '@mui/material';
import ContactList from '../../../../components/templates/contact/ContactList';

export default function ContactLayout() {
    const state = useSelector(state => state.template);
    const layouts = [
        {id: 1},
        {id: 2},
        {id: 3},
        // {id: 4},
    ]
    const dispatch = useDispatch();

    function handleSelect(id) {
        dispatch(setTemplate({...state,contactLayout: id}))
    }
    const scale = .2;
    
    return (
        <div className="px-10 mt-10 col-span-5 w-full flex flex-col gap-6">
          <div>
            <h1 className="text-4xl font-BrFirmaBold font-bold my-0">
            Select layout for Contact Page
            </h1>
            {/* <p className="font-BrFirmaRegular my-2">
                These logo, colors, and font will be used to design your site. You can always change them later.
            </p> */}
          </div>
          <div className='flex gap-2 flex-wrap relative '>
            {layouts.map((layout,i)=>(
                <div
                    key={i} 
                    className={`cursor-pointer ${
                        state.contactLayout === (layout.id)
                        ? "bg-[#C4E9E3]"
                        : "bg-[#F8F8F8]"
                    } p-2 rounded-md shadow-sm h-[400px] max-w-[40%] overflow-hidden`}
                    onClick={() => handleSelect((layout.id))}
                    >
                    <Radio
                        size="small"
                        color="success"
                        checked={state.contactLayout === (layout.id)}
                    />
                    <span className="mt-2 font-poppins text-sm font-bold text-[#2E2E32]">
                        {`Layout ${layout.id}`}
                    </span>
                    <div className="mt-2 origin-top-left w-screen h-screen " 
                        style={{transform: `scale(${scale})`}}
                    >
                        <div className="pr-10">
                            <ContactList id={layout.id} noScroll />
                        </div>
                    </div>
                </div>
            
                // <div key={i} className={`border-solid border-2 ${state.contactLayout === (layout.id) ? 'bg-[#C4E9E3] border-[#1EA994]' :'bg-[#F8F8F8] border-[#F8F8F8]'} px-3 col-span-1 relative cursor-pointe rounded-lg`} 
                //   onClick={()=> handleSelect(layout.id )}>
                //     <div className="flex gap-2">
                //         <Radio size="small" color="success" checked={state.contactLayout === (layout.id)}/>
                //     </div>
                //     <div className="w-full relative h-60 ">
                //         <div className=" h-[90%] bg-[#D9D9D9] top-0 left-0 w-full"
                //         >
                //         </div>
                //     </div>
                // </div>
            ))}
          </div>

        </div>
    )
}