import React from "react";
import activeIcon from "../../assets/IconActiveCheckMark.svg";
import inActiveIcon from "../../assets/IconInactiveCheckMark.svg";

function GettingStartedStepTitle({ active, title, visible }) {
  return (
    <div className={`items-center gap-2 my-4 ${visible ? "flex" : "hidden"}`}>
      <img
        src={active ? activeIcon : inActiveIcon}
        alt=""
        className="w-5 h-5 object-contain"
      />
      <span className="font-semibold text-sm lg:text-base">{title}</span>
    </div>
  );
}

export default GettingStartedStepTitle;
