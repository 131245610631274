import React from "react";

function RatingStars({ rating, size }) {
  return (
    <div
      className={`${Classname.ratingStars} ${
        size === "small" ? "gap-1" : "gap-2"
      }`}
    >
      <img
        src="/IconStarActive.svg"
        alt=""
        className={
          size === "small" ? Classname.smallRatingIcon : Classname.ratingIcon
        }
      />
      <img
        src={rating > 1 ? "/IconStarActive.svg" : "/IconStar.svg"}
        alt=""
        className={
          size === "small" ? Classname.smallRatingIcon : Classname.ratingIcon
        }
      />
      <img
        src={rating > 2 ? "/IconStarActive.svg" : "/IconStar.svg"}
        alt=""
        className={
          size === "small" ? Classname.smallRatingIcon : Classname.ratingIcon
        }
      />
      <img
        src={rating > 3 ? "/IconStarActive.svg" : "/IconStar.svg"}
        alt=""
        className={
          size === "small" ? Classname.smallRatingIcon : Classname.ratingIcon
        }
      />
      <img
        src={rating > 4 ? "/IconStarActive.svg" : "/IconStar.svg"}
        alt=""
        className={
          size === "small" ? Classname.smallRatingIcon : Classname.ratingIcon
        }
      />
    </div>
  );
}

export default RatingStars;

const Classname = {
  ratingContainer: "flex items-center gap-3 mb-2",
  ratingStars: "flex items-center mb-1",
  ratingIcon: "h-5 object-contain",
  smallRatingIcon: "h-3 object-contain",
};
