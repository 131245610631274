import Icon from "../../../HOC/Icon";
import ParisImage from "../../../../assets/images/paris.png";
import BerlinImage from "../../../../assets/images/Berlin, German.png";
import LondonImage from "../../../../assets/images/London, United Kingdom.png";
import { offerSearchTemp } from "../../../../data/flight/offerSearchData";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { encrypt } from "../../../../features/utils/crypto";
import { setBookingData } from "../../../../redux/reducers/flight/flightBookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { clone } from "../../../../features/utils/objClone";
import getCityCodes from "../../../../controllers/Flight/getCityCodes";
import { CircularProgress } from "@mui/material";

const libraries = ["places"];
const mapLoaderObj = {
  googleMapsApiKey:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries,
};

const TopDestination = () => {
  const { isLoaded } = useJsApiLoader(mapLoaderObj);
  const { bookingData } = useSelector((state) => state.flightBooking);
  const { user, agent } = useSelector((state) => state.user.userData);
  const crmData = (agent || user)?.detail?.crmData;

  console.log(crmData, crmData?.destinations);
  const destinations = crmData?.destinations?.map((obj) => {
    const query = JSON.parse(obj?.query || "{}");
    const from = query?.originDestinations?.at(0)?.from;
    const to = query?.originDestinations?.at(0)?.to;
    return {
      name: obj.title,
      location: {
        from: from?.iata,
        to: to?.iata,
        name: from?.city + ", " + to?.city,
      },
      query: obj?.query,
      image: obj?.image,
      rating: obj?.rating,
    };
  });
  const data = [
    {
      name: "Eiffel Tower, Versailles & Seine River Combo Tour",
      location: {
        from: "",
        to: "ORY",
        name: "Paris, France",
      },
      rating: "4.5",
      image: ParisImage,
    },
    {
      name: "Neuschwanstein & Linderhof Castle Full-Day Trip",
      location: {
        from: "",
        to: "MUC",
        name: "Berlin, German",
      },
      rating: "4.5",
      image: BerlinImage,
    },
    {
      name: "Tootbus Must-See Hop-On Hop-Off Bus Tour with Cruise",
      location: {
        from: "",
        to: "LHR",
        name: "London, United Kingdom",
      },
      rating: "4.5",
      image: LondonImage,
    },
    // {name: 'Tootbus Must-See Hop-On Hop-Off Bus Tour with Cruise',location: {
    // from: '',
    // to: '',
    // name: 'London, United Kingdom'},rating: '4.5',image: ParisImage},
  ];
  const [loading, setLoading] = useState(false);

  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getIATACode = (addressComponents) => {
    // Function to extract the IATA code from the address components
    const airportComponent = addressComponents.find((component) =>
      component.types.includes("airport")
    );
    return airportComponent ? airportComponent.short_name : "";
  };
  const getCityName = (addressComponents) => {
    // Function to extract the IATA code from the address components
    const airportComponent = addressComponents.find(
      (component) =>
        /^[a-zA-Z0-9 ]+$/.test(component.short_name) &&
        (component.types.includes("locality") ||
          component.types.includes("administrative_area_level_1") ||
          component.types.includes("administrative_area_level_2"))
    );
    return airportComponent ? airportComponent.short_name : "";
  };

  async function handleSearch(searchData, i) {
    setLoading(i?.toString());
    let modSearch = { ...searchData };
    modSearch.location.from = "LOS";

    if (modSearch?.query) {
      const enc = encrypt(modSearch?.query);
      navigate(`/order/new/flight/offers?q=${enc}`);
    } else search(modSearch);
  }

  function search(searchData) {
    let searchObj = { ...offerSearchTemp };

    const date = [
      moment().add(1, "day").format("YYYY-MM-DD"),
      moment().add(15, "days").format("YYYY-MM-DD"),
    ];
    date.map((d, i) => {
      if (i !== 0) {
        let reversed = clone(searchObj["originDestinations"][i - 1]);
        reversed.to = reversed.from;
        reversed.from = clone(searchObj["originDestinations"][i - 1])?.to;
        searchObj["originDestinations"][i] = reversed;
      } else {
        searchObj["originDestinations"][i]["from"] = searchData?.location?.from;
        searchObj["originDestinations"][i]["to"] = searchData?.location?.to;
      }
      searchObj["originDestinations"][i]["date"] = d;
      return true;
    });

    searchObj["originDestinations"] = searchObj["originDestinations"].slice(
      0,
      2
    );

    // Backward Compatability
    searchObj["originDestinations"]?.map((obj) => {
      obj.departure.date = moment(obj.date).format("YYYY-MM-DD");
      //   obj.from = searchData?.location?.from;
      //   obj.to = searchData?.location?.to;

      return true;
    });
    searchObj["destinations"] = searchObj.originDestinations.map((obj) => {
      return {
        departureLocation: obj.from?.iata || obj?.from,
        arrivalLocation: obj.to?.iata || obj?.to,
        date: obj.date,
      };
    });

    console.log(searchObj);
    let valid = searchObj["originDestinations"]?.map((obj, i) => {
      if (!obj.from || !obj.to) return false;
      if (obj.from === obj.to) return false;
      if (!obj.date) return false;

      // if(i > 0 && obj.departureLocation === searchObj['destinations'][i-1].departureLocation)

      return true;
    });

    if (valid.some((val) => !val))
      return enqueueSnackbar(
        "We're sorry, no nearby airports were found. Verify your location or contact our support staff.",
        { variant: "error" }
      );

    // searchObj['currencyOverride'] = def.currencyCode;
    // setData(newData);

    // return console.log(searchObj)

    let enc = encrypt(JSON.stringify(searchObj));

    // SET TIME ------
    dispatch(setBookingData({ ...bookingData, offer: null, time: null }));

    let referralCode = searchParam.get("refCode");

    navigate(`/order/new/flight/offers?referralCode=${referralCode}&q=${enc}`);
  }

  return (
    <div className="">
      <div className="flex justify-between w-full items-center">
        <span className="text-md font-bold">Top destinations</span>
        <div className="flex gap-2 text-xl">
          <Icon icon="majesticons:chevron-left-line" />
          <Icon icon="majesticons:chevron-right-line" />
        </div>
      </div>
      <div className=" mt-6 overflow-y-hidden overflow-x-auto pb-4 grid grid-flow-col gap-4">
        {(destinations || []).map((obj, i) => (
          <div
            className="w-72 h-52 relative cursor-pointer"
            key={i}
            onClick={() => !loading && handleSearch(obj, i)}
          >
            <img
              src={obj.image}
              alt="Paris"
              className="rounded-lg w-full h-full object-cover"
            />
            <div className="absolute flex gap-2 top-2 right-2 items-center px-2 py-1 bg-white/50 rounded-md">
              <Icon icon="twemoji:star" />
              <span className="text-xs"> {obj.rating} Tourist rating</span>
            </div>
            <div className="absolute w-full bottom-0 rounded-lg bg-gradient-to-t from-[#6D7C94] to-[#6D7C94]/0 text-white py-2 px-2 font-semibold text-sm">
              <span>{obj.name}</span>
              <span className="flex gap-1 items-center">
                <Icon icon="mdi:location" className="text-[#7CDEAC] text-xl" />
                <span className="text-sm">{obj?.location?.name}</span>
              </span>
            </div>
            {loading === i?.toString() ? (
              <div className="absolute top-0 left-0 w-full h-full bg-primary/30 flex items-center justify-center">
                <CircularProgress />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};
export default TopDestination;
