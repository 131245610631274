import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightSeatMapModal } from "../../redux/reducers/modalSlice";
import {
  Check,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: "800px",
};

let unavailableType = "Unavailable";
let standardType = "Standard";
let extraLegRoomType = "Extra leg room";
let conditionType = "Seats with extra condition";

const flight = {
  airlineImage: "/IconTurkish.svg",
  num: "411",
  date: "Fri, 30AUG",
  route: "LOS - LHR",
  departureTime: "09:00AM",
  arrivalTime: "03:55PM",
  duration: "06:55 h",
  plane: "Airbus A330-1000",
  opened: false,
  seatMap: [
    [
      { type: "header", label: "A" },
      { type: "header", label: "B" },
      { type: "corridor", label: "" },
      { type: "header", label: "C" },
      { type: "header", label: "D" },
      { type: "header", label: "E" },
      { type: "header", label: "F" },
      { type: "corridor", label: "" },
      { type: "header", label: "G" },
      { type: "header", label: "H" },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "10" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "10" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "11" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "11" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "12" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "12" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "13" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "13" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "14" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "14" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "15" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "15" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "16" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "16" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "17" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "17" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "18" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "18" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "19" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "19" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "20" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "20" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
    [
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: extraLegRoomType },
      { type: "corridor", label: "21" },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: unavailableType },
      { type: "seat", seatType: standardType },
      { type: "seat", seatType: standardType },
      { type: "corridor", label: "21" },
      { type: "seat", seatType: extraLegRoomType },
      { type: "seat", seatType: standardType },
    ],
  ],
};

export default function FlightViewSeatMapModal() {
  const dispatch = useDispatch();
  const { flightSeatMapModal } = useSelector((state) => state.modal);
  const [flights, setFlights] = useState([flight, flight]);

  const handleOpen = () => dispatch(setFlightSeatMapModal(true));
  const handleClose = () => dispatch(setFlightSeatMapModal(false));

  return (
    <div>
      <Modal
        open={flightSeatMapModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>View seatmap</span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className={Classname.content}>
              <div className="w-full px-4">
                <div className={Classname.detailInfoContainer}>
                  <img
                    src="/IconInfo.svg"
                    alt=""
                    className={Classname.detailInfoIcon}
                  />
                  <span className="font-normal">
                    For informational purpose only, seats can be booked later in
                    booking process after passenger data have been entered.
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col overflow-scroll scroll-hide">
                {flights?.map((item, index) => (
                  <Flight
                    flight={item}
                    key={index}
                    onOpen={() => {
                      setFlights(
                        flights?.map((flightItem, idx) =>
                          idx === index
                            ? { ...flightItem, opened: !flightItem?.opened }
                            : flightItem
                        )
                      );
                    }}
                    onSelect={({ rowIndex, seatIndex }) => {
                      setFlights(
                        flights?.map((flightItem, idx) =>
                          idx === index
                            ? {
                                ...flightItem,
                                selectedRow: rowIndex,
                                selectedSeat: seatIndex,
                              }
                            : flightItem
                        )
                      );
                    }}
                  />
                ))}
              </div>
              <CTA />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Flight = ({ flight, onOpen, onSelect }) => {
  return (
    <div className="mb-2 flex flex-col w-full min-w-[800px]">
      <div
        className="bg-[#F0F6FC] w-full flex items-center justify-between gap-3 px-3 py-2 cursor-pointer"
        onClick={onOpen}
      >
        <div className="flex items-center min-w-[130px]">
          <img
            src={flight?.airlineImage}
            alt=""
            className="h-12 object-contain"
          />
          <span className="font-bold text-sm ml-2 ">VS {flight?.num}</span>
        </div>
        <span className="font-bold text-sm min-w-[]">{flight?.date}</span>
        <span className="font-bold text-sm">{flight?.route}</span>
        <span className="font-bold text-sm">
          {flight?.departureTime} - {flight?.arrivalTime}{" "}
        </span>
        <span className="font-bold text-sm">{flight?.duration}</span>
        <span className="font-bold text-sm">{flight?.plane}</span>
        {flight?.opened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      {flight?.opened && <FlightSeatMap flight={flight} onClick={onSelect} />}
    </div>
  );
};

const FlightSeatMap = ({ flight, onClick }) => {
  return (
    <div className="w-full bg-[#EFF3F5] px-2 py-3">
      <div className="w-full flex items-center justify-center gap-3 my-3">
        <div className="flex items-center justify-center gap-2">
          <div className="w-6 h-6 bg-[#C7E4FA] border-b-4 border-b-[#5A88AB]"></div>
          <span className="text-xs font-semibold">Extra legroom</span>
        </div>
        <div className="flex items-center justify-center gap-2">
          <div className="w-6 h-6 bg-[#BFE8E2] border-b-4 border-b-[#78ADA5]"></div>
          <span className="text-xs font-semibold">Standard</span>
        </div>
        <div className="flex items-center justify-center gap-2">
          <div className="w-6 h-6 bg-[#D6DEE6] border-b-4 border-b-[#8C9EB0] flex items-center justify-center">
            <img
              src="/IconSeatClose.svg"
              alt=""
              className="w-4 object-contain"
            />
          </div>
          <span className="text-xs font-semibold">Unavailable seat</span>
        </div>
        <div className="flex items-center justify-center gap-2">
          <div className="w-6 h-6 flex items-center justify-center">*</div>
          <span className="text-xs font-semibold">
            Seat with some conditions
          </span>
        </div>
      </div>
      {flight?.seatMap?.map((seatRow, rowIndex) => (
        <div
          className="w-full flex items-center gap-1 justify-between"
          key={rowIndex}
        >
          {seatRow?.map((seat, seatIndex) => (
            <Seat
              seat={seat}
              onClick={() => {
                onClick({
                  seatIndex,
                  rowIndex,
                });
              }}
              selected={
                flight?.selectedRow === rowIndex &&
                flight?.selectedSeat === seatIndex
              }
              key={seatIndex}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const Seat = ({ seat, onClick, selected }) => {
  const getClassname = () => {
    switch (seat?.seatType) {
      case unavailableType:
        return "w-12 h-12 bg-[#D6DEE6] border-b-4 border-b-[#8C9EB0] flex items-center justify-center my-2 cursor-not-allowed";
      case extraLegRoomType:
        return "w-12 h-12 bg-[#C7E4FA] border-b-4 border-b-[#5A88AB] flex items-center justify-center my-2 cursor-pointer";
      case standardType:
        return "w-12 h-12 bg-[#BFE8E2] border-b-4 border-b-[#78ADA5] flex items-center justify-center my-2 cursor-pointer";
      default:
        return "w-12 h-12 bg-[#BFE8E2] border-b-4 border-b-[#78ADA5] flex items-center justify-center my-2 cursor-pointer";
    }
  };

  const handleClick = () => {
    if (
      seat?.seatType === standardType ||
      seat?.seatType === extraLegRoomType
    ) {
      onClick();
    }
  };

  if (seat?.type === "header")
    return (
      <div className="w-12 h-12 flex items-center justify-center my-2 font-bold text-sm">
        {seat?.label}
      </div>
    );

  if (seat?.type === "corridor")
    return (
      <div className="w-12 h-12 flex items-center justify-center my-2 font-bold text-sm">
        {seat?.label}
      </div>
    );

  return (
    <div className={getClassname()} onClick={handleClick}>
      {seat?.seatType === unavailableType ? (
        <img src="/IconSeatClose.svg" alt="" />
      ) : selected ? (
        <Check />
      ) : (
        <></>
      )}
    </div>
  );
};

const CTA = () => {
  const handleBack = () => {};

  const handleNext = () => {};

  return (
    <div className="w-full flex px-4 items-center justify-between py-3">
      <button
        className="bg-primary1/10 text-primary1 font-bold text-sm h-14 rounded-md w-44 items-center justify-center"
        onClick={handleBack}
      >
        Back
      </button>
      <button
        className="bg-primary1 text-white font-bold text-sm h-14 rounded-md w-44 items-center justify-center"
        onClick={handleNext}
      >
        Next
      </button>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-start max-h-[85vh] overflow-scroll scroll-hide px-[1px]",
  modalTitle: "text-xl lg:text-3xl font-bold text-center",
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
};
