import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AboutCRM from './About/AboutCRM';
import Icon from '../../HOC/Icon';
import HomeCRM from './Home/HomeCRM';
import ContactCRM from './Contact/ContactCRM';
import HomePic from '../../../assets/CRM/HomePage.png'
import AboutPic from '../../../assets/CRM/AboutPage.png'
import ContactPic from '../../../assets/CRM/ContantPage.png'
import TermsPic from '../../../assets/CRM/termsPage.png'
import TermsCRM from './Terms/TermsCRM';

export default function CRM() {
    const {page} = useParams();
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const step = searchParams.get('step')

    const navigate = useNavigate();
    
    const pages = [
        {id: 'home',label: 'Home Page',component: <HomeCRM />,image: HomePic},
        {id: 'about',label: 'About',component: <AboutCRM />,image: AboutPic},
        {id: 'contact',label: 'Contact',component: <ContactCRM />,image: ContactPic},
        {id: 'terms',label: 'Terms and conditions',component: <TermsCRM />,image: TermsPic},
    ]
    
    const selectedPage = pages?.find(obj => obj.id === page);
    
  return (
    <div>
        <Link to={selectedPage ? `/templating/edit/pages` : '/website'}>
            <div className="h-20 mx-0 pl-4 shadow-md flex gap-2 mb-2 items-center">
                <Icon icon="ion:arrow-back" fontSize="medium" className="text-[#6563FF]"/>
                <span>Go Back</span>
            </div>
        </Link>
        <div >
        {
            selectedPage ?
                selectedPage?.component
            : (
                <div className='pd-md'>
                    <div className='pb-6'>
                        <h2>Select page to edit content</h2>
                        <p>Change every content on your custom website.</p>
                    </div>
                    <div className='flex gap-4 gap-y-10 flex-wrap'>
                        {pages?.map((obj,i) => (
                            <button onClick={() => navigate('/templating/edit/'+obj.id)} key={i} className='w-[300px] flex flex-col gap-4'>
                                <b>{obj?.label}</b>
                                <div className='w-full h-[200px] bg-primary/10'>
                                    <img src={obj?.image} className='w-full h-full ' />
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            )
        }
        </div>
    </div>
  )
}
