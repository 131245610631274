export const tourFilterTime = [
  {
    label: "In the morning",
    label1: "8am - 12pm",
    value: "8am - 12pm",
  },
  {
    label: "In the afternoon",
    label1: "12pm - 5pm",
    value: "12pm - 5pm",
  },
  {
    label: "In the evening",
    label1: "5pm - 12am",
    value: "5pm - 12am",
  },
];

export const tourFilterDuration = [
  {
    label: "0 - 3 hours",
    value: 3,
  },
  {
    label: "3 - 5 hours",
    value: 5,
  },
  {
    label: "5 - 7 hours",
    value: 7,
  },
  {
    label: "Full day (7+ hours)",
    value: 24,
  },
  {
    label: "Multi- day",
    value: 2400,
  },
];

export const tourFilterDestination = [
  "Buckingham Palace",
  "Trafalgar Square",
  "Westminster Abbey",
  "River Thames",
  "Big Ben",
];

export const tourFilterLanguages = [
  { code: "en", name: "English" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "ru", name: "Russian" },
  { code: "it", name: "Italian" },
];

export const tourFilterInterests = [
  "Local culture",
  "City sightseeing",
  "Architecture",
  "Outdoor",
  "Royalty",
];

export const tourFilterServices = [
  { code: "healthMeasures", title: "Additional health measures" },
  { code: "WHEELCHAIR_ACCESSIBLE", title: "Wheelchair accessible" },
  { code: "privateTour", title: "Private tour" },
  { code: "pickup", title: "Hotel pickup possible" },
  { code: "skipTheLine", title: "Skip the line" },
  { code: "smallGroup", title: "Small group" },
];

export const tourFilterCancellation = [
  { code: "STANDARD", title: "Standard cancellation policy" },
  { code: "ALL_SALES_FINAL", title: "All sales are final" },
];
