import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useHotels } from "../../hooks/useHotels";
import { useRef } from "react";
import { URLdecode } from "../../utils/url";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate } from "../../utils/date";
import { useEffect } from "react";
import { formatMoney } from "../../features/utils/formatMoney";
import { shareHotelBookingPDF } from "../../controllers/Flight/shareHotelBookingPDF";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Loader from "../../utils/loader";
import {
  setTourConfirmationPdfModal,
  setTourConfirmationPdfModalEmail,
  setTourConfirmationPdfModalSendingEmail,
} from "../../redux/reducers/modalSlice";
import { useTours } from "../../hooks/useTours";
import useDateFormat from "../../hooks/useDateFormat";
import Barcode from "react-barcode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function TourConfirmationPdfModal() {
  const { travellersString, getIndividualDetails, getGroupDetails } =
    useTours();
  const { capitalizeFirstLetter } = useDateFormat();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { tourConfirmationPdfModal, tourConfirmationPdfModalEmail } =
    useSelector((state) => state.modal);
  const { user } = useSelector((state) => state?.user?.userData);

  const divToExportRef = useRef(null);

  const params = URLdecode();
  const image = params?.image;
  const people = params?.people ? JSON.parse(params?.people) : [];
  const cancellationPolicy = params?.cancellationPolicy
    ? JSON.parse(params?.cancellationPolicy)
    : null;

  const handleOpen = () => dispatch(setTourConfirmationPdfModal(false));
  const handleClose = () => dispatch(setTourConfirmationPdfModal(false));

  useEffect(() => {
    setTimeout(() => {
      if (tourConfirmationPdfModal) {
        handleExport();
      }
    }, 1000);
  }, [tourConfirmationPdfModal]);

  const sendPDF = async (pdf) => {
    dispatch(setTourConfirmationPdfModalSendingEmail(true));
    const formData = new FormData();

    formData?.append("to", tourConfirmationPdfModalEmail);
    formData.append("subject", "Tour Booking Details");
    formData.append("body", "Booking details of " + params?.title);
    formData.append("pdf", pdf, params?.bookId + ".pdf");

    enqueueSnackbar(
      "Sending... (This might take a while if the pdf is large) "
    );
    const res = await shareHotelBookingPDF(formData);
    if (res.return) {
      enqueueSnackbar(res?.msg, { variant: "success" });
    } else enqueueSnackbar(res?.msg, { variant: "error" });
    dispatch(setTourConfirmationPdfModalSendingEmail(false));
  };

  const handleExport = async () => {
    const component = divToExportRef.current;

    if (!component) {
      console.error("Component to export not found");
      return;
    }

    try {
      component.scrollTo(0, component.scrollHeight);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const scrollHeight = component.scrollHeight;

      const canvas = await html2canvas(component, {
        useCORS: true,
        scale: 2,
        windowWidth: document.body.scrollWidth,
        windowHeight: scrollHeight + 500,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      });

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, scrollHeight * 0.4],
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      let position = 0;
      let pageHeight = pdfHeight;

      const addNewPage = () => {
        pdf.addPage();
        position -= pageHeight;
      };

      for (let imgTop = 0; imgTop < imgHeight; imgTop += pageHeight) {
        if (imgTop + pageHeight > imgHeight) {
          pageHeight = imgHeight - imgTop;
        }
        if (position !== 0) {
          addNewPage();
        }
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, pageHeight);
      }

      if (tourConfirmationPdfModalEmail) {
        await sendPDF(pdf.output("blob"));
      } else {
        pdf.save(`${params?.bookId}.pdf`);
      }
    } catch (error) {
      console.error("Error capturing screenshot: ", error);
    }

    dispatch(setTourConfirmationPdfModalEmail(""));
    dispatch(setTourConfirmationPdfModal(false));
  };

  return (
    <div>
      <Modal
        open={tourConfirmationPdfModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container} ref={divToExportRef}>
            <div className="w-full flex justify-between">
              <div className="flex items-center gap-2 mb-6">
                <img
                  src={user?.detail?.agencyLogo}
                  alt=""
                  className="w-7 h-7 object-contain"
                />
                <span className="font-bold h-7 flex items-center ">
                  {user?.detail?.agencyName}
                </span>
              </div>
              <div className="flex flex-col mb-6">
                <span className="flex items-center ">Booking reference</span>
                <span className="font-semibold text-sm flex items-center ">
                  {params?.bookId}
                </span>
              </div>
            </div>
            <div className={Classname.tourDetail}>
              <div className={Classname.tourNameContainer}>
                <div className={Classname.tourDetailContainer}>
                  <span className={Classname.tourName}>{params?.title}</span>
                  <div className={Classname.tourDetailLocationContainer}>
                    <img
                      src="/IconLocationGreen.svg"
                      alt=""
                      className={Classname.tourDetailLocationIcon}
                    />
                    <span>{capitalizeFirstLetter(params?.location)}</span>
                  </div>
                </div>
              </div>
              <div className={Classname.dateContainer}>
                <div className={Classname.dateDetailContainer}>
                  <div className={Classname.dateDetailDate}>
                    <span className="w-32">Departure date:</span>
                    <span className="font-bold">
                      {formatDate(params?.date)}
                    </span>{" "}
                  </div>
                  {params?.tourDuration && (
                    <div className={Classname.dateDetailDate}>
                      <span className="w-32">Tour duration:</span>
                      <span className="font-bold">
                        {params?.tourDuration}
                      </span>{" "}
                    </div>
                  )}
                  {params?.startTime && (
                    <div className={Classname.dateDetailDate}>
                      <span className="w-32">Start time:</span>
                      <span className="font-bold">
                        {params?.startTime}
                      </span>{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className={Classname.tagsContainer}>
                <div className={Classname.tag}>
                  <img
                    src="/tourIconPerson.svg"
                    alt=""
                    className={Classname.tagIcon}
                  />
                  <span className="h-4 flex items-center">
                    {travellersString()}
                  </span>
                </div>
              </div>

              {people && people.length > 0 && (
                <span className="font-bold text-lg mb-6">Guest details</span>
              )}
              {getIndividualDetails()?.map((person, index) => (
                <div
                  className="w-full flex flex-col bg-blue-600/10 rounded-lg px-2 py-3 mb-2"
                  key={index}
                >
                  <span className="font-bold">Traveler {index + 1}</span>

                  <div className="w-full flex flex-wrap justify-between">
                    {person?.map((question, idx) => (
                      <div
                        className="flex items-center gap-2 text-sm lg:text-mdrounded-sm"
                        key={idx}
                      >
                        <span className="">{question?.question}:</span>
                        <span className="font-bold">
                          {question?.answer}
                        </span>{" "}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {getGroupDetails().length > 0 && (
                <div className="w-full flex flex-col bg-blue-600/10 rounded-lg px-2 py-3 mb-2">
                  <span className="font-bold">Group</span>

                  <div className="w-full flex flex-wrap justify-between">
                    {getGroupDetails()?.map((question, idx) => (
                      <div
                        className="flex items-center gap-2 text-sm lg:text-mdrounded-sm min-w-[300px]"
                        key={idx}
                      >
                        <span className="">{question?.question}:</span>
                        <span className="font-bold">
                          {question?.answer}
                        </span>{" "}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {params?.voucher &&
              JSON.parse(params?.voucher) &&
              JSON.parse(params?.voucher)?.url && (
                <div className="w-[200px]">
                  <Barcode
                    value={params?.bookId?.replace("BR-", "")}
                    format="CODE128"
                    width={2}
                    height={50}
                  />
                </div>
              )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main max-h-[90vh] overflow-scroll px-10 py-10",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",
  detail: "flex flex-col lg:flex-row w-full gap-10",
  tourDetail: "flex flex-col flex-1",
  tourDetailTitleContainer: "flex items-center justify-between w-full gap-8 ",
  tourDetailTitleButtonContainer: "gap-6 flex",
  tourDetailTitleExportButton:
    "h-12 px-4 rounded-md border-2 border-gray-300 font-bold mr-3",
  tourDetailTitleManageButton: "h-12 px-4 rounded-md bg-gray-300 font-bold",

  tourNameContainer:
    "flex flex-1 items-center mt-4 lg:mt-0 lg:gap-10 gap-2 pr-2 lg:pr-0",
  tourImageContainer:
    "flex flex-col lg:w-24 items-center gap-2 font-bold text-sm",
  tourImage: "w-full min-w-20 h-20 lg:h-24 object-cover",
  tourDetailContainer: "flex flex-col text-xs lg:text-md gap-3 lg:gap-0",
  tourName: "text-md lg:text-xl  font-bold",
  tourDetailLocationContainer: "gap-2 flex items-center",
  tourDetailLocationIcon: "",

  dateContainer: "flex w-full gap-4 mt-6",
  dateFigureContainer: "flex flex-col items-center py-1",
  datePoint: "w-3 h-3 rounded-full bg-primary1",
  dateLine: "border-r flex flex-1 border-primary1",
  dateDetailContainer: "flex flex-col gap-2",
  dateDetailDate: "flex items-center gap-2 lg:gap-4 text-sm lg:text-md ",
  dateDetailDuration: "text-xs lg:text-sm",

  tagsContainer: "flex items-center flex-wrap gap-4 mt-6 mb-3",
  tag: "flex gap-2 items-center text-sm text-gray-500",
  tagIcon: "w-4 h-4 object-contain",

  orderTags: "flex flex-wrap w-full gap-6 mt-6 mb-4",
  orderTag: "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  orderTagTitle: "text-lg font-bold mb-1",
  orderTagDescription: "flex items-center gap-2 text-sm text-gray-600",
  orderTagIcon: "",

  touristPersonHeader:
    "flex flex-col lg:flex-row w-full justify-around lg:justify-between gap-4",
  touristPerson:
    "text-white bg-primary1 font-bold h-14 px-4 rounded-md flex items-center justify-center gap-2",
  touristPersonIcon: "",

  emailButton:
    "h-16 lg:h-24 w-full rounded-lg border-2 border-black text-black mt-5 lg:mt-10 lg:mb-20 flex items-center justify-center gap-2 font-bold",

  information:
    "flex flex-col flex-1 h-fit lg:max-w-md p-4 rounded-lg border border-gray-400 mb-10",
  informationTitle: "font-bold mt-2 mb-2",
  informationDateContainer: "flex gap-1 pb-3 border-b border-gray-300 mb-3",
  informationDate: "flex font-bold",
  informationStatus:
    "w-32 h-9 rounded-md border border-green-600 bg-green-600/10 text-green-600 flex items-center justify-center mt-2",
  informationProviderContainer: "flex flex-1 mt-2 mb-2 justify-between",
  informationProviderColumn: "flex flex-col",
  informationProviderColumn1: "flex flex-col items-end",
  informationProviderTitle: "",
  informationProviderInfo: "flex items-center gap-2 text-sm font-bold",
  informationProviderImage: "w-6 h-6 object-contain",
  informationShow: "underline text-primary1 underline-offset-2",
  belowSignin: "flex justify-around",

  detailInputContainer: "flex flex-col flex-1",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mt-4 mb-10",
};
