import React from 'react'
import { useSelector } from 'react-redux'

export default function ContactHeader() {
    const {user,agent} = useSelector(state => state.user.userData)
    const crmData = (agent || user)?.detail?.crmData;
  return (
    <div className="pd-10 flex items-center justify-center text-white relative min-h-[150px]">
        <div className="absolute top-0 left-0 w-full h-full bg-black/25">
            {crmData?.contact?.backgroundImage ? 
                <img src={crmData?.contact?.backgroundImage} className='w-full h-full object-cover' />
                :null}
        </div>
        <h4 className="capitalize z-10">How can we help you?</h4>
    </div>
  )
}
