import {
  CircularProgress,
  MenuItem,
  RadioGroup,
  Tab,
  Tabs,
} from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import getTeamMembers from "../../controllers/settings/team/getTeamMembers";
import Modal1 from "../../components/DIsplay/Modal/Modal1";
import EmailInput from "../../components/form/EmailInput";
import RadioInput from "../../components/form/RadioInput";
import Button1 from "../../components/form/Button1";
import addTeamMember from "../../controllers/settings/team/addTeamMember";
import { useSnackbar } from "notistack";
import CustomTable from "../../components/Table/CustomTable";
import { Delete, Settings } from "@mui/icons-material";
import deleteTeamMember from "../../controllers/settings/team/deleteTeamMember";
import LearnMoreButton from "../../components/mini/LearnMoreButton";
import activateTeamMember from "../../controllers/settings/team/activateTeamMember";
import deactivateTeamMember from "../../controllers/settings/team/deactivateTeamMember copy";
import CustomMenu from "../../components/utils/CustomMenu";
import Icon from "../../components/HOC/Icon";
import SelectInput from "../../components/form/SelectInput";
import { teamRolesData } from "../../data/team/rolesData";
import { clone } from "../../features/utils/objClone";
import getSentInvitations from "../../controllers/settings/team/getSentInvitations";
import deleteTeamInvitation from "../../controllers/settings/team/deleteInvitation";
import LoadingBar from "../../components/animation/LoadingBar";
import { useSelector } from "react-redux";
import { getRoles } from "../../controllers/settings/roles/getRoles";
import LearnAboutTeams from "../../components/DIsplay/LearnMore/LearnAboutTeams";
import { getsubDomain } from "../../utils/getsubDomain";
import updateTeamMember from "../../controllers/settings/team/updateTeamMember";

const Action = createContext();

function ActionCol({ params, reload }) {
  const { enqueueSnackbar } = useSnackbar();

  async function handleDelete(type) {
    let res = { return: 0, msg: "Something went wrong on our end. (0xTM31)" };
    if (type === "Invited" || params.row?.status === "Invited")
      res = await deleteTeamInvitation(params.id);
    else res = await deleteTeamMember(params.id);
    if (res.return) {
      enqueueSnackbar("Removed", { variant: "success" });
      reload && reload();
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }
  async function handleActivate() {
    const res = await updateTeamMember(params.id,{accountStatus: 'Active'})
    // const res = await activateTeamMember(params.id);
    if (res.return) {
      enqueueSnackbar("Activated", { variant: "success" });
      reload && reload();
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }
  async function handleDeactivate() {
    // const res = await updateTeamMember(params.id,{accountStatus: 'Deactivated'})
    const res = await deactivateTeamMember(params.id);
    if (res.return) {
      enqueueSnackbar("Deactivated", { variant: "success" });
      reload && reload();
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }

  console.log('parasm: ',params)
  return (
    <div className="flex justify-end px-2">
      <CustomMenu
        element={<Settings className="text-primary/70 cursor-pointer" />}
      >
        <Action.Consumer>
          {({ updateRole, resendInvitation, filter }) => (
            <div className="flex flex-col bg-secondary rounded-lg p-2">
              {["Inactive", "Deactivated"].includes(params?.row?.status) ? ( //|| params?.row?.accountStatus
                <Button1
                  className="flex btn-theme-light !justify-start !text-start items-center gap-2 p-2"
                  onClick={handleActivate}
                >
                  <Icon
                    icon={"mdi:user-check"}
                    fontSize={20}
                    className="w-6 h-6"
                  />
                  Activate
                </Button1>
              ) : ["Active"].includes(params?.row?.status) ? ( //|| params?.row?.accountStatus
                <Button1
                  className="flex btn-theme-light !justify-start !text-start items-center gap-2 p-2"
                  onClick={handleDeactivate}
                >
                  <Icon
                    icon={"basil:user-block-solid"}
                    fontSize={20}
                    className="w-6 h-6"
                  />
                  {/* <Edit className='text-primary/50' fontSize='small' /> */}
                  Deactivate
                </Button1>
              ) : null}
              {["Pending","Invited"].includes(params?.row?.status) ? ( //|| params?.row?.accountStatus
                <Button1
                  className="flex btn-theme-light !justify-start !text-start items-center gap-2 p-2"
                  onClick={() => resendInvitation(params.row)}
                >
                  <Icon
                    icon={"mdi:email-resend"}
                    fontSize={20}
                    className="w-6 h-6"
                  />
                  {/* <Edit className='text-primary/50' fontSize='small' /> */}
                  Resend Invitation
                </Button1>
              ) : (
                <Button1
                  variant="text"
                  className="btn-theme-light flex !justify-start !text-start gap-2"
                  disabled={params.row?.status === "Invited"}
                  onClick={() => updateRole.open(params.row)}
                >
                  <Icon icon={"tabler:edit"} className="w-6" />
                  Update role
                </Button1>
              )}
              {(params.row?.status === "Invited") ? 
                <Button1
                className="!bg-red-500 !text-white flex !gap-2 !justify-start "
                onClick={() => handleDelete(filter?.value)}
                >
                  <Delete className="!w-6 !h-6 " fontSize="small" />
                  Delete
                </Button1>
              :null}
            </div>
          )}
        </Action.Consumer>
      </CustomMenu>
    </div>
  );
}

const filterItems = [
  { i: 0, value: "All" },
  { i: 1, value: "Invited" },
];

export default function TeamMembers({ guide }) {
  const [data, setData] = useState([]);
  const [InvitedData, setInvitedData] = useState([]);
  const [filter, setFilter] = useState(filterItems[0]);
  const [loading, setLoading] = useState(guide ? false : true);
  const [error, setError] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const [openUpdateRole, setOpenUpdateRole] = useState(false);

  const { user } = useSelector((state) => state.user.userData);
  const subdomain = window.location.hostname.includes(".")
    ? window.location.hostname.split(".")[0]
    : null;
  const acceptUrl = subdomain
    ? window.location.href
    : "http://" +user?.detail?.agencyURL+"?view=register";

  useEffect(() => {
    if (!guide) {
      load();
    }
    //eslint-disable-next-line
  }, [filter]);

  async function loadRoles() {
    const res = await getRoles();
    if (res.return) {
      return res?.data?.data;
    } else return [];
  }

  async function load() {
    const roles = await loadRoles();
    setError();
    let res = {
      return: true,
      data: { data: [] },
      msg: "Failed fetching members. This error is from our end please notify customer support!",
    };
    setLoading(true);
    if (filter.value === "All") {
      res = await getTeamMembers();
    }

    if (res.return) {
      let data = res?.data?.data || [];

      const res2 = await getSentInvitations();
      setLoading(false);

      if (res2.return) {
        data = [
          ...data,
          ...(res2?.data?.data?.map((obj, i) => ({
            ...obj,
            id: i + "Inv",
            status: "Invited",
          })) || []),
        ];
      }

      // console.log('dat: ',data)
      data = data.map((obj) => ({
        ...obj,
        ...obj?.member,
        id: obj._id,
        name: (obj?.firstName || "") + " " + (obj?.lastName || ""),
        role: roles?.filter((role) => obj?.roles?.includes(role._id)),
      }));
      data = data.filter(
        (cur, i, arr) => arr.findIndex((obj) => obj.email === cur.email) === i
      );
      if (filter?.value === "All") setData(data);
      else setInvitedData(data);
    } else setError(res.msg);
    setLoading(false);
  }

  async function resendInvitation(data) {
    setLoading(true);
    const res = await addTeamMember({ ...data, acceptUrl });
    setLoading(false);
    if (res.return) {
      enqueueSnackbar("Email have been resent to the user to join the app", {
        variant: "success",
      });
    } else enqueueSnackbar(res.msg || "Invitation Error", { variant: "error" });
  }

  let columns = [
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      renderCell: (params) => {
        let roles = params.value;
        if (!roles) return null;

        return <div>{roles?.map((role) => role.name)?.join(", ")}</div>;
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 2,
      minWidth: 60,
      renderCell: (params) => {
        return <ActionCol reload={load} params={params} />;
      },
    },
  ];

  if (filter?.value === "All")
    columns.unshift({ field: "name", headerName: "Member", flex: 1 });

  return (
    <div
      className={`flex flex-col gap-4 flex-1 ${
        !data?.length && !loading && !guide
          ? "bg-emptypage justify-center "
          : ""
      }`}
    >
      {loading ? (
        <div className="flex flex-col items-center justify-center">
          <LoadingBar />
        </div>
      ) : error?.includes("Network Error!") ? (
        <div className="flex flex-col gap-2 items-center justify-center self-center">
          <h5>{error}</h5>
          <button onClick={load}>Refresh</button>
        </div>
      ) : !data.length && !loading && !guide ? (
        <div
          className={` text-center flex flex-col items-center self-center gap-8`}
        >
          <div>You don't have any team members</div>
          <div className="flex gap-2 flex-wrap justify-center">
            <LearnMoreButton
              label="Learn about team"
              component={
                <LearnAboutTeams
                  callback={({ setOpen }) => (
                    <div className="flex justify-center py-4">
                      <Button1
                        className="!w-auto !px-10"
                        onClick={() => setOpen && setOpen(false)}
                      >
                        Get Started
                      </Button1>
                    </div>
                  )}
                />
              }
            />
            <InviteTeam reload={load} label="Invite a team member" />
          </div>
        </div>
      ) : (
        <div className="xmax-w-[500px] flex flex-col gap-4">
          <div className="flex justify-between items-center gap-4">
            <h5>Team Members</h5>
            <InviteTeam reload={load} />
          </div>
          {!guide && (
            <Tabs
              className="bg-secondary px-2"
              value={filter.i}
              onChange={(ev, newVal) => setFilter(filterItems[newVal])}
            >
              <Tab label="All" />
              <Tab label="Invited" />
            </Tabs>
          )}
          <Action.Provider
            value={{
              updateRole: { open: (val) => setOpenUpdateRole(val || true) },
              resendInvitation,
              filter,
            }}
          >
            <CustomTable
              loading={loading}
              rows={filter?.value === "All" ? data : InvitedData}
              columns={columns}
              // filterModel={filter}
              // onFilterModelChange={(newFilter) => setFilter(newFilter)}

              autoHeight
            />
          </Action.Provider>
          <Modal1
            open={openUpdateRole}
            setOpen={setOpenUpdateRole}
            modalBorderRadius="rounded-2xl"
          >
            <UpdateRole
              data={openUpdateRole}
              close={() => setOpenUpdateRole(false)}
              reload={load}
            />
          </Modal1>
        </div>
      )}
    </div>
  );
}

function UpdateRole({ data, close, reload }) {
  const [reqData, setReqData] = useState({
    email: data?.email || "",
    role: data?.role || "",
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rolesLoading, setRolesLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    loadRoles();
  }, []);

  async function loadRoles() {
    setRolesLoading((l) => ({ ...l, roles: true }));
    const res = await getRoles();
    if (res.return) {
      setRoles(res?.data?.data);
    }
    setRolesLoading((l) => ({ ...l, roles: false }));
  }

  useEffect(() => {
    if (data) setReqData({ email: data?.email || "", role: data?.role || "" });
  }, [data]);

  async function handleSubmit() {
    setLoading(true);
    const res = await updateTeamMember(data?.id, {
      roles: [reqData?.role],
    });
    if (res.return) {
      enqueueSnackbar("Role updated.", { variant: "success" });
      close();
      reload && reload();
    } else enqueueSnackbar(res.msg, { variant: "error" });
    setLoading(false);
  }
  return (
    <div className="card !p-10 flex flex-col gap-4 w-[450px] !max-w-full">
      <h4>Update Role</h4>
      <SelectInput
        size="small"
        className="relative"
        value={reqData.role}
        onChange={(ev) => setReqData({ ...reqData, role: ev.target.value })}
      >
        {roles?.map((role) => (
          <MenuItem value={role._id}>{role.name}</MenuItem>
        ))}
        {/* {teamRolesData.map((obj,i) => (
          <MenuItem value={obj.value} key={i}>{obj.label}</MenuItem>
        ))} */}
      </SelectInput>
      <Button1 onClick={handleSubmit} loading={loading}>
        Update
      </Button1>
    </div>
  );
}

function InviteTeam({ reload, label = "Invite" }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ email: "", role: "" });
  const [roles, setRoles] = useState([]); //clone(teamRolesData);
  const [loadings, setLoadings] = useState({ roles: false });
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user.userData);
  const { enqueueSnackbar } = useSnackbar();
  // const subdomain = window.location.hostname.includes('.') ? window.location.hostname.split('.')[0] : null;
  const subdomain = getsubDomain();
  let acceptUrl = subdomain
    ? window.location.href
    : "http://" +
      user?.detail?.agencyURL+
      "?view=register";
  if (!acceptUrl?.includes("?")) acceptUrl += "?a=1";

  useEffect(() => {
    loadRoles();
  }, []);

  async function loadRoles() {
    setLoadings((l) => ({ ...l, roles: true }));
    const res = await getRoles();
    if (res.return) {
      setRoles(res?.data?.data);
    }
    setLoadings((l) => ({ ...l, roles: false }));
  }
  async function handleSubmit(ev) {
    ev.preventDefault();
    if(!user?.detail?.agencyURL)
      return enqueueSnackbar('Please create an agency link first!', { variant: "error" });

    setLoading(true);
    const res = await addTeamMember({
      email: data.email,
      roles: [data.role],
      acceptUrl: acceptUrl + `&email=${data.email}`,
    });
    setLoading(false);
    if (res.return) {
      setOpen(false);
      enqueueSnackbar("Email have been sent to the user to join the app", {
        variant: "success",
      });
      if (reload) reload();
    } else enqueueSnackbar(res.msg || "Invitation Error", { variant: "error" });
  }

  return (
    <div className="flex flex-col">
      <Button1 variant="contained" onClick={() => setOpen(true)}>
        {label}
      </Button1>
      <Modal1 open={open} setOpen={setOpen} modalBorderRadius="rounded-2xl">
        <form
          onSubmit={handleSubmit}
          className="p-4 flex flex-col gap-4 w-[90vw] max-w-[600px] lg:px-10 lg:py-10"
        >
          <h5 className="w-full text-start">Invite a team member</h5>
          <EmailInput
            label="email"
            value={data.email}
            onChange={(ev) => setData({ ...data, email: ev.target.value })}
          />
          <div className="text-start font-bold">
            Role
            {loadings.roles ? (
              <div className="border-theme1 py-4 flex justify-center">
                <div className="load"></div>
              </div>
            ) : null}
            <RadioGroup
              value={data.role}
              onChange={(ev) => setData({ ...data, role: ev.target.value })}
              className="flex flex-col gap-2"
            >
              {roles.map((role, i) => (
                <RadioInput
                  key={i}
                  value={role?._id}
                  checked={data.role === role?._id}
                >
                  <div className="flex flex-col max-h-[100px] overflow-auto overflow-x-hidden">
                    <h6>{role.name}</h6>
                    <p className="text-primary/80 capitalize">
                      {role?.description ||
                        role?.permissions
                          ?.map((value) =>
                            value?.toLowerCase()?.replaceAll("_", " ")
                          )
                          ?.join(", ")}
                    </p>
                  </div>
                </RadioInput>
              ))}
            </RadioGroup>
          </div>
          <div className="flex gap-2">
            <div className="lg:w-[30%]">
              <Button1
                className="btn-theme-light font-bold"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button1>
            </div>
            <div className="flex-1">
              <Button1 loading={loading} type="submit" className="">
                Send Invite
              </Button1>
            </div>
          </div>
        </form>
      </Modal1>
    </div>
  );
}
