export default function TermsOfService() {
    return (
        <div className="flex flex-col items-center px-10">
            <div className="w-[600px] max-w-full pb-10">
                <div className="p-5 flex justify-end">
                    <a href='/' className="self-end">Home</a>
                </div>
                <div>
                    <p class="c20"><span class="c16 c22"></span></p>
                </div>
                <h5 class="c23 flex justify-center"><span class="c16 c7">TERMS OF SERVICE</span></h5>
                <p class="c23"><span class="c7">&nbsp;</span></p>
                <p class="c17"><span class="c9">Last updated:&nbsp;</span><span class="c0">02/22/2024</span></p>
                <p class="c17"><span class="c14">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0 start" start="1">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Introduction</span></li>
                </ol>
                <p class="c1"><span class="c9">Welcome to&nbsp;</span><span class="c12">INTRAVERSE AFRICA</span><span
                        class="c14">&nbsp;(&ldquo;</span><span class="c12">Company</span><span class="c14">&rdquo;, &ldquo;</span><span
                        class="c12">we</span><span class="c14">&rdquo;, &ldquo;</span><span class="c12">our</span><span
                        class="c14">&rdquo;, &ldquo;</span><span class="c12">us</span><span class="c14">&rdquo;)</span><span
                        class="c0">! As you have just clicked our Terms of Service, please pause, grab a cup of coffee and carefully
                        read the following pages. It will take you approximately 20&nbsp;minutes.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">These Terms of Service (&ldquo;</span><span class="c7">Terms</span><span
                        class="c9">&rdquo;, &ldquo;</span><span class="c7">Terms of Service</span><span class="c9">&rdquo;) govern your
                        use of&nbsp;our web pages located at https://www.intraverse.app and our mobile application&nbsp;Intraverse Agent
                        App (together or individually &ldquo;</span><span class="c12">Service</span><span
                        class="c14">&rdquo;)&nbsp;operated by&nbsp;INTRAVERSE AFRICA.</span></p>
                <p class="c1"><span class="c9">&nbsp;</span></p>
                <p class="c1"><span class="c9">Our Privacy Policy also governs your use of our Service and explains how we collect,
                        safeguard and disclose information that results from your use of our web pages. Please read it here </span><span
                        class="c14">https://www.intraverse.app/privacy-policy</span><span class="c0">.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">Your agreement with us includes these Terms&nbsp;and our Privacy
                        Policy&nbsp;(&ldquo;</span><span class="c7">Agreements</span><span class="c0">&rdquo;). You acknowledge that you
                        have read and understood Agreements, and agree to be bound of them.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">If you do not agree with (or cannot comply with) Agreements, then you may not use the
                        Service, but please let us know by emailing at </span><span class="c14">support@intraverse.app</span><span
                        class="c0">&nbsp;so we can try to find a solution. These Terms apply to all visitors, users and others who wish
                        to access or use Service.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">Thank you for being responsible.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="2">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Communications</span></li>
                </ol>
                <p class="c1"><span class="c0">By creating an Account on our Service, you agree to subscribe to newsletters, marketing
                        or promotional materials and other information we may send. However, you may opt out of receiving any, or all,
                        of these communications from us by following the unsubscribe link or by emailing at.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="3">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Purchases</span></li>
                </ol>
                <p class="c1"><span class="c9">If you wish to purchase any product or service made available through Service
                        (&ldquo;</span><span class="c7">Purchase</span><span class="c0">&rdquo;), you may be asked to supply certain
                        information relevant to your Purchase including, without limitation, your credit card number, the expiration
                        date of your credit card, your billing address, and your shipping information.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">You represent and warrant that: (i) you have the legal right to use any credit card(s) or
                        other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true,
                        correct and complete.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">We may employ the use of third party services for the purpose of facilitating payment and
                        the completion of Purchases. By submitting your information, you grant us the right to provide the information
                        to these third parties&nbsp;subject to our Privacy Policy.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">We reserve the right to refuse or cancel your order at any time for reasons including but
                        not limited to: product or service availability, errors in the description or price of the product or service,
                        error in your order or other reasons.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">We reserve the right to refuse or cancel your order if fraud or an unauthorized or
                        illegal transaction is suspected. </span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="4">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Subscriptions</span></li>
                </ol>
                <p class="c1"><span class="c9">Some parts of Service are billed on a subscription basis (&ldquo;</span><span
                        class="c7">Subscription(s)</span><span class="c9">&rdquo;). You will be billed in advance on a recurring and
                        periodic basis (&ldquo;</span><span class="c7">Billing Cycle</span><span class="c0">&rdquo;). Billing cycles are
                        set&nbsp;either on a monthly or annual basis, depending on the type of subscription plan you select when
                        purchasing a Subscription.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">At the end of each Billing Cycle, your Subscription will automatically renew under the
                        exact same conditions unless you cancel it or&nbsp;</span><span class="c14">INTRAVERSE AFRICA&nbsp;cancels it.
                        You may cancel your Subscription renewal either through your online account management page or by
                        contacting&nbsp;INTRAVERSE AFRICA&nbsp;customer support team.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">A valid payment method, including&nbsp;credit card, is required to process the payment
                        for your subscription. You shall provide&nbsp;INTRAVERSE AFRICA&nbsp;with accurate and complete billing
                        information including full name, address, state, zip code, telephone number, and a valid payment method
                        information. By submitting such payment information, you automatically authorize&nbsp;INTRAVERSE AFRICA&nbsp;to
                        charge all Subscription fees incurred through your account to any such payment instruments.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">Should automatic billing fail to occur for any reason,&nbsp;</span><span
                        class="c14">INTRAVERSE AFRICA&nbsp;will issue an electronic invoice indicating that you must proceed manually,
                        within a certain deadline date, with the full payment corresponding to the billing period as indicated on the
                        invoice.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="5">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Free Trial</span></li>
                </ol>
                <p class="c1"><span class="c14">INTRAVERSE AFRICA&nbsp;may, at its sole discretion, offer a Subscription with a free
                        trial for a limited period of time (&ldquo;</span><span class="c7">Free Trial</span><span
                        class="c0">&rdquo;).</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">You may be required to enter your billing information in order to sign up for Free
                        Trial.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">If you do enter your billing information when signing up for Free Trial, you will not be
                        charged by&nbsp;</span><span class="c14">INTRAVERSE AFRICA&nbsp;until Free Trial has expired. On the last day of
                        Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable
                        Subscription fees for the type of Subscription you have selected.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">At any time and without notice,&nbsp;</span><span class="c14">INTRAVERSE
                        AFRICA&nbsp;reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free
                        Trial offer.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="6">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Fee Changes</span></li>
                </ol>
                <p class="c1"><span class="c14">INTRAVERSE AFRICA, in its sole discretion and at any time, may modify Subscription fees
                        for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing
                        Cycle.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c14">INTRAVERSE AFRICA&nbsp;will provide you with a reasonable prior notice of any change in
                        Subscription fees to give you an opportunity to terminate your Subscription before such change becomes
                        effective.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">Your continued use of Service after Subscription fee change comes into effect constitutes
                        your agreement to pay the modified Subscription fee amount.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="7">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Refunds</span></li>
                </ol>
                <p class="c1"><span class="c0">Except when required by law, paid Subscription fees are non-refundable.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="8">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Content</span></li>
                </ol>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="9">
                    <li class="c6 c5 li-bullet-0" id="h.gjdgxs"><span class="c2">Prohibited Uses</span></li>
                </ol>
                <p class="c1 c13"><span class="c0">You may use Service only for lawful purposes and in accordance with Terms. You agree
                        not to use Service:</span></p>
                <p class="c1 c13"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_45-1 start" start="1">
                    <li class="c11 li-bullet-0"><span class="c0">In any way that violates any applicable national or international law
                            or regulation.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_45-1" start="2">
                    <li class="c11 li-bullet-0"><span class="c0">For the purpose of exploiting, harming, or attempting to exploit or
                            harm minors in any way by exposing them to inappropriate content or otherwise.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_45-1" start="3">
                    <li class="c11 li-bullet-0"><span class="c0">To transmit, or procure the sending of, any advertising or promotional
                            material, including any &ldquo;junk mail&rdquo;, &ldquo;chain letter,&rdquo; &ldquo;spam,&rdquo; or any
                            other similar solicitation.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_45-1" start="4">
                    <li class="c11 li-bullet-0"><span class="c0">To impersonate or attempt to impersonate Company, a Company employee,
                            another user, or any other person or entity.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_45-1" start="5">
                    <li class="c11 li-bullet-0"><span class="c0">In any way that infringes upon the rights of others, or in any way is
                            illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or
                            harmful purpose or activity.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_45-1" start="6">
                    <li class="c11 li-bullet-0"><span class="c0">To engage in any other conduct that restricts or inhibits
                            anyone&rsquo;s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or
                            users of Service or expose them to liability.</span></li>
                </ol>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1 c5 c13"><span class="c0">Additionally, you agree not to:</span></p>
                <p class="c1 c5 c13"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1 start" start="1">
                    <li class="c11 li-bullet-0"><span class="c0">Use Service in any manner that could disable, overburden, damage, or
                            impair Service or interfere with any other party&rsquo;s use of Service, including their ability to engage
                            in real time activities through Service.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="2">
                    <li class="c11 li-bullet-0"><span class="c0">Use any robot, spider, or other automatic device, process, or means to
                            access Service for any purpose, including monitoring or copying any of the material on Service.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="3">
                    <li class="c11 li-bullet-0"><span class="c0">Use any manual process to monitor or copy any of the material on
                            Service or for any other unauthorized purpose without our prior written consent.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="4">
                    <li class="c11 li-bullet-0"><span class="c0">Use any device, software, or routine that interferes with the proper
                            working of Service.</span></li>
                </ol>
                <p class="c17 c26"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="5">
                    <li class="c11 li-bullet-0"><span class="c0">Introduce any viruses, trojan horses, worms, logic bombs, or other
                            material which is malicious or technologically harmful.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="6">
                    <li class="c11 li-bullet-0"><span class="c0">Attempt to gain unauthorized access to, interfere with, damage, or
                            disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database
                            connected to Service.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="7">
                    <li class="c11 li-bullet-0"><span class="c0">Attack Service via a denial-of-service attack or a distributed
                            denial-of-service attack.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="8">
                    <li class="c11 li-bullet-0"><span class="c0">Take any action that may damage or falsify Company rating.</span></li>
                </ol>
                <p class="c8"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_39-1" start="9">
                    <li class="c11 li-bullet-0"><span class="c0">Otherwise attempt to interfere with the proper working of
                            Service.</span></li>
                </ol>
                <p class="c25"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="10">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Analytics</span></li>
                </ol>
                <p class="c1 c5"><span class="c2">&nbsp;</span></p>
                <p class="c1 c5"><span class="c0">We may use third-party Service Providers to monitor and analyze the use of our
                        Service.</span></p>
                <p class="c17 c5"><span class="c0">&nbsp;</span></p>
                <p class="c1 c5"><span class="c16 c7">Google Analytics</span></p>
                <p class="c1"><span class="c0">Google Analytics is a web analytics service offered by Google that tracks and reports
                        website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared
                        with other Google services. Google may use the collected data to contextualise and personalise the ads of its
                        own advertising network.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c14">For more information on the privacy practices of Google, please visit the Google Privacy
                        Terms web page: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708610952469405&amp;usg=AOvVaw0p4Q6BhAMo-ckfjsMcdUNI">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c1"><span class="c10">&nbsp;</span></p>
                <p class="c1"><span class="c14">We also encourage you to review the Google&#39;s policy for safeguarding your data:
                    </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245&amp;sa=D&amp;source=editors&amp;ust=1708610952470044&amp;usg=AOvVaw0yplJZnTrWjmPq5VN5eyFt">https://support.google.com/analytics/answer/6004245</a></span><span
                        class="c0">.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1 c5"><span class="c16 c7">Firebase</span></p>
                <p class="c1"><span class="c0">Firebase is analytics service provided by Google Inc.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c14">You may opt-out of certain Firebase features through your mobile device settings, such
                        as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:
                    </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708610952470757&amp;usg=AOvVaw0dtOkzfZOIJmPWGWuOyw3G">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c1"><span class="c10">&nbsp;</span></p>
                <p class="c1"><span class="c14">For more information on what type of information Firebase collects, please visit the
                        Google Privacy Terms web page: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708610952471346&amp;usg=AOvVaw0vn5MlU696A0qgGt4jiv0p">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c1"><span class="c10">&nbsp;</span></p>
                <p class="c1 c5"><span class="c16 c7">Mixpanel</span></p>
                <p class="c1"><span class="c0">Mixpanel is provided by Mixpanel Inc.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c14">You can prevent Mixpanel from using your information for analytics purposes by
                        opting-out. To opt-out of Mixpanel service, please visit this page: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://mixpanel.com/optout/&amp;sa=D&amp;source=editors&amp;ust=1708610952472099&amp;usg=AOvVaw34812uPheCV2aN0L82sdhQ">https://mixpanel.com/optout/</a></span>
                </p>
                <p class="c1"><span class="c10">&nbsp;</span></p>
                <p class="c1"><span class="c14">For more information on what type of information Mixpanel collects, please visit the
                        Terms of Use page of Mixpanel: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://mixpanel.com/terms/&amp;sa=D&amp;source=editors&amp;ust=1708610952472531&amp;usg=AOvVaw0G3z1PHPd9F9wXwtwsS-92">https://mixpanel.com/terms/</a></span>
                </p>
                <p class="c1"><span class="c10">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="11">
                    <li class="c6 c5 li-bullet-0" id="h.30j0zll"><span class="c2">No Use By Minors</span></li>
                </ol>
                <p class="c1"><span class="c0">Service is intended only for access and use by individuals at least eighteen (18) years
                        old. By accessing or using any of Company, you warrant and represent that you are at least eighteen (18) years
                        of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the
                        terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the
                        access and usage of Service.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="12">
                    <li class="c5 c6 li-bullet-0"><span class="c2">Accounts</span></li>
                </ol>
                <p class="c1"><span class="c0">When you create an account with us, you guarantee that you are above the age of 18, and
                        that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or
                        obsolete information may result in the immediate termination of your account on Service.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">You are responsible for maintaining the confidentiality of your account and password,
                        including but not limited to the restriction of access to your computer and/or account. You agree to accept
                        responsibility for any and all activities or actions that occur under your account and/or password, whether your
                        password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any
                        breach of security or unauthorized use of your account.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">You may not use as a username the name of another person or entity or that is not
                        lawfully available for use, a name or trademark that is subject to any rights of another person or entity other
                        than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or
                        obscene.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">We reserve the right to refuse service, terminate accounts, remove or edit content, or
                        cancel orders in our sole discretion.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="13">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Intellectual Property</span></li>
                </ol>
                <p class="c1"><span class="c9">Service and its original content (excluding Content provided by users), features and
                        functionality are and will remain the exclusive property of&nbsp;</span><span class="c14">INTRAVERSE
                        AFRICA&nbsp;and its licensors. Service is protected by copyright, trademark, and other laws of&nbsp;foreign
                        countries. Our trademarks and trade dress may not be used in connection with any product or service without the
                        prior written consent of&nbsp;INTRAVERSE AFRICA.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="14">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Error Reporting and Feedback</span></li>
                </ol>
                <p class="c1"><span class="c9">You may provide us&nbsp;either directly at support@intraverse.app or via third party
                        sites and tools&nbsp;with information and feedback concerning errors, suggestions for improvements, ideas,
                        problems, complaints, and other matters related to our Service (&ldquo;</span><span
                        class="c7">Feedback</span><span class="c0">&rdquo;). You acknowledge and agree that: (i) you shall not retain,
                        acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii)
                        Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential
                        information or proprietary information from you or any third party; and (iv) Company is not under any obligation
                        of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is
                        not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable,
                        irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify,
                        create derivative works, publish, distribute and commercialize) Feedback in any manner and for any
                        purpose.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">The third party sites and tools mentioned above include the following:</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1 c5"><span class="c7 c16">Bugsnag</span></p>
                <p class="c1"><span class="c14">Bugsnag is a platform for monitoring and logging stability of applications provided by
                        Bugsnag Inc. Please read their Privacy Policy here: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://docs.bugsnag.com/legal/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1708610952475365&amp;usg=AOvVaw3ll6MFi1o_Qg9Vhb1FzjQC">https://docs.bugsnag.com/legal/privacy-policy/</a></span><span
                        class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1 c5"><span class="c16 c7">Sentry</span></p>
                <p class="c1"><span class="c9">Sentry is open-source error tracking solution provided by Functional Software Inc. More
                        information is available here: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://sentry.io/privacy/&amp;sa=D&amp;source=editors&amp;ust=1708610952475947&amp;usg=AOvVaw0o20z45JGQ11hXSOseWt7H">https://sentry.io/privacy/</a></span><span
                        class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1 c5"><span class="c16 c7">Firebase Crashlytics</span></p>
                <p class="c1"><span class="c0">Firebase Crashlytics is bug reporting service provided by Google Inc.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c14">You may opt-out of certain Firebase features through your mobile device settings, such
                        as your device advertising settings or by following the instructions provided by Google in their Privacy Policy:
                    </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708610952476727&amp;usg=AOvVaw1ylcBMwB5riCT6UPZrOyVf">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c1"><span class="c10">&nbsp;</span></p>
                <p class="c1"><span class="c14">For more information on what type of information Firebase collects, please visit the
                        Google Privacy Terms web page: </span><span class="c10"><a class="c3"
                            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1708610952477234&amp;usg=AOvVaw3SYIoKSlqpeERRIDUOPv3D">https://policies.google.com/privacy?hl=en</a></span>
                </p>
                <p class="c15"><span class="c10 c21">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="15">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Links To Other Web Sites</span></li>
                </ol>
                <p class="c1"><span class="c9">Our Service may contain links to third party web sites or services that are not owned or
                        controlled by&nbsp;</span><span class="c0">INTRAVERSE AFRICA.</span></p>
                <p class="c1"><span class="c14">&nbsp;</span></p>
                <p class="c1"><span class="c14">INTRAVERSE AFRICA&nbsp;has no control over, and assumes no responsibility for the
                        content, privacy policies, or practices of any third party web sites or services. We do not warrant the
                        offerings of any of these entities/individuals or their websites.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c9">YOU ACKNOWLEDGE AND AGREE THAT&nbsp;</span><span class="c14">INTRAVERSE
                        AFRICA</span><span class="c14">&nbsp;</span><span class="c0">SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
                        INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
                        ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</span>
                </p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD
                        PARTY WEB SITES OR SERVICES THAT YOU VISIT.</span></p>
                <p class="c1"><span class="c9">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="16">
                    <li class="c6 c5 li-bullet-0" id="h.1fob9te"><span class="c2">Disclaimer Of Warranty </span></li>
                </ol>
                <p class="c1"><span class="c0">THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
                        AVAILABLE&rdquo; BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
                        THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
                        THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
                        RISK.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1 c13"><span class="c0">NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR
                        REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF
                        THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR
                        WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE
                        ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE
                        SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY
                        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</span></p>
                <p class="c1 c13"><span class="c0">&nbsp;</span></p>
                <p class="c1 c13"><span class="c0">COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                        STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND
                        FITNESS FOR PARTICULAR PURPOSE.</span></p>
                <p class="c1 c13"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                        APPLICABLE LAW.</span></p>
                <p class="c1"><span class="c9">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="17">
                    <li class="c6 c5 li-bullet-0" id="h.3znysh7"><span class="c2">Limitation Of Liability</span></li>
                </ol>
                <p class="c1"><span class="c0">EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND
                        AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
                        (INCLUDING ATTORNEYS&#39; FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR
                        ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
                        NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT
                        LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY
                        YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART
                        OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES
                        WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                        PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</span>
                </p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="18">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Termination</span></li>
                </ol>
                <p class="c1"><span class="c0">We may terminate or suspend your account and bar access to Service immediately, without
                        prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation,
                        including but not limited to a breach of Terms.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">If you wish to terminate your account, you may simply discontinue using Service.</span>
                </p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">All provisions of Terms which by their nature should survive termination shall survive
                        termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
                        limitations of liability.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="19">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Governing Law</span></li>
                </ol>
                <p class="c1" id="h.2et92p0"><span class="c0">These Terms shall be governed and construed in accordance with the laws
                        of&nbsp;The Federal Republic of Nigeria&nbsp;without regard to its conflict of law provisions.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">Our failure to enforce any right or provision of these Terms will not be considered a
                        waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
                        remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between
                        us regarding our Service and supersede and replace any prior agreements we might have had between us regarding
                        Service.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="20">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Changes To Service</span></li>
                </ol>
                <p class="c1 c13"><span class="c0">We reserve the right to withdraw or amend our Service, and any service or material we
                        provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any
                        part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some
                        parts of Service, or the entire Service, to users, including registered users.</span></p>
                <p class="c1 c13"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="21">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Amendments To Terms</span></li>
                </ol>
                <p class="c1"><span class="c0">We may amend Terms at any time by posting the amended terms on this site. It is your
                        responsibility to review these Terms periodically.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">Your continued use of the Platform following the posting of revised Terms means that you
                        accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes,
                        as they are binding on you.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <p class="c1"><span class="c0">By continuing to access or use our Service after any revisions become effective, you
                        agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to
                        use Service.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="22">
                    <li class="c6 c5 li-bullet-0" id="h.tyjcwt"><span class="c2">Waiver And Severability</span></li>
                </ol>
                <p class="c1 c13"><span class="c0">No waiver by Company of any term or condition set forth in Terms shall be deemed a
                        further or continuing waiver of such term or condition or a waiver of any other term or condition, and any
                        failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or
                        provision.</span></p>
                <p class="c1 c13"><span class="c0">&nbsp;</span></p>
                <p class="c1 c13"><span class="c0">If any provision of Terms is held by a court or other tribunal of competent
                        jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or
                        limited to the minimum extent such that the remaining provisions of Terms will continue in full force and
                        effect.</span></p>
                <p class="c1 c13"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="23">
                    <li class="c6 c5 li-bullet-0" id="h.3dy6vkm"><span class="c2">Acknowledgement</span></li>
                </ol>
                <p class="c1"><span class="c0">BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ
                        THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</span></p>
                <p class="c1"><span class="c0">&nbsp;</span></p>
                <ol class="c4 lst-kix_list_13-0" start="24">
                    <li class="c6 c5 li-bullet-0"><span class="c2">Contact Us</span></li>
                </ol>
                <p class="c1"><span class="c0">Please send your feedback, comments, requests for technical support:</span></p>
                <p class="c1"><span class="c0">By email: support@intraverse.app.</span></p>
                <div>
                    <p class="c19"><span class="c0"></span></p>
                    <p class="c18"><span class="c0"></span></p>
                </div>
            </div>
        </div>
    )
}