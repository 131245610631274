import React, { useEffect, useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/reducers/tour/filterSlice";
import useDateFormat from "../../../hooks/useDateFormat";
import { URLencode } from "../../../utils/url";
import TourDestinationSearch from "../../../components/tour/TourDestinationSearch";

function TourOrderLocationPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filterData } = useSelector((state) => state.tourFilter);
  const { formatDate, isPastDate } = useDateFormat();
  const [date, setDate] = useState();
  const [country, setCountry] = useState("");
  const [destination, setDestination] = useState("");
  const [tag, setTag] = useState("");
  const [locationError, setLocationError] = useState("");
  const [dateError, setDateError] = useState("");
  const calendarRef = useRef([...Array(10)]);

  const OnDateChange = (text) => {
    setDate(text);
    if (calendarRef.current?.at(1)) {
      calendarRef.current[1]?.toggle(calendarRef.current[1]?.ref?.current);
    }
    calendarRef.current[0]?.toggle();
  };

  useEffect(() => {
    setLocationError("");
  }, [country]);
  useEffect(() => {
    setDateError("");
  }, [date]);

  const OnSearch = () => {
    if (!destination) return setLocationError("Location is required");
    if (isPastDate(date)) {
      setDateError("Departure date can't be a past date");
      return;
    }

    const data = { ...filterData };
    data.date = formatDate(date);
    data.country = country;
    dispatch(setFilter(data));
    const params = {
      location: country,
      destinationId: destination,
      date: formatDate(date),
      tag,
    };
    navigate(`/tour/result?${URLencode(params)}`);
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        {/* Page title */}
        {/* <div className={Classname.titleContainer}>
          {`Tour > Orders >`}{" "}
          <span className={Classname.titleMain}>New Order</span>
        </div> */}

        {/* Form container */}
        <div className={Classname.formContainer}>
          <div className={Classname.form}>
            <span className={Classname.formTitle}>Search for things to do</span>
            <TourDestinationSearch
              onChange={(id, name, tagId) => {
                // console.log(id);
                setDestination(id);
                setCountry(name);
                if (tagId) {
                  setTag(tagId);
                }
              }}
            />
            {locationError && (
              <span className="text-red-600 text-sm mt-2">{locationError}</span>
            )}

            <span className={Classname.formInputLabel}>
              Start date{" "}
              <span className="ml-2 text-sm font-normal text-gray-400">
                (optional)
              </span>
            </span>
            <CalendarInput1
              ref={(el) => (calendarRef.current[0] = el)}
              className="w-full border border-primary/20 rounded-md p-2"
              value={date || ""}
              onChange={(value) => OnDateChange(value?.start || value)}
              config={{ validDates: [new Date()] }}
            />
            {dateError && (
              <span className="text-red-600 text-sm mt-2">{dateError}</span>
            )}
            <button className={Classname.formButton} onClick={OnSearch}>
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourOrderLocationPage;

const Classname = {
  container:
    "flex w-full relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full flex-col flex-1 ",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",

  formContainer:
    "flex w-full flex-1 items-center justify-center px-0 sm:px-10 pt-10",
  form: "w-full max-w-600px flex flex-col",
  formTitle:
    "font-black text-primaryText text-lg lg:text-4xl mb-6 text-center my-14",
  formInputLabel: "flex font-bold text-base mb-2 mt-6 text-gray-500",
  formButton:
    "w-full text-white bg-theme1 font-bold h-12 flex items-center justify-center rounded-xl mt-10",
};
