import React, { useState } from "react";
import CustomRadio from "../../../components/common/CustomRadio";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import MeetingPointAddressModal from "../../../components/modal/MeetingPointAddressModal";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TourManagementPackageMeetingPage() {
  const navigate = useNavigate();
  const [selectedOptionActivityBegin, setSelectedOptionActivityBegin] =
    useState(null);
  const [selectedOptionActivityEnd, setSelectedOptionActivityEnd] =
    useState(null);
  const [selectedOptionPickup, setSelectedOptionPickup] = useState(null);
  const [selectedOptionPickupTime, setSelectedOptionPickupTime] =
    useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelectActivityBegin = (option) => {
    setSelectedOptionActivityBegin(option);
  };
  const handleSelectActivityEnd = (option) => {
    setSelectedOptionActivityEnd(option);
  };
  const handleSelectPickup = (option) => {
    setSelectedOptionPickup(option);
  };
  const handleSelectPickupTime = (option) => {
    setSelectedOptionPickupTime(option);
  };

  const handleAddAddress = () => {
    setModalOpen(true);
  };

  const onContinue = () => {
    navigate("/tourManagement/create/connectivity");
  };
  return (
    <div className={Classname.container}>
      <MeetingPointAddressModal open={modalOpen} setOpen={setModalOpen} />
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={2} />
        <div className={Classname.detail}>
          <div className={Classname.title}>Meeting point or pickup</div>
          <div className={Classname.title}>
            How do customers get to the activity?
          </div>
          <CustomRadio
            label="They go to a set meeting point"
            selected={selectedOptionActivityBegin}
            onSelect={handleSelectActivityBegin}
          />
          <CustomRadio
            value=""
            label="They can choose where you pick them up from certain areas or a list of places"
            selected={selectedOptionActivityBegin}
            onSelect={handleSelectActivityBegin}
          />
          <button
            className={Classname.addressButton}
            onClick={handleAddAddress}
          >
            Add an address
          </button>
          <div className={Classname.title}>
            Describe the meeting point{" "}
            <span className={Classname.titleOptional}> (optional)</span>
          </div>
          <div className={Classname.description}>
            Is there a specific landmark to look out for? How will customers
            recognize the guide?
          </div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className={Classname.textarea}
            placeholder="Enter your details"
          />
          <div className={Classname.title}>
            When do customers need to arrive?
          </div>
          <div className={Classname.description}>
            Do customers need to arrive early to be ready for their activity -
            for example, to pick up tickets, paperwork, or equipment?
          </div>
          <div className="w-80 mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select"
              >
                <MenuItem value="Early">Early</MenuItem>
                <MenuItem value="On-Time">On-Time</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={Classname.title}>
            Where will you drop off the customer at the end of the activity?
          </div>
          <CustomRadio
            label="At the same place you met them"
            selected={selectedOptionActivityEnd}
            onSelect={handleSelectActivityEnd}
          />
          <CustomRadio
            value="1"
            label="At a different place"
            selected={selectedOptionActivityEnd}
            onSelect={handleSelectActivityEnd}
          />
          <CustomRadio
            value=""
            label="No drop-off service, the customer stays at the site or destination"
            selected={selectedOptionActivityEnd}
            onSelect={handleSelectActivityEnd}
          />

          <div className={Classname.title}>
            Where will you pick up customers? (We’ll tell you the customer’s
            pickup address).
          </div>
          <CustomRadio
            label="You’ll pick them up at any address in areas you specify."
            selected={selectedOptionPickup}
            onSelect={handleSelectPickup}
          />
          <CustomRadio
            value=""
            label="You’ll pick them up at specific places like hotels, airports, etc. you list."
            selected={selectedOptionPickup}
            onSelect={handleSelectPickup}
          />

          <div className={Classname.title}>
            Describe the meeting point{" "}
            <span className={Classname.titleOptional}> (optional)</span>
          </div>
          <div className={Classname.description}>
            What should customers look for when waiting for their vehicle? Where
            should they wait? If your pickup areas/places are very specific,
            describe them in more detail.
          </div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className={Classname.textarea}
            placeholder="Enter your details"
          />

          <div className={Classname.title}>
            When will you tell the customer what time you’ll pick them up?
          </div>
          <CustomRadio
            label="You’ll pick them up at the time the activity starts"
            selected={selectedOptionPickupTime}
            onSelect={handleSelectPickupTime}
          />
          <CustomRadio
            value="1"
            label="The day before the activity takes place"
            selected={selectedOptionPickupTime}
            onSelect={handleSelectPickupTime}
          />
          <CustomRadio
            value=""
            label="Within 24 hours after they book"
            selected={selectedOptionPickupTime}
            onSelect={handleSelectPickupTime}
          />

          <div className={Classname.title}>
            When do you usually pick up your customers?
          </div>
          <div className={Classname.description}>
            Note that you’ll still need to communicate the exact pickup time for
            every booking.
          </div>
          <div className="w-80 mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select"
              >
                <MenuItem value="Early">Early</MenuItem>
                <MenuItem value="On-Time">On-Time</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className={Classname.title}>
            Where will you drop off the customer at the end of the activity?
          </div>
          <CustomRadio
            label="At the same place you met them"
            selected={selectedOptionActivityEnd}
            onSelect={handleSelectActivityEnd}
          />
          <CustomRadio
            value="1"
            label="At a different place"
            selected={selectedOptionActivityEnd}
            onSelect={handleSelectActivityEnd}
          />
          <CustomRadio
            value=""
            label="No drop-off service, the customer stays at the site or destination"
            selected={selectedOptionActivityEnd}
            onSelect={handleSelectActivityEnd}
          />

          <div className={Classname.title}>
            What's the transportation used for pickup and drop-off?
          </div>
          <button className={Classname.addressButton}>Add entry</button>

          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default TourManagementPackageMeetingPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  titleOptional: "text-sm text-gray-500 font-normal",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6 ",
  description: "flex text-base mb-4 mt-2",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  addressButton:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3 border-primary1 border rounded-lg mt-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
