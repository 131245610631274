import React, { useEffect, useState } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useSelector } from "react-redux";
import TourCard from "./TourCard";
import { useTours } from "../../hooks/useTours";

function TourMoreSuggestion() {
  const { loadTours } = useLocalStorage();
  const { getTourPackages } = useTours();
  const { tour, activities: loadedActivities } = useSelector(
    (state) => state.tourData
  );

  const [activities, setActivities] = useState(loadTours());
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    if (activities?.length > 0) return;
    await getTourPackages(setLoading);
  };

  const filterData = () => {
    let data = loadTours() || [];
    let supplier = tour?.supplier?.reference;
    if (!supplier) return [];
    data = data.filter(
      (activity) =>
        activity?.supplier?.reference === supplier &&
        activity?.productCode !== tour?.productCode
    );
    setActivities(data);
  };

  useEffect(() => {
    filterData();
  }, [tour, loadedActivities]);

  useEffect(() => {
    loadData();
  }, []);

  if (!tour) return <></>;
  if (!activities || activities.length === 0) return <></>;

  return (
    <div className="flex w-full flex-col my-10">
      <span className="text-xl lg:text-3xl font-bold mb-2">
        Explore more tours
      </span>
      <div className="flex flex-1 w-full overflow-scroll gap-4 scroll-hide">
        {activities?.map((activity, index) => (
          <TourCard tour={activity} key={index} />
        ))}
      </div>
    </div>
  );
}

export default TourMoreSuggestion;
