import React from "react";
import GenerateLinkForm from "../generateForm";

const GenerateLinkHome = () => {
  return (
    <div className="px-10 mt-10">
      <div>
        <h1 className="text-4xl font-BrFirmaBold font-bold my-0">
          {" "}
          Generate your website theme
        </h1>
        <p className="font-BrFirmaRegular my-2">
          These logo, colors, and font will be used to design your site. You can
          always change them later.
        </p>
      </div>
      <GenerateLinkForm />
    </div>
  );
};

export default GenerateLinkHome;
