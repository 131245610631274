import React, { useState } from "react";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";
import { useNavigate } from "react-router-dom";
import InfoCardLightening from "../../../components/management/InfoCardLightening";
import TourManagementAvailabilityHeader from "../../../components/header/TourManagementAvailabilityHeader";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import SelectExceptionDateModal from "../../../components/modal/SelectExceptionDateModal";
import SelectOpeningHoursModal from "../../../components/modal/SelectOpeningHoursModal";

const weekData = [
  {
    value: "Monday",
    label: "Mon",
    start: "",
    end: "",
  },
  {
    value: "Tuesday",
    label: "Tue",
    start: "",
    end: "",
  },
  {
    value: "Wednesday",
    label: "Wed",
    start: "",
    end: "",
  },
  {
    value: "Thursday",
    label: "Thu",
    start: "",
    end: "",
  },
  {
    value: "Friday",
    label: "Fri",
    start: "",
    end: "",
  },
  {
    value: "Saturday",
    label: "Sat",
    start: "",
    end: "",
  },
  {
    value: "Sunday",
    label: "Sun",
    start: "",
    end: "",
  },
];

function formatDate(inputDate) {
  const date = new Date(inputDate);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

function TourManagementPackageAvailabilitySchedulePage() {
  const navigate = useNavigate();
  const [startData, setStartData] = useState("");
  const [weeklySchedule, setWeeklySchedule] = useState(weekData);
  const [exceptions, setExceptions] = useState([]);
  const [dateModal, setDateModal] = useState(false);
  const [timeModal, setTimeModal] = useState(false);
  const [timeType, setTimeType] = useState("");
  const [timeIndex, setTimeIndex] = useState(null);

  const handleStartDateChange = (text) => {
    setStartData(text);
  };

  const handleAddDate = () => {
    setDateModal(true);
  };

  const handleSaveExceptionDate = (date) => {
    setExceptions([
      ...exceptions,
      {
        date: date,
        start: "",
        end: "",
      },
    ]);
  };

  const hanldleRemoveException = (index) => {
    let exceptions1 = exceptions.filter((_, idx) => idx !== index);
    setExceptions(exceptions1);
  };

  const handleOpenTimeModal = (type, index) => {
    setTimeIndex(index);
    setTimeType(type);
    setTimeModal(true);
  };

  const handleSaveOpeningHour = (start, end) => {
    if (timeType === "day") {
      let days = [...weeklySchedule];
      days[timeIndex].start = start;
      days[timeIndex].end = end;
      setWeeklySchedule(days);
    } else {
      let days = [...exceptions];
      days[timeIndex].start = start;
      days[timeIndex].end = end;
      setExceptions(days);
    }
  };

  const onContinue = () => {
    navigate("/tourManagement/create/availability/priceCategory");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={Classname.container}>
      <SelectExceptionDateModal
        open={dateModal}
        setOpen={setDateModal}
        onConfirm={handleSaveExceptionDate}
      />
      <SelectOpeningHoursModal
        open={timeModal}
        setOpen={setTimeModal}
        onConfirm={handleSaveOpeningHour}
      />
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={4} />
        <div className={Classname.detail}>
          <InfoCardLightening description="We have updated our experience. See what has changed here" />
          <TourManagementAvailabilityHeader active={0} />
          <div className={Classname.title}>Name your schedule</div>
          <input
            type="text"
            className={Classname.input}
            placeholder="E.g Weekend’s prices"
          />
          <div className={Classname.title}>
            What’s the starting date of your activity?
          </div>
          <CalendarInput1
            className={Classname.datePickerInput}
            value={startData}
            onChange={(value) => handleStartDateChange(value?.start || value)}
          />

          <FormGroup className="mt-2">
            <FormControlLabel
              control={<Checkbox />}
              label="My activity has an end date"
            />
          </FormGroup>

          <span className="mt-8 mb-2">Standard weekly schedule</span>

          {weeklySchedule.map((schedule, index) => (
            <div
              className={Classname.day}
              key={index}
              onClick={() => handleOpenTimeModal("day", index)}
            >
              <span className={Classname.dayLabel}>{schedule.label}</span>
              {schedule.start && schedule.end ? (
                <div className={Classname.dayAddContainer}>
                  <span>{schedule.start}</span> - <span>{schedule.end}</span>
                </div>
              ) : (
                <div className={Classname.dayAddContainer}>
                  <img
                    src="/IconClock.svg"
                    alt=""
                    className={Classname.dayAddIcon}
                  />
                  <span>Add opening hours</span>
                </div>
              )}
            </div>
          ))}

          <span className="font-bold mt-10 mb-2 text-sm">
            Exceptions (Optional)
          </span>

          <span className={Classname.title}>
            Do you have alternative operating hours?
          </span>
          <span className={Classname.description}>
            Use this if you want different operating hours on a special day,
            like Easter or Christmas
          </span>

          {exceptions.map((date, index) => (
            <div className={Classname.day} key={index}>
              <img
                src="/IconClose2.svg"
                alt=""
                className={Classname.exceptionCloseIcon}
                onClick={() => hanldleRemoveException(index)}
              />
              <div className={Classname.exceptionDate}>
                <img
                  src="/IconDate.svg"
                  alt=""
                  className={Classname.exceptionDateIcon}
                />
                <span>{formatDate(date.date)}</span>
              </div>
              {date.start && date.end ? (
                <div
                  className={Classname.dayAddContainer}
                  onClick={() => handleOpenTimeModal("exception", index)}
                >
                  <span>{date.start}</span> - <span>{date.end}</span>
                </div>
              ) : (
                <div
                  className={Classname.dayAddContainer}
                  onClick={() => handleOpenTimeModal("exception", index)}
                >
                  <img
                    src="/IconClock.svg"
                    alt=""
                    className={Classname.dayAddIcon}
                  />
                  <span>Add opening hours</span>
                </div>
              )}
            </div>
          ))}

          <div className="w-fit mt-4">
            <button className={Classname.saveButton} onClick={handleAddDate}>
              Add Date
            </button>
          </div>

          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton} onClick={handleBack}>
              Back
            </button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Save and continue
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default TourManagementPackageAvailabilitySchedulePage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  datePickerInput: "w-full border border-primary/20 rounded-md p-2 h-14 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6 ",
  description: "flex text-base mb-4 mt-2",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  addressButton:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3  border rounded-lg",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-primary1 border border-primary1 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",

  day: "flex items-center gap-2 lg:gap-4 py-5 cursor-pointer border-b border-gray-400 w-full",
  dayLabel: "w-14 flex items-center justify-center text-center ",
  dayAddContainer: "flex text-primary1 items-center gap-3",
  dayAddIcon: "",

  exceptionCloseIcon: "",
  exceptionDate:
    "py-2 px-3 ml-3 lg:ml-6 mr-2 lg:mr-4 border border-gray-400 rounded-md flex items-center gap-2 text-sm",
  exceptionDateIcon: "",
};
