import React, { useEffect, useState } from "react";
import Modals from "../Modals";
import { Link } from "react-router-dom";

const SalesModal = ({ openSalesModal, toggleSalesModal }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoSlide,setAutoSlide] = useState(true);

  const slides = [
    {
      image: "/black-friday.svg",
      title: "Black Friday Mega Deals",
      href: '/plans',
      content:
        <div>Save Big on our black friday sales. Enjoy upto 30% discount on subscription. 50% off ticketing fee and $250 off our white label websites.
            <div className="text-primary py-2">Offer valid only on Nov 29th</div>
        </div>,
    },
    {
      image: "/bundle-offer.svg",
      title: "White Label Website Deal",
      href: '/plans',
      content:<div>
        Get a massive discount on our White Label Website plan—allowing you to launch a fully customized website for your travel agency at an unbeatable price. Usually $1100, this premium offering is now just $850 for a limited time!
        <div className="text-primary py-2">Offer valid only on Nov 29th</div>
      </div>
    },
    {
      image: "/30banner.svg",
      title: "Subscription Deals",
      href: '/plans',
      content:<div>
        Get a mega 30% price slash across all subscription plans and a massive 50% off ticketing fees this Black Friday. Whether you’re on the Starter, Premium or Ultimate plans, now is the perfect time to upgrade your experience for a fraction of the price!
        <div className="text-primary py-2">Offer valid only on Nov 29th</div>
      </div>
    },
  ];

  useEffect(() => {
    let t = null;
    t = setInterval(() => {
        if(autoSlide)
            setCurrentSlide(currentSlide => (currentSlide+1)%slides?.length)
    },3000)

    return () => clearInterval(t);
    //eslint-disable-next-line
  },[autoSlide])

  return (
    <Modals
      openModals={openSalesModal}
      toggleModals={toggleSalesModal}
      title="Black Friday Mega Sale"
    >
      <div className="relative text-primaryText w-[500px] max-w-full">
        <div className="relative">
          <img
            src={slides[currentSlide].image}
            alt={slides[currentSlide].title}
            className="w-full !h-[200px] object-cover rounded-lg mb-6"
          />

          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {slides.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === currentSlide
                    ? "bg-primary1 ring-primary1 ring ring-offset-1 ring-offset-black"
                    : "bg-secondary"
                }`}
                onClick={() => {setAutoSlide(false); setCurrentSlide(index)}}
              />
            ))}
          </div>
        </div>

        <div className="text-2xl font-bold">{slides[currentSlide].title}</div>
        <p className="text-darkGray my-2 text-lg">
          {slides[currentSlide].content}
        </p>
        {/* <div className="flex justify-end">
          <Link to={slides[currentSlide].href} className="bg-primary1 text-secondary px-8 py-3 rounded-lg hover:bg-blue-700 transition duration-300 text-lg font-black">
            Subscribe Now!
          </Link>
        </div> */}
      </div>
    </Modals>
  );
};

export default SalesModal;