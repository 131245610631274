import React, { useState } from "react";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import FlightMarkup from "../Settings/Order/Markup/FlightMarkup";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";
import { useDispatch } from "react-redux";
import { setGettingStartedLoadingModal } from "../../redux/reducers/modalSlice";

function GettingStartedMarkupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch(setGettingStartedLoadingModal(true));
    setTimeout(() => {
      navigate("/gettingStarted/team");
      dispatch(setGettingStartedLoadingModal(false));
    }, 1000);
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center font-main">
      <GettingStartedLoadingModal />
      <div className="flex w-full flex-col py-10 px-4 lg:px-10 flex-1 max-w-7xl">
        <span className="text-lg lg:text-3xl my-2 font-semibold">Markups</span>
        <FlightMarkup hideMoreOptions />
        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueActive={true}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedMarkupPage;
