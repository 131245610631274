import React, { useState } from 'react'
import { formatMoney, getNumber } from '../../features/utils/formatMoney'
import Button1 from '../../components/form/Button1'
import addCart from '../../controllers/Cart/addCart';
import payForCart from '../../controllers/Cart/payForCart';
import Modal1 from '../../components/DIsplay/Modal/Modal1';
import Icon from '../../components/HOC/Icon';
import payment from "../../assets/icons/payment/payment.svg";
import RadioGroupTemplate1 from '../../components/templates/components/RadioGroup';


export default function CartSummary({data}) {
    const [open,setOpen] = useState(false);
    const [method,setMethod] = useState('Card');
    const [loading,setLoading] = useState(false);

    const options = [
        {
          icon: <img alt="" src={payment} className="" />,
          name: "Pay instantly with a new card",
          description: "Pay with a debit or credit card",
          value: "Card",
        },
    ];
    // options.push({
    //     icon: <Icon icon='vaadin:cash' className="" />,
    //     name: "Direct Bank Transfer",
    //     description: "Send directly to our bank account",
    //     Footer: () => <div className="flex flex-col gap-2">
    //       Kindly use the following bank details:
    //       <hr />
  
    //       <div className="flex flex-col gap-1">
    //         <span>Intraverse Africa Technology Limited </span>
    //         <span>1026964842</span>
    //         <span>UBA</span>
    //       </div>
    //       <hr />
  
    //       <div className="flex flex-col gap-1">
    //         <span>Intraverse Africa Technology Limited </span>
    //         <span>1305167834</span>
    //         <span>Providus Bank</span>
    //       </div>
  
    //     </div>,
    //     value: "Cash",
    // })
          
    let totalPrice = 0;
    try {
        totalPrice = data?.reduce((acc,obj) => acc + getNumber(obj?.price),0)
    } catch(ex) {/* Do Nothing*/}
    
    async function handleCheckout() {
        const url = new URL(window.location.href);

        const searchParams = url.searchParams;
        searchParams.set('payed', 'true');
        url.search = searchParams.toString();
        const newUrl = url.toString();
    
        const payload = {
            bookings: data?.map(obj => obj?.id)
        }
        setLoading(true);
        const res = await addCart(payload);
        if(res.return) {
            const res2 = await payForCart({
                cartId: res?.data?.cart?._id,
                paymentMode: method,
                callback: newUrl,
            })
            if(res2?.return) {
              window.localStorage.setItem('cartId',res?.data?.cart?._id)
              // const res3 = await getCart(res?.data?.cart?._id);
              const url = res2?.data?.data?.authorization_url;
              window.location.href = url;
            }
        }
        setTimeout(() => 
          setLoading(false)
        ,4000)
    }
    
  return (
    <div className='rounded-md border shadow p-5 flex flex-col gap-4 md:min-w-[300px]'>
        <b>Price Summary</b>
        {
            data?.map((obj,i) => (
                <div key={i}>
                    <div className='flex gap-2 justify-between'>
                        <span>
                            {obj?.type}
                        </span>
                        <span>
                            {formatMoney(obj?.price)}
                        </span>
                    </div>
                </div>
            ))
        }
        <hr />
        <div className='flex gap-2 justify-between'>
            <b>Trip total: </b>
            <b>{formatMoney(totalPrice)}</b>
        </div>
        <Button1 onClick={() => setOpen(true)}>Checkout</Button1>
        <Modal1 open={open} setOpen={setOpen}>
            <div className='p-10 flex flex-col gap-4'>
              <div className='text-center'>
                <h6>Choose a payment method</h6>
                <p>Choose one of the payment options from below</p>
              </div>
              <RadioGroupTemplate1
                  options={options}
                  value={method}
                  className="flex flex-col gap-4"
                  radioClass="flex-row-reverse justify-between"
                  onChange={(val) => setMethod(val)}
                  render={(obj) => (
                    <div className="flex gap-2">
                      <div className="w-7 my-1">{obj.icon}</div>
                      <div className="flex flex-col">
                        <span>{obj.name}</span>
                        <small>{obj.description}</small>
                        {method === obj.value && obj?.Footer ? 
                          <div className="py-4">
                            <obj.Footer />
                          </div>
                        : null}
                      </div>
                    </div>
                  )}
              />
              <Button1 loading={loading} onClick={handleCheckout}>Pay</Button1>
              <Button1 className="!btn !bg-primary/90 !text-secondary" loading={loading} onClick={()=>setOpen(false)}>Hold booking and pay later</Button1>
            </div>
        </Modal1>
    </div>
  )
}
