import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightCheckedBaggageModal } from "../../redux/reducers/modalSlice";
import { Close } from "@mui/icons-material";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: "800px",
};

export default function FlightCheckedBaggageModal() {
  const dispatch = useDispatch();
  const { flightCheckedBaggageModal } = useSelector((state) => state.modal);
  const [selectedRoute, setSelectedRoute] = useState(0);

  const handleOpen = () => dispatch(setFlightCheckedBaggageModal(true));
  const handleClose = () => dispatch(setFlightCheckedBaggageModal(false));

  const routes = ["LOS - IST", "LOS - IST", "LOS - IST"];

  const bags = [
    {
      weight: "23kg",
      price: "₦40,000",
    },
    {
      weight: "23kg",
      price: "₦40,000",
    },
    {
      weight: "23kg",
      price: "₦40,000",
    },
  ];

  return (
    <div>
      <Modal
        open={flightCheckedBaggageModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between items-center bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>
                Checked baggage information
              </span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className={Classname.content}>
              <div className="flex overflow-scroll scroll-hide cursor-pointer w-full pb-3 border-b mb-4">
                {routes?.map((route, index) => (
                  <div
                    className={`border py-2 px-3 font-bold text-sm ${
                      index === 0
                        ? " rounded-l-md"
                        : index === routes.length - 1
                        ? " rounded-r-md"
                        : ""
                    } ${
                      index === selectedRoute &&
                      "border-primary1 text-primary1 bg-primary1/20"
                    } `}
                    key={index}
                    onClick={() => setSelectedRoute(index)}
                  >
                    {route}
                  </div>
                ))}
              </div>
              {bags?.map((bag, index) => (
                <div
                  className="w-full py-3 border-y mb-2 flex justify-between items-center"
                  key={index}
                >
                  <div className="flex flex-1 items-center">
                    <img
                      src="/BagIcon.svg"
                      alt=""
                      className="w-12 lg:w-16 object-contain mr-3 lg:mr-5"
                    />
                    <span className="text-sm lg:text-base font-bold">
                      {bag?.weight}{" "}
                      <span className="font-normal">Checked bag</span>{" "}
                    </span>
                  </div>
                  <span className="text-sm lg:text-base font-bold flex-1">
                    {bag?.price}{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-start lg:items-center px-4 max-h-[80vh] overflow-scroll overflow-x-auto scroll-hide py-3",
  modalTitle: "text-base lg:text-3xl font-bold text-start",
  modalDescription: "text-gray-600 mb-6 text-center text-sm lg:text-base",
  modalTourName: "font-bold flex flex-col items-center text-center",
  bottomContent:
    "w-full flex items-center justify-center gap-4 py-4 lg:py-10 bg-gray-100 px-4",
  button: "h-16 rounded-md flex items-center justify-center w-60 font-bold",
  backButton: "text-black",
  confirmButton: "text-white bg-primary1",
  wait: "font-bold",
};
