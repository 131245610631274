import React, { useState } from "react";
import GettingStartedStepTitle from "../../components/gettingStarted/GettingStartedStepTitle";
import GettingStartedVerticalLine from "../../components/gettingStarted/GettingStartedVerticalLine";
import GettingStartedCheckbox from "../../components/gettingStarted/GettingStartedCheckbox";
import GettingStartedVideo from "../../components/gettingStarted/GettingStartedVideo";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import { gettingStartedVideos } from "../../data/tour/gettingStartedData";

function GettingStartedActivateBusiness() {
  const navigate = useNavigate();
  const [videoWatched, setVideoWatched] = useState(false);
  const [businessActive, setBusinessActive] = useState(false);

  const handleActivateBusiness = () => {
    navigate("/welcome");
    setBusinessActive(true);
  };

  const handleContinue = () => {
    navigate("/gettingStarted/logoandlink");
  };

  return (
    <div className="flex flex-col py-10 px-4 lg:px-10 flex-1">
      <span className="text-[#818083] text-sm lg:text-base">Step 1</span>
      <span className="text-lg lg:text-3xl my-2">Activate business</span>
      <span className="text-[#818083] text-sm lg:text-base">
        Setup order requirements based on business needs
      </span>
      <GettingStartedStepTitle
        visible
        active={videoWatched}
        title={"How to activate your business"}
      />
      <div className="flex gap-6">
        <GettingStartedVerticalLine height="h-[320px]" active={videoWatched} />
        <div className="flex flex-1 flex-col">
          <GettingStartedVideo video={gettingStartedVideos.activateBusiness} />
          <GettingStartedCheckbox
            text="I have watched the video"
            active={videoWatched}
            setActive={setVideoWatched}
          />
        </div>
      </div>
      <GettingStartedStepTitle
        visible={videoWatched}
        active={businessActive}
        title={"Activate my business"}
      />
      {videoWatched && (
        <div className="flex gap-6">
          <GettingStartedVerticalLine height="h-10" active={false} />
          <div className="flex flex-1 flex-col">
            <button
              className="bg-primary1 rounded-md text-white text-sm lg:text-base font-bold px-6 lg:px-10 w-fit py-2 lg:py-3"
              onClick={handleActivateBusiness}
            >
              Activate business
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-col flex-1"></div>
      <GettingStartedFooter
        onContinue={handleContinue}
        continueActive={businessActive}
        onPrevious={() => navigate(-1)}
        previousText="Go back"
      />
    </div>
  );
}

export default GettingStartedActivateBusiness;
