import { useState } from "react";
import FlightOfferDisplay1 from "./FlightOfferDisplay1";
import { useSelector } from "react-redux";
const FlightOfferDisplayList = ({
  id,
  data,
  path,
  showDetail,
  select,
  offer,
}) => {
  switch (id) {
    case "1":
      return (
        <FlightOfferDisplay1
          path={path}
          data={data}
          showDetail={showDetail}
          select={select}
          offer={offer}
        />
      );
    default:
      return (
        <FlightOfferDisplay1
          path={path}
          data={data}
          showDetail={showDetail}
          select={select}
          offer={offer}
        />
      );
  }
};
export default function SortedOffersList({
  obj,
  offer,
  params: { qIndex, showDetail, handleOfferSelect, ...restParams },
}) {
  const template = useSelector((state) => state.template);
  const [view, setView] = useState(false);

  return (
    <div className="px-4">
      <FlightOfferDisplayList
        path={qIndex}
        offer={offer}
        data={obj?.objects[0]}
        showDetail={async () => await showDetail(obj)}
        select={handleOfferSelect}
      />
      {obj.objects.length > 1 ? (
        <div
          className={
            "flex flex-col gap-4 relative  " + (view ? "bg-[#f9f9f9] " : "")
          }
        >
          {/* <div className={'absolutex -xtranslate-y-[12px] p-2 test w-full '+(view?'bg-[#f3f7ff]':'')}></div> */}
          <div className="relative flex items-center gap-2 mb-2 py-2">
            <div
              className={`border flex-1 ${view ? "border-transparent" : ""}`}
            ></div>
            <button
              className={
                "text-sm text-theme1 font-bold self-center bottom-0 max-h-[1.3rem] rounded-t-lg "
              }
              style={{ color: template?.secondaryColor }}
              onClick={() => setView(!view)}
            >
              <div className={"px-4 pb-2 rounded-lg "}>
                {view
                  ? `Hide flights`
                  : `${
                      obj.objects.length - 1
                    } more flight options available at this price`}
              </div>
            </button>
            <div
              className={`border flex-1 ${view ? "border-transparent" : ""}`}
            ></div>
          </div>
          {view ? (
            <div className="flex flex-col gap-4 p-3">
              {obj.objects.slice(1).map((obj, i) => (
                <FlightOfferDisplayList
                  key={i}
                  path={qIndex}
                  offer={offer}
                  data={obj}
                  showDetail={async () => showDetail && (await showDetail(obj))}
                  select={handleOfferSelect}
                  {...restParams}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
