import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setFlightShareModal } from "../../redux/reducers/modalSlice";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: "800px",
};

export default function FlightShareModal() {
  const dispatch = useDispatch();
  const { flightShareModal } = useSelector((state) => state.modal);

  const handleOpen = () => dispatch(setFlightShareModal(true));
  const handleClose = () => dispatch(setFlightShareModal(false));

  return (
    <div>
      <Modal
        open={flightShareModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container}>
            <div className="flex w-full justify-between bg-primary1 py-4 px-4 text-white">
              <span className={Classname.modalTitle}>Share offers</span>
              <div className="p-2" onClick={handleClose}>
                <Close color="inherit" />
              </div>
            </div>
            <div className={Classname.content}>
              <PassengerDetails />
              <OptionsSection />
              <CTA />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const PassengerDetails = () => {
  const [detail, setDetail] = useState({
    title: "",
    firstName: "",
    lastName: "",
    passportNo: "",
    email: "",
    phoneNo: "",
    description: "",
  });

  const titleOptions = ["Mr", "Mrs"];

  return (
    <div className="w-full flex flex-col">
      <span className="font-bold text-base lg:text-xl mt-3">
        Passenger details{" "}
        <span className="font-normal text-gray-600">(optional)</span>
      </span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          All passenger details are optional to create an offer.
        </span>
      </div>
      <div className="flex flex-col lg:flex-row gap-3 w-full mb-2">
        <div className="w-28 flex flex-col gap-1">
          <span className={Classname.inputLabel}>Title</span>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={detail?.title}
              className="h-10"
              onChange={(e) => setDetail({ ...detail, title: e.target.value })}
            >
              {titleOptions.map((r, index) => (
                <MenuItem key={index} value={r}>
                  {r}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="lg:w-28 flex flex-col gap-1 flex-1">
          <span className={Classname.inputLabel}>First name</span>
          <input
            type="text"
            value={detail.firstName}
            className={Classname.input}
            onChange={(e) =>
              setDetail({ ...detail, firstName: e.target.value })
            }
          />
        </div>
        <div className="lg:w-28 flex flex-col gap-1 flex-1">
          <span className={Classname.inputLabel}>Surname</span>
          <input
            type="text"
            value={detail.lastName}
            className={Classname.input}
            onChange={(e) => setDetail({ ...detail, lastName: e.target.value })}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-3 w-full mb-4">
        <div className="lg:w-28 flex flex-col gap-1 flex-1">
          <span className={Classname.inputLabel}>Passport no</span>
          <input
            type="text"
            value={detail.passportNo}
            className={Classname.input}
            onChange={(e) =>
              setDetail({ ...detail, passportNo: e.target.value })
            }
          />
        </div>
        <div className="lg:w-28 flex flex-col gap-1 flex-1">
          <span className={Classname.inputLabel}>Email</span>
          <input
            type="text"
            value={detail.email}
            className={Classname.input}
            onChange={(e) => setDetail({ ...detail, email: e.target.value })}
          />
        </div>
        <div className="lg:w-28 flex flex-col gap-1 flex-1">
          <span className={Classname.inputLabel}>Phone no</span>
          <input
            type="text"
            value={detail.phoneNo}
            className={Classname.input}
            onChange={(e) => setDetail({ ...detail, phoneNo: e.target.value })}
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-3 w-full mb-4">
        <div className="flex flex-col gap-1 flex-1">
          <span className="text-xs text-gray-400">
            Free text (This refers any extra optional information you want to
            add to your customer’s quote.)
          </span>
          <textarea
            type="text"
            value={detail.description}
            className="flex flex-1 h-[200px] py-1 rounded-md px-4 border-2 border-gray-200"
            onChange={(e) =>
              setDetail({ ...detail, description: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};

const options = [
  {
    title: "PDF File",
    choice: ["Create", "Download"],
    selected: "Create",
  },
  {
    title: "Price",
    choice: ["Display Price", "Hide Price"],
    selected: "Display Price",
  },
  {
    title: "PDF File",
    choice: ["Included in Fare", "Listed Separately"],
    selected: "Included in Fare",
  },
  {
    title: "PDF File",
    choice: ["Selected Flight only", "All Flight Options"],
    selected: "Selected Flight only",
  },
];

const OptionsSection = () => {
  const [values, setValues] = useState(options);
  return (
    <div className="flex flex-col gap-1 w-full">
      {values?.map((item, index) => (
        <div className="w-full items-center justify-between flex " key={index}>
          <div className="w-[100px] lg:w-[250px]  text-left text-xs lg:text-sm font-medium text-gray-500">
            {item.title}
          </div>
          {item.choice.map((choice, idx) => (
            <div
              key={idx}
              className="flex items-center cursor-pointer w-[250px]  text-xs lg:text-sm"
              onChange={() => {
                setValues(
                  values.map((p, valuesIndex) =>
                    valuesIndex === index ? { ...p, selected: choice } : p
                  )
                );
              }}
            >
              <Radio checked={item.selected === choice} />
              <span className="">{choice}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const CTA = () => {
  const buttons = [
    { label: "Get PDF document", onClick: () => {} },
    { label: "Send via Whatsapp", onClick: () => {} },
    { label: "Send via Email", onClick: () => {} },
    { label: "Copy to clipboard", onClick: () => {} },
  ];
  return (
    <div className="flex flex-col items-center justify-center w-full mb-10">
      <p className="text-xs my-3">
        PDF documents can be edited in Word or other word processing programs.
        To do this, select the option "PDF document - download". Information
        provided on the travel documents comply with current legal requirements.
        They are a necessary part of the travel document. They are printed in
        the interest of the traveler, the agent as well as the travel provider.
        Any change of these necessary formulations can lead to legal
        disadvantages of individual parties.
      </p>
      <div className="gap-2 lg:gap-3 w-full flex flex-wrap mt-2">
        {buttons?.map(({ label, onClick }, index) => (
          <button
            key={index}
            className="flex flex-col flex-1 min-w-[150px] text-xs lg:text-sm rounded-md items-center justify-center text-[#2E2E32] bg-[#E5E7EB] hover:bg-primary1 hover:text-white py-2 transition-all duration-300 font-bold"
            onClick={() => onClick()}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

const Classname = {
  container: "flex flex-col font-main",
  content:
    "flex flex-col items-center px-4 max-h-[80vh] overflow-scroll scroll-hide",
  modalTitle: "text-xl lg:text-3xl font-bold text-center",
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-[6px] rounded-md px-4 border-2 border-gray-200",
  inputLabel: "text-gray-500 font-bold text-sm",
};
