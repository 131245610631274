import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/form/TextInput'
import EmailInput from '../../../../components/form/EmailInput'
import Button1 from '../../../../components/form/Button1'
import PasswordInput from '../../../../components/form/PasswordInput'
import { Link, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import updatePassword from '../../../../controllers/user/updatePassword'
import { useDispatch, useSelector } from 'react-redux'
import updateProfile from '../../../../controllers/user/updateProfile'
import resendVerifyEmail from '../../../../controllers/Auth/resendVerifyEmail'
import requestEmailUpdate from '../../../../controllers/user/requestEmailUpdate'
import Modal1 from '../../../../components/DIsplay/Modal/Modal1'
import updateEmail from '../../../../controllers/user/updateEmail'
import { logout, setUser } from '../../../../redux/reducers/userSlice'

export default function AccountSettings() {
  const {userData,userData:{user}} = useSelector(state => state.user);
  const [data,setData] = useState({firstName: '',lastName: '',email: ''});
  const [loading,setLoading] = useState(false);
  const [resent,setResent] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [haveAccountPassword,setHaveAccountPassword] = useState(!user?.googleId || user?.hasPassword);

  const [openChangeEmail,setOpenChangeEmail] = useState(false);
  const [emailData,setEmailData] = useState({oldEmail: user?.email,newEmail: '',otp: ''})
  const [emailLoading,setEmailLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setData(data => ({...data,firstName: user?.firstName || '',lastName: user?.lastName || '',email: user?.email || ''}))
  },[user])

  function handleFullName(val) {
    let fullName = val.split(' ');
    let firstName = fullName[0]
    let lastName = fullName[1]
    setData({...data,firstName,lastName})
  }

  async function handleSubmit(ev) {
    ev && ev.preventDefault();

    setLoading(true);
    const res = await updateProfile(data);
    if(res.return) {
      if(user?.email !== data?.email) {
        const emailRes = await requestEmailUpdate();
        if(emailRes.return) {
          setOpenChangeEmail(true);
          setEmailData({...emailData,newEmail: data?.email})
          enqueueSnackbar(emailRes?.msg,{variant: 'success'})
        }
      }
      enqueueSnackbar('Profile Name Updated.',{variant: 'success'})
    } else enqueueSnackbar(res.msg,{variant: 'error'})
    setLoading(false);
  }

  async function handleResendLink() {
    setResent(true)
    const res = await resendVerifyEmail(data);
    if(res.return) {
      enqueueSnackbar(res.msg || 'Verification code sent.',{variant: 'success'});
      // setData({...data,otp: ''})
    } else {
      enqueueSnackbar(res.msg || 'Something went wrong!', {variant: 'error'})
      setResent(false)
    }

  }

  
  async function handleUpdateEmail(ev) {
    ev?.preventDefault();
    setEmailLoading(true);
    const res = await updateEmail(emailData)
    if(res.return) {
      dispatch(setUser({...user,email: emailData?.newEmail}))
      enqueueSnackbar('Email updated',{variant: 'success'})
      setEmailData({...emailData,newEmail: '',otp: ''})
      setOpenChangeEmail(false);
    }
    else enqueueSnackbar(res?.msg,{variant: 'error'})
    
    setEmailLoading(false);
  }

  function handleVerify() {
    dispatch(logout())
    navigate({
      pathname: '/',
      search: `view=verify&email=${data?.email}`
    })
  }

  return (
    <div className='content-max-w flex flex-col gap-4'>
      <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
        <h5>Information</h5>
        <TextInput label={'Full name'} placeholder={'Okafor Chiemena'} 
          value={data.firstName+' '+data.lastName}
          onChange={(ev) => handleFullName(ev.target.value)}
        />
        <EmailInput 
          value={data.email}
          onChange={(ev) => setData({...data,email: ev.target.value})}
          tooltip={!user?.isEmailVerified ?
            <div>
              To complete your registration, please verify your email with the link we sent to <b>{data.email}. </b> 
              Didn't receive an email? <b className='cursor-pointer' onClick={handleResendLink}>{!resent ? 'Resend confirmation link' : 'Sent'}</b>
              <div className='flex justify-start py-2'>
                <Button1 variant='outlined' size='small' className='!w-auto' onClick={handleVerify}>
                  Verify here
                </Button1>
              </div>
            </div>
            : null
          } 
        />
        {/* <div className='flex justify-end gap-4'>
          <small className='cursor-pointer' onClick={() => setOpenChangeEmail(true)}>Enter OTP</small>
        </div> */}
        <Modal1 open={openChangeEmail} setOpen={setOpenChangeEmail}>
          <div className='card p-5 flex flex-col gap-4 w-[350px] max-w-full'>
            <TextInput label={'Old Email'} value={emailData?.oldEmail} disabled />
            <EmailInput label='New Email'
              value={emailData?.newEmail}
              onChange={(ev) => setEmailData({...emailData,newEmail: ev.target.value})}
            />
            <TextInput label='OTP' placeholder={'Enter OTP you got from your old email.'}
              value={emailData?.otp}
              onChange={ev => setEmailData({...emailData,otp: ev.target.value})}
            />
            <div className='flex gap-4'>
              <Button1 className='!btn !w-auto !bg-transparent !text-primary' onClick={() => setOpenChangeEmail(false)}>Cancel</Button1>
              <Button1 loading={emailLoading} onClick={handleUpdateEmail} className='whitespace-nowrap'>Update Email</Button1>
            </div>
          </div>
        </Modal1>

        <div className='flex justify-end'>
          <Button1 type='submit' loading={loading} className='sm:!w-auto'>Save Changes</Button1>
        </div>
      </form>
      {haveAccountPassword ? 
        <ChangePassword hasPassword={!user?.googleId} />
      :
        <button className='btn-outlined border-primary text-primary' onClick={() => setHaveAccountPassword(true)}>Create an account with your email</button>
      }
      <div className='bg-red-300/50 text-gray-600 flex flex-col gap-3 p-4 mt-10 rounded-lg'>
        <h5>Delete Account</h5>
        <p className='text-red-500 font-bold'>
          Once you delete your account, you will no longer have access to Intraverse Apps and API, all pending organization invitations will be revoked as well as organization memberships.
          If you'd like to delete your account, please contact <Link className='text-red-900' to="#">customer support.</Link>
        </p>
      </div>
    </div>
  )
}

function ChangePassword({hasPassword}) {
  const [data,setData] = useState({oldPassword: '',newPassword: '',confirmPassword: ''});
  const {enqueueSnackbar} = useSnackbar();
  const [loading,setLoading] = useState(false);

  async function handleSubmit(ev) {
    ev.preventDefault();

    if(data.confirmPassword !== data.newPassword)
      return enqueueSnackbar("Password's doesn't match!",{variant: 'error'})

    setLoading(true);
    const res = await updatePassword(data);
    setLoading(false);
    if(res.return) {
      enqueueSnackbar('Password updated',{variant: 'success'})
    } else enqueueSnackbar(res.msg,{variant: 'error'})
    
  }
  return (
    <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
      <h5>Password</h5>
      {hasPassword ? 
        <PasswordInput label='Old Password'required
          value={data.oldPassword}
          onChange={(ev) => setData({...data,oldPassword: ev.target.value})}
        />
      :null}
      <div className='flex gap-4'>
        <PasswordInput className='flex-1' label={hasPassword?'New Password':'Enter Password'} required
          value={data.newPassword}
          onChange={(ev) => setData({...data,newPassword: ev.target.value})}
        />
        <PasswordInput className='flex-1' label='Confirm Password' required
          value={data.confirmPassword}
          onChange={(ev) => setData({...data,confirmPassword: ev.target.value})}
        />
      </div>
      <div className='flex justify-end'>
        <Button1 type='submit' loading={loading} className='sm:!w-auto'>Save Password</Button1>
      </div>
    </form>
  )
}