export const alertType = {
  autocanceled: "error",
  cancelled: "error",
  canceled: "error",
  past: "error",
  lost: "error",
  spent: "error",
  suspended: "error",
  expired: "error",
  Expired: "error",
  Failed: "error",
  
  
  confirmed: "success",
  created: "success",
  earned: "success",
  active: "success",
  Success: "success",
  success: "success",
  'issuable': "success",
  'paid': "success",
  'completed': "success",
  'issued': "success",
  'awaiting ticketing': 'success',
  'Awaiting ticketing': 'success',
  pendingticketissue: 'success',
  pendingticketissueapproval: 'success',
  
  
  pending: "warn",
  inactive: "warn",
  'booked': 'warn',
  'not paid': 'warn',
  "on hold": "warn",
  'needs review': 'warn',
  Pending: "warn",
  Issuing: 'warn',
  issuing: 'warn',
  
};
