import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTicketsPage } from "../../redux/reducers/orderSlice";
import useOrders from "../../hooks/useOrders";

function OrderPagination({callback,perPage=3}) {
  const { getOrders } = useOrders();
  const [ordersList,ogData] = getOrders() || [[],[]];

  console.log(ordersList)
  
  const count = Math.ceil(Math.max(ordersList?.length,perPage)/perPage)
  const {ticketsPage} = useSelector(state => state.order)
  // const [page, setPage] = useState(ticketsPage);
  const dispatch = useDispatch();

  const handlePageChange = (pg) => {
    // setPage(pg);
    dispatch(setTicketsPage(pg))
    callback && callback(pg)
  };

  return (
    <div className="flex justify-end mt-10">
      <Pagination
        count={count}
        onChange={(e, pg) => handlePageChange(pg)}
        page={ticketsPage||1}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
}

export default OrderPagination;
