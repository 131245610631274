import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard/Dashboard";
import { store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Settings from "./pages/Settings/Settings";
import AuthValidate from "./components/Auth/AuthValidate";
import SettingSideBar from "./pages/Settings/Sidebar";
import TeamMembers from "./pages/Settings/TeamMembers";
import ContactEmails from "./pages/Settings/ContactEmails";
import SecuritySettings from "./pages/Settings/Security";
import BalanceSetting from "./pages/Settings/Balance";
import DeveloperSetting from "./pages/Settings/Developer";
import AccountNav from "./components/Settings/AccountNav";
import AccountSetupNav from "./components/Settings/AccountSetupNav";
import AccountSettings from "./pages/Settings/Agency/Account/Account";
import AgencySetupSetting from "./pages/Settings/Agency/AgencySetup.js.js";
import MarkupSetting from "./pages/Settings/Order/Markup/Markup.js";
import CommissionSettings from "./pages/Settings/Agency/Commision";
import PointsSettings from "./pages/Settings/Agency/Points";
import PageNotFound from "./pages/PageNotFound";
import PaymentSetting from "./pages/Settings/Agency/Payment";
import Finance from "./pages/Settings/Finance/Finance";
import FinanceContainer from "./pages/Settings/Finance/FinanceContainer";
import Orders from "./pages/Orders/Orders";
import UserManagement from "./pages/UserManagement/UserManagement";
import Links from "./pages/Links/Links";
import FlightAncillaries from "./pages/Orders/Flight/Book/FlightAncillaries.js";
import ProfileSurvey from "./components/ProfileSurvey/New/ProfileSurvey.js";
import LearnSupport from "./pages/Welcome/LearnSupport.js";
import LearnPricing from "./pages/Welcome/LearnPricing.js";
import LearnMiles from "./pages/Welcome/LearnMiles/LearnMiles.js";
import ProfileStatusCheck from "./components/ProfileSurvey/New/ProfileStatusCheck.js";
import Test from "./pages/test.js";
import FlightPayment from "./pages/Orders/Flight/Book/FlightPayment.js";
import FlightOrder from "./pages/Orders/Flight/Booked/FlightOrder.js";
import OrderNav from "./components/Settings/OrderNav.js";
import PayoutMethods from "./pages/Settings/Finance/PayoutMethods.js";
import PaymentGateway from "./pages/Settings/Agency/PaymentGateway.js";
import SupplierSettings from "./pages/Settings/Order/Suppliers/Suppliers.js";
import PromoSettings from "./pages/Settings/Order/PromoCodes/PromoCodes.js";
import EmailTemplateSettings from "./pages/Settings/EmailTemplates/EmailTemplates.js";
import ChangeFlightOrder from "./pages/Orders/Flight/Booked/ChangeOrder/ChangeOrder.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TermsOfService from "./pages/TermsOfService.js";
import AgencyGettingStarted from "./pages/Welcome/Team/GettingStarted.js";
import Training from "./components/ProfileSurvey/New/Training.js";
import AccountRoles from "./pages/Settings/Roles/AccountRoles.js";
import TourOrderLocationPage from "./pages/Orders/Tours/TourOrderLocationPage.jsx";
import TourOrderResultPage from "./pages/Orders/Tours/TourOrderResultPage.jsx";
import TourOrderSingleTourPage from "./pages/Orders/Tours/TourOrderSingleTourPage.jsx";
import TourOrderUserInfoPage from "./pages/Orders/Tours/TourOrderUserInfoPage.jsx";
import TourOrderPaymentPage from "./pages/Orders/Tours/TourOrderPaymentPage.jsx";
import TourOrderConfirmationPage from "./pages/Orders/Tours/TourOrderConfirmationPage.jsx";
import AccommodationNewOrderPage from "./pages/Orders/accommodation/AccommodationNewOrderPage.jsx";
import AccommodationResultPage from "./pages/Orders/accommodation/AccommodationResultPage.jsx";
import AccommodationHotelPage from "./pages/Orders/accommodation/AccommodationHotelPage.jsx";
import AccommodationUserInfoPage from "./pages/Orders/accommodation/AccommodationUserInfoPage.jsx";
import AccommodationPaymentPage from "./pages/Orders/accommodation/AccommodationPaymentPage.jsx";
import AccommodationConfirmationPage from "./pages/Orders/accommodation/AccommodationConfirmationPage.jsx";
import TourManagementCreatePage from "./pages/management/tour/TourManagementCreatePage.jsx";
import TourManagementPackageNamePage from "./pages/management/tour/TourManagementPackageNamePage.jsx";
import TourManagementPackageDescriptionPage from "./pages/management/tour/TourManagementPackageDescriptionPage.jsx";
import TourManagementPackageLocationPage from "./pages/management/tour/TourManagementPackageLocationPage.jsx";
import TourManagementPackageKeywordsPage from "./pages/management/tour/TourManagementPackageKeywordsPage.jsx";
import TourManagementPackageGuideInfoPage from "./pages/management/tour/TourManagementPackageGuideInfoPage.jsx";
import TourManagementPackageExtraInfoPage from "./pages/management/tour/TourManagementPackageExtraInfoPage.jsx";
import TourManagementPackageFoodPage from "./pages/management/tour/TourManagementPackageFoodPage.jsx";
import TourManagementPackageTransportationPage from "./pages/management/tour/TourManagementPackageTransportationPage.jsx";
import TourManagementPackagePhotoUploadPage from "./pages/management/tour/TourManagementPackagePhotoUploadPage.jsx";
import TourManagementPackageOptionsPage from "./pages/management/tour/TourManagementPackageOptionsPage.jsx";
import TourManagementPackageOptionsSetupPage from "./pages/management/tour/TourManagementPackageOptionsSetupPage.jsx";
import TourManagementPackageMeetingPage from "./pages/management/tour/TourManagementPackageMeetingPage.jsx";
import TourManagementPackageConnectivityPage from "./pages/management/tour/TourManagementPackageConnectivityPage.jsx";
import TourManagementPackageAvailabilityPage from "./pages/management/tour/TourManagementPackageAvailabilityPage.jsx";
import TourManagementPackageAvailabilitySchedulePage from "./pages/management/tour/TourManagementPackageAvailabilitySchedulePage.jsx";
import TourManagementPackageAvailabilityAddOnPage from "./pages/management/tour/TourManagementPackageAvailabilityAddOnPage.jsx";
import TourManagementPackageAvailabilityPricePage from "./pages/management/tour/TourManagementPackageAvailabilityPricePage.jsx";
import TourManagementPackageAvailabilityCapacityPage from "./pages/management/tour/TourManagementPackageAvailabilityCapacityPage.jsx";
import TourManagementPackageAvailabilityPriceCategoryPage from "./pages/management/tour/TourManagementPackageAvailabilityPriceCategoryPage.jsx";
import GettingStartedHome from "./pages/GettingStarted/GettingStartedHome.jsx";
import GettingStartedActivateBusiness from "./pages/GettingStarted/GettingStartedActivateBusiness.jsx";
import GettingStartedMarkups from "./pages/GettingStarted/GettingStartedMarkups.jsx";
import GettingStartedMarkupPage from "./pages/GettingStarted/GettingStartedMarkupPage.jsx";
import GettingStartedTeam from "./pages/GettingStarted/GettingStartedTeam.jsx";
import GettingStartedTeamInvitePage from "./pages/GettingStarted/GettingStartedTeamInvitePage.jsx";
import GettingStartedPricing from "./pages/GettingStarted/GettingStartedPricing.jsx";
import GettingStartedCongratulations from "./pages/GettingStarted/GettingStartedCongratulations.jsx";
import Subscription from "./components/ProfileSurvey/Subscription/Subscription.js";
import SubscriptionPlans from "./pages/Subscription/SubscriptionPlans.js";
import AgencyLink from "./pages/Settings/Agency/AgencyLink.js";
import GettingStartedLogo from "./pages/GettingStarted/GettingStartedLogo.jsx";
import GettingStartedLink from "./pages/GettingStarted/GettingStartedLink.jsx";
import GettingStartedSubscription from "./pages/GettingStarted/GettingStartedSubscription.jsx";
import ProfileManagement from "./pages/Profile/ProfileManagement.js";
import GettingStartedSubscribe from "./pages/GettingStarted/GettingStartedSubscribe.jsx";
import GenerateLink from "./pages/Settings/generateLink/index.jsx";
import SearchResults from "./components/templates/searchResults/SearchResults.js";
import OffersContainer from "./pages/Orders/Flight/FlightSearch/OffersContainer.js";
import Preview from "./components/templates/preview/Preview.js";
import SearchContainer from "./pages/Orders/Flight/SearchContainer.js";
import CustomerDashboard from "./components/templates/dashboard/Dashboard.jsx";
import Cart from "./pages/Cart/Cart.js";
import FlightBookContainer from "./pages/Orders/Flight/Book/FlightBookContainer.js";
import FlightBookDetailsContainer from "./pages/Orders/Flight/Book/FlightBookDetailsContainer.js";
import MyWebsite from "./pages/MyWebsite/MyWebsite.js";
import { isCustomerSite } from "./utils/getsubDomain.js";
import hexToRGB from "./utils/style/hexToRGB.js";
import Terms from "./components/templates/about/Terms.js";
import AboutList from "./components/templates/about/AboutList.js";
import ContactList from "./components/templates/contact/ContactList.js";
import CRM from "./components/templates/CRM/index.js";
import Policy from "./pages/Settings/Agency/Policy.js";
import NewOrders from "./pages/Orders/NewOrders.jsx";
import TicketManagementFlightOrderDetail from "./pages/TicketManagement/Flight/TicketManagementFlightOrderDetail.jsx";
import TicketManagementFlightChangeDate from "./pages/TicketManagement/Flight/TicketManagementFlightChangeDate.jsx";
import TicketManagementFlightSearchFlight from "./pages/TicketManagement/Flight/TicketManagementFlightSearchFlight.jsx";
import TicketManagementFlightConfirmation from "./pages/TicketManagement/Flight/TicketManagementFlightConfirmation.jsx";
import TicketManagementFlightChangeInfo from "./pages/TicketManagement/Flight/TicketManagementFlightChangeInfo.jsx";
import TicketManagementFlightRefund from "./pages/TicketManagement/Flight/TicketManagementFlightRefund.jsx";
import TicketManagementFlightVoid from "./pages/TicketManagement/Flight/TicketMangementFlightVoid.jsx";
import TicketManagementFlightAddBag from "./pages/TicketManagement/Flight/TicketManagementFlightAddBag.jsx";
import OffersContainerV2 from "./pages/Orders/Flight/FlightSearch/OffersContainer-v2.jsx";
import WhiteLabel from "./pages/Links/WhiteLabel.jsx";
import CustomDomain from "./pages/Settings/Agency/CustomDomain.js";
// import 'dotenv/config'

const rootElement = document.getElementById("root");

const CustomThemeProvider = ({ children }) => {
  // const themeHook = useTheme();
  const template = useSelector((state) => state.template);
  const customer = isCustomerSite();

  let templateColor = template?.secondaryColor;
  if (!templateColor?.includes("#")) templateColor = "#" + templateColor;

  if (customer)
    document.documentElement.style.setProperty(
      "--theme-color",
      hexToRGB(templateColor)
    );

  // Create a new theme object with the updated primary color
  const theme = createTheme({
    palette: {
      primary: {
        main: customer ? templateColor || "#0067FF" : "#0067FF",
      },
      // secondary: {
      //   main: '#1E61DC',
      // },
      // primaryLight: {
      //   main: themeColor ? themeColor+'aa' : "#3a3"
      // }
    },
    components: {
      // MuiPopover: {
      //   defaultProps: {
      //     container: rootElement,
      //   },
      // },
      MuiTabs: {
        defaultProps: {
          // Add your default props for MuiTabs here
          container: rootElement,
        },
      },
      // MuiPopover: {
      //   defaultProps: {
      //     // Add your default props for MuiTabs here
      //     container: rootElement,
      //   },
      // },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function App() {
  return (
    <Router>
      <Provider store={store}>
        <CustomThemeProvider>
          <SnackbarProvider
            autoHideDuration={5000}
            maxSnack={3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Routes>
              <Route path="test" element={<Test />} />

              <Route path="/" element={<AuthValidate />}>
                <Route path="training" element={<Training />} />
                <Route path="profile" element={<ProfileSurvey />} />

                <Route path="/" element={<Navbar />}>
                  <Route path="account" element={<CustomerDashboard />} />

                  <Route path="/welcome">
                    <Route index element={<ProfileStatusCheck />} />
                    <Route path="support" element={<LearnSupport />} />
                    <Route path="pricing" element={<LearnPricing />} />
                    <Route path="learn" element={<LearnMiles />} />
                  </Route>
                  <Route path="/manage" element={<ProfileManagement />} />
                  <Route path="" element={<GettingStartedHome />} />

                  <Route path="gettingStarted">
                    <Route
                      path="business/activate"
                      element={<GettingStartedActivateBusiness />}
                    />
                    <Route path="logo" element={<GettingStartedLogo />} />
                    <Route path="link" element={<GettingStartedLink />} />
                    <Route path="markups" element={<GettingStartedMarkups />} />
                    <Route
                      path="markups/create"
                      element={<GettingStartedMarkupPage />}
                    />
                    <Route path="team" element={<GettingStartedTeam />} />
                    <Route
                      path="team/invite"
                      element={<GettingStartedTeamInvitePage />}
                    />
                    <Route
                      path="subscription"
                      element={<GettingStartedSubscription />}
                    />
                    <Route path="pricing" element={<GettingStartedPricing />} />
                    <Route
                      path="subscribe"
                      element={<GettingStartedSubscribe />}
                    />
                    <Route
                      path="congratulations"
                      element={<GettingStartedCongratulations />}
                    />
                  </Route>
                  {/* <Route path="/" element={<GettingStarted />} /> */}
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/order">
                    <Route index element={<Orders />} />
                    <Route
                      path="neworder"
                      element={<NewOrders />}
                      // element={<CreateFlightOrder />}
                    />
                    <Route
                      path="new/:order"
                      element={<SearchContainer />}
                      // element={<CreateFlightOrder />}
                    />
                    <Route
                      path="new/flight/offers"
                      element={<OffersContainer />}
                      // element={<OffersList />}
                    />
                    <Route
                      path="new/flight/newoffers"
                      element={<OffersContainerV2 />}
                      // element={<OffersList />}
                    />
                    <Route
                      path="new/flight/book/:id"
                      element={<FlightBookContainer />}
                    />
                    <Route
                      path="new/flight/book/details/:id"
                      element={<FlightBookDetailsContainer />}
                    />
                    <Route
                      path="new/flight/book/ancillaries/:id"
                      element={<FlightAncillaries />}
                    />
                    <Route
                      path="new/flight/book/payment/:id"
                      element={<FlightPayment />}
                    />
                    <Route path="flight/:id" element={<FlightOrder />} />
                    <Route
                      path="flight/change/:id"
                      element={<ChangeFlightOrder />}
                    />
                    <Route
                      path="flight/ticket/detail/:id"
                      element={<TicketManagementFlightOrderDetail />}
                    />
                    <Route
                      path="flight/ticket/changedate"
                      element={<TicketManagementFlightChangeDate />}
                    />
                    <Route
                      path="flight/ticket/searchflight"
                      element={<TicketManagementFlightSearchFlight />}
                    />
                    <Route
                      path="flight/ticket/confirmation"
                      element={<TicketManagementFlightConfirmation />}
                    />
                    <Route
                      path="flight/ticket/changeinfo"
                      element={<TicketManagementFlightChangeInfo />}
                    />
                    <Route
                      path="flight/ticket/refund/:id"
                      element={<TicketManagementFlightRefund />}
                    />
                    <Route
                      path="flight/ticket/void/:id"
                      element={<TicketManagementFlightVoid />}
                    />
                    <Route
                      path="flight/ticket/addbag"
                      element={<TicketManagementFlightAddBag />}
                    />
                  </Route>

                  <Route
                    path="/agency-welcome"
                    element={<AgencyGettingStarted />}
                  />

                  <Route path="tour">
                    <Route path="new" element={<TourOrderLocationPage />} />
                    <Route path="result" element={<TourOrderResultPage />} />
                    <Route
                      path="singleTour"
                      element={<TourOrderSingleTourPage />}
                    />
                    <Route
                      path="userInfo"
                      element={<TourOrderUserInfoPage />}
                    />
                    <Route path="payment" element={<TourOrderPaymentPage />} />
                    <Route
                      path="confirmation"
                      element={<TourOrderConfirmationPage />}
                    />
                  </Route>
                  <Route path="accommodation">
                    <Route path="new" element={<AccommodationNewOrderPage />} />
                    <Route
                      path="results"
                      element={<AccommodationResultPage />}
                    />
                    <Route path="hotel" element={<AccommodationHotelPage />} />
                    <Route
                      path="userinfo"
                      element={<AccommodationUserInfoPage />}
                    />
                    <Route
                      path="payment"
                      element={<AccommodationPaymentPage />}
                    />
                    <Route
                      path="confirmation"
                      element={<AccommodationConfirmationPage />}
                    />
                  </Route>
                  <Route path="tourManagement">
                    <Route
                      path="create"
                      element={<TourManagementCreatePage />}
                    />
                    <Route
                      path="create/name"
                      element={<TourManagementPackageNamePage />}
                    />
                    <Route
                      path="create/description"
                      element={<TourManagementPackageDescriptionPage />}
                    />
                    <Route
                      path="create/location"
                      element={<TourManagementPackageLocationPage />}
                    />
                    <Route
                      path="create/keyword"
                      element={<TourManagementPackageKeywordsPage />}
                    />
                    <Route
                      path="create/guide"
                      element={<TourManagementPackageGuideInfoPage />}
                    />
                    <Route
                      path="create/food"
                      element={<TourManagementPackageFoodPage />}
                    />
                    <Route
                      path="create/transportation"
                      element={<TourManagementPackageTransportationPage />}
                    />
                    <Route
                      path="create/extrainfo"
                      element={<TourManagementPackageExtraInfoPage />}
                    />
                    <Route
                      path="create/photoUpload"
                      element={<TourManagementPackagePhotoUploadPage />}
                    />
                    <Route
                      path="create/options"
                      element={<TourManagementPackageOptionsPage />}
                    />
                    <Route
                      path="create/optionsSetup"
                      element={<TourManagementPackageOptionsSetupPage />}
                    />
                    <Route
                      path="create/meeting"
                      element={<TourManagementPackageMeetingPage />}
                    />
                    <Route
                      path="create/connectivity"
                      element={<TourManagementPackageConnectivityPage />}
                    />
                    <Route
                      path="create/availability"
                      element={<TourManagementPackageAvailabilityPage />}
                    />
                    <Route
                      path="create/availability/schedule"
                      element={
                        <TourManagementPackageAvailabilitySchedulePage />
                      }
                    />
                    <Route
                      path="create/availability/priceCategory"
                      element={
                        <TourManagementPackageAvailabilityPriceCategoryPage />
                      }
                    />
                    <Route
                      path="create/availability/capacity"
                      element={
                        <TourManagementPackageAvailabilityCapacityPage />
                      }
                    />
                    <Route
                      path="create/availability/price"
                      element={<TourManagementPackageAvailabilityPricePage />}
                    />
                    <Route
                      path="create/availability/add-on"
                      element={<TourManagementPackageAvailabilityAddOnPage />}
                    />
                  </Route>

                  <Route path="users" element={<UserManagement />} />
                  <Route path="website" element={<MyWebsite />} />
                  <Route path="plans" element={<SubscriptionPlans />} />
                  <Route path="link" element={<Links />} />
                  <Route path="whitelabel" element={<WhiteLabel />} />
                  <Route path="cart" element={<Cart />} />

                  <Route path="about" element={<AboutList />} />
                  <Route path="contact" element={<ContactList />} />
                  <Route path="terms" element={<Terms />} />

                  <Route path="*" element={<PageNotFound />} />
                </Route>

                <Route path="templating">
                  <Route index element={<GenerateLink />} />
                  <Route path="edit/:page" element={<CRM />} />
                </Route>

                <Route path="settings" element={<SettingSideBar />}>
                  <Route path="" element={<AccountNav />}>
                    <Route index element={<AccountSettings />} />
                    <Route path="team" element={<TeamMembers />} />
                    <Route path="roles" element={<AccountRoles />} />
                    <Route path="security" element={<SecuritySettings />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>
                  <Route path="agency" element={<AccountSetupNav />}>
                    <Route index element={<AgencySetupSetting />} />
                    <Route path="brand" element={<Settings />} />
                    <Route path="link" element={<AgencyLink />} />
                    <Route path="customer" element={<CustomDomain />} />
                    <Route path="contact" element={<ContactEmails />} />
                    <Route path="developer" element={<DeveloperSetting />} />
                    <Route path="policy" element={<Policy />} />
                    <Route path="subscription" element={<Subscription />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>
                  <Route path="order" element={<OrderNav />}>
                    <Route index element={<MarkupSetting />} />
                    <Route path="commission" element={<CommissionSettings />} />
                    <Route path="points" element={<PointsSettings />} />
                    <Route path="suppliers" element={<SupplierSettings />} />
                    <Route path="dealcodes" element={<PromoSettings />} />
                    <Route
                      path="emailTemplates"
                      element={<EmailTemplateSettings />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>
                  <Route path="finance" element={<FinanceContainer />}>
                    <Route index element={<Finance />} />
                    <Route path="method" element={<PayoutMethods />} />
                    <Route path="balance" element={<BalanceSetting />} />
                    <Route path="payment" element={<PaymentSetting />} />
                    <Route path="gateway" element={<PaymentGateway />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Route>
                </Route>
              </Route>

              <Route path="preview/template/:id" element={<Preview />} />

              <Route
                path="order/new/flight/template/:id"
                element={<Preview />}
              />
              <Route
                path="order/new/flight/template/:id/offers"
                element={<SearchResults />}
              />

              <Route path="tos" element={<TermsOfService />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </SnackbarProvider>
        </CustomThemeProvider>
      </Provider>
      <ToastContainer />
    </Router>
  );
}

export default App;
