import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import CalendarInput1 from "../../../components/form/CalendarInput1";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementFlightChangeInfoStepsHeader from "../../../components/TicketManagement/TicketManagementFlightChangeInfoStepsHeader";
import ConfirmFlightNameChangeModal from "../../../components/modal/ConfirmFlightNameChangeModal";
import { useDispatch } from "react-redux";
import { setConfirmFlightNameChangeModal } from "../../../redux/reducers/modalSlice";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementUploadDocument from "../../../components/TicketManagement/TicketManagementUploadDocument";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementAddRemark from "../../../components/TicketManagement/TicketManagementAddRemark";

function TicketManagementFlightChangeInfo() {
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightNameChangeModal />
      <TicketManagementLoadingModal />

      <TicketManagementHeader />
      <TicketManagementFlightChangeInfoStepsHeader step={1} />
      <TicketManagementPassengerSelection />
      <SelectContentChange />
      <PassengerDetails />
      <TicketManagementUploadDocument />
      <TicketManagementAddRemark />
      <TicketManagementContactDetails />
      <TicketActionButtons />
    </div>
  );
}

const SelectContentChange = () => {
  const [contentChange, setContentChange] = useState("");

  const contentChanges = [
    "Passenger name",
    "Gender",
    "date of birth",
    "Change ID / Passport details",
  ];

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">
        Select content change <span className="font-normal">(Required)</span>
      </span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          There are two types of changes: Voluntary and Involuntary changes.
          Voluntary changes refer to changes that are made in accordance to the
          fare rules set by airlines. These include modifications that are
          allowed within the allowed guideline. Any other change is considered
          involuntary. Involuntary changes require a document to support
          contentChange for change. The document would serve as an evidence for
          contentChanges behind the change and help ensure a smooth processing.
        </span>
      </div>
      <div className="max-w-sm">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={contentChange}
            onChange={(e) => setContentChange(e?.target?.value)}
          >
            {contentChanges.map((r, index) => (
              <MenuItem key={index} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const peopleDetails = [
  {
    title: "Mr",
    firstName: "John",
    middleName: "david",
    lastName: "davidson",
    gender: "Male",
    dob: "2000-04-04",
    nationality: "Nigeria",
    passport: "1232323232",
    expiryDate: "2025-04-04",
  },
];

const PassengerDetails = () => {
  const [passengers, setPassengers] = useState(peopleDetails);
  const dobRef = useRef([...Array(passengers.length)]);
  const expiryRef = useRef([...Array(passengers.length)]);

  const titles = ["Mr", "Mrs"];
  const genders = ["Male", "Female"];
  const countries = ["United States", "Canada", "Nigeria", "UK"];

  return (
    <div className={Classname.cardContainer}>
      <span className="font-bold text-black">
        Passenger details <span className="font-normal">(Required)</span>
      </span>
      <div className={Classname.detailInfoContainer}>
        <img src="/IconInfo.svg" alt="" className={Classname.detailInfoIcon} />
        <span className="font-normal">
          Use all given names and surnames exactly as they appear in your
          passport/ID to avoid boarding complications
        </span>
      </div>
      {passengers.map((passenger, index) => (
        <div
          className="w-full flex flex-col text-[#818083] font-bold text-sm"
          key={index}
        >
          <span className="mt-2 mb-2 text-sm text-gray-400">
            Passenger {index + 1}
          </span>
          <div className="gap-1 flex flex-col w-24">
            <span>Title</span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={passenger?.title}
                placeholder="Select cabin"
                className="h-10"
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, title: e.target.value } : p
                    )
                  );
                }}
              >
                {titles.map((title, idx) => (
                  <MenuItem value={title} key={idx}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-col lg:flex-row gap-2 w-full items-center mt-2">
            <div className="gap-1 flex flex-col flex-1 w-full lg:w-fit">
              <span>First name</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.firstName}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, firstName: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
            <div className="gap-1 flex flex-col flex-1 w-full lg:w-fit">
              <span>Middle name</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.middleName}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, middleName: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
            <div className="gap-1 flex flex-col flex-1 w-full lg:w-fit">
              <span>Last name</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.lastName}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, lastName: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
          </div>
          <div className="flex gap-2 w-full items-center mt-2">
            <div className="gap-1 flex flex-col flex-1">
              <span>Gender</span>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={passenger?.gender}
                  placeholder="Select cabin"
                  className="h-10"
                  onChange={(e) => {
                    setPassengers(
                      passengers.map((p, idx) =>
                        idx === index ? { ...p, gender: e.target.value } : p
                      )
                    );
                  }}
                >
                  {genders.map((gender, idx) => (
                    <MenuItem value={gender} key={idx}>
                      {gender}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="gap-1 flex flex-col flex-1">
              <span>Date of birth</span>
              <CalendarInput1
                ref={(el) => (dobRef.current[index] = el)}
                className="w-full border border-primary/20 rounded-md p-2"
                value={passenger.dob || ""}
                onChange={(value) => {
                  dobRef.current[index]?.toggle();

                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, dob: value?.start || value } : p
                    )
                  );
                }}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-2 w-full items-center mt-2">
            <div className="gap-1 flex flex-col flex-1 w-full lg:w-fit">
              <span>Nationality</span>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={passenger?.nationality}
                  placeholder="Select cabin"
                  className="h-10"
                  onChange={(e) => {
                    setPassengers(
                      passengers.map((p, idx) =>
                        idx === index
                          ? { ...p, nationality: e.target.value }
                          : p
                      )
                    );
                  }}
                >
                  {countries.map((country, idx) => (
                    <MenuItem value={country} key={idx}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="gap-1 flex flex-col flex-1 w-full lg:w-fit">
              <span>Passport</span>
              <input
                type="text"
                placeholder="Contact name"
                className={Classname.input}
                value={passenger?.passport}
                onChange={(e) => {
                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index ? { ...p, passport: e.target.value } : p
                    )
                  );
                }}
              />
            </div>
            <div className="gap-1 flex flex-col flex-1 w-full lg:w-fit">
              <span>Expiry date</span>
              <CalendarInput1
                ref={(el) => (expiryRef.current[index] = el)}
                className="w-full border border-primary/20 rounded-md p-2"
                value={passenger.expiryDate || ""}
                onChange={(value) => {
                  expiryRef.current[index]?.toggle();

                  setPassengers(
                    passengers.map((p, idx) =>
                      idx === index
                        ? { ...p, expiryDate: value?.start || value }
                        : p
                    )
                  );
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const TicketActionButtons = () => {
  const dispatch = useDispatch();
  const handleCancel = () => {};

  const handleSubmit = () => {
    dispatch(setConfirmFlightNameChangeModal(true));
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSubmit}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Submit request
      </button>
    </div>
  );
};

export default TicketManagementFlightChangeInfo;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
