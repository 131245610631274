import { useState } from 'react';
import splitCapitals from '../../../features/utils/splitCapital';
import Skeleton from '@mui/material/Skeleton';

export default function FlightOfferSort1({ cat, getCatInfo, sortByCat, loading }) {
  const [value, setValue] = useState();

  return (
    <div className="flex whitespace-nowrap overflow-x-auto bg-white rounded-md w-full border">
      {Object.entries(cat).map(([name, obj], i) => {
        let catInfo = getCatInfo(obj[0]);
        return (
          <div
            key={i}
            className={`p-5 py-2 flex justify-center shadow-sm items-center ${i === value ? ' !bg-[#1C2D5C] !text-secondary' : ''}`}
            style={{ textTransform: 'capitalize', borderRadius: 2, marginRight: 2, cursor: 'pointer' }}
            onClick={() => {
              setValue(i);
              sortByCat(obj);
            }}
          >
            {loading ? (
              <div className='grid w-full items-center justify-center gap-2'>
                <Skeleton variant="rect" width={100} height={10} animation="wave" />
                <Skeleton variant="rect" width={150} height={10} animation="wave" />
              </div>
            ) : (
              <div className="text-start flex flex-col gap-1 justify-center items-center">
                <mall>{splitCapitals(name)}</mall>
                <div className="flex gap-1 relative justify-center items-center">
                  <span>{catInfo.amount}</span>
                  <div className="-translate-y-[1px] px-1 ">{catInfo.time ? '|' : ''}</div>
                  <div>{['quickest'].includes(name.toLowerCase()) ? catInfo.duration : catInfo.time}</div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
