import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import bookingDataReducer from "./reducers/flight/flightBookingSlice";
import filterSlice from "./reducers/tour/filterSlice";
import tabSlice from "./reducers/tour/tabSlice";
import tourDataSlice from "./reducers/tour/tourDataSlice";
import hotelDataSlice from "./reducers/tour/hotelDataSlice";
import dataSlice from "./reducers/tour/dataSlice";
import modalSlice from "./reducers/modalSlice";
import overviewSlice from "./reducers/overviewSlice";
import templateReducer from "./reducers/template/templateSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import orderSlice from "./reducers/orderSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, templateReducer);

const stateSyncConfig = { blacklist: ["persist/PERSIST", "persist/REHYDRATE"] };
const middlewares = [createStateSyncMiddleware(stateSyncConfig)];

export const store = configureStore({
  reducer: {
    flightBooking: bookingDataReducer,
    user: userReducer,
    tourFilter: filterSlice,
    tabSlice: tabSlice,
    tourData: tourDataSlice,
    hotelData: hotelDataSlice,
    data: dataSlice,
    modal: modalSlice,
    overview: overviewSlice,
    template: persistedReducer,
    order: orderSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

initMessageListener(store);
