import { Button, Menu, MenuItem, Popover, Radio } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { URLdecode } from "../../../utils/url";
import { formatDate } from "../../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import {
  setTourCancelModal,
  setTourConfirmationPdfModal,
  setTourConfirmationPdfModalEmail,
} from "../../../redux/reducers/modalSlice";
import { useSnackbar } from "notistack";
import Loader from "../../../utils/loader";
import { useNavigate } from "react-router-dom";
import useDateFormat from "../../../hooks/useDateFormat";
import { useTours } from "../../../hooks/useTours";
import TourConfirmationPdfModal from "../../../components/modal/TourConfirmationPdfModal";
import LoadingModal from "../../../components/modal/LoadingModal";
import TourCancelModal from "../../../components/modal/TourCancelModal";

function TourOrderConfirmationPage() {
  const navigate = useNavigate();
  const { cancelAndQuote, confirmPackage } = useTours();
  const dispatch = useDispatch();
  const { tourConfirmationPdfModalSendingEmail } = useSelector(
    (state) => state.modal
  );
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const {
    travellersString,
    getIndividualDetails,
    getGroupDetails,
    getBooking,
  } = useTours();
  const { capitalizeFirstLetter } = useDateFormat();

  const params = URLdecode();
  const image = params?.image;
  const people = params?.people ? JSON.parse(params?.people) : [];
  const cancellationPolicy = params?.cancellationPolicy
    ? JSON.parse(params?.cancellationPolicy)
    : null;
  const voucher = params?.voucher ? JSON.parse(params?.voucher) : null;

  const [emailEnabled, setEmailEnabled] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const loadData = async () => {
    if (!params?.status || params?.status === "Booked") {
      await getBooking();
    }
    if (params?.status === "Paid") {
      await confirmPackage();
    }
  };

  useEffect(() => {
    localStorage.setItem("tourBookingId", params?.bookingId);
  }, []);

  useEffect(() => {
    loadData();
  }, [params?.status]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmail = () => {
    setEmailEnabled(true);
  };

  const handleExport = () => {
    dispatch(setTourConfirmationPdfModal(true));
  };

  const handleShare = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return enqueueSnackbar("Email is required.", { variant: "warning" });
    }

    if (!emailPattern.test(email)) {
      return enqueueSnackbar("Invalid email format.", { variant: "warning" });
    }

    dispatch(setTourConfirmationPdfModal(true));
    dispatch(setTourConfirmationPdfModalEmail(email));
  };

  const handleCancel = async () => {
    // dispatch(setTourCancelModal(true));
    handleClose();
    await cancelAndQuote();
  };
  const handleRefund = async () => {
    // dispatch(setTourCancelModal(true));
    handleClose();
    await cancelAndQuote();
  };
  const handleTicket = () => {
    handleClose();
    handleScrollToBottom();
  };

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className={Classname.container}>
      <LoadingModal />
      <TourCancelModal />
      <TourConfirmationPdfModal />
      <div className={Classname.content}>
        {/* Page title */}
        <div className={Classname.titleContainer}>
          {`Tours > `} <span className={Classname.titleMain}>Confirmation</span>
        </div>
        <div className={Classname.detail}>
          <div className={Classname.tourDetail}>
            <div className={Classname.tourDetailTitleContainer}>
              <span className="font-bold">Tour details</span>
              <div className={Classname.tourDetailTitleButtonContainer}>
                <button
                  className={Classname.tourDetailTitleExportButton}
                  onClick={handleExport}
                >
                  Export itinerary
                </button>
                <div className="">
                  <button
                    className={Classname.tourDetailTitleExportButton}
                    onClick={handleClick}
                  >
                    Manage order
                  </button>
                  {/* <Button
                    id="basic-button"
                    variant="text"
                    onClick={handleClick}
                    className={Classname.tourDetailTitleExportButton}
                  >
                    Manage order
                  </Button> */}
                  <Menu
                    id="basic-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleCancel}>Cancel order</MenuItem>
                    <MenuItem onClick={handleRefund}>Refund</MenuItem>
                    <MenuItem onClick={handleTicket}>Send ticket</MenuItem>

                    {voucher?.url && (
                      <MenuItem onClick={handleTicket}>
                        <a href={voucher?.url} target="_blank" rel="noreferrer">
                          Print Voucher
                        </a>
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
            <div className={Classname.tourNameContainer}>
              <div className={Classname.tourImageContainer}>
                <img src={image} alt="" className={Classname.tourImage} />
                {/* <span>gb Travels</span> */}
              </div>
              <div className={Classname.tourDetailContainer}>
                <span className={Classname.tourName}>{params?.title}</span>
                <div className={Classname.tourDetailLocationContainer}>
                  <img
                    src="/IconLocationGreen.svg"
                    alt=""
                    className={Classname.tourDetailLocationIcon}
                  />
                  <span>{capitalizeFirstLetter(params?.location)}</span>
                </div>
              </div>
            </div>
            <div className={Classname.dateContainer}>
              <div className={Classname.dateDetailContainer}>
                <div className={Classname.dateDetailDate}>
                  <span className="w-32">Departure date:</span>
                  <span className="font-bold">
                    {formatDate(params?.date)}
                  </span>{" "}
                </div>
                {params?.tourDuration && (
                  <div className={Classname.dateDetailDate}>
                    <span className="w-32">Tour duration:</span>
                    <span className="font-bold">
                      {params?.tourDuration}
                    </span>{" "}
                  </div>
                )}
                {params?.startTime && (
                  <div className={Classname.dateDetailDate}>
                    <span className="w-32">Start time:</span>
                    <span className="font-bold">{params?.startTime}</span>{" "}
                  </div>
                )}
              </div>
            </div>
            <div className={Classname.tagsContainer}>
              <div className={Classname.tag}>
                <img
                  src="/tourIconPerson.svg"
                  alt=""
                  className={Classname.tagIcon}
                />
                <span>{travellersString()}</span>
              </div>
            </div>
            <div className={Classname.orderTags}>
              {cancellationPolicy && (
                <div className={Classname.orderTag}>
                  <span className={Classname.orderTagTitle}>
                    Cancellation policy
                  </span>
                  <div className={Classname.orderTagDescription}>
                    <img
                      src="/IconCheckmark1.svg"
                      alt=""
                      className={Classname.orderTagIcon}
                    />
                    <span>{cancellationPolicy.description}</span>
                  </div>
                </div>
              )}
            </div>
            {people && people.length > 0 && (
              <span className="font-bold text-lg mb-6">Guest details</span>
            )}
            {getIndividualDetails()?.map((person, index) => (
              <div
                className="w-full flex flex-col bg-blue-600/10 rounded-lg px-2 py-3 mb-2"
                key={index}
              >
                <span className="font-bold">Traveler {index + 1}</span>

                <div className="w-full flex flex-wrap justify-between">
                  {person?.map((question, idx) => (
                    <div
                      className="flex items-center gap-2 lg:gap-4 text-sm lg:text-mdrounded-sm"
                      key={idx}
                    >
                      <span className="">{question?.question}:</span>
                      <span className="font-bold">{question?.answer}</span>{" "}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {getGroupDetails().length > 0 && (
              <div className="w-full flex flex-col bg-blue-600/10 rounded-lg px-2 py-3 mb-2">
                <span className="font-bold">Group</span>

                <div className="w-full flex flex-wrap justify-between">
                  {getGroupDetails()?.map((question, idx) => (
                    <div
                      className="flex items-center gap-2 lg:gap-4 text-sm lg:text-mdrounded-sm min-w-[300px]"
                      key={idx}
                    >
                      <span className="">{question?.question}:</span>
                      <span className="font-bold">{question?.answer}</span>{" "}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {emailEnabled ? (
              <div className="w-full flex flex-col">
                <div className={Classname.detailInputContainer}>
                  <span className={Classname.detailInputLabel}>
                    Enter order email*
                  </span>
                  <div className={Classname.detailInput}>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={Classname.detailInputBar}
                      placeholder="e.g xyz@gmail.coom"
                    />
                  </div>

                  {tourConfirmationPdfModalSendingEmail ? (
                    <Loader loading />
                  ) : (
                    <button
                      className={Classname.detailCTAButton}
                      onClick={handleShare}
                    >
                      Share
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <button className={Classname.emailButton} onClick={handleEmail}>
                <img src="/IconMailIcon.svg" alt="" />
                <span>Share via email</span>
              </button>
            )}
          </div>

          <div className={Classname.information}>
            <span className={Classname.informationTitle}>Tour Information</span>
            <span className={Classname.informationDateContainer}>
              Order date:{" "}
              <strong className={Classname.informationDate}>
                {formatDate(params?.bookingDate)}
              </strong>{" "}
            </span>
            <span>Status</span>
            <div
              className={
                params?.status === "Paid" || params?.status === "Issued"
                  ? Classname.informationStatus
                  : Classname.cancelledStatus
              }
            >
              {params?.status}
            </div>
            {/* <div className={Classname.informationProviderContainer}>
              <div className={Classname.informationProviderColumn}>
                <span className={Classname.informationTitle}>Provider</span>
                <div className={Classname.informationProviderInfo}>
                  <img
                    src={placeholderImage}
                    alt=""
                    className={Classname.informationProviderImage}
                  />
                  <span>gb Travels</span>
                </div>
              </div>
              <div className={Classname.informationProviderColumn1}>
                <span className={Classname.informationTitle}>Issuing Date</span>
                <span className={Classname.informationProviderInfo}>
                  09/04/2023
                </span>
              </div>
            </div> */}
            <span className="mt-4">Order ID</span>
            <span className="font-bold">{params?.bookId}</span>
            {/* <span className="mt-4">Ticket Number</span>
            <span className={Classname.informationShow}>show</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourOrderConfirmationPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",
  detail: "flex flex-col lg:flex-row w-full gap-10",
  tourDetail: "flex flex-col flex-1",
  tourDetailTitleContainer: "flex items-center justify-between w-full gap-8 ",
  tourDetailTitleButtonContainer: "gap-6 flex",
  tourDetailTitleExportButton:
    "h-12 px-4 rounded-md border-2 border-gray-300 font-bold mr-3",
  tourDetailTitleManageButton: "h-12 px-4 rounded-md bg-gray-300 font-bold",

  tourNameContainer:
    "flex flex-1 items-center mt-4 lg:mt-0 lg:gap-10 gap-2 pr-2 lg:pr-0",
  tourImageContainer:
    "flex flex-col lg:w-24 items-center gap-2 font-bold text-sm",
  tourImage: "w-full min-w-20 h-20 lg:h-24 object-cover",
  tourDetailContainer: "flex flex-col text-xs lg:text-md gap-3 lg:gap-0",
  tourName: "text-md lg:text-xl  font-bold",
  tourDetailLocationContainer: "gap-2 flex items-center",
  tourDetailLocationIcon: "",

  dateContainer: "flex w-full gap-4 mt-6",
  dateFigureContainer: "flex flex-col items-center py-1",
  datePoint: "w-3 h-3 rounded-full bg-primary1",
  dateLine: "border-r flex flex-1 border-primary1",
  dateDetailContainer: "flex flex-col gap-2",
  dateDetailDate: "flex items-center gap-2 lg:gap-4 text-sm lg:text-md ",
  dateDetailDuration: "text-xs lg:text-sm",

  tagsContainer: "flex items-center flex-wrap gap-4 mt-6",
  tag: "flex gap-2 items-center text-sm text-gray-500",
  tagIcon: "w-4 h-4 object-contain",

  orderTags: "flex flex-wrap w-full gap-6 mt-6 mb-4",
  orderTag: "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  orderTagTitle: "text-lg font-bold mb-1",
  orderTagDescription: "flex items-center gap-2 text-sm text-gray-600",
  orderTagIcon: "",

  touristPersonHeader:
    "flex flex-col lg:flex-row w-full justify-around lg:justify-between gap-4",
  touristPerson:
    "text-white bg-primary1 font-bold h-14 px-4 rounded-md flex items-center justify-center gap-2",
  touristPersonIcon: "",

  emailButton:
    "h-16 lg:h-24 w-full rounded-lg border-2 border-black text-black mt-5 lg:mt-10 lg:mb-20 flex items-center justify-center gap-2 font-bold",

  information:
    "flex flex-col flex-1 h-fit lg:max-w-md p-4 rounded-lg border border-gray-400 mb-10",
  informationTitle: "font-bold mt-2 mb-2",
  informationDateContainer: "flex gap-1 pb-3 border-b border-gray-300 mb-3",
  informationDate: "flex font-bold",
  informationStatus:
    "w-32 h-9 rounded-md border border-green-600 bg-green-600/10 text-green-600 flex items-center justify-center mt-2",
  cancelledStatus:
    "px-4 h-9 w-fit rounded-md border border-red-600 bg-red-600/10 text-red-600 flex items-center justify-center mt-2",
  informationProviderContainer: "flex flex-1 mt-2 mb-2 justify-between",
  informationProviderContainer: "flex flex-1 mt-2 mb-2 justify-between",
  informationProviderColumn: "flex flex-col",
  informationProviderColumn1: "flex flex-col items-end",
  informationProviderTitle: "",
  informationProviderInfo: "flex items-center gap-2 text-sm font-bold",
  informationProviderImage: "w-6 h-6 object-contain",
  informationShow: "underline text-primary1 underline-offset-2",
  belowSignin: "flex justify-around",

  detailInputContainer: "flex flex-col flex-1",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mt-4 mb-10",
};
