import React from 'react'
import Button1 from '../../form/Button1'

export default function Deals() {
  return (
    <div className='w-full pd-md'>
        <div className='rounded-lg card p-12 bg-orange-700/50 flex justify-between items-center py-20'>
            <div className='card p-10 rounded-lg flex flex-col gap-4'>
                <h5>Stay Closer to the culture</h5>
                <div>Members always get our best deals on city breaks.</div>
                <div className='self-start'>
                    <Button1>Unlock your deals</Button1>
                </div>
            </div>
            <div></div>
        </div>
    </div>
  )
}
