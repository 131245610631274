import React, { useEffect, useRef, useState } from "react";
import { Radio } from "@mui/material";
import FooterList from '../../../../components/templates/footers/footerList'
import { useDispatch, useSelector } from "react-redux";
import { setFooterStyle } from "../../../../redux/reducers/template/templateSlice";

const FooterStyle = () => {
  const state = useSelector((state) => state.template);
  const [scale, setScale] = useState(0.4);
  const iframeRefScale = useRef(null);
  const dispatch = useDispatch();
  const updateFooterStyle = (value) => {
    dispatch(setFooterStyle(value));  
  };
  let curHeight = window.innerHeight;
  const updateScale = () => {
      let screenWidth = window.innerWidth;
      if (iframeRefScale.current) {
        setScale((iframeRefScale.current.offsetWidth / screenWidth));
      }
  }
  useEffect(()=>{
    window.addEventListener('resize', updateScale)
    updateScale()
    return ()=>{
      window.removeEventListener('resize', updateScale)
    }
  },[])
  return (
    <div className="px-10 mt-10 col-span-3">
      <div>
        <h1 className="text-4xl font-BrFirmaBold font-bold my-0">
          {" "}
          Select a footer style
        </h1>
        <p className="font-BrFirmaRegular my-2">
          These logo, colors, and font will be used to design your site. You can
          always change them later.
        </p>
      </div>
      <div className="mt-10 overflow-hidden" ref={iframeRefScale}>
        <span className="font-poppins font-bold text-[#818083]">
          {" "}
          Select header style
        </span>
        <div className="grid grid-flow-row gap-4 mt-4">
        {Array.from({ length: 2 }, (_, index) => {
          const id = index + 1;
          return (
            <div
              className={`cursor-pointer ${
                state.footerStyle == String(id)
                  ? "bg-[#C4E9E3]"
                  : "bg-[#F8F8F8]"
              } p-2 rounded-md shadow-sm`}
              onClick={() => updateFooterStyle(String(id))}
            >
              <Radio
                size="small"
                color="success"
                checked={state.footerStyle == String(id) ? true : false}
              />
              <span className="mt-2 font-poppins text-sm font-bold text-[#2E2E32]">
                {String(id)}
              </span>
              <div className="mt-2 origin-top-left w-screen" style={{transform: `scale(${scale})`}}>
                <div className="pr-10">
                  <FooterList id={String(id)} />
                </div>
              </div>
            </div>
          )
        }
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterStyle;
