import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import TourManagementSidebar from "../../../components/sidebar/TourManagementSidebar";
import InfoCardLightening from "../../../components/management/InfoCardLightening";

function TourManagementPackageDescriptionPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [description, setDescription] = useState("");
  const [highlights, setHighlights] = useState([""]);

  const onContinue = () => {
    navigate("/tourManagement/create/location");
  };

  const onSave = () => {};

  const handleHightlightChange = (e, index) => {
    let detail = [...highlights];
    detail[index] = e.target.value;
    setHighlights(detail);
  };

  const handleAddHighlight = () => {
    setHighlights([...highlights, ""]);
  };

  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <div className="hidden lg:flex">
          <TourManagementSidebar selected={3} />
        </div>
        <div className={Classname.detail}>
          <span className={Classname.title}>Introduce your package</span>
          <p className={Classname.description}>
            Give the customer a taste of what they’ll do in 2 or 3 sentences.
            This will be the first thing customers read after the title, and
            will inspire them to continue.
          </p>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className={Classname.textarea}
            placeholder="Enter your details"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />
          <span className={Classname.title}>Add a full description</span>
          <p className={Classname.description}>
            Now, provide all the details about what the customer will see and
            experience during the activity, in the correct order. Bring the
            activity to life.
          </p>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className={Classname.textarea}
            placeholder="Enter your details"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <span className={Classname.title}>Summarize the highlights</span>
          <p className={Classname.description}>
            Write 3-5 sentences explaining what makes your activity special and
            stand out from the competition. Customers will use these to compare
            between different activities.
          </p>
          {highlights.map((hl, index) => (
            <input
              key={index}
              type="text"
              placeholder="Enter your details"
              value={hl}
              onChange={(e) => handleHightlightChange(e, index)}
              className={Classname.input}
            />
          ))}
          <span
            className={Classname.highlightButton}
            onClick={handleAddHighlight}
          >
            + Add more highlights
          </span>

          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourManagementPackageDescriptionPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  highlightButton:
    "font-bold flex items-center gap-2 cursor-pointer text-primary1 py-4",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
