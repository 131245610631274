import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react'
import Slider from 'react-slick'
import { useSelector } from "react-redux";

export default function Banner(props) {
    const {user,agent} = useSelector(state => state.user.userData);
    const crmData = (agent || user)?.detail?.crmData;
    
  return (
    <Slider className="flex flex-wrap gap-10 justify-between px-10 md:px-24 mb-10 mt-10 overflow-hidden"
        {...{
        dots: true,
        infinite: true,
        slidesToShow: props?.slidesToShow || 3,
        // slidesToScroll: 1,
        speed: 10000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: true,
        ...{props},
        }}
    >
        {crmData?.banners?.map((obj,i) => (
            <div key={i} className="w-full px-2">
                <div className="bg-[#D9D9D9] h-44 w-full max-w-full flex justify-center items-center">
                    <img src={obj?.image} className="w-full h-full object-cover" />
                    {/* <p>Banner here 486 X 243</p> */}
                </div>           
            </div>
        ))}
            {/* <div className="bg-[#D9D9D9] h-44 w-full max-w-full flex justify-center items-center">
                    <p>Banner here 486 X 243</p>
                </div>
                </div>
                <div className="w-full px-2">
                <div className="bg-[#D9D9D9] h-44 w-full max-w-full flex justify-center items-center">
                    <p>Banner here 486 X 243</p>
                </div>
                </div>
                <div className="w-full px-2">
                <div className="bg-[#D9D9D9] h-44 w-full max-w-full flex justify-center items-center">
                <p>Banner here 486 X 243</p>
            </div> */}
    </Slider>

  )
}
