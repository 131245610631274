import { useEffect, useRef } from "react";
import FooterList from "../../footers/footerList";
import AboutHeader from "../AboutHeader";
import WhoWeAre from "../WhoWeAre";
import WhatWeBelieve from "../WhatWeBelieve";
import CoreValues from "../CoreValues";
import BlogLink from "../BlogLink";
import { useSelector } from "react-redux";
import AboutCard1 from "../Card1";

export default function About({preview,noScroll}) {
	const {user,agent} = useSelector(state => state.user.userData);
	const ref = useRef();
	const data = (agent || user)?.detail?.crmData?.aboutUs;

	useEffect(() => {
		if(!noScroll)
		ref.current?.scrollIntoView({behavior: 'smooth'})
	},[])

	return ( 
		<div ref={ref}>
			<AboutHeader />

			<div className="flex flex-wrap-reverse gap-5 pd-md mt-10">
				<div className="flex justify-evenly flex-wrap gap-6 gap-y-32 flex-1">
					{data?.sections?.map((obj,i) => (
						<AboutCard1 key={i} obj={obj} />
					))}
				</div>
				{/* <div className="w-[450px] h-[240px] flex items-center justify-center bg-primary/10">
					Image
				</div> */}
			</div>
			<div className="bg-primary/5">
				<CoreValues data={data?.values} />
			</div>
			<BlogLink data={data?.blog} />
			<FooterList />
		</div>
	)
}