import { useLocation,} from "react-router-dom";
import FooterList from "../../footers/footerList";
import { useEffect, useRef, useState } from "react";
import ContactForm from "../ContactForm";
import WorkingHours from "../WorkingHours";
import ContactHeader from "../ContactHeader";

export default function Contact2({noScroll}) {
	const location = useLocation();
	const id = location.hash.slice(1)
	const [nav,setNav] = useState(id || 'query');

	const ref = useRef();

	useEffect(() => {
		if(!noScroll)
			ref.current?.scrollIntoView({behavior: 'smooth'})
	},[])

	useEffect(() => {
		setNav(id || 'query')
	},[id])

	return ( 
		<div ref={ref}>
			<ContactHeader />
			<div className="border-b flex justify-center">
				<div className="w-[600px] max-w-full flex gap-3">
					<a href='#query' className={`${nav === 'query' ? 'border-b-2' : ''} border-theme1 p-4 px-6`}>Send us your query</a>
					<a href='#hours' className={`${nav === 'hours' ? 'border-b-2' : ''} border-theme1 p-4 px-6`}>Working hours</a>
					{/* <Link to='#faq' className="border-b-2 border-theme1 p-4 px-6">FAQ</Link> */}
				</div>
			</div>
			<div className="flex gap-4 justify-evenly flex-wrap py-10">
				<ContactForm />
				<WorkingHours />
			</div>
			{/* <div className="flex justify-center">
			</div> */}
			<FooterList />
		</div>
	)
}