import React, { useState } from "react";
import CustomRadio from "../../../components/common/CustomRadio";
import TourManagementHeader from "../../../components/header/TourManagementHeader";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TourManagementOptionsSidebar from "../../../components/sidebar/TourManagementOptionsSidebar";

function TourManagementPackageOptionsSetupPage() {
  const navigate = useNavigate();
  const [selectedOptionPrivateActivity, setSelectedOptionPrivateActivity] =
    useState(null);
  const [selectedOptionGuideMaterials, setSelectedOptionGuideMaterials] =
    useState(null);
  const [selectedOptionWillSkip, setSelectedOptionWillSkip] = useState(null);
  const [selectedOptionWheelchair, setSelectedOptionWheelchair] =
    useState(null);
  const [selectedOptionActivityDuration, setSelectedOptionActivityDuration] =
    useState(null);
  const [selectedOptionTicketValidity, setSelectedOptionTicketValidity] =
    useState(null);

  const handleSelectPrivateActivity = (option) => {
    setSelectedOptionPrivateActivity(option);
  };
  const handleSelectGuideMaterials = (option) => {
    setSelectedOptionGuideMaterials(option);
  };
  const handleSelectWillSkip = (option) => {
    setSelectedOptionWillSkip(option);
  };
  const handleSelectWheelchair = (option) => {
    setSelectedOptionWheelchair(option);
  };
  const handleSelectActivityDuration = (option) => {
    setSelectedOptionActivityDuration(option);
  };
  const handleSelectTicketValidity = (option) => {
    setSelectedOptionTicketValidity(option);
  };

  const onContinue = () => {
    navigate("/tourManagement/create/meeting");
  };
  return (
    <div className={Classname.container}>
      <TourManagementHeader />
      <div className={Classname.content}>
        <TourManagementOptionsSidebar selected={1} />
        <div className={Classname.detail}>
          <div className={Classname.title}>Option setup</div>
          <div className="flex gap-1">
            <div className="font-bold">Option reference code </div>
            <span> (optional)</span>
          </div>
          <div className={Classname.description}>
            Provide a reference code to help you keep track of which option the
            customer has booked. This is for your own records and won’t be seen
            by the customer.
          </div>
          <input
            className={Classname.input}
            placeholder="Enter reference code"
          />
          <div className={Classname.title}>Option setup</div>
          <div className="font-bold mb-2">
            What languages does the guide speak during the activity? Choose all
            that apply.
          </div>
          <div className="w-80 mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={keyword}
                label="Language"
                // onChange={OnKeywordChange}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="font-semibold mt-2">Is this a private activity?</div>
          <div className={Classname.description}>
            This means that only one group or person can participate. There
            won’t be other customers in the same activity.
          </div>
          <CustomRadio
            label="No"
            value="no"
            selected={selectedOptionPrivateActivity}
            onSelect={handleSelectPrivateActivity}
          />
          <CustomRadio
            value="yes"
            label="Yes"
            selected={selectedOptionPrivateActivity}
            onSelect={handleSelectPrivateActivity}
          />
          {/* max group size */}
          <div className="font-semibold mt-2">Maximum group size</div>
          <div className={Classname.description}>
            What is the maximum total of people in your experience? This number
            includes people who book outside of Intraverse.
          </div>
          <div className="w-80 mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Group size</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={keyword}
                label="Group size"
                // onChange={OnKeywordChange}
              >
                <MenuItem value="10 people">10 people</MenuItem>
                <MenuItem value="20 people">20 people</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={Classname.title}>
            What guide materials do you provide in which languages? Choose all
            that apply. (optional)
          </div>
          <CustomRadio
            label="Audio guides and headphones"
            value="Audio"
            selected={selectedOptionGuideMaterials}
            onSelect={handleSelectGuideMaterials}
          />
          <CustomRadio
            value="booklets"
            label="Information booklets"
            selected={selectedOptionGuideMaterials}
            onSelect={handleSelectGuideMaterials}
          />
          {/* will customer skip */}
          <div className={Classname.title}>
            Will the customer skip the line to get in? If so, which line?
          </div>
          <CustomRadio
            label="No"
            value="no"
            selected={selectedOptionWillSkip}
            onSelect={handleSelectWillSkip}
          />
          <CustomRadio
            value="yes"
            label="Yes"
            selected={selectedOptionWillSkip}
            onSelect={handleSelectWillSkip}
          />
          {/* wheelchair accessible */}
          <div className={Classname.title}>
            Is the activity wheelchair accessible?
          </div>
          <CustomRadio
            label="No"
            value="no"
            selected={selectedOptionWheelchair}
            onSelect={handleSelectWheelchair}
          />
          <CustomRadio
            value="yes"
            label="Yes"
            selected={selectedOptionWheelchair}
            onSelect={handleSelectWheelchair}
          />
          <div className={Classname.title}>Duration or validity</div>
          <div className={Classname.description}>
            Some activities start and stop at specific times, like a tour.
            Others allow customers to use their ticket anytime within a certain
            amount of time, like a 2-day city pass.
          </div>
          <div className={Classname.title}>
            Which best describes your activity?
          </div>
          <CustomRadio
            value="specific"
            label="It lasts for a specific amount of time (duration)"
            description="Example: 3-hour guided tour"
            selected={selectedOptionActivityDuration}
            onSelect={handleSelectActivityDuration}
          />
          <div className="w-80 mb-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={keyword}
                label="Duration"
                // onChange={OnKeywordChange}
              >
                <MenuItem value="3 hour">3 hour</MenuItem>
                <MenuItem value="5 hour">5 hour</MenuItem>
              </Select>
            </FormControl>
          </div>
          <CustomRadio
            value="custom"
            label="Customers can use their ticket anytime during a certain period (validity)"
            description="Example: museum tickets that can be used anytime during opening hours"
            selected={selectedOptionTicketValidity}
            onSelect={handleSelectTicketValidity}
          />
          <div className={Classname.title}>Cut-off time</div>
          <div className={Classname.description}>
            The cut-off time is the latest you accept new bookings before the
            activity starts.
          </div>
          <div className={Classname.description}>
            Having a cut-off time of 30 minutes or fewer can lead to 50% more
            bookings.
          </div>
          <div className={Classname.title}>
            How far in advance do you stop accepting new bookings before the
            activity starts?
          </div>
          {/* TODO: MATERIAL UI DROPDOWN */}
          <div className={Classname.buttonsContainer}>
            <button className={Classname.saveButton}>Save and exit</button>
            <button className={Classname.continueButton} onClick={onContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TourManagementPackageOptionsSetupPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center lg:px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-1 pt-20",
  detail: "flex flex-col py-10 px-3 lg:px-6 w-full",
  title: "font-bold text-lg mt-6 mb-2",
  input: "flex w-full h-14 border border-gray-400 rounded-md px-4 mt-2",
  textarea:
    "flex w-full h-44 py-4 border border-gray-400 rounded-md px-4 mt-2 mb-6",
  description: "flex text-base mb-4 mt-2",
  buttonsContainer: "flex flex-1 justify-end gap-3 mt-10",
  optionButton:
    "font-bold w-fit flex items-center gap-2 cursor-pointer text-primary1 py-3 px-3 text-blue-500 border rounded-lg",
  saveButton:
    "h-12 px-6 flex items-center justify-center font-bold text-gray-700 border border-gray-500 rounded-md",
  continueButton:
    "h-12 px-6 flex items-center justify-center text-white font-bold bg-primary1 rounded-md",
};
