import React, { useEffect, useState } from "react";
import BusinessTypeChooseModal from "../modal/BusinessTypeChooseModal";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessTypeChooseModal } from "../../redux/reducers/modalSlice";
import { useNavigate } from "react-router-dom";

function GuideCTA() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user?.userData);
  const [tasks, setTasks] = useState([]);
  const [stage, setStage] = useState(0);
  const [guideStarted, setGuideStarted] = useState(false);

  const businessUnderReview = user?.detail?.requestedVerification;

  const load = () => {
    const activateBusiness = user?.detail?.isVerified;
    const logo = user?.guideStep === "logo";
    const link = user?.guideStep === "link";
    const markup = user?.guideStep === "markup";
    const team = user?.guideStep === "team";
    const subscription =
      user?.guideStep === "subscription" || user?.guideStep === "subscribe";
    const done = user?.guideStep === "done";

    if (team) return setStage(6);
    if (markup) return setStage(5);
    if (link) return setStage(4);
    if (logo) return setStage(3);
    if (subscription) return setStage(2);
    if (activateBusiness) return setStage(1);
    if (done) return setStage(7);
  };

  useEffect(() => {
    load();
  }, [user]);

  const handleButton = () => {
    switch (stage) {
      case 6:
        navigate("/");
        return;
      case 5:
        navigate("/gettingStarted/team");
        return;
      case 4:
        navigate("/gettingStarted/markups");
        return;
      case 3:
        navigate("/gettingStarted/link");
        return;
      case 2:
        navigate("/gettingStarted/logo");
        return;
      case 1:
        navigate("/gettingStarted/subscription");
        return;
      default:
        dispatch(setBusinessTypeChooseModal(true));
        return;
    }
  };

  if (user?.guideStep === "done") {
    return null;
  }

  if (businessUnderReview) {
    return (
      <span className="font-semibold text-green-400 text-sm lg:text-lg mt-4">
        Business under review
      </span>
    );
  }

  return (
    <div className="bg-[#EDEDED] flex items-center w-fit mt-4 pr-4 rounded-md">
      <BusinessTypeChooseModal />
      <div className="h-40 lg:h-12 flex w-3 bg-primary1 mr-3"></div>
      <div className="flex flex-col lg:flex-row items-center">
        <span className="text-sm lg:text-base">
          {user?.detail?.requestedVerification || user?.detail?.isVerified
            ? "Continue with the guide to setup your business"
            : " New to Intraverse? Try the 4 step guide to get started."}
        </span>
        <button
          onClick={handleButton}
          className="bg-[#FAFAFA] px-4 py-2 rounded-md w-full lg:w-fit lg:ml-7 cursor-pointer text-sm mt-3 lg:mt-0"
        >
          {user?.detail?.requestedVerification || user?.detail?.isVerified
            ? "Continue guide"
            : "Start your guide"}
        </button>
      </div>
    </div>
  );
}

export default GuideCTA;
