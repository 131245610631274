import React from 'react'
import { isCustomerSite } from '../../../../utils/getsubDomain'
import FlightBookDetails from './FlightBookDetails';
import FlightBookDetailsList from '../../../../components/templates/flight/FlightBookDetails/FlightBookDetailsList';

export default function FlightBookDetailsContainer() {
    const customer = isCustomerSite();
  return !customer ? (
    <FlightBookDetails />
  ) : <FlightBookDetailsList />
}
