import React from "react";
import WhiteLabelHeroSection from "../../sections/whitelabel/WhiteLabelHeroSection";
import WhiteLabelWaySection from "../../sections/whitelabel/WhiteLabelWaySection";
import WhiteLabelNeedSection from "../../sections/whitelabel/WhiteLabelNeedSection";
import WhiteLabelFeaturesSection from "../../sections/whitelabel/WhiteLabelFeaturesSection";

function WhiteLabel() {
  return (
    <div className="flex flex-col font-main">
      <WhiteLabelHeroSection />
      <WhiteLabelWaySection />
      <WhiteLabelNeedSection />
      <WhiteLabelFeaturesSection />
    </div>
  );
}

export default WhiteLabel;
