export default function hexToRGB(hex) {
    // Remove the # if it's present
    hex = hex.replace('#', '');
  
    // Convert the hex value to decimal
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    // Return the RGB values as a string
    return `${r}, ${g}, ${b}`;
  }