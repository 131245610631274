import React, { useState } from "react";
import GettingStartedStepTitle from "../../components/gettingStarted/GettingStartedStepTitle";
import GettingStartedVerticalLine from "../../components/gettingStarted/GettingStartedVerticalLine";
import GettingStartedFooter from "../../components/gettingStarted/GettingStartedFooter";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import GettingStartedLoadingModal from "../../components/modal/GettingStartedLoadingModal";
import { useDispatch, useSelector } from "react-redux";
import { setGettingStartedLoadingModal } from "../../redux/reducers/modalSlice";
import updateProfile from "../../controllers/user/updateProfile";
import { setUser } from "../../redux/reducers/userSlice";
import { ProfilePicture1 } from "../../components/form/ProfilePicture1";

function GettingStartedLogo() {
  const { user } = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [later, setLater] = useState(false);
  const [logo, setLogo] = useState(user?.detail?.agencyLogo || "");
  const [name, setName] = useState(user?.detail?.agencyName || "");
  const [subdomain, setSubdomain] = useState(
    user?.detail?.agencyURL
      ? `${user?.detail?.agencyURL?.split(".")[1]}.${
          user?.detail?.agencyURL?.split(".")[2]
        }`
      : ""
  );
  const { enqueueSnackbar } = useSnackbar();
  const [creating, setCreating] = useState(false);
  const [createLink, setCreateLink] = useState(
    user?.detail?.agencyName ? true : false
  );

  const handleContinue = async () => {
    dispatch(setGettingStartedLoadingModal(true));

    const formData = new FormData();
    formData.append("agencyLogo", logo);
    formData.append("guideStep", "logo");

    const res = await updateProfile(formData);
    dispatch(setGettingStartedLoadingModal(false));

    if (res.return) {
      if (res?.data) dispatch(setUser(res.data));
      navigate("/gettingStarted/link");
    } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
  };

  return (
    <div className="flex flex-col flex-1 w-full items-center">
      <div className="flex flex-col py-10 px-4 lg:px-10 flex-1 w-full max-w-7xl">
        <GettingStartedLoadingModal />
        <span className="text-[#818083] text-sm lg:text-base">Step 3</span>
        <span className="text-lg lg:text-3xl my-2">Setup your business</span>
        <span className="text-[#818083] text-sm lg:text-base mb-4 ">
          Pick a logo for your agency which will be used in all communications
        </span>
        <GettingStartedStepTitle visible active={logo} title={"Add a logo"} />
        <div className="mb-4"></div>
        <div className="flex gap-6">
          <GettingStartedVerticalLine height="h-[110px]" active={false} />
          <div className="flex flex-1 flex-col">
            <ProfilePicture1
              value={logo}
              onChange={(value) => {
                setLogo(value);
              }}
            />
          </div>
        </div>

        <div className="flex flex-col flex-1"></div>
        <GettingStartedFooter
          onContinue={handleContinue}
          continueActive={logo && (later || name)}
          onPrevious={() => navigate(-1)}
        />
      </div>
    </div>
  );
}

export default GettingStartedLogo;
