import React, { useEffect, useRef, useState } from 'react'
import TextInput from '../../../form/TextInput';


export default function BlogForm({user,obj,handleChange}) {
    const [data,setData] = useState(obj||{text:'',link: ''});
    const inputRefs = useRef([]);

    // useEffect(() => {
    //     inputRefs?.current?.map(elem => elem.addEventListener('blur',save))
    // },[inputRefs?.current?.length])

    function save() {
        // if (inputRefs.current?.every(elem => !elem?.contains(document.activeElement))) {
            handleChange && handleChange(data);
        // }
    }
        
    return (
        <div className='relative flex flex-col items-center'>
            <div className='flex gap-4 p-4 md:w-[70%]'>
                <div className='flex gap-2 flex-1'>
                    <div className='grow'>
                        <TextInput label='Blog Text' value={data?.text} onChange={(ev) => setData({...data,text: ev.target.value})} 
                            inputRef={(ref) => inputRefs.current[0] = ref}
                            onBlur={save}
                        />
                    </div>
                    <div className='flex-1'>
                        <TextInput label='Button Link' value={data?.link} onChange={(ev) => setData({...data,link: ev?.target?.value})} 
                            inputRef={(ref) => inputRefs.current[1] = ref}
                            onBlur={save}
                        />
                    </div>
                </div>
                {/* <div>
                    <Button1 onClick={save} variant='text'>Save</Button1>
                </div> */}
            </div>
        </div>
    )
}