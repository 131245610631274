import React from 'react'
import { isCustomerSite } from '../../../../utils/getsubDomain';
import SearchResults from '../../../../components/templates/searchResults/SearchResults';
import OffersList from './OffersList';

export default function OffersContainer() {
    const customer = isCustomerSite();

  return customer ? (
    <SearchResults />
  ) :
  <OffersList />
}
